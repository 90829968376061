import { StudentDocument } from "src/firebase/FirestoreClient";
import { DocumentDescription } from "./DocumentCard";

const DOCUMENT_SCHEMA: Record<StudentDocument, DocumentDescription> = {
  ssn: {
    label: "SSN Card",
    key: "ssn",
  },
  birth_certificate: {
    label: "Birth Certificate Or Passport",
    key: "birth_certificate",
  },
  driver_license: {
    label: "Driver's License",
    key: "driver_license",
  },
  driver_record: {
    label: "Driver's Record",
    key: "driver_record",
  },
  utility_bill: {
    label: "Utility Bill (Proof of Address)",
    key: "utility_bill",
    optional: true,
  },
  food_stamps: {
    label: "Proof of Public Assistance",
    key: "food_stamps",
    optional: true,
  },
  pay_stub: {
    label: "Bank Statement",
    key: "pay_stub",
    optional: true,
  },
  selective_services: {
    label: "Selective Services Registration",
    key: "selective_services",
    description: "Required for Males born after Jan 1, 1960",
    optional: true,
  },
  dislocated_worker: {
    key: "dislocated_worker",
    label: "Dislocated Worker",
    optional: true,
  },
};

export default DOCUMENT_SCHEMA;
