import { Alert, Grid, Stack } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SubmitButton from "src/components/Button/SubmitButton";
import RadioInput from "src/components/Input/RadioInput";
import TextInput from "src/components/Input/TextInput";
import { BankAccount } from "src/types/BankAccount";
import bankAccountValidator from "src/utils/bankAccountValidator";
import usRoutingNumberValidator from "src/utils/usRoutingNumberValidator";

type Props = {
  onSubmit: SubmitHandler<BankAccount>;
  defaultValues?: Partial<BankAccount>;
};

export default function BankAccountForm({ onSubmit, defaultValues }: Props) {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = useForm<BankAccount>({ defaultValues });

  const type = watch("type");
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4} marginBottom={6} marginX={{ md: 4 }}>
        <Alert severity="info">
          {t(
            "We'll use this information to reimburse you for training-related expenses!"
          )}
        </Alert>

        <RadioInput
          name="type"
          control={control}
          label={t("Routing Number")}
          items={[
            {
              value: "ach",
              label: "ACH",
            },
            {
              value: "cashapp",
              label: "Cash App",
            },
            {
              value: "venmo",
              label: "Venmo",
            },
          ]}
          rules={{ required: true }}
        />

        {type === "ach" && (
          <>
            <TextInput
              name="routingNumber"
              control={control}
              label="Routing Number"
              rules={{ required: true, validate: usRoutingNumberValidator }}
            />
            <TextInput
              name="accountNumber"
              control={control}
              label="Account Number"
              rules={{ required: true, validate: bankAccountValidator }}
            />
            <Alert severity="info">
              {t(
                "If you don't have a bank for ACH, we recommend you download an app like CashApp which gives you access to basic bank features."
              )}
              <br />
              <br />
              <a
                href="https://cash.app/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ paddingLeft: 0 }}
              >
                Go To Cash App
              </a>
            </Alert>
          </>
        )}

        {(type === "cashapp" || type === "venmo") && (
          <TextInput
            name="appUsername"
            control={control}
            label="Username - make sure it's correct!"
            rules={{ required: true }}
          />
        )}
      </Stack>
      <Grid container justifyContent="space-between">
        <SubmitButton disabled={isSubmitting} loading={isSubmitting}>
          {t("Submit")}
        </SubmitButton>
      </Grid>
    </form>
  );
}
