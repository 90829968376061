import { useQuery } from "@apollo/client";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import QuizCard from "src/components/Card/QuizCard";
import ResourceCard from "src/components/Card/TopicCard/ResourceCard";
import { Header, PaddedLayout } from "src/components/Layout";
import Template from "src/components/Layout/Template";
import { trackPage } from "src/utils";
import sortGraphqlItemAscOrder from "src/utils/sortGraphqlItemAscOrder";
import useErrorHandler from "src/utils/useErrorHandler";
import AppSkeleton from "../AppSkeleton";
import { GetResourcesByUnitDocument } from "./GetResourcesByUnit.generated";
import { GetUnitWithAssessmentsDocument } from "./GetUnitWithAssessments.generated";

type Props = { unitId: string };
export default function UnitView({ unitId }: Props) {
  const errorHandler = useErrorHandler();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();

  const { data: unitData, loading: unitDataLoading } = useQuery(
    GetUnitWithAssessmentsDocument,
    {
      variables: { unitId },
      onError: errorHandler,
    }
  );
  const { data: resourcesData, loading: resourcesDataLoading } = useQuery(
    GetResourcesByUnitDocument,
    {
      variables: { unitId },
      onError: errorHandler,
    }
  );

  const resources = resourcesData?.allResources || [];
  const unit = unitData?.unit;

  useEffect(() => {
    if (!unit) return;
    trackPage(unit.course.name, unit.name, {
      name: unit.name,
      course: unit.course.name,
    });
  }, [unit]);

  if (resourcesDataLoading || unitDataLoading) return <AppSkeleton />;
  if (!unit) throw new Error("Unit does not exist");

  // add progress
  // once module is complete, toast and navigate to the right person once they are done

  return (
    <Template>
      <Header
        title={unit.name}
        section={unit.course.name}
        onBack={() => navigate("/")}
      />
      {sortGraphqlItemAscOrder(resources).map((resource) => (
        <PaddedLayout key={`${resource.id}`} noXMargin>
          <ResourceCard resource={resource} />
        </PaddedLayout>
      ))}
      {unit.assessments.length ? (
        <PaddedLayout>
          <Typography>{t("Practice Exams")}</Typography>
          <Typography variant="body2" mb={theme.spacing(2)}>
            {t(
              "These are practice exams that resemble the official permit exam. Good luck!"
            )}
          </Typography>
          <Stack spacing={theme.spacing(2)}>
            {unit.assessments.map((exam) => (
              <Box id={exam.id}>
                <QuizCard quiz={exam} />
              </Box>
            ))}
          </Stack>
        </PaddedLayout>
      ) : (
        <div />
      )}
    </Template>
  );
}
