import { addDoc, collection, getDoc } from "firebase/firestore";
import Joi from "joi";
import { Reimbursement } from "src/types/Reimbursement";
import { UserAccount } from "src/types/User";
import isReimbursementGuard from "src/utils/isReimbursementGuard";
import serviceFunction from "src/utils/serviceFunction";
import cleanFirestoreDoc from "./cleanFirestoreDoc";
import generateFirestoreTimestamps from "./generateFirestoreTimestamps";
import uploadFile from "./uploadFile";

// Define the data required to create a reimbursement (omitting system-managed fields like timestamps and uid)
export type CreateReimbursementData = Pick<
  Reimbursement,
  "title" | "amount" | "milestoneId"
> & { user: UserAccount; file: File };

const schema = Joi.object<CreateReimbursementData>({
  user: Joi.object().required(),
  file: Joi.object().required(),
  milestoneId: Joi.string().optional(),
  title: Joi.string().required(),
  amount: Joi.number().required(),
});

const createReimbursement = serviceFunction(
  schema,
  async (
    { user, file, ...rest }: CreateReimbursementData,
    clients
  ): Promise<Reimbursement> => {
    // Extract the file extension
    const fileExtension = file.name.split(".").pop();
    if (!fileExtension)
      throw new Error("File must have an extension (e.g .png)");
    const key = `${rest.title}-${Date.now().toString()}.${fileExtension}`;
    const filePath = `documents/${user.uid}/reimbursements/${key}`;

    // Upload the file to Firebase Storage
    const uploadResult = await uploadFile(
      { url: filePath, blob: file },
      clients
    );

    const doc = await addDoc(
      collection(clients.db, `users/${user.uid}/reimbursements`),
      {
        ...rest,
        userId: user.uid,
        filePath: uploadResult.ref.fullPath,
        sentReimbursement: false,
        ...generateFirestoreTimestamps(new Date(), "createdAt"),
      }
    );

    const reimbursement = cleanFirestoreDoc(await getDoc(doc));

    // Ensure the document created matches the Reimbursement structure
    if (!isReimbursementGuard(reimbursement)) {
      throw new Error("Something went wrong while creating the reimbursement");
    }

    return reimbursement;
  }
);

export default createReimbursement;
