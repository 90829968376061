import { Box } from "@mui/material";
import React from "react";

type Props = {
  /**
   * Ratio of width over height, e.g. 3 / 2
   */
  ratio: number;
  children: React.ReactNode;
};

export default function AspectRatio({ ratio, children }: Props) {
  return (
    <Box
      sx={{
        height: 0,
        width: 1,
        overflow: "hidden",
        position: "relative",
      }}
      style={{ paddingTop: `${100 / ratio}%` }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          height: 1,
          width: 1,
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
