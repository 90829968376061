import { ButtonProps } from "@mui/material";
import { format, isValid, parseISO } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { UserAccount } from "src/types/User";
import formatNumberToE164 from "src/utils/formatNumberToE164";
import isValidSSN from "src/utils/isValidSSN";
import useUsersBackgroundsById from "src/utils/useUsersBackgroundsById";
import AdminOnlyButton from "./AdminOnlyButton";

type BackgroundCheckData = {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  email: string;
  phone: string;
  ssn: string;
  zipcode: string;
  licenseExpiredDate?: string;
  licenseNumber?: string;
  licenseState?: string;
  licenseIssueDate?: string;
};

type ExportProps = {
  users: UserAccount[];
} & ButtonProps;

function formatCheckrDate(dateString?: string) {
  if (!dateString) return "";

  if (isValid(parseISO(dateString))) {
    return format(parseISO(dateString), "yyyy-MM-dd");
  }

  return "INVALID DATE";
}

function formatSSN(ssn: string) {
  if (!isValidSSN(ssn)) return "";

  const cleanedSsn = ssn.replace(/\D/g, "");

  if (cleanedSsn.length !== 9)
    throw new Error("At least one SSN input is invalid");

  // Format the cleaned SSN to ###-##-####
  const formattedSsn = `${cleanedSsn.slice(0, 3)}-${cleanedSsn.slice(
    3,
    5
  )}-${cleanedSsn.slice(5)}`;

  return formattedSsn;
}

function formatZip(zip?: string) {
  if (!zip) return "";
  // Ensure the zip is treated as a string
  const zipString = String(zip).trim();

  // If zip is empty or not a valid number string, return an empty string
  if (!zipString.match(/^\d+$/)) return "";

  // Prepend single quote to force string format in CSV
  return `${zipString}`;
}

const generateCSV = (data: BackgroundCheckData[]) => {
  const header: string[] = [
    "first_name",
    "middle_name",
    "last_name",
    "date_of_birth",
    "ssn",
    "zip",
    "phone",
    "email",
    "driver_license_number",
    "driver_license_state",
    "driver_license_expiration_date",
    "driver_license_issue_date",
  ];

  const rows = data.map((user) =>
    [
      user.firstName,
      "",
      user.lastName,
      formatCheckrDate(user.dateOfBirth),
      formatSSN(user.ssn),
      formatZip(user.zipcode),
      formatNumberToE164(user.phone),
      user.email,
      user.licenseNumber,
      user.licenseState,
      formatCheckrDate(user.licenseExpiredDate),
      formatCheckrDate(user.licenseIssueDate),
    ].join(",")
  );

  return [header.join(","), ...rows].join("\n");
};

const downloadCSV = (csvContent: string) => {
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `background_check_export_${new Date().toISOString()}.csv`;
  link.click();
};

const ExportForBackgroundCheckButton = ({ users, ...rest }: ExportProps) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const backgroundsById = useUsersBackgroundsById(users);

  const exportToCSV = async () => {
    setLoading(true);

    const allUserData: BackgroundCheckData[] = await Promise.all(
      users.map(async (user) => {
        const background = backgroundsById[user.uid];
        const expireDate = background?.driverLicenseExpirationDate;
        const issueDate = background?.driverLicenseIssueDate;
        return {
          id: user.uid,
          firstName: user.firstName,
          lastName: user.lastName,
          dateOfBirth: user.dateOfBirth,
          email: user.email,
          phone: user.phone,
          ssn: background?.ssn || "",
          zipcode: background?.zipcode || "",
          licenseExpiredDate: isValid(expireDate)
            ? format(parseISO(expireDate as unknown as string), "yyyy-MM-dd")
            : "",
          licenseNumber: background?.driverLicenseNumber || "",
          licenseState: background?.driverLicenseState || "",
          licenseIssueDate: isValid(issueDate)
            ? format(parseISO(issueDate as unknown as string), "yyyy-MM-dd")
            : "",
        };
      })
    );

    const csvContent = generateCSV(allUserData);
    downloadCSV(csvContent);
    setLoading(false);
  };

  return (
    <AdminOnlyButton
      variant="contained"
      color="primary"
      onClick={exportToCSV}
      disabled={loading}
      {...rest}
    >
      {t("Export for Checkr")}
    </AdminOnlyButton>
  );
};

export default ExportForBackgroundCheckButton;
