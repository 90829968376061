import { ArrowBack } from "@mui/icons-material";
import { Box, Button, Grid, Stack } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SubmitButton from "src/components/Button/SubmitButton";
import FloatingVideo from "src/components/FloatingVideo";
import TextInput from "src/components/Input/TextInput";
import YesNoToggleInput from "src/components/Input/YesNoToggleInput";
import { SSN_VIDEO_EXPLANATION } from "src/utils/constants";
import isBoolean from "src/utils/isBoolean";
import isValidSSN from "src/utils/isValidSSN";
import LABEL_MAP from "../ClientScreen/BackgroundTab/OnboardingSurveyLabels";

export type SocialSecurityData = {
  hasSocialSecurityNumber: boolean;
  hasSocialSecurityCardAvailable: boolean;
  ssn: string;
};

type Props = {
  defaultValues: Partial<SocialSecurityData>;
  onSubmit: (data: SocialSecurityData) => void | Promise<void>;
  onBack?: () => void;
};

export default function SocialSecurityForm({
  defaultValues,
  onSubmit,
  onBack,
}: Props) {
  const { t } = useTranslation();
  const [showVideo, setShowVideo] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = useForm<SocialSecurityData>({
    mode: "onTouched",
    defaultValues,
  });

  const hasSocialSecurityNumber = watch("hasSocialSecurityNumber");

  return (
    <Box>
      <Button
        sx={{ paddingLeft: 0, marginLeft: 0, marginBottom: 4 }}
        onClick={() => setShowVideo(true)}
      >
        {t("Why do you need this?")}
      </Button>
      {showVideo && (
        <FloatingVideo videoUrl={SSN_VIDEO_EXPLANATION} playVideo={showVideo} />
      )}
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <Stack spacing={4} marginBottom={6}>
          <YesNoToggleInput
            name="hasSocialSecurityNumber"
            prompt={LABEL_MAP.hasSocialSecurityNumber}
            control={control}
            helperText={t(
              "Answer 'Yes' even if you don't remember it from memory. "
            )}
            rules={{
              validate: (value) =>
                isBoolean(value) || t("This field is required"),
            }}
          />
          {hasSocialSecurityNumber && (
            <>
              <YesNoToggleInput
                name="hasSocialSecurityCardAvailable"
                prompt={LABEL_MAP.hasSocialSecurityCardAvailable}
                control={control}
                rules={{
                  validate: (value) =>
                    isBoolean(value) || t("This field is required"),
                }}
                helperText={t(
                  "A physical card will be needed later on before you go to the DMV. If you don't have it, you can request a replacement from the Social Security Administration."
                )}
              />
              <TextInput
                control={control}
                name="ssn"
                prompt={LABEL_MAP.ssn}
                rules={{
                  validate: (value) =>
                    isValidSSN(value) ||
                    value === "" ||
                    value === undefined ||
                    t("Please enter a valid SSN"),
                }}
                helperText="We will need this to confirm your eligibility, but you can keep it blank if you do not remember it."
                required={hasSocialSecurityNumber}
              />
            </>
          )}
        </Stack>
        <Grid container justifyContent="space-between">
          {onBack && (
            <Button onClick={onBack}>
              <ArrowBack />
              &nbsp;
              {t("Back")}
            </Button>
          )}
          <SubmitButton loading={isSubmitting}>{t("Continue")}</SubmitButton>
        </Grid>
      </form>
    </Box>
  );
}
