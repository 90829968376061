import { Box, Typography } from "@mui/material";

export default function BackgroundInvestigationDisclosure() {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Disclosure Regarding Background Investigation
      </Typography>
      <Typography variant="body1" gutterBottom>
        Ameelio Emerge Public Benefit Corporation (DBA Emerge Career) (the
        “Company”) may obtain information about you from a third party consumer
        reporting agency for employment purposes. Thus, you may be the subject
        of a “consumer report” which may include information about your
        character, general reputation, personal characteristics, and/or mode of
        living. These reports may contain information regarding your credit
        history, criminal history, motor vehicle records (“driving records”),
        verification of your education or employment history, or other
        background checks.
      </Typography>
      <Typography variant="body1" gutterBottom>
        You have the right, upon written request made within a reasonable time,
        to request whether a consumer report has been run about you and to
        request a copy of your report. These searches will be conducted by
        Checkr, Inc., One Montgomery Street, Suite 2400, San Francisco, CA 94104
        | (844) 824-3257
        {/* |{" "} <Link
          href="https://app.emergecareer.com/help"
          target="_blank"
          rel="noopener noreferrer"
        >
          Help Center
        </Link>{" "}
        |{" "}
        <Link
          href="https://app.emergecareer.com/chat"
          target="_blank"
          rel="noopener noreferrer"
        >
          Candidate Portal
        </Link> . */}
      </Typography>
    </Box>
  );
}
