import { Tabs, TabsProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTabs = styled((props: TabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "75%",
    backgroundColor: "#ffffff",
  },
});

export default StyledTabs;
