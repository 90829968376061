import Dexie, { Table } from "dexie";
import { ResourceRecord } from "src/clients/schema";
import {
  QuizGrade,
  UpdateBookPayload,
  UpdateResourcePayload,
} from "src/firebase/FirestoreClient";

const DB_NAME = "emerge_db";

// TODO: once fully implemented, this should require the full resource
// i.e Partial

export type CachedResourceMetadata = Pick<
  ResourceRecord,
  "id" | "name" | "order" | "resourceType" | "url" | "duration" | "thumbnailUrl"
>;

export type CachedResource = CachedResourceMetadata & {
  cacheId?: number;
  blob: Blob;
};

type CachedAsset = { cacheId?: number; blob: Blob; url: string };

export type CachedOfflineActionType = "resource" | "quiz" | "book";

type CachedOfflineBookAction = { type: "book"; payload: UpdateBookPayload };
type CachedOfflineResourceAction = {
  type: "resource";
  payload: UpdateResourcePayload;
};
type CachedOfflineQuizAction = {
  type: "quiz";
  payload: Omit<QuizGrade, "uid">;
};

type CachedOfflineAction = {
  cacheId?: number;
  id: string;
} & (
  | CachedOfflineBookAction
  | CachedOfflineResourceAction
  | CachedOfflineQuizAction
);

// NOTE: to run a migration, you need update this number
const VERSION_NUMBER = 4;

export class CacheDatabase extends Dexie {
  resources!: Table<CachedResource, number>;

  assets!: Table<CachedAsset, number>;

  actions!: Table<CachedOfflineAction, number>;

  constructor() {
    super(DB_NAME);
    this.version(VERSION_NUMBER).stores({
      resources: "++cacheId, id, title, url, blob",
      assets: "++cacheId, &url, blob",
      books:
        "++cacheId, id, title, blob, author, type, fileBlob, thumbnailBlob",
      actions: "++cacheId, id, type, payload",
    });
  }

  /* Video */
  async removeVideo(cacheId: number) {
    return await this.transaction("rw", this.resources, () => {
      this.resources.delete(cacheId);
    });
  }

  async getVideo(url: string): Promise<CachedResource | undefined> {
    return await this.resources.get({ url });
  }

  // TODO: Bring download and asset calls here
  async addVideo(partial: Omit<CachedResource, "cacheId">) {
    // TODO: next step is to remove this completely from resources
    await this.upsertAsset({ url: partial.url, blob: partial.blob });

    return await this.transaction("rw", this.resources, () => {
      this.resources.add({
        ...partial,
      });
    });
  }

  /* Assets */
  async upsertAsset(asset: CachedAsset) {
    // const existing = await this.actions.where({ url: asset.url }).first();

    // if (existing)
    //   return this.transaction("rw", this.actions, () => {
    //     this.actions.update(existing.cacheId!, { ...asset });
    //   });

    return await this.transaction("rw", this.assets, () => {
      this.assets.add(asset);
    });
  }

  async getAsset(url: string) {
    return await this.assets.get({ url });
  }

  /* Remove Offline Action */
  async upsertOfflineAction(action: CachedOfflineAction) {
    const existing = await this.actions
      .where({ id: action.id, type: action.type })
      .first();

    // if exists, update
    if (existing) {
      return await this.transaction("rw", this.actions, () => {
        this.actions.update(existing.cacheId!, { ...action });
      });
    }

    // create
    return await this.transaction("rw", this.actions, () => {
      this.actions.add(action);
    });
  }

  async removeOfflineAction(cacheId: number) {
    return await this.transaction("rw", this.resources, () => {
      this.resources.delete(cacheId);
    });
  }

  // destructive
}

export const db = new CacheDatabase();

export async function purgeDatabase() {
  return await db.delete();
}
