import { Alert, Grid, Stack } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SubmitButton from "src/components/Button/SubmitButton";
import SliderInput from "src/components/Input/SliderInput";

export type JobPreferenceFormData = {
  canLift: number;
  canCrossStateLines: number;
  awayFromHome5Days: number;
  awayFromHome2Weeks: number;
};

type Props = {
  onSubmit: SubmitHandler<JobPreferenceFormData>;
  defaultValues?: Partial<JobPreferenceFormData>;
};

export default function JobPreferenceForm({ onSubmit, defaultValues }: Props) {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = useForm<JobPreferenceFormData>({ defaultValues });

  const canLift = watch("canLift");
  const canCrossStateLines = watch("canCrossStateLines");
  const awayFromHome5Days = watch("awayFromHome5Days");
  const awayFromHome2Weeks = watch("awayFromHome2Weeks");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4} marginBottom={6} marginX={{ md: 4 }}>
        <SliderInput
          name="canLift"
          control={control}
          label="Are you able and willing to lift more than 25 pounds of weight?"
        />
        {canLift <= 3 && (
          <Alert severity="warning">
            {t(
              "A lot of trucking jobs involve lifting and moving things like loading and unloading cargo."
            )}
          </Alert>
        )}

        <SliderInput
          name="canCrossStateLines"
          control={control}
          label="Can you cross state lines?"
        />
        {canCrossStateLines <= 3 && (
          <Alert severity="warning">
            {t("Many trucking jobs require crossing state lines.")}
          </Alert>
        )}

        <SliderInput
          name="awayFromHome5Days"
          control={control}
          label="Would you stay away from home for more than 5 days?"
        />
        {awayFromHome5Days <= 3 && (
          <Alert severity="warning">
            {t(
              "Regional trucking jobs usually have you on the road during the week, but you’ll be back home by the weekend."
            )}
          </Alert>
        )}
        <SliderInput
          name="awayFromHome2Weeks"
          control={control}
          label="Would you stay away from home for more than 2 weeks?"
        />
        {awayFromHome2Weeks <= 3 && (
          <Alert severity="warning">
            {t("Many trucking jobs require you to go over-the-road.")}
          </Alert>
        )}
      </Stack>
      <Grid container justifyContent="space-between">
        <SubmitButton disabled={isSubmitting} loading={isSubmitting}>
          {t("Continue")}
        </SubmitButton>
      </Grid>
    </form>
  );
}
