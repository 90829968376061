import { Box, Stack } from "@mui/material";
import Lottie from "lottie-react";
import IconLottie from "src/assets/Lotties/IconLottie.json";
import { BaseSkeleton, ListSkeleton } from "src/components/Skeleton/closet";

export default function AppSkeleton() {
  return (
    <Stack spacing={2} sx={{ position: "relative" }}>
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: 1000,
          border: "1px solid #ccc",
          padding: 60,
        }}
      >
        <Lottie
          animationData={IconLottie}
          style={{
            height: 40,
          }}
        />
      </Box>
      <BaseSkeleton height={120} />
      <Box component="main" sx={{ flexGrow: 1, marginLeft: 3 }}>
        <ListSkeleton />
      </Box>
    </Stack>
  );
}
