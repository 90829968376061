import { QuizRecord } from "src/clients/schema";
import { QuizGrade } from "src/firebase/FirestoreClient";
import { CompleteGrade } from "src/types";
import calculateAverage from "./calculateAverage";
import convertSnakeCaseToReadableString from "./convertSnakeCaseToReadableString";
import groupBy from "./groupBy";
import sortBy from "./sortBy";

export type CompleteGradeReport = {
  completeQuizGrades: CompleteGrade[];
  gradeGroups: Record<string, CompleteGrade[]>;
  gradeGroupAverages: Record<string, number | undefined>;
  totalRequiredQuizzes: number;
  totalCompletedRequiredQuizzes: number;
  // exams
  examsAverage?: number;
  examsTotalCompleted: number;
  examsTotal: number;
  // practice
  practiceQuizTotal: number;
  practiceQuizCompleted: number;
  practiceQuizAverage: number;
};

export default function preprocessQuizGrades(
  grades: QuizGrade[],
  allQuizzes: Pick<
    QuizRecord,
    "id" | "name" | "quizType" | "category" | "isRequired"
  >[]
): CompleteGradeReport {
  const completeQuizGrades = sortBy(allQuizzes, "name").map((quiz) => {
    const grade = grades.find((item) => item.quizId === quiz.id);
    return { ...grade, ...quiz };
  });

  // all exams

  const allExams = completeQuizGrades.filter(
    (quiz) => quiz.quizType === "exam"
  );
  const completedExams = allExams.filter((quiz) => !!quiz.grade);

  const examsAverage = calculateAverage(
    completedExams.map((val) => val.grade!)
  );

  const gradeGroups = groupBy(completeQuizGrades, (grade) => grade.category);

  const gradeGroupAverages: CompleteGradeReport["gradeGroupAverages"] = {};

  Object.entries(gradeGroups).forEach(([key, value]) => {
    const existingGrades = value.filter(
      (grade) => !!grade.grade && grade.quizType === "exam"
    );

    const average = calculateAverage(existingGrades.map((val) => val.grade!));
    const formattedKey = convertSnakeCaseToReadableString(key);
    gradeGroupAverages[formattedKey] = average;
  });

  const totalRequiredQuizzes = allQuizzes.filter(
    (quiz) => quiz.isRequired
  ).length;

  const totalCompletedRequiredQuizzes = completeQuizGrades.filter(
    (quiz) => quiz.grade && quiz.isRequired
  ).length;

  // practice quizzes
  const allPracticeQuizzes = completeQuizGrades.filter(
    (quiz) => quiz.quizType === "practice"
  );

  const completedPracticeQuizzes = allPracticeQuizzes.filter(
    (quiz) => quiz.grade !== undefined
  );

  const totalPracticeScore = completedPracticeQuizzes.reduce(
    (acc, quiz) => acc + quiz.grade!,
    0
  );

  const averagePracticeGrade =
    completedPracticeQuizzes.length > 0
      ? totalPracticeScore / completedPracticeQuizzes.length
      : 0;

  return {
    completeQuizGrades,
    gradeGroups,
    gradeGroupAverages,
    totalRequiredQuizzes,
    totalCompletedRequiredQuizzes,
    // exams
    examsAverage,
    examsTotalCompleted: completedExams.length,
    examsTotal: allExams.length,
    // practiceQuizzes
    practiceQuizTotal: allPracticeQuizzes.length,
    practiceQuizCompleted: completedPracticeQuizzes.length,
    practiceQuizAverage: averagePracticeGrade,
  };
}
