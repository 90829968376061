import { ArrowBack } from "@mui/icons-material";
import { Box, Button, Grid, Stack } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SubmitButton from "src/components/Button/SubmitButton";
import SelectInput from "src/components/Card/SelectInput";
import FloatingVideo from "src/components/FloatingVideo";
import DateInput from "src/components/Input/DateInput";
import TextInput from "src/components/Input/TextInput";
import USState from "src/types/states";
import { DRIVER_LICENSE_VIDEO_EXPLANATION } from "src/utils/constants";
import isValidDriverLicense from "src/utils/isValidDriverLicense";
import LABEL_MAP from "../ClientScreen/BackgroundTab/OnboardingSurveyLabels";

export type DriverLicenseData = {
  driverLicenseNumber?: string;
  driverLicenseState?: USState;
  driverLicenseIssueDate?: Date;
  driverLicenseExpirationDate?: Date;
};

type Props = {
  defaultValues: Partial<DriverLicenseData>;
  onSubmit: (data: DriverLicenseData) => void | Promise<void>;
  onBack: () => void;
};

// TODO: work on journey for person that does not know their driver's license number
// maybe prompt to tell us who can help us in their network
export default function DriverLicenseForm({
  defaultValues,
  onSubmit,
  onBack,
}: Props) {
  const { t } = useTranslation();
  const [showVideo, setShowVideo] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = useForm<DriverLicenseData>({
    mode: "onTouched",
    defaultValues,
  });

  const driverLicenseState = watch("driverLicenseState");

  return (
    <Box>
      <Button
        sx={{ paddingLeft: 0, marginLeft: 0, marginBottom: 4 }}
        onClick={() => setShowVideo(true)}
      >
        {t("Why do you need this?")}
      </Button>
      {showVideo && (
        <FloatingVideo
          videoUrl={DRIVER_LICENSE_VIDEO_EXPLANATION}
          playVideo={showVideo}
        />
      )}
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <Stack spacing={4} marginBottom={6}>
          <SelectInput
            name="driverLicenseState"
            prompt={LABEL_MAP.driverLicenseState}
            control={control}
            items={Object.entries(USState).map((entry) => ({
              value: entry[0],
              name: entry[1],
            }))}
            rules={{
              required: true,
              validate: (value) => !!value || t("The state is required"),
            }}
          />
          <DateInput
            control={control}
            name="driverLicenseIssueDate"
            prompt={LABEL_MAP.driverLicenseIssueDate}
            disableFuture
            disableHighlightToday
            helperText={t(
              "If the day is unknown, use the first day of the month. If the month or year are unknown, leave it blank."
            )}
          />
          <DateInput
            control={control}
            name="driverLicenseExpirationDate"
            prompt={LABEL_MAP.driverLicenseExpirationDate}
            helperText={t(
              "If the day is unknown, use the first day of the month. If the month or year are unknown, leave it blank."
            )}
            disableHighlightToday
          />
          {driverLicenseState && (
            <TextInput
              name="driverLicenseNumber"
              prompt={LABEL_MAP.driverLicenseNumber}
              control={control}
              rules={{
                validate: (value) =>
                  value && isValidDriverLicense(driverLicenseState, value),
              }}
            />
          )}
        </Stack>
        <Grid container justifyContent="space-between">
          <Button onClick={onBack}>
            <ArrowBack />
            &nbsp;
            {t("Back")}
          </Button>
          <SubmitButton loading={isSubmitting}>{t("Continue")}</SubmitButton>
        </Grid>
      </form>
    </Box>
  );
}
