import { Launch } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  List,
  ListItem,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ModalContainer from "src/components/ModalContainer";
import { UserAccount } from "src/types/User";
import groupBy from "src/utils/groupBy";
import ClientProfileLink from "./ClientProfileLink";

type BaseUser = Pick<UserAccount, "uid" | "firstName" | "lastName">;

type Props<T extends BaseUser> = {
  title: string;
  students: T[];
  groupByCallback: (student: T) => string;
};

const StatusSummaryCard = <T extends BaseUser>({
  title,
  students,
  groupByCallback,
}: Props<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const groupedData = groupBy(students, groupByCallback);
  const statuses = Object.entries(groupedData).map(([status, users]) => ({
    status,
    count: users.length,
    students: users,
  }));

  const total = statuses.reduce((acc, status) => acc + status.count, 0);

  return (
    <Box>
      <Card>
        <CardContent>
          <Stack spacing={1}>
            <Box>
              <Typography fontWeight="medium">{title}</Typography>
            </Box>
            <Typography variant="h4">{total}</Typography>
            <List>
              {statuses.map((status) => (
                <ListItem key={status.status}>
                  <Typography variant="body2">
                    {status.status}: {status.count}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Stack>
        </CardContent>
        <CardActions>
          <Button
            sx={{ marginLeft: "auto" }}
            onClick={() => setIsOpen(true)}
            startIcon={<Launch />}
          >
            {t("See more")}
          </Button>
        </CardActions>
      </Card>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <ModalContainer>
          <Card>
            <CardContent
              sx={{
                overflowY: "auto",
                height: "60vh",
                maxHeight: "60vh",
              }}
            >
              <Typography>{title}</Typography>
              <Typography color="text.secondary" variant="body2">
                {total} {t("results found")}
              </Typography>
              {statuses.map((status) => (
                <Box key={status.status} mb={2}>
                  <Typography variant="h6">{status.status}</Typography>
                  <List>
                    {status.students.map((student) => (
                      <ListItem key={student.uid}>
                        <ClientProfileLink client={student} />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              ))}
            </CardContent>
          </Card>
        </ModalContainer>
      </Modal>
    </Box>
  );
};

export default StatusSummaryCard;
