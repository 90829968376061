import { ArrowBack } from "@mui/icons-material";
import { Button, Grid, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SubmitButton from "src/components/Button/SubmitButton";
import YesNoToggleInput from "src/components/Input/YesNoToggleInput";
import isBoolean from "src/utils/isBoolean";
import LABEL_MAP from "../ClientScreen/BackgroundTab/OnboardingSurveyLabels";

export type MedicalData = {
  canPassVisionTest?: boolean;
  hasHistoryChronicalIllness?: boolean;
  isTakingMedication?: boolean;
};

type Props = {
  defaultValues: Partial<MedicalData>;
  onSubmit: (data: MedicalData) => void | Promise<void>;
  onBack: () => void;
};

export default function MedicalForm({
  defaultValues,
  onSubmit,
  onBack,
}: Props) {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<MedicalData>({
    mode: "onTouched",
    defaultValues,
  });

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <Stack spacing={4} marginBottom={6}>
        <YesNoToggleInput
          name="canPassVisionTest"
          prompt={LABEL_MAP.canPassVisionTest}
          control={control}
          helperText={t(
            "Wearing glasses doesn't make you ineligible; you'll simply take the test with them on."
          )}
          rules={{
            validate: (value) =>
              isBoolean(value) || t("This field is required"),
          }}
        />
        <YesNoToggleInput
          name="hasHistoryChronicalIllness"
          prompt={LABEL_MAP.hasHistoryChronicalIllness}
          helperText={t(
            "Common ones: heart conditions, epilepsy, vision and/or hearing loss etc."
          )}
          control={control}
          rules={{
            validate: (value) =>
              isBoolean(value) || t("This field is required"),
          }}
        />
        <YesNoToggleInput
          name="isTakingMedication"
          prompt={LABEL_MAP.isTakingMedication}
          control={control}
          helperText={t(
            "Common ones: marijuana, amphetamines, opioids (e.g methadone, codeine, hydrocodone), PC"
          )}
          rules={{
            validate: (value) =>
              isBoolean(value) || t("This field is required"),
          }}
        />
      </Stack>
      <Grid container justifyContent="space-between">
        <Button onClick={onBack}>
          <ArrowBack />
          &nbsp;
          {t("Back")}
        </Button>
        <SubmitButton loading={isSubmitting}>{t("Next")}</SubmitButton>
      </Grid>
    </form>
  );
}
