import { Timestamp } from "firebase/firestore";
import { ChatMessage, ChatMessageSenderType } from "./ChatMessage";

export default function isChatMessageGuard(
  value: unknown
): value is ChatMessage {
  if (typeof value !== "object" || value === null) return false;

  const obj = value as ChatMessage;

  return (
    typeof obj.uid === "string" &&
    typeof obj.createdAt === "string" &&
    obj.createdAtFirestoreTimestamp instanceof Timestamp &&
    typeof obj.participantId === "string" &&
    typeof obj.senderId === "string" &&
    Object.values(ChatMessageSenderType).includes(
      obj.senderType as ChatMessageSenderType
    ) &&
    typeof obj.text === "string"
  );
}
