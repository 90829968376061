import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Fade,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { lazy, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import Modal from "src/components/Modal";
import { trackEvent } from "src/utils";

const VideoPlayer = lazy(() => import("src/components/VideoPlayer"));

export type Testimonial = {
  name: string;
  uri: string;
  description: string;
  imgUri: string;
};

type Props = Testimonial;

const TestimonialCard = ({ name, uri, description, imgUri }: Props) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const { t } = useTranslation();

  const handleClick = () => {
    setOpen(true);
    trackEvent("Resources - Clicked on Testimonial", { name });
  };

  return (
    <Box>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Fade in={open}>
          <Box
            sx={{
              top: "50%",
              left: "50%",
              width: 500,
              position: "absolute",
              transform: "translate(-50%, -50%)",
            }}
          >
            <VideoPlayer url={uri} />
          </Box>
        </Fade>
      </Modal>
      <Card>
        <CardMedia
          component="img"
          image={imgUri}
          sx={{ height: 250 }}
          alt={`${name} headshot`}
        />
        <CardContent>
          <Stack spacing={theme.spacing(1)}>
            <div>
              <Typography variant="subtitle1">
                {t("{{firstName}}'s Reentry", { firstName: name })}
              </Typography>
            </div>
            <Typography variant="body1">{description}</Typography>
          </Stack>
        </CardContent>
        <CardActions>
          <Button variant="outlined" onClick={handleClick}>
            Watch
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default TestimonialCard;
