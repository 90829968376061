import { Box, Typography } from "@mui/material";
import { t } from "i18next";
import { ReactNode } from "react";
import { useForm } from "react-hook-form";
import SubmitButton from "src/components/Button/SubmitButton";
import CheckboxInput from "src/components/Input/CheckboxInput";
import TextInput from "src/components/Input/TextInput";
import { useCurrentUser } from "src/SessionBoundary";
import { ConsentType } from "src/types/Consent";
import { getFullName } from "src/utils";
import cleanAndNormalize from "src/utils/cleanAndNormalize";
import AcknowledgmentAuthorization from "./AcknowledgmentAuthorization";
import BackgroundInvestigationDisclosure from "./BackgroundInvestigationDisclosure";
import FCRAAgreement from "./FCRAAgreement";

export type ConsentFormData = {
  typedSignature?: string;
  consent: boolean;
};

type Props = {
  onSubmit: (
    data: ConsentFormData & {
      consentText: string;
      type: ConsentType;
      version: string;
    }
  ) => void;
  defaultValues?: ConsentFormData;
  type: ConsentType;
};

const legalSpecifications: Record<
  Props["type"],
  {
    agreement: ReactNode;
    consent: string;
    version: string;
    required: boolean;
    signature?: { message: string };
  } & ({ required: false; signature: { message: string } } | { required: true })
> = {
  fcra: {
    agreement: <FCRAAgreement />,
    version:
      "https://drive.google.com/file/d/1Z2lCVcDCSwJ4Z-FfuiHhTQuWiIiDVb_R/view?usp=sharing",
    consent: t(
      "I acknowledge receipt of the Summary of Your Rights Under the Fair Credit Reporting Act (FCRA) and certify that I have read and understand this document."
    ),
    required: true,
  },
  background_authorization: {
    agreement: <AcknowledgmentAuthorization />,
    version:
      "https://drive.google.com/file/d/1oW9C_abJLVbI8FwIycqXs4y5kiho4hDP/view?usp=sharing",
    consent: t(
      "Please check this box if you would like to receive a copy of a consumer report."
    ),
    required: false,
    signature: {
      message:
        "By typing my name below, I consent to a background check and indicate my agreement to all of the above. By typing my name below, I also agree that all communications, agreements, notices, documents and disclosures relating to my background check will be sent electronically. I understand that my electronic signature is as valid as a handwritten signature.",
    },
  },
  background_disclosure: {
    agreement: <BackgroundInvestigationDisclosure />,
    version:
      "https://drive.google.com/file/d/1zQHBreM2gGb8i_GGqmgmmw1PXnFtTYgi/view?usp=sharing",
    consent: t(
      "I acknowledge receipt of the Disclosure Regarding Background Investigation and certify that I have read and understand this document."
    ),
    required: true,
  },
  terms_of_service: {
    agreement: t(
      "By using this site, you agree to our Terms of Service and Privacy Policy."
    ),
    version: "v1",
    consent: t("I agree to the Terms of Service and Privacy Policy."),
    required: true,
  },
  privacy_policy: {
    agreement: t(
      "By using this site, you agree to our Terms of Service and Privacy Policy."
    ),
    version: "v1",
    consent: t("I agree to the Terms of Service and Privacy Policy."),
    required: true,
  },
};

export default function LegalConsentForm({
  defaultValues,
  onSubmit,
  type,
}: Props) {
  const user = useCurrentUser();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ConsentFormData>({
    defaultValues,
  });

  const specifications = legalSpecifications[type];

  const onSubmitHandler = handleSubmit((formData: ConsentFormData) => {
    onSubmit({
      ...formData,
      consentText: specifications.consent,
      type,
      version: specifications.version,
    });
  });

  return (
    <Box component="form" onSubmit={onSubmitHandler}>
      <Box
        sx={{
          maxHeight: 300,
          overflowY: "scroll",
          border: "1px solid #ccc",
          padding: 2,
          marginBottom: 2,
        }}
      >
        {specifications.agreement}
      </Box>
      <CheckboxInput
        control={control}
        name="consent"
        label={specifications.consent}
        rules={{
          validate: (value) =>
            !specifications.required ||
            value ||
            t("You must agree to the terms to continue."),
        }}
      />

      {specifications.signature && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "80%",
            marginTop: 4,
          }}
        >
          <Typography
            variant="body2"
            component="label"
            htmlFor="fullName"
            sx={{ marginBottom: 1 }}
          >
            {specifications.signature.message}
          </Typography>
          <TextInput
            control={control}
            rules={{
              validate: (value) => {
                if (!specifications.signature) return true;
                if (
                  value &&
                  cleanAndNormalize(value) ===
                    cleanAndNormalize(getFullName(user))
                ) {
                  return true;
                }
                return t(
                  "Your signature must match the name you submitted: {{fullName}}.",
                  { fullName: getFullName(user) }
                );
              },
            }}
            name="typedSignature"
          />
        </Box>
      )}
      <SubmitButton sx={{ marginTop: 4 }} loading={isSubmitting}>
        {t("Continue")}
      </SubmitButton>
    </Box>
  );
}
