import { useQuery } from "@apollo/client";
import { Box, Chip, Grid, Stack } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ExamCard from "src/components/Card/ExamCard";
import { Header, PaddedLayout } from "src/components/Layout";
import LoadingPage from "src/pages/LoadingPage";
import { CLP_ID } from "src/utils/constants";
import sortBy from "src/utils/sortBy";
import useErrorHandler from "src/utils/useErrorHandler";
import { GetMockExamsDocument } from "../GetMockExams.generated";

type ExamCategory =
  | "general_knowledge"
  | "combination_vehicles"
  | "air_brakes"
  | "pre_trip";

const examLabels: Record<ExamCategory, string> = {
  general_knowledge: "General Knowledge",
  combination_vehicles: "Combination Vehicles",
  air_brakes: "Air Brakes",
  pre_trip: "Pre Trip",
};

export default function ExamListScreen() {
  const errorHandler = useErrorHandler();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] =
    useState<ExamCategory>("general_knowledge");

  const { data } = useQuery(GetMockExamsDocument, {
    variables: { courseId: CLP_ID, category: selectedCategory },
    onError: errorHandler,
  });

  const exams = data?.allQuizzes;

  if (!exams) return <LoadingPage />;

  const sorted = sortBy(exams, (exam) =>
    parseInt(exam.name.trim().replace(/[^0-9]/g, ""), 10)
  );

  return (
    <Box>
      <Header title={t("Exam Practice")} onBack={() => navigate(-1)} />

      <PaddedLayout>
        <Stack direction="row" flexWrap="wrap" spacing={1} mb={4}>
          {Object.keys(examLabels).map((category) => (
            <Box
              key={category}
              onClick={() => setSelectedCategory(category as ExamCategory)}
              sx={{ cursor: "pointer" }}
            >
              <Chip
                label={examLabels[category as ExamCategory]}
                variant={selectedCategory === category ? "filled" : "outlined"}
              />
            </Box>
          ))}
        </Stack>

        <Grid container spacing={2} sx={{ overflowY: "auto" }}>
          {sorted.map((exam) => (
            <Grid item xs={12} md={6} key={exam.id}>
              <ExamCard exam={exam} />
            </Grid>
          ))}
        </Grid>
      </PaddedLayout>
    </Box>
  );
}
