import { Grid, useTheme } from "@mui/material";
import TestimonialCard, { Testimonial } from "./TestimonialCard";

export const TESTIMONIALS: Testimonial[] = [
  {
    name: "Florene",
    uri: "https://f004.backblazeb2.com/file/emerge-courses/videos/testimonials/Florene.mp4",
    description:
      "Florene shares how her new CDL career is helping her pursue her dreams.",
    imgUri:
      "https://f004.backblazeb2.com/file/emerge-courses/testimonials/Florene+Little.jpg",
  },
  {
    name: "Trevon",
    uri: "https://f004.backblazeb2.com/file/emerge-courses/videos/testimonials/Trevon.mp4",
    description:
      "Trevon shares how the program helped him finally get off the streets.",
    imgUri:
      "https://f004.backblazeb2.com/file/emerge-courses/testimonials/Dyshawn.jpg",
  },
];
const TestimonialList = () => {
  const theme = useTheme();
  return (
    <Grid container spacing={theme.spacing(4)}>
      {TESTIMONIALS.map((testimonial) => (
        <Grid item>
          <TestimonialCard {...testimonial} key={testimonial.name} />
        </Grid>
      ))}
    </Grid>
  );
};

export default TestimonialList;
