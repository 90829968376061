import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbarContext } from "src/SnackbarProvider";

type CopyButtonProps = {
  onSuccess?: () => void;
  onError?: (error: Error) => void;
};

export default function useCopyContent({
  onSuccess,
  onError,
}: CopyButtonProps) {
  const snackbarContext = useSnackbarContext();
  const { t } = useTranslation();

  const handleClick = useCallback(
    (content: string) => {
      navigator.clipboard
        .writeText(content)
        .then(() => {
          snackbarContext.alert("success", t("Copied to clipboard"));
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch((error) => {
          if (onError) {
            onError(error);
          }
        });
    },
    [onSuccess, onError, snackbarContext, t]
  );
  return handleClick;
}
