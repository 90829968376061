import { OpenInBrowser, VideocamOutlined } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { CohortMeeting } from "src/types/CohortMeeting";

type Props = {
  meeting: CohortMeeting;
};

function MeetingCard({ meeting }: Props) {
  const { t } = useTranslation();
  return (
    <Card>
      <CardContent>
        <Stack spacing={1} direction="row" alignItems="center">
          <VideocamOutlined color="warning" />
          <Typography fontWeight="semibold">{meeting.name}</Typography>
        </Stack>

        <Typography variant="body2" color="text.secondary">
          {format(new Date(meeting.startDate), "MMM d,  h:mm aaa")}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          onClick={() => window.open(meeting.meetingLink, "_blank")}
          startIcon={<OpenInBrowser />}
        >
          {t("Join Meeting")}
        </Button>
      </CardActions>
    </Card>
  );
}

export default MeetingCard;
