import { UserAccount } from "src/types/User";

export default function isStudentGuard(obj: unknown): obj is UserAccount {
  const student = obj as UserAccount;

  return (
    student &&
    typeof student === "object" &&
    typeof student.firstName === "string" &&
    // TODO: uncomment this, need to backfill data for students that were created before createbulkusers stored the email info
    // fix was introduced here: https://github.com/Emerge-Career/emerge-career-dev/pull/143
    // typeof student.email === "string" &&
    (typeof student.cohortId === "undefined" ||
      typeof student.cohortId === "string") &&
    typeof student.lastName === "string" &&
    typeof student.city === "string" &&
    typeof student.state === "string"
  );
}
