import { StudentReference } from "src/types/StudentReference";

export default function isStudentReferenceGuard(
  obj: unknown
): obj is StudentReference {
  const reference = obj as StudentReference;
  return (
    reference &&
    typeof reference === "object" &&
    typeof reference.uid === "string" &&
    typeof reference.type === "string" &&
    typeof reference.firstName === "string" &&
    typeof reference.lastName === "string" &&
    typeof reference.relationship === "string" &&
    typeof reference.phone === "string" &&
    typeof reference.userId === "string" &&
    typeof reference.createdAt === "string" &&
    (typeof reference.status === "undefined" ||
      typeof reference.status === "string")
  );
}
