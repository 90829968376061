import { Card, CardContent, useTheme } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

import { GetResourcesByUnitQuery } from "src/pages/UnitPage/GetResourcesByUnit.generated";
import QuizCard from "../QuizCard";
import ResourceLink from "./ResourceLink";

interface Props {
  resource: GetResourcesByUnitQuery["allResources"][0];
}

export default function ResourceCard({ resource }: Props) {
  const theme = useTheme();
  return (
    <Card>
      <CardContent>
        <Grid2 container spacing={theme.spacing(4)}>
          <Grid2
            xs={12}
            md={6}
            sx={[
              {
                md: {
                  borderRight: 2,
                  borderColor: (theme) => theme.palette.grey[200],
                },
              },
            ]}
          >
            <ResourceLink key={resource.id} resource={resource} />
          </Grid2>
          {resource.quiz && (
            <Grid2 xs={12} md={6} spacing={theme.spacing(2)}>
              <QuizCard quiz={resource.quiz} />
            </Grid2>
          )}
        </Grid2>
      </CardContent>
    </Card>
  );
}
