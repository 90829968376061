import { ref, uploadBytes, UploadResult } from "firebase/storage";
import Joi from "joi";
import { Clients } from "src/contexts/AppContext";
import serviceFunction from "src/utils/serviceFunction";

type Data = {
  url: string;
  blob: Blob;
};

const schema = Joi.object<Data>({
  url: Joi.string().required(),
  blob: Joi.object().required(),
});

const uploadFile = serviceFunction(
  schema,
  async ({ blob, url }, { storage }: Clients): Promise<UploadResult> => {
    const storageRef = ref(storage, url);
    return await uploadBytes(storageRef, blob);
  }
);

export default uploadFile;
