export default function stringToColor(string: string): string {
  let hash = 0;

  /* eslint-disable no-bitwise */
  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  const colors: number[] = [];

  for (let i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    colors.push(value);
  }

  // Check if the color is too light or not vivid enough
  while (
    Math.max(...colors) > 200 ||
    Math.max(...colors) - Math.min(...colors) < 50
  ) {
    hash = (hash << 5) - hash;
    for (let i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      colors[i] = value;
    }
  }

  let color = "#";
  for (let i = 0; i < 3; i += 1) {
    color += `00${colors[i].toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */
  return color;
}
