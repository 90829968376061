import { Timestamp } from "firebase/firestore";
import {
  StudentEmployerRelationship,
  StudentEmployerRelationshipStatus,
} from "../types/Employer";

export default function isStudentEmployerRelationshipGuard(
  obj: unknown
): obj is StudentEmployerRelationship {
  if (typeof obj !== "object" || obj === null) {
    return false;
  }

  const relationship = obj as StudentEmployerRelationship;
  return (
    typeof relationship.uid === "string" &&
    typeof relationship.createdAt === "string" &&
    relationship.createdAtFirestoreTimestamp instanceof Timestamp &&
    typeof relationship.lastUpdatedAt === "string" &&
    relationship.lastUpdatedAtFirestoreTimestamp instanceof Timestamp &&
    typeof relationship.status === "string" &&
    Object.values(StudentEmployerRelationshipStatus).includes(
      relationship.status
    )
  );
}
