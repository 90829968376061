import { Box, Grid, Stack } from "@mui/material";
import { useEffect } from "react";
import { isMobileOnly } from "react-device-detect";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SubmitButton from "src/components/Button/SubmitButton";
import CheckboxInput from "src/components/Input/CheckboxInput";
import PasswordInput from "src/components/Input/PasswordInput";
import TextInput from "src/components/Input/TextInput";
import Link from "src/components/Link";
import { useSnackbarContext } from "src/SnackbarProvider";
import removeStringSpaces from "src/utils/removeStringSpaces";
import isEmail from "src/utils/validations";

export type CredentialsStepData = {
  firstName: string;
  lastName: string;
  email: string;
  doesNotHaveEmail: boolean;
  password: string;
  passwordConfirmation: string;
};

type Props = {
  defaultValues: CredentialsStepData;
  onSubmit: (data: CredentialsStepData) => void | Promise<void>;
};

export default function CredentialSignupForm({
  defaultValues,
  onSubmit,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    getValues,
    formState: { isSubmitting },
    setValue,
    watch,
  } = useForm<CredentialsStepData>({
    mode: "onTouched",
    defaultValues,
  });
  const { alert } = useSnackbarContext();

  const isMatchingPassword = (value: string) => value === getValues("password");
  const doesNotHaveEmail = watch("doesNotHaveEmail");
  const firstName = watch("firstName");
  const lastName = watch("lastName");

  useEffect(() => {
    if (!doesNotHaveEmail) {
      setValue("email", defaultValues.email);
    }
    if (doesNotHaveEmail) {
      const normalizedFirstName = removeStringSpaces(firstName).toLowerCase();
      const normalizedLastName = removeStringSpaces(lastName).toLowerCase();
      setValue(
        "email",
        `${normalizedFirstName}.${normalizedLastName}@emergecareer.com`
      );
      alert("info", t("We are assigning you an Emerge email for the program."));
    }
  }, [
    doesNotHaveEmail,
    getValues,
    setValue,
    alert,
    t,
    defaultValues,
    firstName,
    lastName,
  ]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2} marginBottom={6}>
        <TextInput
          control={control}
          name="firstName"
          label={t("First name (legal)")}
          rules={{
            required: { value: true, message: t("First name is required") },
          }}
        />
        <TextInput
          control={control}
          name="lastName"
          label={t("Last name (legal)")}
          rules={{
            required: { value: true, message: t("Last name is required") },
          }}
        />
        <Box>
          <TextInput
            control={control}
            name="email"
            disabled={doesNotHaveEmail}
            label={t("Email address")}
            rules={{
              validate: (value) =>
                isEmail(value) || t("Please enter a valid email address"),
            }}
          />
          {!isMobileOnly && (
            <CheckboxInput
              control={control}
              name="doesNotHaveEmail"
              label={t("If you do NOT have an email address, check this box")}
            />
          )}
        </Box>

        <PasswordInput
          control={control}
          name="password"
          label={t("Password")}
          rules={{
            required: { value: true, message: t("Password is required") },
            minLength: 7,
          }}
          helperText={t(
            "Create a strong password with a mix of letters, numbers and symbols"
          )}
        />
        <PasswordInput
          control={control}
          name="passwordConfirmation"
          label={t("Confirm password")}
          rules={{
            required: true,
            validate: (value) =>
              isMatchingPassword(value) || t("Passwords must match"),
          }}
        />
      </Stack>
      <Grid container justifyContent="space-between" alignItems="center">
        <Link to="/login">{t("Sign in instead")}</Link>
        <SubmitButton loading={isSubmitting}>{t("Next")}</SubmitButton>
      </Grid>
    </form>
  );
}
