enum SocialBenefits {
  FoodStamps = "Food Stamps/EBT",
  TANF = "Temporary Assistance for Needy Families (TANF)",
  SSI = "Supplemental Security Income (SSI)",
  Disability = "Disability Benefits",
  WorkersComp = "Workers Compensation",
  Unemployment = "Unemployment Benefits",
  Medicaid = "Medicaid",
  Other = "Other",
}

export default SocialBenefits;
