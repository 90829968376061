import {
  Card,
  CardContent,
  CardProps,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

type Props = {
  title: string;
  subtitle?: string;
  value: string;
} & CardProps;

export default function StatisticsCard({
  title,
  value,
  subtitle,
  ...rest
}: Props): JSX.Element {
  const theme = useTheme();
  return (
    <Card {...rest}>
      <CardContent>
        <Stack>
          <Typography fontWeight="medium">{title}</Typography>
          <Typography variant="body2" color="text.secondary">
            {subtitle}
          </Typography>

          <Typography variant="h4" mt={theme.spacing(1)}>
            {value}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}
