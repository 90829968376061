import { Business, Delete, Edit, Person, Star } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardProps,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { lazy } from "react";
import "react-h5-audio-player/lib/styles.css";
import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import AdminOnlyButton from "src/components/Button/AdminOnlyButton";
import { EltoMetadata, Note } from "src/types/Note";
import { DOMAIN_URL } from "src/utils/constants";
import formatOptionalTimestamp from "src/utils/formatOptionalTimestamp";
import isNoteEltoMetadata from "src/utils/isNoteEltoMetadata";
import CopyButton from "./CopyButton";
import NoteCardIcon from "./NoteCardIcon";

const AudioPlayer = lazy(() => import("react-h5-audio-player"));

type Props = CardProps & {
  note: Pick<
    Note,
    | "uid"
    | "author"
    | "authorOrganization"
    | "type"
    | "assetUrl"
    | "date"
    | "description"
    | "userId"
    | "integrationMetadata"
  >;
  onEdit?: () => void;
  onDelete?: () => void;
  onView?: () => void;
  isPinned?: boolean;
  onPin?: () => void;
};

const NoteCard = ({
  note,
  onEdit,
  onDelete,
  onView,
  isPinned,
  onPin,
  ...rest
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  // Check if there's a voicemail or recording media
  const voicemailUrl =
    note.integrationMetadata?.source === "openphone" &&
    note.integrationMetadata.data.type === "call" &&
    note.integrationMetadata.data.payload.voicemail?.url
      ? note.integrationMetadata.data.payload.voicemail.url
      : undefined;

  const recording =
    note.integrationMetadata?.source === "openphone" &&
    note.integrationMetadata.data.type === "call" &&
    note.integrationMetadata.data.recording?.media &&
    note.integrationMetadata.data.recording.media.length > 0
      ? note.integrationMetadata.data.recording.media[0].url
      : undefined;

  return (
    <Card onClick={onView} sx={onView ? { cursor: "pointer" } : {}} {...rest}>
      <CardContent>
        <Stack spacing={theme.spacing(2)}>
          <Stack direction="row" justifyContent="space-between">
            <Stack
              spacing={theme.spacing(1)}
              direction="row"
              alignItems="center"
            >
              {onPin && (
                <IconButton
                  onClick={onPin}
                  color={isPinned ? "primary" : "default"}
                  size="small"
                  sx={{ cursor: "pointer" }}
                >
                  <Star />
                </IconButton>
              )}
              <NoteCardIcon type={note.type} />

              <Stack>
                <Typography fontWeight="medium">{note.type}</Typography>
                <Stack
                  direction="row"
                  spacing={theme.spacing(0.5)}
                  alignItems="center"
                >
                  <Person
                    fontSize="small"
                    sx={(theme) => ({ color: theme.palette.text.secondary })}
                  />
                  <Typography variant="body2" color="text.secondary">
                    {note.author}
                  </Typography>
                </Stack>

                <Stack
                  direction="row"
                  spacing={theme.spacing(0.5)}
                  alignItems="center"
                >
                  <Business
                    fontSize="small"
                    sx={(theme) => ({ color: theme.palette.text.secondary })}
                  />
                  <Typography variant="body2" color="text.secondary">
                    {note.authorOrganization}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Typography variant="body2">
                {formatOptionalTimestamp(note.date)}
              </Typography>
              <CopyButton
                text={`${DOMAIN_URL}/admin/clients/${note.userId}?tab=notes&id=${note.uid}`}
                iconType="share"
              />
              {onEdit && (
                <IconButton onClick={onEdit} data-testid="edit-button">
                  <Edit />
                </IconButton>
              )}
              {onDelete && (
                <Box>
                  <IconButton onClick={onDelete} data-testid="delete-button">
                    <Delete fontSize="small" />
                  </IconButton>
                </Box>
              )}
            </Stack>
          </Stack>
          <Typography>{note.description}</Typography>
          {note.integrationMetadata && (
            <Stack direction="row" alignItems="center" spacing={1}>
              {note.integrationMetadata.source === "elto" && (
                <Avatar
                  alt="Elto Avatar"
                  src="https://f004.backblazeb2.com/file/emerge-courses/thumbnails/logos/EltoIcon.jpeg"
                  sx={{ height: 25, width: 25, objectFit: "cover" }}
                />
              )}
              {note.integrationMetadata.source === "customer.io" && (
                <Avatar
                  alt="Customer.io avatar"
                  src="https://f004.backblazeb2.com/file/emerge-courses/thumbnails/logos/CustomerIoLogo.jpeg"
                  sx={{ height: 25, width: 25, objectFit: "cover" }}
                />
              )}

              {note.integrationMetadata.source === "openphone" && (
                <Avatar
                  alt="Open phone avatar"
                  src="https://f004.backblazeb2.com/file/emerge-courses/thumbnails/logos/OpenPhoneLogo.png"
                  sx={{ height: 25, width: 25, objectFit: "cover" }}
                />
              )}
              <Typography color="text.secondary" fontStyle="italic">
                {t("Auto-generated")}
              </Typography>
            </Stack>
          )}
          {voicemailUrl && <AudioPlayer src={voicemailUrl} />}
          {recording && <AudioPlayer src={recording} />}
        </Stack>
      </CardContent>
      <CardActions>
        {note.assetUrl && (
          <Button
            variant="outlined"
            onClick={() => window.open(note.assetUrl, "_blank")}
          >
            {t("View Attachment")}
          </Button>
        )}
        {isNoteEltoMetadata(note.integrationMetadata) && (
          <AdminOnlyButton
            variant="outlined"
            onClick={() =>
              window.open(
                // TODO: figure out why type narrowing is not working
                `https://app.elto.ai/workspaces/17b92919-2e9b-46da-84a1-884fd030c2c7/dials/${(
                  note.integrationMetadata!.data as EltoMetadata
                ).dialId!}`,
                "_blank"
              )
            }
          >
            {t("View on Elto")}
          </AdminOnlyButton>
        )}
      </CardActions>
    </Card>
  );
};

export default NoteCard;
