import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";
import { School } from "src/types/School";
import isSchoolGuard from "src/utils/isSchoolGuard";
import cleanFirestoreDoc from "./cleanFirestoreDoc";

interface SchoolFilters {
  ids?: string[];
  // Add more filters as needed
}

export default async function fetchSchools(
  { db }: Clients,
  filters: SchoolFilters = {}
): Promise<School[]> {
  const { ids } = filters;

  if (ids && ids.length > 0) {
    const schoolPromises = ids.map((id) => getDoc(doc(db, "schools", id)));
    const snapshots = await Promise.all(schoolPromises);
    const schools = snapshots
      .map((snapshot) => cleanFirestoreDoc(snapshot))
      .filter(isSchoolGuard);
    return schools;
  }

  const schoolQuery = collection(db, "schools");
  const snapshot = await getDocs(schoolQuery);
  const schools = snapshot.docs.map(cleanFirestoreDoc).filter(isSchoolGuard);
  return schools;
}
