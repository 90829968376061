import { Modal as MUIModal, ModalProps } from "@mui/material";
import React from "react";

type Props = ModalProps & { children: React.ReactNode[] | React.ReactNode };

const Modal = ({ children, ...props }: Props) => (
  <MUIModal {...props}>{children}</MUIModal>
);

export default Modal;
