import { Timestamp } from "firebase/firestore";

export enum AmericanTimezone {
  Eastern = "America/New_York",
  Central = "America/Chicago",
  Mountain = "America/Denver",
  Pacific = "America/Los_Angeles",
  Alaska = "America/Anchorage",
  Hawaii = "America/Adak",
}

export type CohortMeeting = {
  uid: string;
  name: string;
  startDate: string;
  startDateFirestoreTimestamp: Timestamp;
  durationMinutes: number;
  cohortId: string;
  meetingLink: string;
  timezone: AmericanTimezone;
};
