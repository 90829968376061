import { UserAccount, UserOnboardingStatus } from "src/types/User";

export default function isUserAccountDeactivated(
  user: Pick<
    UserAccount,
    "onboardingStatus" | "applicationStatus" | "enrollmentStatus"
  >
) {
  return (
    user.onboardingStatus === UserOnboardingStatus.GLOBAL_EXIT ||
    user.onboardingStatus === UserOnboardingStatus.DROPPED ||
    user.onboardingStatus === UserOnboardingStatus.REJECTED ||
    user.onboardingStatus === UserOnboardingStatus.UNSUCCESSFUL_EXIT ||
    user.applicationStatus === "dropped" ||
    user.applicationStatus === "rejected" ||
    user.applicationStatus === "inactive" ||
    user.applicationStatus === "withdrawn" ||
    user.enrollmentStatus === "justifiable_exit" ||
    user.enrollmentStatus === "unsuccessful_exit"
  );
}
