import { useEffect, useState } from "react";

const useStorageStats = () => {
  const [usage, setUsage] = useState<number>();
  const [quota, setQuota] = useState<number>();

  async function calculateStorage() {
    if (navigator.storage && navigator.storage.estimate) {
      const storage = await navigator.storage.estimate();
      setUsage(storage.usage);
      setQuota(storage.quota);
    }
  }

  useEffect(() => {
    window.addEventListener("storage", calculateStorage);

    (async () => await calculateStorage())();

    return () => {
      window.removeEventListener("storage", () => {});
    };
  }, []);

  return {
    usage,
    quota,
    remaining: usage && quota ? quota - usage : undefined,
    usagePct: usage && quota ? usage / quota : undefined,
  };
};

export default useStorageStats;
