import { differenceInDays } from "date-fns";
import { StudentPlanMilestone } from "src/types/StudentPlan";
import { UserAccount } from "src/types/User";
import {
  SupportTier,
  UserWithPriorityDescription,
} from "./CoachSegmentPriorityCard";
import determineEngagementRisk from "./determineEngagementRisk";

interface SplitUsersOptions {
  getOverdueMilestones: (
    user: UserAccount
  ) => StudentPlanMilestone[] | undefined;
  getUpcomingMilestones: (
    user: UserAccount
  ) => StudentPlanMilestone[] | undefined;
  getEngagementRatioByUser: (user: UserAccount) => number | undefined;
  getLessonProgress: (user: UserAccount) => number | undefined;
  getExamProgress: (user: UserAccount) => number | undefined;
}

export default function splitUsersByPriority(
  users: UserAccount[],
  options: SplitUsersOptions
) {
  const {
    getOverdueMilestones,
    getUpcomingMilestones,
    getEngagementRatioByUser,
    getLessonProgress,
    getExamProgress,
  } = options;
  const today = new Date();

  const groups = {
    jobSearching: [] as UserWithPriorityDescription[],
    skillsTraining: [] as UserWithPriorityDescription[],
    advanced: [] as UserWithPriorityDescription[],
    beginners: [] as UserWithPriorityDescription[],
    newcomers: [] as UserWithPriorityDescription[],
    practiceExams: [] as UserWithPriorityDescription[],
    readyToTest: [] as UserWithPriorityDescription[],
  };

  const addToGroup = (
    group: keyof typeof groups,
    user: UserAccount,
    desc: string,
    tier: SupportTier = "healthy"
  ) => {
    groups[group].push({ ...user, description: desc, tierOfSupport: tier });
  };

  users.forEach((user) => {
    const overdueMilestones = getOverdueMilestones(user) || [];
    const lessonProgress = getLessonProgress(user);
    const lastStatusUpdate = user.applicationStatusLastUpdatedAt
      ? new Date(user.applicationStatusLastUpdatedAt)
      : undefined;
    const engagementRatio = getEngagementRatioByUser(user);
    const upcomingMilestones = getUpcomingMilestones(user) || [];

    if (
      user.employmentStatus === "job_searching" ||
      user.enrollmentStatus === "licensed"
    ) {
      addToGroup(
        "jobSearching",
        user,
        "Actively looking for jobs or licensed."
      );
      return;
    }

    const isSkillsTraining =
      user.enrollmentStatus &&
      ["skills_training", "completed_skills_training"].includes(
        user.enrollmentStatus
      );

    if (isSkillsTraining || user.applicationStatus === "enrolled") {
      addToGroup(
        "skillsTraining",
        user,
        isSkillsTraining ? "In skills training" : "Ready to enroll."
      );
      return;
    }

    if (lastStatusUpdate && differenceInDays(today, lastStatusUpdate) <= 7) {
      const tier =
        engagementRatio && engagementRatio <= 1 ? "attention" : "healthy";
      const desc = tier === "attention" ? "Low engagement ratio" : "Healthy.";
      addToGroup("newcomers", user, desc, tier);
      return;
    }

    const riskData = determineEngagementRisk({
      lastLoggedIn: user.lastLoggedIn,
      overdueMilestones,
      upcomingMilestones,
      engagementRatio,
    });

    const examsCompleted = getExamProgress(user);

    if (!lessonProgress || lessonProgress <= 15) {
      addToGroup(
        "beginners",
        user,
        riskData.description,
        riskData.tierOfSupport
      );
    } else if (lessonProgress < 20 || !examsCompleted) {
      addToGroup(
        "advanced",
        user,
        riskData.description,
        riskData.tierOfSupport
      );
    } else if (examsCompleted <= 14) {
      addToGroup(
        "practiceExams",
        user,
        riskData.description,
        riskData.tierOfSupport
      );
    } else {
      addToGroup(
        "readyToTest",
        user,
        riskData.description,
        riskData.tierOfSupport
      );
    }
  });

  return groups;
}
