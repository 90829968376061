import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { InputAdornment } from "@mui/material";
import React, { ForwardedRef, useState } from "react";
import Button from "../Button";
import withController from "../withController";
import { TextInputBase, TextInputProps } from "./TextInput";

type ManagedProps = "type";
type PasswordInputProps = Omit<TextInputProps, ManagedProps>;

function PasswordInput(
  props: PasswordInputProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const [hide, setHide] = useState(true);
  const { value } = props;

  return (
    <TextInputBase
      {...props}
      type={hide ? "password" : "text"}
      ref={ref}
      InputProps={{
        endAdornment: value ? (
          <InputAdornment position="end">
            <Button
              aria-label="toggle password visibility"
              onClick={() => setHide(!hide)}
            >
              <RemoveRedEyeIcon />
            </Button>
          </InputAdornment>
        ) : null,
      }}
    />
  );
}

export const PasswordInputBase = React.forwardRef(
  PasswordInput
) as typeof PasswordInput;

export default withController(PasswordInputBase);
