import { doc, setDoc } from "firebase/firestore";
import Joi from "joi";
import { Clients } from "src/contexts/AppContext";
import { OrganizationDocument } from "src/types";
import { UserAccount, UserUploadedDocument } from "src/types/User";
import serviceFunction from "src/utils/serviceFunction";
import generateFirestoreTimestamps from "./generateFirestoreTimestamps";
import uploadFile from "./uploadFile";

type Data = {
  user: UserAccount;
  organizationDocument: OrganizationDocument;
  file: File;
};

const schema = Joi.object<Data>({
  user: Joi.object().required(),
  organizationDocument: Joi.object().required(),
  file: Joi.object().required(),
});

const createUserUploadedOrganizationDocument = serviceFunction(
  schema,
  async ({ user, organizationDocument, file }, clients: Clients) => {
    if (!user.organizationId)
      throw new Error("User does not belong to an organization");

    // Generate a unique file URL

    const key = `organization-${user.organizationId}-document-${organizationDocument.uid}`;
    const filePath = `documents/${user.uid}/${key}`;

    // Upload the file to Firebase Storage
    const uploadResult = await uploadFile(
      { url: filePath, blob: file },
      clients
    );

    // Create the document metadata
    const documentData: Omit<UserUploadedDocument, "uid"> = {
      stage: organizationDocument.stage,
      path: uploadResult.ref.fullPath,
      userId: user.uid,
      organizationDocumentId: organizationDocument.uid,
      name: organizationDocument.name,
    };

    // Add the document metadata to Firestore
    await setDoc(doc(clients.db, "users", user.uid, "documents", key), {
      ...documentData,
      ...generateFirestoreTimestamps(new Date(), "createdAt"),
    });

    return {
      ...documentData,
      uid: key,
    };
  }
);

export default createUserUploadedOrganizationDocument;
