import { Box, Skeleton, Tab, Tabs } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { TextbookChapterRecord, TextbookRecord } from "src/clients/schema";

type Item = Pick<TextbookChapterRecord, "id" | "startPage" | "endPage"> & {
  textbook: Pick<TextbookRecord, "title" | "url" | "id">;
};

type Props = {
  chapters: Item[];
};

const ResourceScreenTextbooks = ({ chapters }: Props) => {
  const [selectedTextbook, setSelectedTextbook] = useState<string>();

  useEffect(() => {
    if (chapters.length)
      setSelectedTextbook((tab) => tab || chapters[0].textbook.id);
  }, [chapters]);

  const selectedChapter = useMemo(
    () => chapters.find((chapter) => chapter.textbook.id === selectedTextbook),
    [selectedTextbook, chapters]
  );

  if (!chapters.length) return <div />;
  return (
    <Box>
      <Tabs value={selectedTextbook}>
        {chapters.map((chapter) => (
          <Tab
            key={chapter.id}
            label={chapter.textbook.title}
            value={chapter.textbook.id}
            onClick={() => setSelectedTextbook(chapter.textbook.id)}
          />
        ))}
      </Tabs>
      {selectedChapter ? (
        <Box>
          {/* TODO: wire this up as an epub viewer */}
          {/* <TextbookViewer
            url={selectedChapter.textbook.url}
            startPage={selectedChapter.startPage}
            endPage={selectedChapter.endPage}
          /> */}
        </Box>
      ) : (
        <Skeleton />
      )}
    </Box>
  );
};

export default ResourceScreenTextbooks;
