import { useCallback, useEffect, useState } from "react";
import { useAppContext } from "src/contexts/AppContext";
import fetchTimelineEventsCount from "src/firebase/fetchTimelineEventsCount";
import { UserAccount } from "src/types/User";
import useErrorHandler from "src/utils/useErrorHandler";

export const ENGAGEMENT_TIME_HORIZON = 7; // The time horizon for engagement (in days)

interface UseTimelineEventCountResult {
  loading: boolean;
  getActionsCountByUser: (user: UserAccount) => number | undefined;
  getEngagementRatioByUser: (user: UserAccount) => number | undefined;
}

export default function useTimelineEventCountByUserId(
  users: UserAccount[]
): UseTimelineEventCountResult {
  const [loading, setLoading] = useState(true); // Loading state
  const [actionsCountByStudent, setActionsCountByStudent] = useState<
    Record<string, number | undefined>
  >({});
  const { clients } = useAppContext();
  const errorHandler = useErrorHandler();

  useEffect(() => {
    if (!users || users.length === 0) {
      setLoading(false); // Stop loading if there are no users
      return;
    }

    const fetchActionsCountData = async () => {
      setLoading(true);

      const clientEventCounts = await Promise.allSettled(
        users.map(async (client) => {
          const count = await fetchTimelineEventsCount(
            client.uid,
            clients,
            ENGAGEMENT_TIME_HORIZON
          );
          return { count, client };
        })
      );

      const actionsCountData: Record<string, number> = {};
      clientEventCounts
        .filter(
          (
            result
          ): result is PromiseFulfilledResult<{
            count: number;
            client: UserAccount;
          }> => result.status === "fulfilled"
        )
        .forEach(({ value }) => {
          actionsCountData[value.client.uid] = value.count;
        });

      setActionsCountByStudent(actionsCountData);
      setLoading(false); // Set loading to false after data is fetched
    };

    fetchActionsCountData().catch((error) => {
      errorHandler(error);
      setLoading(false); // Set loading to false in case of error
    });
  }, [users, clients, errorHandler]);

  // Getter function for retrieving actions count by user ID
  const getActionsCountByUser = useCallback(
    (user: UserAccount): number | undefined => actionsCountByStudent[user.uid],
    [actionsCountByStudent] // Recompute only when actionsCountByStudent changes
  );

  // useCallback function to return engagement ratio for a user
  const getEngagementRatioByUser = useCallback(
    (user: UserAccount): number | undefined => {
      const actionsCount = getActionsCountByUser(user);
      if (actionsCount === undefined) return undefined;

      // Engagement ratio: actions taken divided by the engagement time horizon
      return actionsCount / ENGAGEMENT_TIME_HORIZON;
    },
    [getActionsCountByUser] // Recompute only when getActionsCountByUserId changes
  );

  return {
    loading,
    getActionsCountByUser,
    getEngagementRatioByUser,
  };
}
