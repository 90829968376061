import { OrganizationStaff } from "src/types";

export default function isOrganizationStaffGuard(
  obj: unknown
): obj is OrganizationStaff {
  const staff = obj as OrganizationStaff;
  return (
    typeof staff?.uid === "string" &&
    typeof staff?.firstName === "string" &&
    typeof staff?.lastName === "string" &&
    typeof staff?.organizationId === "string" &&
    typeof staff?.phone === "string" &&
    typeof staff?.email === "string"
  );
}
