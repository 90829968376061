import { ArrowBack } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "./Button";

type Props = { onBack?: () => void; label?: string };
export default function BackHomeButton({ onBack, label }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Button
      startIcon={<ArrowBack />}
      onClick={() => (onBack ? onBack() : navigate("/"))}
    >
      {label || t("Back to Home")}
    </Button>
  );
}
