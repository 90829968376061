import { Box, Stack, useTheme } from "@mui/material";
import { isMobileOnly } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LogoWhite } from "src/assets/LogoWhite.svg";
import LogoutButton from "src/components/Button/LogoutButton";

const Menu = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      sx={{
        backgroundColor: theme.palette.primary.dark,
        padding: theme.spacing(2),
        borderBottom: 1,
        borderColor: theme.palette.grey[400],
      }}
      justifyContent="center"
      alignItems="center"
    >
      <Box
        onClick={() => navigate("/")}
        sx={{
          cursor: "pointer",
          alignSelf: "flex-end",
        }}
      >
        <Stack direction="row">
          <LogoWhite width={240} style={{ maxWidth: "100%" }} />
        </Stack>
      </Box>
      {!isMobileOnly && (
        <Box marginLeft="auto">
          <LogoutButton />
        </Box>
      )}
    </Stack>
  );
};

export default Menu;
