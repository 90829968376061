import { OrganizationDocument, trainingStages } from "src/types";

export default function isOrganizationDocument(
  obj: unknown
): obj is OrganizationDocument {
  const organizationDocument = obj as OrganizationDocument;

  return (
    typeof obj === "object" &&
    obj !== null &&
    typeof organizationDocument.uid === "string" &&
    typeof organizationDocument.name === "string" &&
    typeof organizationDocument.description === "string" &&
    (organizationDocument.fileUri === undefined ||
      typeof organizationDocument.fileUri === "string") &&
    trainingStages.includes(organizationDocument.stage) &&
    typeof organizationDocument.organizationId === "string" &&
    (organizationDocument.externalUrl === undefined ||
      typeof organizationDocument.externalUrl === "string") &&
    (organizationDocument.optional === undefined ||
      typeof organizationDocument.optional === "boolean")
  );
}
