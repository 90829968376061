import Button, { ButtonProps as BaseProps } from "./Button";

type ManagedProps = "type";
type Props = Omit<BaseProps, ManagedProps> & {
  loading: boolean;
};

export default function SubmitButton({ loading, children, ...rest }: Props) {
  return (
    <Button
      {...rest}
      type="submit"
      size={rest.size || "large"}
      variant={rest.variant || "contained"}
      disabled={rest.disabled || loading}
    >
      {children}
      {loading && "..."}
    </Button>
  );
}
