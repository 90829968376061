import { t } from "i18next";
import { UserAccount, UserOnboardingStatus } from "src/types/User";

export const trainingLabels = [
  "Admissions",
  "Online Training",
  "Skills Training",
  "Job Search",
  "On the Job",
] as const;
export type TrainingLabel = typeof trainingLabels[number];

export default function getCurrentTrainingStep(
  user: Pick<
    UserAccount,
    | "applicationStatus"
    | "employmentStatus"
    | "onboardingStatus"
    | "enrollmentStatus"
  >
): TrainingLabel {
  if (
    user.employmentStatus === "employed" ||
    user.employmentStatus === "conditional_offer" ||
    user.onboardingStatus === UserOnboardingStatus.EMPLOYED
  ) {
    return t("On the Job");
  }

  // Job search
  if (
    user.employmentStatus ||
    user.enrollmentStatus === "licensed" ||
    user.onboardingStatus === UserOnboardingStatus.LICENSED
  ) {
    return "Job Search";
  }

  // Skills training
  if (
    user.enrollmentStatus === "skills_training" ||
    user.enrollmentStatus === "completed_skills_training" ||
    user.onboardingStatus === UserOnboardingStatus.RANGE ||
    user.onboardingStatus === UserOnboardingStatus.GRADUATED
  ) {
    return t("Skills Training");
  }

  // Enrollment status
  if (
    user.applicationStatus === "enrolled" ||
    user.enrollmentStatus === "theory_training" ||
    user.enrollmentStatus === "completed_theory_training" ||
    user.onboardingStatus === UserOnboardingStatus.COMPLETED_THEORY ||
    user.onboardingStatus === UserOnboardingStatus.ENROLLED
  ) {
    return t("Online Training");
  }

  if (user.applicationStatus) {
    return t("Admissions");
  }
  return t("Admissions"); // Default step
}
