import { addDoc, collection, getDoc } from "firebase/firestore";
import Joi from "joi";
import { Consent, consentTypes } from "src/types/Consent";
import isConsentGuard from "src/types/isConsentGuard";
import { UserAccount } from "src/types/User";
import serviceFunction from "src/utils/serviceFunction";
import cleanFirestoreDoc from "./cleanFirestoreDoc";
import generateFirestoreTimestamps from "./generateFirestoreTimestamps";

export type CreateConsentData = {
  user: UserAccount;
} & Pick<Consent, "type" | "typedSignature" | "consentText" | "version">;

const schema = Joi.object<CreateConsentData>({
  user: Joi.object().required(),
  type: Joi.string()
    .valid(...consentTypes)
    .required(),
  typedSignature: Joi.string().optional(),
  consentText: Joi.string().required(),
  version: Joi.string().required(),
});

const createConsent = serviceFunction(
  schema,
  async ({ user, ...data }, { db }): Promise<Consent> => {
    const now = new Date();

    const consentData = {
      ...data,
      ...generateFirestoreTimestamps(now, "consentGivenAt"),
      userId: user.uid,
    };

    const docRef = await addDoc(
      collection(db, "users", user.uid, "consents"),
      consentData
    );
    const consent = cleanFirestoreDoc(await getDoc(docRef));

    if (!isConsentGuard(consent)) {
      throw new Error("Error creating consent.");
    }

    return consent as Consent;
  }
);

export default createConsent;
