import { collection, getCountFromServer } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";

export default async function fetchLessonProgressCount(
  clientId: string,
  { db }: Clients
): Promise<number> {
  const resourceCollection = collection(db, "users", clientId, "resources");
  const snapshot = await getCountFromServer(resourceCollection);

  return snapshot.data().count;
}
