import { Alert, Snackbar } from "@mui/material";
import { AlertProps } from "@mui/material/Alert";
import React, { createContext, useContext, useMemo, useState } from "react";

export type SnackbarContextType = {
  alert: (
    severity: AlertProps["severity"],
    message: string,
    disableAutoHide?: boolean
  ) => void;
  close: () => void;
};

const SnackbarContext = createContext<SnackbarContextType>(
  {} as SnackbarContextType
);

export function useSnackbarContext(): SnackbarContextType {
  return useContext(SnackbarContext);
}

export default function SnackbarProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [open, setOpen] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");
  const [currentSeverity, setCurrentSeverity] =
    useState<AlertProps["severity"]>("info");
  const [autoHideOn, setAutoHideOn] = useState(true);

  function handleClose() {
    setAutoHideOn(true);
    setOpen(false);
  }

  const snackbarContext = useMemo(
    (): SnackbarContextType => ({
      alert: (severity, message, disableAutoHide) => {
        setAutoHideOn(!disableAutoHide);
        setCurrentSeverity(severity);
        setCurrentMessage(message);
        setOpen(true);
      },
      close: () => {
        handleClose();
      },
    }),
    []
  );

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={autoHideOn ? 6000 : null}
        onClose={() => handleClose()}
      >
        <Alert severity={currentSeverity}>{currentMessage}</Alert>
      </Snackbar>
      <SnackbarContext.Provider value={snackbarContext}>
        {children}
      </SnackbarContext.Provider>
    </>
  );
}
