import { Box, Typography } from "@mui/material";
import { StudentDocument } from "src/firebase/FirestoreClient";

type Props = {
  key: StudentDocument;
};

export default function DefaultEnrollmentDocumentDescription({ key }: Props) {

    
  switch (key) {
    case "resume":
      return null; // No children for "resume"
    case "ssn":
      return null; // No children for "ssn"
    case "birth_certificate":
      return (
        <Box>
          <Typography
            variant="body2"
            sx={{ textDecoration: "underline" }}
            color="text.secondary"
          >
            Instructions
          </Typography>
          <ul>
            <li>
              <Typography variant="body2" color="text.secondary">
                - Birth Certificate
              </Typography>
            </li>
            <li>
              <Typography variant="body2" color="text.secondary">
                - Passport
              </Typography>
            </li>
            <li>
              <Typography variant="body2" color="text.secondary">
                - Work Permit
              </Typography>
            </li>
          </ul>
        </Box>
      );
    case "driver_license":
      return (
        <Box>
          <Typography variant="body2" color="text.secondary">
            An active driver&apos;s license is required for you to become a CDL
            driver. You cannot have pending fees/fines on your license.
          </Typography>
        </Box>
      );
    case "driver_record":
      return (
        <Box>
          <Typography
            variant="body2"
            sx={{ textDecoration: "underline" }}
            color="text.secondary"
          >
            Instructions
          </Typography>
          <Typography variant="body2" color="text.secondary">
            For CA, you can request your online driving record by{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.dmv.ca.gov/portal/customer-service/request-vehicle-or-driver-records/online-driver-record-request/"
            >
              clicking here
            </a>
            .
          </Typography>
        </Box>
      );
    case "utility_bill":
      return null; // No children for "utility_bill"
    case "food_stamps":
      return (
        <Box>
          <Typography
            variant="body2"
            sx={{ textDecoration: "underline" }}
            color="text.secondary"
          >
            Instructions
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Any of the documents below will satisfy the requirement:
          </Typography>
          <ul>
            <li>
              <Typography variant="body2" color="text.secondary">
                - Food Stamps (SNAP) Eligibility Verification
              </Typography>
            </li>
            <li>
              <Typography variant="body2" color="text.secondary">
                - Copy of Authorization to Receive Food Stamps
              </Typography>
            </li>
            <li>
              <Typography variant="body2" color="text.secondary">
                - TANF Eligibility Verification
              </Typography>
            </li>
            <li>
              <Typography variant="body2" color="text.secondary">
                - SSI/SDI Receipt of Benefits Verification
              </Typography>
            </li>
            <li>
              <Typography variant="body2" color="text.secondary">
                - Medicaid Card
              </Typography>
            </li>
          </ul>
        </Box>
      );
    case "pay_stub":
      return (
        <Box>
          <Typography
            variant="body2"
            sx={{ textDecoration: "underline" }}
            color="text.secondary"
          >
            Instructions
          </Typography>
          <Typography variant="body2" color="text.secondary">
            This is <b>not</b> required if you received public assistance over
            the past months OR are currently experiencing homelessness.
          </Typography>
          <br />
          <Typography variant="body2" color="text.secondary">
            Otherwise, we need a bank statement showing your income to be
            eligible for a low-income scholarship.
          </Typography>
        </Box>
      );
    case "selective_services":
      return (
        <Box>
          <Typography
            variant="body2"
            sx={{ textDecoration: "underline" }}
            color="text.secondary"
          >
            Instructions
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Required for Males born after Jan 1, 1960. Upload a screenshot of
            the Selective Services Verification portal.{" "}
            <a
              href="https://www.sss.gov/verify/"
              rel="noreferrer"
              target="_blank"
            >
              Click here to access the website
            </a>
          </Typography>
        </Box>
      );
    case "dislocated_worker":
      return (
        <Box>
          <Typography
            variant="body2"
            sx={{ textDecoration: "underline" }}
            color="text.secondary"
          >
            Instructions
          </Typography>
          <Typography variant="body2" color="text.secondary">
            In case you are experiencing a layoff, upload one of the following
            to support your application:
          </Typography>
          <ul>
            <li>
              <Typography variant="body2" color="text.secondary">
                - Notice of Layoff
              </Typography>
            </li>
            <li>
              <Typography variant="body2" color="text.secondary">
                - Verification from Employer
              </Typography>
            </li>
            <li>
              <Typography variant="body2" color="text.secondary">
                - Termination Letter
              </Typography>
            </li>
          </ul>
        </Box>
      );
    default:
      return <div />;
  }
}
