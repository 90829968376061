import safeCapitalizeInitials from "./safeCapitalizeInitials";

export * from "./Tracking";
export function capitalizeInitial(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getFullName(user: {
  firstName: string;
  lastName: string;
}): string {
  return safeCapitalizeInitials(`${user.firstName} ${user.lastName}`);
}

export function shuffle<T>(original: T[]): T[] {
  const array = [...original];
  let currentIndex = array.length;
  let randomIndex = 0;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export function normalizeStr(str: string): string {
  return str.toLowerCase().trim();
}

export function unique<T>(value: T, index: number, self: T[]): boolean {
  return self.indexOf(value) === index;
}

export async function timeout(
  time: number,
  promise: Promise<unknown>
): Promise<unknown> {
  return await new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error("Request timed out."));
    }, time);
    promise.then(resolve, reject);
  });
}
