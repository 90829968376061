import {
  Call,
  Comment,
  Dangerous,
  Flag,
  PriorityHigh,
  Textsms,
} from "@mui/icons-material";
import { Box } from "@mui/material";
import NoteType from "src/utils/NoteType.enum";

type Props = {
  type: NoteType;
};

const NoteCardIcon = ({ type }: Props) => {
  switch (type) {
    case NoteType.Call:
      return (
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.info.light,
            padding: theme.spacing(2),
            borderRadius: 4,
          })}
        >
          <Call sx={(theme) => ({ color: theme.palette.info.dark })} />
        </Box>
      );
    case NoteType.Text:
      return (
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.info.light,
            padding: theme.spacing(2),
            borderRadius: 4,
          })}
        >
          <Textsms sx={(theme) => ({ color: theme.palette.info.dark })} />
        </Box>
      );
    case NoteType.Comment:
      return (
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.secondary.light,
            padding: theme.spacing(2),
            borderRadius: 4,
          })}
        >
          <Comment sx={(theme) => ({ color: theme.palette.secondary.dark })} />
        </Box>
      );
    case NoteType.Milestone:
      return (
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.primary.light,
            padding: theme.spacing(2),
            borderRadius: 4,
          })}
        >
          <Flag sx={(theme) => ({ color: theme.palette.primary.dark })} />
        </Box>
      );
    case NoteType.ActionItem:
      return (
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.secondary.light,
            padding: theme.spacing(2),
            borderRadius: 4,
          })}
        >
          <PriorityHigh
            sx={(theme) => ({ color: theme.palette.secondary.dark })}
          />
        </Box>
      );
    case NoteType.Challenge:
      return (
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.secondary.light,
            padding: theme.spacing(2),
            borderRadius: 4,
          })}
        >
          <Dangerous
            sx={(theme) => ({ color: theme.palette.secondary.dark })}
          />
        </Box>
      );
    default:
      return <div>{type}</div>;
  }
};

export default NoteCardIcon;
