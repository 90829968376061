import { addDoc, collection, getDoc } from "firebase/firestore";
import Joi from "joi";
import { Feedback } from "src/types/Feedback";
import { UserAccount } from "src/types/User";
import isFeedbackGuard from "src/utils/isFeedbackGuard";
import serviceFunction from "src/utils/serviceFunction";
import cleanFirestoreDoc from "./cleanFirestoreDoc";
import generateFirestoreTimestamps from "./generateFirestoreTimestamps";

// Feedback data structure for creation
export type CreateTheoryTrainingFeedbackData = {
  user: UserAccount;
  rating: number;
  resourceId: string;
  type: "theory"; // Discriminator for theory feedback
};

export type CreateSkillsTrainingFeedbackData = {
  user: UserAccount;
  rating: number;
  type: "skills"; // Discriminator for skills feedback
};

export type CreateFeedbackData =
  | CreateTheoryTrainingFeedbackData
  | CreateSkillsTrainingFeedbackData;

// Schema for theory training feedback
const theoryFeedbackSchema = Joi.object<CreateTheoryTrainingFeedbackData>({
  user: Joi.object().required(),
  rating: Joi.number().min(1).max(5).required(),
  resourceId: Joi.string().required(),
  type: Joi.string().valid("theory").required(),
});

// Schema for skills training feedback
const skillsFeedbackSchema = Joi.object<CreateSkillsTrainingFeedbackData>({
  user: Joi.object().required(),
  rating: Joi.number().min(1).max(5).required(),
  type: Joi.string().valid("skills").required(),
});

// Create feedback service function
const createFeedback = serviceFunction(
  Joi.alternatives().try(theoryFeedbackSchema, skillsFeedbackSchema), // Support both feedback types
  async (data: CreateFeedbackData, clients): Promise<Feedback> => {
    const { user, ...rest } = data;

    // Add the feedback document to Firestore
    const doc = await addDoc(
      collection(clients.db, `users/${user.uid}/feedback`),
      {
        userId: user.uid,
        ...generateFirestoreTimestamps(new Date(), "createdAt"),
        ...rest,
      }
    );

    const feedback = cleanFirestoreDoc(await getDoc(doc));

    // Ensure the created feedback is valid using the type guard
    if (!isFeedbackGuard(feedback)) {
      throw new Error("Invalid feedback structure");
    }

    return feedback;
  }
);

export default createFeedback;
