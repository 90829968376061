import { useEffect, useMemo, useState } from "react";
import { useAppContext } from "src/contexts/AppContext";
import fetchSchools from "src/firebase/fetchSchools";
import { School } from "src/types/School";
import useErrorHandler from "./useErrorHandler";

export default function useSchools() {
  const [schools, setSchools] = useState<School[]>([]);
  const errorHandler = useErrorHandler();
  const { clients } = useAppContext();

  useEffect(() => {
    async function loadSchools() {
      try {
        const response = await fetchSchools(clients);
        setSchools(response);
      } catch (error) {
        errorHandler(error);
      }
    }
    loadSchools();
  }, [clients, errorHandler]);

  const schoolsById = useMemo(
    () =>
      schools.reduce((acc, school) => {
        acc[school.uid] = school;
        return acc;
      }, {} as Record<string, School>),
    [schools]
  );

  return { schoolsById, schools };
}
