import { collection, getDocs } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";
import { Consent } from "src/types/Consent";
import isConsentGuard from "src/types/isConsentGuard";
import cleanFirestoreDoc from "./cleanFirestoreDoc";

export default async function fetchConsents(
  userId: string,
  { db }: Clients
): Promise<Consent[]> {
  const consents = (
    await getDocs(collection(db, "users", userId, "consents"))
  ).docs
    .map(cleanFirestoreDoc)
    .filter(isConsentGuard)
    .filter((consent) => !consent.revokedAt);

  return consents;
}
