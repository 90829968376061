import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Grid, Stack } from "@mui/material";
import { differenceInYears, sub } from "date-fns";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import SubmitButton from "src/components/Button/SubmitButton";
import SelectInput from "src/components/Card/SelectInput";
import CheckboxInput from "src/components/Input/CheckboxInput";
import DateInput from "src/components/Input/DateInput";
import TextInput from "src/components/Input/TextInput";
import isValidUSPhoneNumber from "src/utils/isValidUSPhoneNumber";
import ReferralSource from "src/utils/ReferralSource.enum";
import sanitizeText from "src/utils/sanitizeText";
import { TermsPrivacySignUp } from "./TermsPrivacy";

export type ProfileStepData = {
  dateOfBirth?: Date;
  termsOfService: boolean;
  referralSource?: keyof typeof ReferralSource;
  phone: string;
};

type Props = {
  defaultValues: ProfileStepData;
  onBack?: () => void;
  onSubmit: (data: ProfileStepData) => void | Promise<void>;
};

const MAX_USER_AGE = 100;
const MIN_USER_AGE = 16;

// TODO: terms URL
// TODO: privacy policy URL
export default function ProfileSignupForm({
  defaultValues,
  onBack,
  onSubmit,
}: Props) {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ProfileStepData>({
    mode: "onTouched",
    defaultValues,
  });

  return (
    <form
      onSubmit={handleSubmit((data) =>
        onSubmit({
          dateOfBirth: data.dateOfBirth,
          termsOfService: data.termsOfService,
          phone: data.phone,
          referralSource: data.referralSource,
        })
      )}
    >
      <Stack spacing={2} marginBottom={6}>
        <TextInput
          control={control}
          name="phone"
          label={t("Phone number")}
          rules={{
            required: { value: true, message: t("Phone number is required") },
            validate: (value) =>
              isValidUSPhoneNumber(sanitizeText(value)) ||
              t("Please enter a valid phone number"),
          }}
        />

        <DateInput
          control={control}
          name="dateOfBirth"
          label={t("DOB (MM/DD/YYYY)")}
          disableFuture
          disableHighlightToday
          minDate={sub(new Date(), { years: MAX_USER_AGE, days: 364 })}
          rules={{
            required: { value: true, message: t("Date of birth is required") },
            validate: (value) => {
              const invalidDate = t("Please enter a valid date");
              // Invalid data (or value = undefined - though I believe the undefined case
              // will be handled by required)
              if (!value || value?.toString() === "Invalid Date")
                return invalidDate;

              // Older than oldest age ever recorded
              const age = differenceInYears(new Date(), value);
              if (age > MAX_USER_AGE || age <= 0) return invalidDate;

              // Younger than allowed
              if (age < MIN_USER_AGE)
                return t(
                  "You must be at least {{minUserAge}} years old to have an Ameelio account",
                  { minUserAge: MIN_USER_AGE }
                );

              // Checks passed
              return true;
            },
          }}
        />
        <SelectInput
          name="referralSource"
          label={t("Referral Source")}
          control={control}
          items={Object.entries(ReferralSource).map((entry) => ({
            value: entry[0],
            name: entry[1],
          }))}
          rules={{
            validate: (value) =>
              !!value || t("The referral source is required"),
          }}
        />
      </Stack>
      <Box marginBottom={6}>
        <CheckboxInput
          control={control}
          name="termsOfService"
          label={<TermsPrivacySignUp />}
          rules={{
            validate: (value) =>
              value || t("Please accept the terms and conditions"),
          }}
        />
      </Box>
      <Grid container justifyContent="space-between">
        {onBack && (
          <Button onClick={onBack}>
            <ArrowBackIcon />
            &nbsp;
            {t("Back")}
          </Button>
        )}
        <SubmitButton loading={isSubmitting}>{t("Next")}</SubmitButton>
      </Grid>
    </form>
  );
}
