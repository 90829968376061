type ItemsStatus<T> = {
  existingItems: { key: string; item: T }[];
  missingItems: string[];
};

const getItemsStatus = <T>(
  keysINeed: string[],
  existingItems: T[],
  compareFn: (key: string, item: T) => boolean = (key, item) =>
    Object.prototype.hasOwnProperty.call(item, key)
): ItemsStatus<T> => {
  const itemsIHaveList: { key: string; item: T }[] = [];
  const itemsIDontHaveList: string[] = [];

  keysINeed.forEach((key) => {
    const matchingItem = existingItems.find((item) => compareFn(key, item));
    if (matchingItem) {
      itemsIHaveList.push({ key, item: matchingItem });
    } else {
      itemsIDontHaveList.push(key);
    }
  });

  return {
    existingItems: itemsIHaveList,
    missingItems: itemsIDontHaveList,
  };
};

export default getItemsStatus;
