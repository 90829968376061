import { collection, getDocs } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";
import isChatMessageGuard from "src/pages/DashboardPage/AdminParticipantChatTab/isChatMessageGuard";
import cleanFirestoreDoc from "./cleanFirestoreDoc";

export default async function fetchNewChatMessageCount(
  userId: string,
  readerId: string,
  { db }: Clients
): Promise<number> {
  const snapshot = await getDocs(collection(db, "users", userId, "messages"));

  const documents = snapshot.docs
    .map(cleanFirestoreDoc)
    .filter(isChatMessageGuard);

  return documents.filter((doc) => doc.senderId !== readerId && !doc.readAt)
    .length;
}
