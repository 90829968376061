import { useCallback, useEffect, useState } from "react";
import { useAppContext } from "src/contexts/AppContext";
import fetchExamProgressCount from "src/firebase/fetchExamProgressCount"; // Your new service function
import { UserAccount } from "src/types/User";
import batchUserRequest from "src/utils/batchUserRequest";
import useErrorHandler from "src/utils/useErrorHandler";

interface UseUsersExamProgressCountResult {
  loading: boolean;
  getExamProgress: (user: UserAccount) => number | undefined;
}

export default function useUsersExamProgressCount(
  users: UserAccount[]
): UseUsersExamProgressCountResult {
  const [loading, setLoading] = useState(true);
  const [progressCountsById, setProgressCountsById] = useState<{
    [id: string]: number | undefined;
  }>({});
  const { clients } = useAppContext();
  const errorHandler = useErrorHandler();

  useEffect(() => {
    if (!users || users.length === 0) {
      setLoading(false);
      return;
    }

    const fetchProgressCounts = async () => {
      setLoading(true);

      try {
        // Use batchUserRequest to batch requests with concurrency control
        const countsData = await batchUserRequest(
          users,
          async (user: UserAccount) => {
            const count = await fetchExamProgressCount(user.uid, clients);
            return count;
          }
        );

        setProgressCountsById(countsData);
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProgressCounts();
  }, [users, clients, errorHandler]);

  // Getter function for retrieving exam progress by user ID
  const getExamProgress = useCallback(
    (user: UserAccount): number | undefined => progressCountsById[user.uid],
    [progressCountsById]
  );

  return {
    loading,
    getExamProgress,
  };
}
