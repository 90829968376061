/* eslint-disable no-alert */
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import {
  connectFirestoreEmulator,
  enableIndexedDbPersistence,
  getFirestore,
  initializeFirestore,
} from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import firebaseConfig from "./config";

const app = initializeApp(firebaseConfig);

export const auth = getAuth();
initializeFirestore(app, {
  ignoreUndefinedProperties: true,
});
export const db = getFirestore();

export const storage = getStorage(app);
export const functions = getFunctions(app);

if (process.env.NODE_ENV === "development") {
  connectFunctionsEmulator(functions, "localhost", 5006);
  connectFirestoreEmulator(db, "localhost", 5007);
  connectAuthEmulator(auth, "http://localhost:5005");
  connectStorageEmulator(storage, "localhost", 5008);
}

// enableS db persistence
if (process.env.NODE_ENV !== "test") {
  enableIndexedDbPersistence(db).catch((err) => {
    if (err.code === "failed-precondition") {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
      //  window.alert("You cannot have multiple tabs of the application open.");
    } else if (err.code === "unimplemented") {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
      window.alert(
        "The browser does not support all of the features required for this application to work."
      );
    } else {
      window.alert(err.code);
    }
  });
}
