import { doc, updateDoc } from "firebase/firestore";
import Joi from "joi";
import { StudyPlanData } from "src/pages/OnboardingScreen/generateStudyPlan";
import { UserAccount } from "src/types/User";
import serviceFunction from "src/utils/serviceFunction";
import generateFirestoreTimestamps from "./generateFirestoreTimestamps";

type Data = {
  user: UserAccount;
  studyPlan: StudyPlanData;
};

const schema = Joi.object<Data>({
  user: Joi.object().required(),
  studyPlan: Joi.object().required(),
});

const createUserStudyPlan = serviceFunction(
  schema,
  async ({ user, studyPlan }, { db }): Promise<void> => {
    await updateDoc(doc(db, `users/${user.uid}`), {
      theoryTrainingPlan: {
        ...studyPlan,
        ...generateFirestoreTimestamps(new Date(), "createdAt"),
      },
    });
  }
);

export default createUserStudyPlan;
