export default function safeCapitalizeInitials(input: unknown): string {
  if (typeof input !== "string") return "";

  const words = input.split(" ");

  for (let i = 0; i < words.length; i += 1) {
    const word = words[i];
    if (word.length > 0) {
      words[i] = word[0].toUpperCase() + word.slice(1);
    }
  }

  return words.join(" ");
}
