import { subDays } from "date-fns";
import {
  collection,
  getCountFromServer,
  query,
  where,
} from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";

export default async function fetchTimelineEventsCount(
  clientId: string,
  { db }: Clients,
  timeHorizonDays?: number
): Promise<number> {
  const timelineEventsCollection = collection(
    db,
    "users",
    clientId,
    "timelineEvents"
  );

  const eventsQuery = timeHorizonDays
    ? query(
        timelineEventsCollection,
        where(
          "createdAtFirestoreTimestamp",
          ">",
          subDays(new Date(), timeHorizonDays)
        )
      )
    : timelineEventsCollection;

  const snapshot = await getCountFromServer(eventsQuery);

  return snapshot.data().count;
}
