import { theme } from "../theme";

const { breakpoints } = theme;

type MediaQueryFn = (mediaQuery: string) => boolean;

/*
2022.11.18 - The definitive source for default breakpoints is 
ALWAYS the official MUI docs: https://mui.com/material-ui/customization/breakpoints/
Code comments below are included for convenience, but may change.
*/

/*
Returns true if media query (max-width < breakpoint SMALL (600px))
*/
export function onlyMobile(queryFn: MediaQueryFn): boolean {
  return queryFn(breakpoints.down("sm"));
}

/*
Returns true if media query (max-width < breakpoint MEDIUM (900px))
*/
export function belowLargeTablet(queryFn: MediaQueryFn): boolean {
  return queryFn(breakpoints.down("md"));
}

/*
Returns true if media query (max-width < breakpoint LARGE (1200px))
*/
export function belowLaptop(queryFn: MediaQueryFn): boolean {
  return queryFn(breakpoints.down("lg"));
}

/*
Returns true if media query (max-width < breakpoint EXTRA-LARGE (1536px))
*/
export function belowDesktop(queryFn: MediaQueryFn): boolean {
  return queryFn(breakpoints.down("xl"));
}

/*
Returns true if media query (min-width >= breakpoint SMALL (600px))
*/
export function smallTabletAndUp(queryFn: MediaQueryFn): boolean {
  return queryFn(breakpoints.up("sm"));
}

/*
Returns true if media query (min-width >= breakpoint MEDIUM (900px))
*/
export function largeTabletAndUp(queryFn: MediaQueryFn): boolean {
  return queryFn(breakpoints.up("md"));
}

/*
Returns true if media query (min-width >= breakpoint LARGE (1200px))
*/
export function laptopAndUp(queryFn: MediaQueryFn): boolean {
  return queryFn(breakpoints.up("lg"));
}

/*
Returns true if media query (min-width >= breakpoint EXTRA-LARGE (1536px))
*/
export function desktopAndUp(queryFn: MediaQueryFn): boolean {
  return queryFn(breakpoints.up("xl"));
}
