import {
  Avatar as Base,
  AvatarProps as BaseProps,
  Tooltip,
} from "@mui/material";
import { UserOnboardingStatus } from "src/types/User";
import { getFullName } from "src/utils";
import toInitials from "src/utils/toInitials";
import onboardingStatusToColor from "./onboardingStatusToColor";

type Props = BaseProps & {
  contact?: {
    firstName: string;
    lastName: string;
    onboardingStatus: UserOnboardingStatus;
  };
  tooltip?: boolean;
};

export default function Avatar({ contact, children, tooltip, ...rest }: Props) {
  // pick a color from the palette if there's no image
  const hue =
    contact && !rest.src
      ? onboardingStatusToColor(contact.onboardingStatus)
      : undefined;
  const colorProps = hue
    ? { color: hue[900], backgroundColor: hue[100] }
    : undefined;
  const sxProps = { ...rest.sx, ...colorProps };

  return (
    <Tooltip
      title={tooltip && contact ? getFullName(contact) : ""}
      placement="top"
      arrow
    >
      <Base {...rest} sx={sxProps}>
        {children || (contact && toInitials(contact))}
      </Base>
    </Tooltip>
  );
}
