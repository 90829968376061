import { useQuery } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import { useSession } from "src/contexts/AuthContext";
import { QuizGrade } from "src/firebase/FirestoreClient";
import { GetQuizzesDocument } from "src/pages/DashboardPage/GetQuizzes.generated";
import preprocessQuizGrades, {
  CompleteGradeReport,
} from "./preprocessQuizGrades";
import useErrorHandler from "./useErrorHandler";

export default function useCompleteQuizGrades(uid: string): {
  completeReport?: CompleteGradeReport;
  findGradeByQuizId: (quizId: string) => QuizGrade | undefined;
} {
  const [grades, setGrades] = useState<QuizGrade[]>();
  const session = useSession();
  const errorHandler = useErrorHandler();
  const [completeReport, setCompleteReport] = useState<CompleteGradeReport>();
  const { data } = useQuery(GetQuizzesDocument);

  useEffect(() => {
    session.fetchClientGrades(uid).then(setGrades).catch(errorHandler);
  }, [session, uid, errorHandler]);

  useEffect(() => {
    if (!grades || !data) return;
    setCompleteReport(preprocessQuizGrades(grades, data.allQuizzes));
  }, [grades, data]);

  const findGradeByQuizId = useCallback(
    (quizId: string) => grades?.find((grade) => grade.quizId === quizId),
    [grades]
  );

  return { completeReport, findGradeByQuizId };
}
