import MilestoneType from "src/types/MilestoneType.enum";

export const MilestoneTypeLabel: { [key in MilestoneType]: string } = {
  [MilestoneType.MEDICAL_EXAM]: "Take the DOT Physical Exam",
  [MilestoneType.THEORY_TRAINING_COMPLETION]: "Complete All Practice Exams",
  [MilestoneType.THEORY_EXAM]: "Obtain the Learner's Permit",
  [MilestoneType.DRUG_TEST]: "Pass Drug Test",
  [MilestoneType.SKILLS_TRAINING_START]: "Start Driving Training",
  [MilestoneType.SKILLS_TRAINING_END]: "End Driving Training",
  [MilestoneType.LICENSE_EXAM]: "CDL Exam",
  [MilestoneType.OTHER]: "Other Milestone",
};

// Helper function to get the label for a given milestone type
export function getMilestoneLabel(type: MilestoneType): string {
  return MilestoneTypeLabel[type] || "Unknown Milestone";
}
