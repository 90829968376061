import { TriagedApplicationData } from "./triageApplicationData";

export default function calculateApplicationScreeningScore(
  triageApplicationData: TriagedApplicationData
) {
  return (
    triageApplicationData.disqualifiers.length * -100 +
    triageApplicationData.qualifiers.length * 5 +
    triageApplicationData.needsAttention.length * -15
  );
}
