import { Box } from "@mui/material";
import { UnitRecord } from "src/clients/schema";
import { ScreenTitle } from "src/components/Typography";

type Props = {
  unit: Pick<UnitRecord, "thumbnailUrl" | "name">;
};

export default function ResourceScreenHeader({ unit }: Props) {
  return (
    <Box sx={{ backgroundImage: `url(${unit.thumbnailUrl})`, padding: 4 }}>
      <ScreenTitle color="white">{unit.name}</ScreenTitle>
    </Box>
  );
}
