enum HousingSituation {
  OwnHouse = "I own a house",
  RentApt = "I can afford to pay rent for an apartment",
  FriendsFamily = "I can live with family or friends",
  TemporaryShelter = "I can stay in a temporary shelter",
  Homeless = "I do not have a housing plan",
}

export function getHousingSituationLabel(
  situation: HousingSituation,
  isIncarcerated: boolean
): string {
  const incarceratedLabels: Record<HousingSituation, string> = {
    [HousingSituation.OwnHouse]: "I own a house",
    [HousingSituation.RentApt]: "I rent an apartment",
    [HousingSituation.FriendsFamily]: "I live with family or friends",
    [HousingSituation.TemporaryShelter]:
      "I stay in a temporary shelter or halfway house",
    [HousingSituation.Homeless]: "I do not have a housing plan",
  };

  const postReleaseLabels: Record<HousingSituation, string> = {
    [HousingSituation.OwnHouse]: "I own a house",
    [HousingSituation.RentApt]: "I can afford to pay rent for an apartment",
    [HousingSituation.FriendsFamily]: "I can live with family or friends",
    [HousingSituation.TemporaryShelter]: "I can stay in a temporary shelter",
    [HousingSituation.Homeless]: "I do not have a housing plan",
  };

  return isIncarcerated
    ? incarceratedLabels[situation]
    : postReleaseLabels[situation];
}

export default HousingSituation;
