import { useEffect, useState } from "react";
import { useAppContext } from "src/contexts/AppContext";
import fetchBackground from "src/firebase/fetchBackgroundProfile";
import { BackgroundFormData } from "src/pages/BackgroundSurveyScreen/BackgroundSurveyView";
import { UserAccount } from "src/types/User";
import useErrorHandler from "src/utils/useErrorHandler";

export default function useUsersBackgroundsById(users: UserAccount[]) {
  const [backgroundsById, setBackgroundsById] = useState<{
    [id: string]: BackgroundFormData | undefined;
  }>({});
  const { clients } = useAppContext();
  const errorHandler = useErrorHandler();

  useEffect(() => {
    if (!users) return;

    const fetchBackgrounds = async () => {
      const backgroundsData: { [id: string]: BackgroundFormData | undefined } =
        {};

      await Promise.allSettled(
        users.map(async (user) => {
          const background = await fetchBackground(user.uid, clients);
          backgroundsData[user.uid] = background;
        })
      );

      setBackgroundsById(backgroundsData);
    };

    fetchBackgrounds().catch(errorHandler);
  }, [users, clients, errorHandler]);

  return backgroundsById;
}
