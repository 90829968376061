import { Alert, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import useAddToHomescreenPrompt from "src/hooks/useAddToHomescreenPrompt";

const PWASnackbar = () => {
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (prompt) setIsOpen(true);
  }, [prompt]);
  return (
    <Snackbar
      open={isOpen}
      onClose={() => setIsOpen(false)}
      autoHideDuration={7000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        severity="info"
        action={
          <Button onClick={promptToInstall} variant="outlined">
            {t("Install")}
          </Button>
        }
      >
        {t("Installing provides a quick way to return to Emerge!")}
      </Alert>
    </Snackbar>
  );
};

export default PWASnackbar;
