export type ItemsStatus<U, V> = {
  existingItems: V[];
  missingItems: U[];
};

type GetItemsStatusArgs<T, U, V> = {
  requiredItems: U[];
  allItemsIHave: T[];
  requiredItemsExtractor: (item: U) => string;
  allItemsIHaveExtractor: (item: T) => string;
  mergeItems: (requiredItem: U, existingItem: T) => V;
};

export default function getItemsStatusWithExtractors<T, U, V>({
  requiredItems,
  allItemsIHave,
  requiredItemsExtractor,
  allItemsIHaveExtractor,
  mergeItems,
}: GetItemsStatusArgs<T, U, V>): ItemsStatus<U, V> {
  const itemsIHaveList: V[] = [];
  const itemsIDontHaveList: U[] = [];

  const keysINeed = requiredItems.map(requiredItemsExtractor);

  keysINeed.forEach((key, index) => {
    const requiredItem = requiredItems[index];
    const matchingItem = allItemsIHave.find(
      (item) => allItemsIHaveExtractor(item) === key
    );
    if (matchingItem) {
      itemsIHaveList.push(mergeItems(requiredItem, matchingItem));
    } else {
      itemsIDontHaveList.push(requiredItem);
    }
  });

  return {
    existingItems: itemsIHaveList,
    missingItems: itemsIDontHaveList,
  };
}
