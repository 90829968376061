import { track } from "@amplitude/analytics-browser";

export function amplitudeClickHandler(
  eventName: string,
  onClick: () => void,
  eventProperties: Record<string, string | number> = {}
) {
  return () => {
    track(eventName, eventProperties);
    onClick();
  };
}

export function trackEvent(
  eventName: string,
  eventProperties: Record<string, string | number> = {}
) {
  track(eventName, eventProperties);
}
