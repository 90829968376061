import { collection, getDocs, query, where } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";
import { Admin } from "src/types/Admin";
import { AdminActionItem } from "src/types/AdminActionItem";
import { UserAccount } from "src/types/User";
import isAdminActionItemGuard from "src/utils/isAdminActionItemGuard";
import cleanFirestoreDoc from "./cleanFirestoreDoc";

export default async function fetchAdminActionItemsForUser(
  admin: Admin,
  user: UserAccount,
  { db }: Clients
): Promise<AdminActionItem[]> {
  const actionItems = (
    await getDocs(
      query(
        collection(db, "admins", admin.uid, "actionItems"),
        where("userId", "==", user.uid)
      )
    )
  ).docs
    .map(cleanFirestoreDoc)
    .filter(isAdminActionItemGuard);

  return actionItems.sort(
    (a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime()
  );
}
