import { doc, getDoc } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";
import { UserAccount } from "src/types/User";
import cleanFirestoreDoc from "./cleanFirestoreDoc";

export default async function fetchUser(
  userId: string,
  { db }: Clients
): Promise<UserAccount> {
  const res = await getDoc(doc(db, "users", userId));

  if (!res.exists()) throw new Error("User not found");

  const user = cleanFirestoreDoc(res);

  return user as UserAccount;
}
