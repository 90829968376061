import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { ResourceRecord } from "src/clients/schema";
import { GetOnboardingUnitDocument } from "src/pages/OnboardingScreen/GetOnboardingUnit.generated";
import { useCurrentUser } from "src/SessionBoundary";
import { CLP_ID } from "./constants";
import useCompleteQuizGrades from "./useCompleteQuizGrades";
import useResourceProgress from "./useResourceProgress";

export enum OnboardingUnitType {
  ProgramOverview = 0,
  IntroUnit = 1,
}

export default function useOnboardingUnitProgress(type: OnboardingUnitType): {
  loading: boolean;
  currentLesson?: Pick<ResourceRecord, "id">;
  isComplete: boolean;
  totalLessons: number;
  completeLessons: number;
  unitId: string | undefined;
} {
  // video progress
  const { progress } = useResourceProgress();
  const user = useCurrentUser();
  const { completeReport, findGradeByQuizId } = useCompleteQuizGrades(user.uid);
  const [loading, setLoading] = useState(false);
  const [isUnitComplete, setIsUnitComplete] = useState(false);
  const [resource, setResource] = useState<
    Pick<ResourceRecord, "id"> | undefined
  >();
  const unitDocument = useQuery(GetOnboardingUnitDocument, {
    variables: { courseId: CLP_ID, order: type },
  });
  const [completeLessons, setCompleteLessons] = useState(0);
  const [totalLessons, setTotalLessons] = useState(0);

  useEffect(() => {
    const unit = unitDocument.data?.unit;
    setLoading(true);
    if (!progress || !completeReport || !unit) return;

    const resources = unit.resources.map((resource) => ({
      ...resource,
      videoCompleted:
        progress[resource.id]?.progressFraction >= 0.8 ||
        progress[resource.id]?.hasCompleted,
      quizCompleted: !resource.quiz || !!findGradeByQuizId(resource.quiz?.id),
    }));

    // get current resource
    const currentResource = resources.find(
      (resource) => !resource.videoCompleted || !resource.quizCompleted
    );

    const completeLessons = resources.filter(
      (resource) => resource.videoCompleted && resource.quizCompleted
    );

    // if unit is complete, there is no current resource
    setResource(currentResource);
    setCompleteLessons(completeLessons.length);
    setTotalLessons(resources.length);
    setIsUnitComplete(!currentResource);
    setLoading(false);
  }, [completeReport, findGradeByQuizId, progress, unitDocument]);

  return {
    loading: loading || unitDocument.loading,
    currentLesson: resource,
    isComplete: isUnitComplete,
    completeLessons,
    totalLessons,
    unitId: unitDocument.data?.unit ? unitDocument.data.unit.id : undefined,
  };
}
