enum Disability {
  ATTENTION_DEFICIT = "Attention deficit",
  AUTISM = "Autism",
  BLIND_OR_VISUALLY_IMPAIRED = "Blind or visually impaired",
  DEAF_OR_HARD_OF_HEARING = "Deaf or hard of hearing",
  HEALTH_RELATED_DISABILITY = "Health-related disability",
  LEARNING_DISABILITY = "Learning disability",
  MENTAL_HEALTH_CONDITION = "Mental health condition",
  MOBILITY_RELATED_DISABILITY = "Mobility-related disability",
  SPEECH_RELATED_DISABILITY = "Speech-related disability",
  PREFER_NOT_TO_ANSWER = "Prefer not to answer",
  OTHER = "Other (please specify, optional)",
  NOT_APPLICABLE = "Not applicable",
}

export default Disability;
