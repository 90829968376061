import { AccessAlarm } from "@mui/icons-material";
import { Checkbox, Stack, Typography } from "@mui/material";
import { isBefore } from "date-fns";
import { MilestoneTask } from "src/types/MilestoneTask";
import formatDateString from "src/utils/formatDateString";

type Props = {
  task: MilestoneTask;
  onCheck: () => void;
};

export default function MilestoneTaskItem({ task, onCheck }: Props) {
  return (
    <Stack direction="row" alignItems="start">
      <Checkbox checked={!!task.completedAt} onChange={onCheck} />

      <Stack spacing={0.25}>
        <Typography>{task.title}</Typography>
        <Typography variant="body2" color="text.secondary">
          {task.body}
        </Typography>
        {isBefore(new Date(task.deadline), new Date()) ? (
          <Stack direction="row" spacing={0.25}>
            <AccessAlarm color="warning" fontSize="small" />
            <Typography variant="body2" color="text.secondary">
              {formatDateString(task.deadline, "date")}
            </Typography>
          </Stack>
        ) : (
          <Typography variant="body2" color="text.secondary">
            {formatDateString(task.deadline, "date")}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}
