import { doc, getDoc, updateDoc } from "firebase/firestore";
import Joi from "joi";
import { Reimbursement } from "src/types/Reimbursement";
import isReimbursementGuard from "src/utils/isReimbursementGuard";
import serviceFunction from "src/utils/serviceFunction";
import cleanFirestoreDoc from "./cleanFirestoreDoc";
import generateFirestoreTimestamps from "./generateFirestoreTimestamps";

// Define the shape of the data expected by this service function
export type UpdateReimbursementCompletionStatusData = {
  reimbursement: Reimbursement;
  completed: boolean;
};

const schema = Joi.object<UpdateReimbursementCompletionStatusData>({
  reimbursement: Joi.object().required(),
  completed: Joi.boolean().required(),
});

const updateReimbursementCompletionStatus = serviceFunction(
  schema,
  async (
    { reimbursement, completed }: UpdateReimbursementCompletionStatusData,
    { db }
  ): Promise<Reimbursement> => {
    const reimbursementRef = doc(
      db,
      `users/${reimbursement.userId}/reimbursements/${reimbursement.uid}`
    );

    if (completed) {
      // If completed is true, set the timestamps for completion
      await updateDoc(reimbursementRef, {
        sentReimbursement: true,
        ...generateFirestoreTimestamps(new Date(), "reimbursedAt"),
      });
    } else {
      // If completed is false, delete the completion timestamps
      await updateDoc(reimbursementRef, {
        sentReimbursement: false,
        reimbursedAt: null,
        reimbursedAtFirestoreTimestamp: null,
      });
    }

    // Fetch the updated document and return it
    const updatedReimbursementDoc = await getDoc(reimbursementRef);
    const updatedReimbursement = cleanFirestoreDoc(updatedReimbursementDoc);

    if (!isReimbursementGuard(updatedReimbursement)) {
      throw new Error(
        "Something went wrong while updating the reimbursement status"
      );
    }

    return updatedReimbursement;
  }
);

export default updateReimbursementCompletionStatus;
