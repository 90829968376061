import {
  Card,
  CardContent,
  Step,
  StepLabel,
  Stepper,
  StepperProps,
} from "@mui/material";
import { isMobileOnly } from "react-device-detect";
import { UserAccount } from "src/types/User";
import getCurrentTrainingStep, {
  trainingLabels,
} from "./getCurrentTrainingStep";

type Props = {
  user: UserAccount;
};

export default function TrainingProgressStepper({ user }: Props) {
  const currentStep = getCurrentTrainingStep(user);

  const stepIndex = trainingLabels.indexOf(currentStep);

  const stepperProps: StepperProps = isMobileOnly
    ? {
        alternativeLabel: false,
        orientation: "horizontal",
      }
    : { alternativeLabel: true, orientation: "horizontal" };

  return (
    <Card
      sx={{
        // background: "linear-gradient(37deg, #002D5A 28.63%, #134D89 106.9%)",
        md: { padding: 4 },
      }}
    >
      <CardContent sx={{ overflowX: "auto" }}>
        <Stepper activeStep={stepIndex} {...stepperProps}>
          {trainingLabels.map((label) => (
            <Step key={label} sx={{ color: "white" }}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </CardContent>
    </Card>
  );
}

// function CustomStepIcon(props: StepIconProps) {
//   const { active, completed, className } = props;

//   return (
//     <div ownerState={{ active }} className={className}>
//       {completed ? (
//         <Check className="QontoStepIcon-completedIcon" />
//       ) : (
//         <div className="QontoStepIcon-circle" />
//       )}
//     </div>
//   );
// }
