import { getBlob, ref } from "firebase/storage";
import { Clients } from "src/contexts/AppContext";

export default async function fetchStorageBlob(
  { path, filename }: { path: string; filename?: string },
  { storage }: Clients
): Promise<File> {
  const file = new File([await getBlob(ref(storage, path))], filename || path);

  return file;
}
