import {
  Box,
  capitalize,
  Card,
  CardContent,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { QuizRecord } from "src/clients/schema";
import { useCurrentUser } from "src/SessionBoundary";
import hasPassedQuiz from "src/utils/hasPassedQuiz";
import useCompleteQuizGrades from "src/utils/useCompleteQuizGrades";
import Button from "../Button";

interface Props {
  quiz: Pick<QuizRecord, "id" | "name" | "quizType">;
  locked?: boolean;
}

const QuizCard = ({ quiz, locked }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useCurrentUser();
  const { findGradeByQuizId } = useCompleteQuizGrades(user.uid);
  const grade = findGradeByQuizId(quiz.id);
  const theme = useTheme();

  if (grade)
    return (
      <Card
        onClick={() => navigate(`/quizzes/${quiz.id}`)}
        sx={{ backgroundColor: theme.palette.grey[200] }}
      >
        <CardContent>
          <Stack spacing={2}>
            <Box>
              <Typography variant="body2">
                {capitalize(quiz.quizType)}: {quiz.name}
              </Typography>
              <Stack direction="row" spacing={1}>
                {hasPassedQuiz(grade) ? (
                  <Typography variant="body2" color="green">
                    {t("Passed")}
                  </Typography>
                ) : (
                  <Typography variant="body2" color="red">
                    {t("Failed")}
                  </Typography>
                )}
                <Typography variant="body2">
                  {grade.totalCorrectAnswers} / {grade.totalQuestions} (
                  {grade.grade * 100}%)
                </Typography>
              </Stack>
            </Box>
            <Button variant="outlined">{t("Re-Take")}</Button>
          </Stack>
        </CardContent>
      </Card>
    );

  const handleClick = () => {
    if (locked) return;
    navigate(`/quizzes/${quiz.id}`);
  };

  return (
    <Card
      onClick={handleClick}
      sx={{ backgroundColor: theme.palette.grey[200] }}
    >
      <CardContent>
        <Stack spacing={2}>
          <Typography variant="subtitle2">
            {capitalize(quiz.quizType)}: {quiz.name}
          </Typography>

          <Button variant="contained" disabled={locked}>
            {t("Take the Video Quiz")}
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default QuizCard;
