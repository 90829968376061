import { collection, getDocs } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";
import { MilestoneTask } from "src/types/MilestoneTask";
import isMilestoneTaskGuard from "src/utils/isMilestoneTaskGuard";
import cleanFirestoreDoc from "./cleanFirestoreDoc";

export default async function fetchMilestoneTasks(
  { userId, milestoneId }: { userId: string; milestoneId: string },
  { db }: Clients
): Promise<MilestoneTask[]> {
  const tasks = (
    await getDocs(collection(db, "users", userId, "plan", milestoneId, "tasks"))
  ).docs
    .map(cleanFirestoreDoc)
    .filter(isMilestoneTaskGuard);

  return tasks;
}
