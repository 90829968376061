import { useCallback, useEffect, useState } from "react";
import { useAppContext } from "src/contexts/AppContext";
import fetchStudentPlanMilestones from "src/firebase/fetchStudentPlanMilestones";
import { StudentPlanMilestone } from "src/types/StudentPlan";
import { UserAccount } from "src/types/User";
import batchUserRequest from "src/utils/batchUserRequest"; // Import the batch request utility
import isMilestoneOverdue from "src/utils/isMilestoneOverdue"; // Import the overdue milestone checker
import useErrorHandler from "src/utils/useErrorHandler";
import isUpcomingMilestone from "./isUpcomingMilestone";

interface UseUsersMilestonesReturn {
  loading: boolean;
  getMilestonesById: (user: UserAccount) => StudentPlanMilestone[] | undefined;
  getOverdueMilestones: (
    user: UserAccount
  ) => StudentPlanMilestone[] | undefined;
  getUpcomingMilestones: (
    user: UserAccount
  ) => StudentPlanMilestone[] | undefined;
}

export default function useUsersMilestonesById(
  users: UserAccount[]
): UseUsersMilestonesReturn {
  const [loading, setLoading] = useState(true);
  const [milestonesById, setMilestonesById] = useState<{
    [id: string]: StudentPlanMilestone[] | undefined;
  }>();

  const { clients } = useAppContext();
  const errorHandler = useErrorHandler();

  useEffect(() => {
    if (!users) return;

    const fetchMilestones = async () => {
      try {
        setLoading(true);

        // Fetch milestones using the batch request utility
        const milestonesData = await batchUserRequest(
          users,
          async (user) => await fetchStudentPlanMilestones(user.uid, clients)
        );

        setMilestonesById(milestonesData);
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    };

    fetchMilestones();
  }, [users, clients, errorHandler]);

  // Function to get milestones by user account, wrapped in useCallback
  const getMilestonesById = useCallback(
    (user: UserAccount): StudentPlanMilestone[] | undefined => {
      // Return undefined if still loading
      if (loading || !milestonesById) return undefined;

      // Throw error if the user's uid is not in the milestonesById object
      if (!Object.keys(milestonesById).includes(user.uid)) {
        throw new Error(`User with ID ${user.uid} does not exist`);
      }

      // Return the loaded milestones if everything is good
      return milestonesById[user.uid];
    },
    [loading, milestonesById]
  );

  // Function to get overdue milestones by user account, wrapped in useCallback
  const getOverdueMilestones = useCallback(
    (user: UserAccount): StudentPlanMilestone[] | undefined => {
      // Return undefined if still loading
      if (loading || !milestonesById) return undefined;

      // Throw error if the user's uid is not in the milestonesById object
      if (!Object.keys(milestonesById).includes(user.uid)) {
        throw new Error(`User with ID ${user.uid} does not exist`);
      }

      // Filter the overdue milestones
      const milestones = milestonesById[user.uid];
      return milestones?.filter(isMilestoneOverdue);
    },
    [loading, milestonesById]
  );

  const getUpcomingMilestones = useCallback(
    (user: UserAccount): StudentPlanMilestone[] | undefined => {
      // Return undefined if still loading
      if (loading || !milestonesById) return undefined;

      // Throw error if the user's uid is not in the milestonesById object
      if (!Object.keys(milestonesById).includes(user.uid)) {
        throw new Error(`User with ID ${user.uid} does not exist`);
      }

      // Filter the overdue milestones
      const milestones = milestonesById[user.uid];
      return milestones?.filter(isUpcomingMilestone);
    },
    [loading, milestonesById]
  );

  return {
    loading,
    getMilestonesById,
    getOverdueMilestones,
    getUpcomingMilestones,
  };
}
