/* eslint-disable react/function-component-definition */
import {
  Box,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio as BaseItem,
  RadioGroup as Base,
  RadioGroupProps as BaseProps,
  RadioProps,
} from "@mui/material";
import React, { ForwardedRef } from "react";
import withController from "../withController";

type ManagedProps = "value" | "defaultValue";
export type RadioGroupProps = Omit<BaseProps, ManagedProps> & {
  label: string;
  onChange: Required<BaseProps>["onChange"];
  value: Required<BaseProps>["value"];
  error?: boolean;
  helperText?: string;
  emptyText?: string;
  items: RadioProps &
    {
      value: string | boolean;
      label: string;
      disabled?: boolean;
    }[];
};

/**
 * RadioGroupBase defines any custom behaviors and styles of our standard select input.
 */
function RadioGroup(
  props: RadioGroupProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const { id, items, error, helperText, emptyText, label, ...rest } = props;
  return (
    <Box>
      <FormLabel>{label}</FormLabel>
      <FormHelperText error={error}>{helperText}</FormHelperText>
      <Base
        id={id}
        {...rest}
        onChange={rest.onChange}
        value={rest.value}
        sx={{ width: 1 }}
        ref={ref}
      >
        {items.map(({ value, disabled, label: itemLabel }) => (
          <FormControlLabel
            disabled={disabled}
            control={<BaseItem />}
            label={itemLabel}
            value={value}
            id={`${value}-${itemLabel}`}
            key={`${value}-${itemLabel}`}
          />
        ))}
      </Base>
    </Box>
  );
}

export const RadioGroupBase = React.forwardRef(RadioGroup) as typeof RadioGroup;

export default withController(RadioGroupBase);
