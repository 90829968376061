import { Reimbursement } from "src/types/Reimbursement";

export default function isReimbursementGuard(
  data: unknown
): data is Reimbursement {
  if (typeof data !== "object" || data === null) return false;

  const obj = data as Reimbursement;

  return (
    typeof obj.uid === "string" &&
    typeof obj.userId === "string" &&
    typeof obj.title === "string" &&
    typeof obj.amount === "number" &&
    typeof obj.filePath === "string" &&
    typeof obj.createdAt === "string" &&
    (!obj.milestoneId || typeof obj.milestoneId === "string") &&
    typeof obj.sentReimbursement === "boolean"
  );
}
