import { addDoc, collection, getDoc } from "firebase/firestore";
import Joi from "joi";
import MilestoneType from "src/types/MilestoneType.enum";
import { StudentPlanMilestone } from "src/types/StudentPlan";
import { UserAccount } from "src/types/User";
import isStudentPlanMilestoneGuard from "src/utils/isStudentPlanMilestoneGuard";
import serviceFunction from "src/utils/serviceFunction";
import cleanFirestoreDoc from "./cleanFirestoreDoc";
import generateFirestoreTimestamps from "./generateFirestoreTimestamps";

type Data = {
  date: Date;
  title: string;
  user: UserAccount;
  type: MilestoneType;
};

const schema = Joi.object<Data>({
  date: Joi.date().required(),
  title: Joi.string().required(),
  user: Joi.object().required(),
  type: Joi.string()
    .valid(...Object.values(MilestoneType))
    .required(),
});

const createStudentMilestonePlan = serviceFunction(
  schema,
  async ({ user, date, ...data }, { db }): Promise<StudentPlanMilestone> => {
    const doc = await addDoc(collection(db, `users/${user.uid}/plan`), {
      ...data,
      userId: user.uid,
      ...generateFirestoreTimestamps(date, "date"),
      ...generateFirestoreTimestamps(new Date(), "createdAt"),
      ...generateFirestoreTimestamps(new Date(), "lastUpdatedAt"),
      completedAt: null,
      completedAtFirestoreTimestamp: null,
    });
    const milestonePlan = cleanFirestoreDoc(await getDoc(doc));
    if (!isStudentPlanMilestoneGuard(milestonePlan))
      throw new Error("The milestone plan format is invalid.");
    return milestonePlan;
  }
);

export default createStudentMilestonePlan;
