import { Timestamp } from "firebase/firestore";
import { CreateUserInput } from "src/contexts/AuthContext";
import { BackgroundFormData } from "src/pages/BackgroundSurveyScreen/BackgroundSurveyView";
import { StudyPlanData } from "src/pages/OnboardingScreen/generateStudyPlan";
import { JobPreferenceFormData } from "src/pages/OnboardingScreen/JobPreferenceForm";
import { BackgroundCheckReport } from "./BackgroundCheck";
import { BankAccount } from "./BankAccount";
import { TrainingStage } from "./Organization";
import {
  ApplicationStatus,
  DeprecatedApplicationStatus,
  EmploymentStatus,
  EnrollmentStatus,
  IncarcerationStatus,
} from "./UserStatus";

export enum UserOnboardingStatus {
  CREATED_ACCOUNT = "created_account",
  APPLIED = "applied",
  PRE_ADMISSION = "pre_admission",
  ENROLLED = "enrolled",
  COMPLETED_THEORY = "theory_completed",
  RANGE = "range",
  GRADUATED = "graduated",
  LICENSED = "licensed",
  EMPLOYED = "employed",
  DROPPED = "dropped",
  GLOBAL_EXIT = "global_exit",
  REJECTED = "rejected",
  UNSUCCESSFUL_EXIT = "unsuccessful_exit",
}

// TODO: nix this
export type UserTrainingData = {
  schoolId?: string;
  onboardingStatus: UserOnboardingStatus;
  // whether the user already has a DOL account
  dolId?: string;
  // the ID of the workforce board staff member assigned to the user
  careerNavigatorId?: string;
  // the ID of the Emerge admin assigned to the user
  emergeCoachId?: string;
};
export type UserSkillsTrainingPlan = {
  skillsTrainingSchedule: string;
  projectedDurationWeeks: number;
};

export type UserSkillsCoachChecklist = {
  hasIssuedTheoryCertification?: boolean;
  hasSubmittedSchoolEnrollment?: boolean;
  hasPaidSchool?: boolean;
  hasSubmittedFundingAgencyEnrollment?: boolean;
};

export type UserAccount = {
  uid: string;

  // NEW status fields
  incarcerationStatus?: keyof typeof IncarcerationStatus;
  incarcerationStatusDetails?: string;
  incarcerationStatusLastUpdatedAt?: string;
  applicationStatus?:
    | keyof typeof ApplicationStatus
    | keyof typeof DeprecatedApplicationStatus;
  applicationStatusDetails?: string;
  applicationStatusLastUpdatedAt?: string;
  enrollmentStatus?: keyof typeof EnrollmentStatus;
  enrollmentStatusDetails?: string;
  enrollmentStatusLastUpdatedAt?: string;
  employmentStatus?: keyof typeof EmploymentStatus;
  employmentStatusDetails?: string;
  employmentStatusLastUpdatedAt?: string;

  resources: { [resourceId: string]: UserResourceProgress };
  lastLoggedIn?: string;
  createdAt?: string;
  appVersion?: string;
  // video ask profile
  videoAskRespondentId?: string;

  // background check
  backgroundCheckReport?: BackgroundCheckReport;

  // application form
  application?: BackgroundFormData;

  // success plan onboarding
  jobPreference?: JobPreferenceFormData;
  theoryTrainingPlan?: StudyPlanData;
  skillsTrainingPlan?: UserSkillsTrainingPlan;
  skillsCoachChecklist?: UserSkillsCoachChecklist;

  // orientation videos
  hasCompletedOrientationLesson?: boolean;

  // bank account
  bankAccount?: BankAccount;
} & UserTrainingData &
  CreateUserInput;

export type UserResourceProgress = {
  userId: string;
  resourceId: string;
  progressSeconds: number;
  progressFraction: number;
  hasCompleted?: boolean;
};

export type UserUploadedDocument = {
  uid: string;
  name: string;
  path: string;
  userId: string;
  stage?: TrainingStage;
  verifiedAt?: string | null;
  verifiedAtFirestoreTimestamp?: Timestamp | null;
  organizationDocumentId?: string; // Only if the source is an organization, note the edge case in case the person gets moved from one organization to another
};

export enum UserType {
  User = "user",
  OrganizationStaff = "organizationStaff",
  Admin = "admin",
}
