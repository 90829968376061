import { Timestamp } from "firebase/firestore";
import { Employer } from "../types/Employer";

export default function isEmployerGuard(obj: unknown): obj is Employer {
  if (typeof obj !== "object" || !obj) {
    return false;
  }

  const employer = obj as Employer;
  return (
    typeof employer.uid === "string" &&
    typeof employer.createdAt === "string" &&
    employer.createdAtFirestoreTimestamp instanceof Timestamp &&
    typeof employer.lastUpdatedAt === "string" &&
    employer.lastUpdatedAtFirestoreTimestamp instanceof Timestamp &&
    typeof employer.name === "string" &&
    typeof employer.description === "string" &&
    typeof employer.applicationLink === "string"
  );
}
