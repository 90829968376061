import {
  Button as MUIButton,
  ButtonProps,
  Link as MUILink,
  LinkProps as MUILinkProps,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Tooltip from "./Tooltip";

type LinkProps = Omit<
  MUILinkProps<typeof RouterLink, unknown>,
  "component" | "href"
> & {
  button?: undefined;
};

type ButtonLinkProps = Omit<
  ButtonProps<typeof RouterLink, unknown>,
  "component" | "onClick"
> & {
  button: true;
  disabledReason?: string;
};

type Props = LinkProps | ButtonLinkProps;

/**
 * Link is to be used for all internal navigation, whether the result
 * looks like a button or underlined text (default).
 *
 * https://mui.com/material-ui/guides/routing/#link
 */
export default function Link(props: Props): JSX.Element {
  // NOTE: must use delayed destructuring for type narrowing
  const { button } = props;
  if (button) {
    const { sx, disabledReason, disabled, ...rest } = props;
    return (
      <Tooltip title={disabledReason || ""} disabled={!disabled}>
        <div>
          <MUIButton
            component={RouterLink}
            {...rest}
            disabled={disabled}
            sx={{ ...(sx || {}), display: "inline-flex" }}
            disableElevation
          />
        </div>
      </Tooltip>
    );
  }

  const { sx, ...rest } = props;
  return (
    <MUILink
      component={RouterLink}
      {...rest}
      sx={{ ...(sx || {}), textDecoration: "none" }}
    />
  );
}
