import { LockOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { collection, onSnapshot } from "firebase/firestore";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppContext } from "src/contexts/AppContext";
import cleanFirestoreDoc from "src/firebase/cleanFirestoreDoc";
import createChatMessage from "src/firebase/createChatMessage";
import fetchAdmins from "src/firebase/fetchAdmins";
import updateChatMessageReadAt from "src/firebase/updateChatMessageReadAt";
import { useSessionAccountInformation } from "src/SessionBoundary";
import { Admin } from "src/types/Admin";
import { UserAccount, UserType } from "src/types/User";
import { getFullName } from "src/utils";
import formatOptionalTimestamp from "src/utils/formatOptionalTimestamp";
import getInitials from "src/utils/getInitials";
import sortBy from "src/utils/sortBy";
import stringToColor from "src/utils/stringToColor";
import useErrorHandler from "src/utils/useErrorHandler";
import AppSkeleton from "../../AppSkeleton";
import useScrolledToBottom from "../useScrolledToBottom";
import { ChatMessage, ChatMessageSenderType } from "./ChatMessage";
import isChatMessageGuard from "./isChatMessageGuard";

interface Props {
  client: UserAccount;
}

export default function AdminParticipantChat({ client }: Props) {
  const [messageText, setMessageText] = useState("");
  const [messages, setMessages] = useState<ChatMessage[]>();
  const [isProcessing, setIsProcessing] = useState(false);
  const { t } = useTranslation();
  const errorHandler = useErrorHandler();
  const { scrolledToBottom } = useScrolledToBottom(true);
  const account = useSessionAccountInformation();
  const { clients } = useAppContext();
  const [admins, setAdmins] = useState<Admin[]>();
  const theme = useTheme();

  React.useEffect(() => {
    fetchAdmins(clients).then(setAdmins).catch(errorHandler);
  }, [clients, errorHandler]);

  React.useEffect(() => {
    setMessages([]);
    const unsub = onSnapshot(
      collection(clients.db, "users", client.uid, "messages"),
      (snapshot) => {
        const addedChanges = snapshot
          .docChanges()
          .filter((change) => change.type === "added");

        if (!addedChanges.length) return;

        const addedMessages = addedChanges.map((change) => {
          const newMessage = cleanFirestoreDoc(change.doc);

          if (!isChatMessageGuard(newMessage))
            throw new Error("Type narrowing");

          // mark as read if viewer is admin recipient or sender
          const isActiveChatParticipant =
            account.type === UserType.User || account.type === UserType.Admin;

          if (
            isActiveChatParticipant &&
            !newMessage.readAt &&
            newMessage.senderId !== account.uid
          ) {
            updateChatMessageReadAt(
              { message: newMessage, user: client },
              clients
            );
          }

          return newMessage;
        });

        setMessages((existing) =>
          sortBy(
            [...(existing || []), ...addedMessages],
            (message) => new Date(message.createdAt).getTime(),
            "asc"
          )
        );
      }
    );

    return unsub;
  }, [account, client, clients]);

  const sendMessage = async () => {
    if (account.type === UserType.OrganizationStaff)
      throw new Error(
        "Only participants and Emerge staff can send messages for now"
      );

    if (messageText.trim() === "") return;
    setIsProcessing(true);

    const senderType =
      account.type === UserType.User
        ? ChatMessageSenderType.Participant
        : ChatMessageSenderType.Admin;

    await createChatMessage(
      {
        senderType,
        participantId: client.uid,
        text: messageText,
        senderId: account.uid,
      },
      clients
    )
      .then(() => setMessageText(""))
      .catch(errorHandler);

    setIsProcessing(false);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") sendMessage();
  };

  if (!messages || !admins) return <AppSkeleton />;

  const renderMessage = (message: ChatMessage) => {
    const sender =
      message.senderType === ChatMessageSenderType.Admin
        ? admins.find((admin) => message.senderId === admin.uid)
        : client;

    if (!sender) throw new Error("Sender not found");

    return (
      <ListItem key={message.uid}>
        {message.senderType === ChatMessageSenderType.Admin && (
          <ListItemAvatar>
            <Avatar
              alt="avatr"
              src={
                admins.find((admin) => message.senderId === admin.uid)?.photoUrl
              }
            />
          </ListItemAvatar>
        )}

        <ListItemText
          sx={{
            textAlign:
              message.senderType === ChatMessageSenderType.Admin
                ? "left"
                : "right",
          }}
          primary={
            message.senderId === account.uid
              ? `You: ${message.text}`
              : `${getFullName(sender)}: ${message.text}`
          }
          secondary={formatOptionalTimestamp(message.createdAt)}
        />
        {message.senderType === ChatMessageSenderType.Participant && (
          <ListItemAvatar sx={{ marginLeft: 2 }}>
            <Avatar
              alt={getFullName(sender)}
              sx={{ backgroundColor: stringToColor(getFullName(sender)) }}
            >
              {getInitials(getFullName(sender))}
            </Avatar>
          </ListItemAvatar>
        )}
      </ListItem>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        minHeight: 500,
      }}
    >
      <List sx={{ flexGrow: 1, maxHeight: 500, overflowY: "auto", p: 2 }}>
        {messages.map(renderMessage)}
        {scrolledToBottom && (
          <div
            ref={(node) =>
              node?.scrollIntoView({
                behavior: "auto",
                block: "start",
              })
            }
          />
        )}
      </List>
      {account.type !== UserType.OrganizationStaff && (
        <Box sx={{ display: "flex", p: 2 }}>
          <TextField
            sx={{ flexGrow: 1, mr: 2 }}
            label={t("Type your message")}
            variant="outlined"
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            onKeyPress={handleKeyPress}
            disabled={isProcessing}
          />
          <Button
            sx={{ whiteSpace: "nowrap" }}
            variant="contained"
            onClick={() => sendMessage()}
            disabled={isProcessing}
          >
            {t("Send")}
          </Button>
        </Box>
      )}
      <Box
        sx={{
          textAlign: "center",
          p: 2,
          fontStyle: "italic",
          margin: "auto",
        }}
      >
        <Stack alignItems="center" spacing={1} direction="row">
          <LockOutlined
            sx={{ color: theme.palette.grey[500] }}
            fontSize="small"
          />
          <Typography variant="body2" color="text.secondary">
            {t("This chat is monitored by your Emerge success team.")}
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
}
