import { useEffect, useState } from "react";
import { useAppContext } from "src/contexts/AppContext";
import fetchAdmins from "src/firebase/fetchAdmins";
import { Admin } from "src/types/Admin"; // Assume Admin type exists
import useErrorHandler from "src/utils/useErrorHandler";

export default function useAdminsById() {
  const [adminsById, setAdminsById] = useState<{ [adminId: string]: Admin }>(
    {}
  );
  const [admins, setAdmins] = useState<Admin[]>([]);
  const [loading, setLoading] = useState(true);
  const { clients } = useAppContext();
  const errorHandler = useErrorHandler();

  useEffect(() => {
    const fetchAndMapAdmins = async () => {
      setLoading(true);
      try {
        const admins = await fetchAdmins(clients);
        setAdmins(admins);
        const adminsMap = admins.reduce(
          (acc: typeof adminsById, admin: Admin) => {
            acc[admin.uid] = admin;
            return acc;
          },
          {}
        );
        setAdminsById(adminsMap);
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    };

    fetchAndMapAdmins();
  }, [clients, errorHandler]);

  return { adminsById, admins, loading };
}
