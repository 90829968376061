import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import FullscreenBox from "src/components/FullScreenBox";
import { ScreenTitle } from "src/components/Typography";
import Placeholder from "src/Placeholder.svg";

export default function NotFoundScreen(): JSX.Element {
  const navigate = useNavigate();

  return (
    <FullscreenBox
      sx={{
        backgroundColor: "#FAFAFA",
      }}
    >
      <Stack
        spacing={6}
        sx={{
          marginTop: "160px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={Placeholder} alt="" width="160" height="160" />
        <ScreenTitle>Page not found</ScreenTitle>
        <Typography>
          The page you&apos;`re looking for doesn&apos;`t exist
        </Typography>
        <Button onClick={() => navigate("/")}>Return home</Button>
      </Stack>
    </FullscreenBox>
  );
}
