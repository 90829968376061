import {
  amber,
  blue,
  blueGrey,
  brown,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  red,
  teal,
  yellow,
} from "@mui/material/colors";
import { UserOnboardingStatus } from "src/types/User";

export default function onboardingStatusToColor(status: UserOnboardingStatus) {
  switch (status) {
    case UserOnboardingStatus.CREATED_ACCOUNT:
      return grey;
    case UserOnboardingStatus.APPLIED:
      return brown;
    case UserOnboardingStatus.PRE_ADMISSION:
      return amber;
    case UserOnboardingStatus.ENROLLED:
      return lightBlue;
    case UserOnboardingStatus.COMPLETED_THEORY:
      return indigo;
    case UserOnboardingStatus.RANGE:
      return blue;
    case UserOnboardingStatus.GRADUATED:
      return teal;
    case UserOnboardingStatus.LICENSED:
      return lightGreen;
    case UserOnboardingStatus.EMPLOYED:
      return green;
    case UserOnboardingStatus.GLOBAL_EXIT:
      return yellow;
    case UserOnboardingStatus.UNSUCCESSFUL_EXIT:
      return grey;
    case UserOnboardingStatus.REJECTED:
    case UserOnboardingStatus.DROPPED:
      return red;
    default:
      return blueGrey;
  }
}
