import { collectionGroup, getDocs } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";
import { Cohort } from "src/types/Cohort";
import isCohortActive from "src/utils/isCohortActive";
import isCohortGuard from "src/utils/isCohortGuard";
import sortBy from "src/utils/sortBy";
import cleanFirestoreDoc from "./cleanFirestoreDoc";

export default async function fetchActiveCohorts({
  db,
}: Clients): Promise<Cohort[]> {
  const cohorts = (await getDocs(collectionGroup(db, "cohorts"))).docs
    .map(cleanFirestoreDoc)
    .filter(isCohortGuard)
    .filter(isCohortActive);

  return sortBy(cohorts, "name");
}
