import { Timestamp } from "firebase/firestore";

export enum ChatMessageSenderType {
  Participant = "participant",
  Admin = "admin",
}

export type ChatMessage = {
  uid: string;
  createdAt: string;
  createdAtFirestoreTimestamp: Timestamp;
  // the participant who is receiving or sending the message
  participantId: string;
  // can be a participant or admin
  senderId: string;
  senderType: ChatMessageSenderType;
  text: string;
  readAt?: string;
  readtAtFirestoreTimestamp?: Timestamp;
};
