import { Box, Grid } from "@mui/material";
import { differenceInDays, isFuture, isPast } from "date-fns";
import { OnlineTrainingDataGridRow } from "./OnlineTrainingDataGridRow";
import StatusSummaryCard from "./StatusSummaryCard"; // Import the StatusSummaryCard component

type Props = {
  data: OnlineTrainingDataGridRow[];
};

export default function OnlineTrainingKeyIndicatorsSection({ data }: Props) {
  // Grouping by activity level
  const loggedInThisWeek = (student: OnlineTrainingDataGridRow) =>
    !student.lastLoggedIn
      ? "No log in data"
      : differenceInDays(new Date(), student.lastLoggedIn) <= 7
      ? "Logged in this week"
      : differenceInDays(new Date(), student.lastLoggedIn) > 7 &&
        differenceInDays(new Date(), student.lastLoggedIn) < 14
      ? "At risk"
      : "Inactive";

  // Grouping by lesson completion
  const lessonCompletionCategory = (student: OnlineTrainingDataGridRow) => {
    const { lessonProgress } = student;
    if (!lessonProgress) return "No lessons completed";
    if (lessonProgress >= 21) return "Completed all lessons";
    if (lessonProgress >= 16) return "Completed more than 75% of lessons";
    if (lessonProgress >= 11) return "Completed more than 50% of lessons";
    if (lessonProgress >= 6) return "Completed more than 25% of lessons";
    return "Completed more than 1 lesson";
  };

  // Grouping by exam completion
  const examCompletionCategory = (student: OnlineTrainingDataGridRow) => {
    const { examCount } = student;
    if (!examCount) return "No exams completed";
    if (examCount >= 21) return "Completed more than 21 exams";
    if (examCount >= 14) return "Completed more than 14 exams";
    if (examCount >= 7) return "Completed more than 7 exams";
    return "Completed more than 1 exam";
  };

  // Grouping by testing status
  const testingCategory = (student: OnlineTrainingDataGridRow) => {
    const { theoryExamMilestone } = student;

    if (!theoryExamMilestone || theoryExamMilestone === "loading") {
      return "Missing test milestone";
    }

    if (theoryExamMilestone.completedAt) return "Test completed";

    const examDate = new Date(theoryExamMilestone.date);

    if (isPast(examDate)) {
      return "Overdue testing";
    }

    if (isFuture(examDate) && differenceInDays(examDate, new Date()) <= 7) {
      return "Testing this week";
    }

    if (
      isFuture(examDate) &&
      differenceInDays(examDate, new Date()) > 7 &&
      differenceInDays(examDate, new Date()) <= 14
    ) {
      return "Testing next week";
    }

    return "No upcoming test";
  };

  return (
    <Box>
      <Grid container spacing={2} justifyContent="space-between">
        {/* Activity level status summary */}
        <Grid item xs={12} sm={3}>
          <StatusSummaryCard
            title="Activity Level"
            students={data}
            groupByCallback={loggedInThisWeek}
          />
        </Grid>

        {/* Lesson completion status summary */}
        <Grid item xs={12} sm={3}>
          <StatusSummaryCard
            title="Lessons Completed"
            students={data}
            groupByCallback={lessonCompletionCategory}
          />
        </Grid>

        {/* Exam completion status summary */}
        <Grid item xs={12} sm={3}>
          <StatusSummaryCard
            title="Exams Completed"
            students={data}
            groupByCallback={examCompletionCategory}
          />
        </Grid>

        {/* Testing status summary */}
        <Grid item xs={12} sm={3}>
          <StatusSummaryCard
            title="Testing Status"
            students={data}
            groupByCallback={testingCategory}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
