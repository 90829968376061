import { AmericanTimezone, CohortMeeting } from "./CohortMeeting";

export default function isCohortMeetingGuard(
  obj: unknown
): obj is CohortMeeting {
  if (typeof obj !== "object") return false;
  const cohortMeeting = obj as CohortMeeting;

  return (
    typeof cohortMeeting.name === "string" &&
    typeof cohortMeeting.startDate === "string" &&
    typeof cohortMeeting.durationMinutes === "number" &&
    typeof cohortMeeting.cohortId === "string" &&
    typeof cohortMeeting.meetingLink === "string" &&
    Object.values(AmericanTimezone).includes(cohortMeeting.timezone)
  );
}
