import { deleteDoc, doc } from "firebase/firestore";
import Joi from "joi";
import { Reimbursement } from "src/types/Reimbursement";
import { UserAccount } from "src/types/User";
import serviceFunction from "src/utils/serviceFunction";

type Data = {
  user: UserAccount;
  reimbursement: Reimbursement;
};

const schema = Joi.object<Data>({
  user: Joi.object().required(),
  reimbursement: Joi.object().required(),
});

const deleteReimbursement = serviceFunction(
  schema,
  async ({ user, reimbursement }, { db }): Promise<void> => {
    await deleteDoc(
      doc(db, `users/${user.uid}/reimbursements/${reimbursement.uid}`)
    );
  }
);

export default deleteReimbursement;
