import { deleteDoc, doc } from "firebase/firestore";
import Joi from "joi";
import { StudentPlanMilestone } from "src/types/StudentPlan";
import { UserAccount } from "src/types/User";
import serviceFunction from "src/utils/serviceFunction";

type Data = {
  user: UserAccount;
  milestone: StudentPlanMilestone;
};

const schema = Joi.object<Data>({
  user: Joi.object().required(),
  milestone: Joi.object().required(),
});

const deleteMilestone = serviceFunction(
  schema,
  async ({ user, milestone }, { db }): Promise<void> => {
    await deleteDoc(doc(db, `users/${user.uid}/plan/${milestone.uid}`));
  }
);

export default deleteMilestone;
