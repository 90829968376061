import USState from "src/types/states";

export default function isStateOnboarded(state: USState) {
  if (
    state === USState.CA ||
    state === USState.MA ||
    state === USState.PA ||
    state === USState.CO ||
    state === USState.NY
  )
    return true;
  return false;
}
