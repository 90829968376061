import { Timestamp } from "firebase/firestore";

// Define specific status types for each stage
export enum IncarcerationStatus {
  in_custody_pre_trial = "In-Custody: Pre-Trial",
  in_custody_sentenced = "In-Custody: Sentenced",
  in_custody_transferred = "In-Custody: Transferred",
  out_custody_unrestricted = "Out-of-Custody: Unrestricted",
  out_custody_supervision = "Out-of-Custody: Supervision",
  justice_involved = "Justice-Involved",
  non_justice_involved = "No Justice Involvement",
}

export enum DeprecatedApplicationStatus {
  interviewed = "Interviewed",
  dropped = "Dropped",
}

export enum ApplicationStatus {
  rejected = "Rejected",
  inactive = "Inactive",
  withdrawn = "Withdrawn",
  on_hold = "On Hold",
  conditionally_enrolled = "Conditional Acceptance",
  enrolled = "Accepted",
}

export enum EnrollmentStatus {
  theory_training = "Theory Training",
  completed_theory_training = "Completed Theory",
  skills_training = "Skills Training",
  completed_skills_training = "Completed Skills Training",
  licensed = "Licensed",
  justifiable_exit = "Justifiable Exit",
  unsuccessful_exit = "Unsuccessful Exit",
}

export enum EmploymentStatus {
  economically_inactive = "Economically Inactive",
  job_searching = "Job Searching",
  conditional_offer = "Conditional Offer",
  employed = "Employed",
}

export type ProgramStatus =
  | keyof typeof IncarcerationStatus
  | keyof typeof ApplicationStatus
  | keyof typeof EnrollmentStatus
  | keyof typeof EmploymentStatus;

export type StatusHistory<T extends ProgramStatus> = {
  uid: string;
  previousStatus: T;
  previousStatusDetails?: string;
  previousInternalStatusDetails?: string;
  status: T;
  statusDetails?: string;
  internalStatusDetails?: string;
  timestamp: string;
  timestampFirestoreTimestamp: Timestamp;
  changedByAdminId: string;
};
