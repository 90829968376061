import { AddOutlined, WarningOutlined } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  Modal,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as EmptyBoxIcon } from "src/assets/EmptyBox.svg";
import Button from "src/components/Button";
import ModalContainer from "src/components/ModalContainer";
import { ListSkeleton } from "src/components/Skeleton/closet";
import { useAppContext } from "src/contexts/AppContext";
import createUserChatMessage from "src/firebase/createUserChatMessage";
import {
  StudentReference,
  StudentReferenceType,
} from "src/types/StudentReference";
import { UserAccount } from "src/types/User";
import useBackgroundProfile from "src/utils/useBackgroundProfile";
import useUserReferences from "src/utils/useUserReferences";
import { MIN_NUMBER_REFERENCES } from "./OnboardingScreen";
import ProgressIndicator from "./ProgressIndicator";
import ReferenceCard from "./ReferenceCard";
import ReferenceForm, { ReferenceFormData } from "./ReferenceForm";

type Props = {
  client: UserAccount;
  onChangeTotalReferences?: (totalItems: number) => void;
};

export default function StudentReferenceSection({
  client: user,
  onChangeTotalReferences,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();
  const [creatingType, setCreatingType] = useState<
    "regular" | "community_supervision" | undefined
  >();
  const [selected, setSelected] = useState<StudentReference>();
  const {
    references,
    communitySupervisor,
    createNewReference,
    updateExistingReference,
    loading,
    totalReferences,
  } = useUserReferences(user);
  const { clients } = useAppContext();

  const { isUnderSupervision } = useBackgroundProfile(user);
  const navigate = useNavigate();

  const handleCreate = async (data: ReferenceFormData) => {
    await createNewReference(data);
    setCreatingType(undefined);

    if (onChangeTotalReferences) onChangeTotalReferences(totalReferences + 1);
  };

  const handleUpdate = async (data: ReferenceFormData) => {
    // typen narrowing
    if (!selected) return;

    await updateExistingReference(selected, data);
    setSelected(undefined);
  };

  const handleNotOnSupervisionClick = async () => {
    await createUserChatMessage(
      {
        user,
        text: "The platform is asking me to add my community supervisor information, but I am not on supervised probation or parole. Please adjust this on my profile!",
      },
      clients
    ).finally(() => {
      navigate("/coach");
    });
  };

  if (loading) {
    return (
      <Card>
        <CardContent>
          <ListSkeleton />
        </CardContent>
      </Card>
    );
  }

  const needsToAddCommunitySupervisor =
    isUnderSupervision && !communitySupervisor;

  return (
    <Box overflow="auto">
      <Stack direction="row" alignItems="center" gap={1} marginBottom={2}>
        <Box>
          <ProgressIndicator
            progress={references.length}
            total={MIN_NUMBER_REFERENCES}
            label={t("supporters added")}
          />
        </Box>
      </Stack>
      {needsToAddCommunitySupervisor && (
        <Alert
          severity="info"
          variant="standard"
          sx={{ marginY: theme.spacing(2) }}
          action={
            <Button
              variant="outlined"
              color="inherit"
              fullWidth
              onClick={() => setCreatingType("community_supervision")}
            >
              {t("ADD")}
            </Button>
          }
        >
          <AlertTitle>{t("Your Probation/Parole Officer")}</AlertTitle>
          <Typography variant="body2">
            {t(
              "We'll reach out to make sure they understand how to support you in this program."
            )}
          </Typography>

          <Button
            color="error"
            startIcon={<WarningOutlined />}
            sx={{ paddingLeft: 0, marginTop: 2 }}
            onClick={handleNotOnSupervisionClick}
          >
            {t("Not under supervision? Click here")}
          </Button>
        </Alert>
      )}

      {references.length ? (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={4}
          >
            <Typography>{t("Support network")}</Typography>
            <Button
              onClick={() => setCreatingType("regular")}
              variant={
                references.length < MIN_NUMBER_REFERENCES
                  ? "contained"
                  : "outlined"
              }
              startIcon={<AddOutlined />}
            >
              {t("Add Reference")}
            </Button>
          </Stack>

          <Stack gap={2}>
            {references.map((reference) => (
              <Box key={reference.uid}>
                <ReferenceCard
                  onEdit={() => setSelected(reference)}
                  reference={reference}
                />
              </Box>
            ))}
          </Stack>
        </>
      ) : (
        <Card variant="outlined">
          <CardContent>
            <Stack width="100%" alignItems="center" gap={theme.spacing(2)}>
              <EmptyBoxIcon width={200} />
              <Typography>{t("No supporters have been added yet.")}</Typography>
              <Button
                variant="contained"
                onClick={() => setCreatingType("regular")}
                startIcon={<AddOutlined />}
              >
                {t("Add my first supporter")}
              </Button>
            </Stack>
          </CardContent>
        </Card>
      )}

      <Modal open={!!creatingType} onClose={() => setCreatingType(undefined)}>
        <div>
          <ModalContainer>
            <Card>
              <CardContent>
                <ReferenceForm
                  onSubmit={handleCreate}
                  defaultValues={
                    creatingType === "community_supervision"
                      ? {
                          type: StudentReferenceType.community_supervision,
                          relationship: "Probation/Parole Officer",
                        }
                      : undefined
                  }
                />
              </CardContent>
            </Card>
          </ModalContainer>
        </div>
      </Modal>

      <Modal open={!!selected} onClose={() => setSelected(undefined)}>
        <div>
          <ModalContainer>
            <Card>
              <CardContent>
                <ReferenceForm
                  onSubmit={handleUpdate}
                  defaultValues={selected}
                />
              </CardContent>
            </Card>
          </ModalContainer>
        </div>
      </Modal>
    </Box>
  );
}
