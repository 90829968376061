/**
 * Validates a Social Security Number (SSN).
 * Accepts SSN in formats: XXX-XX-XXXX, XXXXXXXXX, or XXX XX XXXX.
 * @param {string} ssn - The SSN to validate.
 * @returns {boolean} True if the SSN is valid, false otherwise.
 */
export default function isValidSSN(ssn: unknown): boolean {
  // Remove dashes or spaces for simplicity
  if (typeof ssn !== "string") return false;

  const cleanSSN = ssn.replace(/[- ]/g, "");

  // Check if the SSN has exactly nine digits
  if (!/^\d{9}$/.test(cleanSSN)) {
    return false;
  }

  // Split the SSN into its parts
  const part1 = cleanSSN.substring(0, 3);
  const part2 = cleanSSN.substring(3, 5);
  const part3 = cleanSSN.substring(5, 9);

  // Check for any section that is entirely zeros
  if (part1 === "000" || part2 === "00" || part3 === "0000") {
    return false;
  }

  // Additional checks can be added here (e.g., avoid specific patterns)

  return true;
}
