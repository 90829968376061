import { addDoc, collection, getDoc } from "firebase/firestore";
import Joi from "joi";
import { MilestoneTaskFormData } from "src/pages/ClientScreen/StudentPlanTab/MilestoneTaskForm";
import { MilestoneTask } from "src/types/MilestoneTask";
import { StudentPlanMilestone } from "src/types/StudentPlan";
import isMilestoneTaskGuard from "src/utils/isMilestoneTaskGuard";
import serviceFunction from "src/utils/serviceFunction";
import cleanFirestoreDoc from "./cleanFirestoreDoc";
import generateFirestoreTimestamps from "./generateFirestoreTimestamps";

type Data = MilestoneTaskFormData & {
  milestone: StudentPlanMilestone;
};

const schema = Joi.object<Data>({
  milestone: Joi.object().required(),
  title: Joi.string().required(),
  deadline: Joi.date().required(),
  body: Joi.string().optional(),
  link: Joi.string().optional(),
});

const createMilestoneTask = serviceFunction(
  schema,
  async ({ deadline, milestone, ...data }, { db }): Promise<MilestoneTask> => {
    const doc = await addDoc(
      collection(db, `users/${milestone.userId}/plan/${milestone.uid}/tasks`),
      {
        userId: milestone.userId,
        milestoneId: milestone.uid,
        ...data,
        ...generateFirestoreTimestamps(deadline, "deadline"),
        ...generateFirestoreTimestamps(new Date(), "createdAt"),
        ...generateFirestoreTimestamps(new Date(), "lastUpdatedAt"),
        completedAt: null,
        completedAtFirestoreTimestamp: null,
      }
    );

    const task = cleanFirestoreDoc(await getDoc(doc));

    if (!isMilestoneTaskGuard(task))
      throw new Error("Invalid action item format.");

    return task;
  }
);

export default createMilestoneTask;
