import { CalendarMonth, Rocket } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import { isBefore } from "date-fns";
import Lottie from "lottie-react";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Confetti from "src/assets/Lotties/Confetti.json"; // Import the confetti Lottie JSON
import { ReactComponent as PuzzleIcon } from "src/assets/Puzzle.svg";
import ModalContainer from "src/components/ModalContainer";
import { StudentPlanMilestone } from "src/types/StudentPlan";
import formatDateString from "src/utils/formatDateString";

interface OverdueMilestoneModalProps {
  overdueMilestone?: StudentPlanMilestone;
  onComplete: () => void;
  onReschedule: () => void;
}

export default function OverdueMilestoneCheckInModal({
  overdueMilestone,
  onComplete,
  onReschedule,
}: OverdueMilestoneModalProps) {
  const { t } = useTranslation();
  const [showConfetti, setShowConfetti] = useState(false); // State to control confetti visibility

  const handleComplete = () => {
    setShowConfetti(true); // Show confetti when milestone is marked complete
    setTimeout(() => {
      setShowConfetti(false); // Hide confetti after 3 seconds
      onComplete();
    }, 3000);
  };

  const cutoffDate = new Date("2024-09-18");

  if (
    overdueMilestone &&
    isBefore(new Date(overdueMilestone.date), cutoffDate)
  ) {
    return <div />;
  }

  if (showConfetti) {
    return (
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          zIndex: 9999,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          pointerEvents: "none", // Ensure confetti doesn't block interactions
          overflow: "hidden", // Prevent scrolling due to large animation
        }}
      >
        {/* Lottie animation with a larger size */}
        <Lottie
          animationData={Confetti}
          autoplay
          loop={false} // Confetti doesn't need to loop
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
      </Box>
    );
  }
  return (
    <Modal open={!!overdueMilestone}>
      <ModalContainer>
        {showConfetti && (
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              zIndex: 9999,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              pointerEvents: "none", // Ensure confetti doesn't block interactions
              overflow: "hidden", // Prevent scrolling due to large animation
            }}
          >
            {/* Lottie animation with a larger size */}
            <Lottie
              animationData={Confetti}
              autoplay
              loop={false} // Confetti doesn't need to loop
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
          </Box>
        )}
        <div>
          {overdueMilestone && (
            <Card>
              <CardHeader
                avatar={<PuzzleIcon width={100} />}
                title="Milestone Check-In"
                subheader={overdueMilestone.title}
              />
              <CardContent>
                <Typography gutterBottom>
                  <Trans
                    t={t}
                    defaults="Your goal was to complete <bold>{{ name }}</bold> by <bold>{{ date }}</bold>. How did it go? "
                    components={{ bold: <b /> }}
                    tOptions={{ interpolation: { escapeValue: false } }}
                    values={{
                      date: formatDateString(overdueMilestone.date, "date"),
                      name: `${overdueMilestone.title}`,
                    }}
                  />
                </Typography>
              </CardContent>

              <CardActions>
                <Box
                  width="100%"
                  sx={{
                    display: "flex",
                    margin: { xs: 4, sm: 0 }, // Add margin on small screens and up
                    flexDirection: {
                      xs: "column", // Stack vertically on extra small screens
                      sm: "row", // Display side by side on small screens and up
                    },
                    justifyContent: {
                      xs: "center", // Center align vertically stacked buttons
                      sm: "space-between", // Space between on small screens and up
                    },
                    gap: { xs: 2, sm: 0 }, // Add gap between buttons on small screens and up
                  }}
                >
                  <Button
                    onClick={handleComplete}
                    color="success"
                    variant="contained"
                    startIcon={<Rocket />}
                  >
                    {t("Mark as complete")}
                  </Button>
                  <Button
                    onClick={() => {
                      onReschedule();
                    }}
                    variant="outlined"
                    startIcon={<CalendarMonth />}
                  >
                    {t("Reschedule for later")}
                  </Button>
                </Box>
              </CardActions>
            </Card>
          )}
        </div>
      </ModalContainer>
    </Modal>
  );
}
