import {
  HandshakeTwoTone,
  RouteTwoTone,
  VideoCameraFrontTwoTone,
} from "@mui/icons-material";
import { t } from "i18next";
import { SliderExplanationStepProp } from "../SliderExplanationScreen";

export type VideoAskItem = {
  formId: string;
  label: string;
  shareId: string;
  sliderSteps?: SliderExplanationStepProp[];
};

const TEST_INTERVIEW = {
  formId: "ae487cb8-f308-4e78-8590-70f0fa3a654d",
  shareId: "fwi0cmap6",
  label: "Test Interview",
};

type VideoAskKey = "LEGACY_SUCCESS_PLAN" | "SUCCESS_PLAN" | "FIRST_INTERVIEW";

const VideoAskSchema: Record<VideoAskKey, VideoAskItem> = {
  FIRST_INTERVIEW:
    process.env.NODE_ENV === "development"
      ? TEST_INTERVIEW
      : {
          formId: "29c98395-f254-4966-ad5e-242a5f5bc8c6",
          shareId: "forbuhp81",
          label: t("First Interview"),
        },
  LEGACY_SUCCESS_PLAN: {
    formId: "15b0f1cb-7587-498c-93a1-fb426d450fca",
    shareId: "faabhf1c5",
    label: t("Legacy Success Plan"),
  },
  SUCCESS_PLAN:
    process.env.NODE_ENV === "development"
      ? TEST_INTERVIEW
      : {
          formId: "c490643e-e778-47ac-9b79-6f464d9bc423",
          shareId: "f84idswrv",
          label: t("Success Plan"),
          sliderSteps: [
            {
              type: "component",
              icon: RouteTwoTone,
              title: "Setting You Up for Success",
              titleProps: { fontWeight: "bold" },
              body: "We’ll walk you through key things to consider so you’re fully prepared for the road ahead in your training",
            },
            {
              type: "component",
              icon: VideoCameraFrontTwoTone,
              title: "How This Works",
              titleProps: { fontWeight: "bold" },
              body: "You’ll record short video answers—this will only take a few minutes, and you can do it at your own pace.",
            },
            {
              type: "component",
              icon: HandshakeTwoTone,
              title: "Relax, We’ve Got Your Back",
              titleProps: { fontWeight: "bold" },
              body: "Be yourself—we’re here to support you every step of the way, no matter your situation.",
            },
          ],
        },
};

export default VideoAskSchema;
