import {
  Box,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Chip,
  Modal,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import SubmitButton from "src/components/Button/SubmitButton";
import ImageInput from "src/components/ImageInput";
import ModalContainer from "src/components/ModalContainer";
import { useAppContext } from "src/contexts/AppContext";
import { useSession } from "src/contexts/AuthContext";
import { StudentDocument } from "src/firebase/FirestoreClient";
import updateDocumentVerification from "src/firebase/updateDocumentVerification";
import { useSessionAccountInformation } from "src/SessionBoundary";
import { useSnackbarContext } from "src/SnackbarProvider";
import { UserType, UserUploadedDocument } from "src/types/User";
import useErrorHandler from "src/utils/useErrorHandler";
import ID from "./OrgID.svg";

export type DocumentDescription = {
  label: string;
  key: StudentDocument;
  description?: string;
  optional?: boolean;
};

type Props = {
  documentDescription: DocumentDescription;
  submittedDocument?: UserUploadedDocument;
  handleSubmission: (file: File, key: StudentDocument) => Promise<void>;
  children?: React.ReactNode;
};

type DocumentCardData = {
  uploadedDocument: File;
};

export default function DocumentCard({
  documentDescription,
  submittedDocument,
  handleSubmission,
  children,
}: Props): JSX.Element {
  const session = useSession();
  const { t } = useTranslation();
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(!!submittedDocument?.verifiedAt);
  const snackbarContext = useSnackbarContext();
  const account = useSessionAccountInformation();
  const { clients } = useAppContext();
  const errorHandler = useErrorHandler();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
    watch,
  } = useForm<DocumentCardData>();

  const handleOpen = async () => {
    setLoading(true);
    if (submittedDocument) {
      snackbarContext.alert(
        "info",
        t("Fetching the document from our secure storage.")
      );
      const file = await session.getStorageBlob(
        submittedDocument.name,
        submittedDocument.userId
      );
      if (file) setValue("uploadedDocument", file);
    }
    setLoading(false);
    setIsOpen(true);
  };

  const handleVerification = () => {
    if (account.type !== UserType.Admin)
      throw new Error("Only admins can verify");

    if (!submittedDocument)
      throw new Error("Must have submitted the document to verify it");
    updateDocumentVerification(
      { verified: !isVerified, document: submittedDocument },
      clients
    )
      .then(() => setIsVerified(!isVerified))
      .catch(errorHandler);
  };

  const file = watch("uploadedDocument");

  return (
    <div>
      <Card variant="outlined">
        <Stack spacing={theme.spacing(1)}>
          <CardContent>
            <Stack direction="row" alignItems="center" spacing={0.5}>
              {submittedDocument ? (
                <Chip
                  label={t("Submitted")}
                  variant="outlined"
                  size="small"
                  color="success"
                />
              ) : (
                <>
                  {documentDescription.optional ? (
                    <Chip
                      label={t("Optional")}
                      variant="outlined"
                      size="small"
                    />
                  ) : (
                    <Chip
                      label={t("Required")}
                      color="warning"
                      variant="outlined"
                      size="small"
                    />
                  )}
                  <Typography variant="body2" color="text.secondary">
                    {t("Not Submitted")}
                  </Typography>
                </>
              )}
            </Stack>

            <Typography fontWeight="bold" mt={theme.spacing(1)}>
              {documentDescription.label}
            </Typography>

            <Typography variant="body2" color="text.secondary">
              {isVerified ? t("Verified 🎉") : t("Not Verified")}
            </Typography>
            <Box marginTop={theme.spacing(2)}>{children}</Box>
          </CardContent>
          <CardActions>
            {submittedDocument ? (
              <Button
                variant="outlined"
                onClick={handleOpen}
                disabled={loading}
              >
                {t("View")}
              </Button>
            ) : (
              <Button variant="contained" onClick={handleOpen}>
                {t("Upload")}
              </Button>
            )}
          </CardActions>
        </Stack>
      </Card>

      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <div>
          <ModalContainer>
            <Card>
              <CardContent>
                {documentDescription.description && (
                  <Box mb={theme.spacing(2)}>
                    <Typography variant="body2" color="text.secondary">
                      {documentDescription.description}
                    </Typography>
                  </Box>
                )}
                <form
                  onSubmit={handleSubmit(async (data) => {
                    await handleSubmission(
                      data.uploadedDocument,
                      documentDescription.key
                    );
                    setIsOpen(false);
                  })}
                >
                  <ImageInput
                    label={documentDescription.label}
                    name="uploadedDocument"
                    control={control}
                    rules={{ validate: (v) => !!v }}
                    placeholderImage={ID}
                    placeholderImageWidth={176}
                  />

                  <Stack
                    direction="row"
                    sx={{ marginTop: 4 }}
                    alignItems="center"
                  >
                    {!submittedDocument ? (
                      <SubmitButton
                        size="large"
                        disabled={isSubmitting || !file}
                        loading={isSubmitting}
                        sx={{ marginRight: 4 }}
                      >
                        {t("Upload")}
                      </SubmitButton>
                    ) : submittedDocument.path !== file?.name ? (
                      <SubmitButton
                        size="large"
                        disabled={isSubmitting}
                        variant="outlined"
                        loading={isSubmitting}
                        sx={{ marginRight: 4 }}
                      >
                        {t("Re-upload")}
                      </SubmitButton>
                    ) : (
                      <div />
                    )}
                  </Stack>
                </form>
              </CardContent>
              <CardActions>
                {file && (
                  <a download={file.name} href={URL.createObjectURL(file)}>
                    {t("Download")}
                  </a>
                )}
                {file && account.type === UserType.Admin && (
                  <Stack direction="row" alignItems="center">
                    <Checkbox
                      onClick={handleVerification}
                      color="success"
                      size="small"
                      checked={isVerified}
                    />
                    <Typography variant="body2" color="text.secondary">
                      {isVerified ? t("Verified 🎉") : t("Not Verified")}
                    </Typography>
                  </Stack>
                )}
              </CardActions>
            </Card>
          </ModalContainer>
        </div>
      </Modal>
    </div>
  );
}
