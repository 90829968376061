import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import Joi from "joi";
import {
  BackgroundFormData,
  EligibilityFormData,
} from "src/pages/BackgroundSurveyScreen/BackgroundSurveyView";
import { UserAccount } from "src/types/User";
import isBackgroundGuard from "src/utils/isBackgroundGuard";
import serviceFunction from "src/utils/serviceFunction";
import cleanFirestoreDoc from "./cleanFirestoreDoc";
import generateFirestoreTimestamps from "./generateFirestoreTimestamps";

const JoiSchema: Record<keyof EligibilityFormData | "user", Joi.AnySchema> = {
  user: Joi.object().required(),
  hasSocialSecurityCardAvailable: Joi.boolean().optional(),
  hasSocialSecurityNumber: Joi.boolean().required(),
  ssn: Joi.string().optional(),
  driverLicenseExpirationDate: Joi.date().optional(),
  driverLicenseIssueDate: Joi.date().optional(),
  driverLicenseNumber: Joi.string().optional(),
  driverLicenseState: Joi.string().optional(),
};

const schema = Joi.object<EligibilityFormData & { user: UserAccount }>(
  JoiSchema
);

const upsertEligibilityBackground = serviceFunction(
  schema,
  async (
    {
      user,

      driverLicenseExpirationDate,
      driverLicenseIssueDate,
      ...rest
    },
    { db }
  ): Promise<BackgroundFormData> => {
    const updated = {
      ...generateFirestoreTimestamps(
        driverLicenseIssueDate,
        "driverLicenseIssueDate"
      ),
      ...generateFirestoreTimestamps(
        driverLicenseExpirationDate,
        "driverLicenseExpirationDate"
      ),
      ...rest,
    };
    await setDoc(doc(db, "backgrounds", user.uid), updated, { merge: true });

    const firestoreDoc = await getDoc(doc(db, "backgrounds", user.uid));

    const background = cleanFirestoreDoc(firestoreDoc);

    if (!isBackgroundGuard(background)) {
      throw new Error("Something went wrong");
    }

    await updateDoc(doc(db, "users", user.uid), {
      application: {
        ...user.application,
        ...generateFirestoreTimestamps(
          driverLicenseIssueDate,
          "driverLicenseIssueDate"
        ),
        ...generateFirestoreTimestamps(
          driverLicenseExpirationDate,
          "driverLicenseExpirationDate"
        ),
        ...rest,
      },
    });

    return background;
  }
);

export default upsertEligibilityBackground;
