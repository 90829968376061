import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppContext } from "src/contexts/AppContext";
import { useSession } from "src/contexts/AuthContext";
import fetchDocuments from "src/firebase/fetchDocuments";
import { StudentDocument } from "src/firebase/FirestoreClient";
import { useSnackbarContext } from "src/SnackbarProvider";
import { UserUploadedDocument } from "src/types/User";
import useErrorHandler from "src/utils/useErrorHandler";

export default function useUploadedDocuments(client: { uid: string }) {
  const [documents, setDocuments] = useState<UserUploadedDocument[]>();
  const { clients } = useAppContext();
  const errorHandler = useErrorHandler();
  const session = useSession();
  const { alert } = useSnackbarContext();
  const { t } = useTranslation();

  const findDocument = useCallback(
    (type: StudentDocument) =>
      (documents || []).find((doc) => doc.name === type),
    [documents]
  );

  const loadDocuments = useCallback(async () => {
    fetchDocuments(client.uid, clients)
      .then((documents) => {
        setDocuments(documents);
      })
      .catch(errorHandler);
  }, [errorHandler, client, clients]);

  const uploadDocument = useCallback(
    async (file: File, type: StudentDocument) => {
      await session.uploadStudentDocument({ file, type }, client?.uid);
      alert("success", t("Successfully uploaded the document"));
      await loadDocuments();
    },
    [alert, t, session, loadDocuments, client]
  );

  useEffect(() => {
    loadDocuments();
  }, [loadDocuments]);

  return { documents, findDocument, uploadDocument, refetch: loadDocuments };
}
