import { ChipProps } from "@mui/material";
import { BackgroundCheckReport } from "src/types/BackgroundCheck";

export type CoachAction = {
  action: string;
  color: ChipProps["color"];
};

export function determineCoachAdmissionAction({
  applicationScore,
  lastLoggedIn,
  applicationStatus,
}: {
  applicationScore?: number;
  lastLoggedIn?: string;
  applicationStatus?: string;
}): CoachAction | undefined {
  const now = new Date();
  const lastSeenDate = lastLoggedIn ? new Date(lastLoggedIn) : undefined;

  if (!applicationStatus) {
    const daysSinceLastSeen = lastSeenDate
      ? Math.floor(
          (now.getTime() - lastSeenDate.getTime()) / (1000 * 60 * 60 * 24)
        )
      : undefined;

    // Ineligible case: Coach needs to reject
    if (applicationScore !== undefined && applicationScore < 0) {
      return { action: "Needs to reject", color: "error" };
    }

    // Inactivity cases based on days since last seen
    if (daysSinceLastSeen !== undefined && daysSinceLastSeen >= 14) {
      return { action: "Need to move to inactivity", color: "secondary" };
    }
    if (daysSinceLastSeen !== undefined && daysSinceLastSeen >= 7) {
      return { action: "Need to reactivate", color: "warning" };
    }
    if (daysSinceLastSeen !== undefined && daysSinceLastSeen >= 3) {
      return { action: "Need to check-in", color: "info" };
    }
  }

  return undefined; // No action required
}

export function determineConditionalAcceptanceAction({
  lastLoggedIn,
  backgroundCheckReport,
}: {
  lastLoggedIn?: string;
  backgroundCheckReport?: BackgroundCheckReport;
}): CoachAction | undefined {
  const now = new Date();
  const lastSeenDate = lastLoggedIn ? new Date(lastLoggedIn) : undefined;

  const daysSinceLastSeen = lastSeenDate
    ? Math.floor(
        (now.getTime() - lastSeenDate.getTime()) / (1000 * 60 * 60 * 24)
      )
    : undefined;

  // Inactivity cases based on days since last seen
  if (daysSinceLastSeen !== undefined && daysSinceLastSeen >= 14) {
    return { action: "Need to move to inactivity", color: "secondary" };
  }
  if (daysSinceLastSeen !== undefined && daysSinceLastSeen >= 7) {
    return { action: "Need to reactivate", color: "warning" };
  }

  if (!backgroundCheckReport) {
    return { action: "Need to run background check", color: "warning" };
  }

  if (
    backgroundCheckReport.mvrRiskScore &&
    backgroundCheckReport.criminalRiskScore &&
    !backgroundCheckReport.disposition
  ) {
    return { action: "Need to make background check decision", color: "info" };
  }

  if (backgroundCheckReport.disposition === "rejected") {
    return { action: "Need to reject", color: "error" };
  }

  if (daysSinceLastSeen !== undefined && daysSinceLastSeen >= 3) {
    return { action: "Need to check-in", color: "info" };
  }

  return undefined; // No action required
}
