import { collection, getDocs, query, where } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";
import { Admin } from "src/types/Admin";
import { UserAccount } from "src/types/User";
import isStudentGuard from "src/utils/isStudentGuard";
import sortBy from "src/utils/sortBy";
import cleanFirestoreDoc from "./cleanFirestoreDoc";

export default async function fetchStudentsByCoach(
  { coach }: { coach: Admin },
  { db }: Clients
): Promise<UserAccount[]> {
  const students = (
    await getDocs(
      query(collection(db, "users"), where("emergeCoachId", "==", coach.uid))
    )
  ).docs
    .map(cleanFirestoreDoc)
    .filter(isStudentGuard);

  const sorted = sortBy(students, "firstName");

  return sorted;
}
