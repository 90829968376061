/* eslint-disable @typescript-eslint/no-explicit-any */
import { collection, getDocs } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";

import isUserDocumentGuard from "src/types/isUserDocumentGuard";
import { UserUploadedDocument } from "src/types/User";
import cleanFirestoreDoc from "./cleanFirestoreDoc";

export default async function fetchDocuments(
  userId: string,
  { db }: Clients
): Promise<UserUploadedDocument[]> {
  const snapshot = await getDocs(collection(db, "users", userId, "documents"));

  const documents = snapshot.docs
    .map((doc) => {
      const cleanedDoc = cleanFirestoreDoc(doc);
      if (!cleanedDoc) return cleanedDoc;
      // Backwards compatibility: Move TYPE to name if present
      if (!(cleanedDoc as any).name) {
        (cleanedDoc as any).name = cleanedDoc.uid;
      }
      return cleanedDoc;
    })
    .filter(isUserDocumentGuard);

  return documents;
}
