import { collection, getDocs } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";
import { OrganizationDocument } from "src/types";
import isOrganizationDocument from "src/utils/isOrganizationDocumentGuard";
import cleanFirestoreDoc from "./cleanFirestoreDoc";

export default async function fetchOrganizationDocuments(
  organizationId: string,
  { db }: Clients
): Promise<OrganizationDocument[]> {
  const organizationDocuments = (
    await getDocs(collection(db, "organizations", organizationId, "documents"))
  ).docs
    .map(cleanFirestoreDoc)
    .filter(isOrganizationDocument);

  return organizationDocuments;
}
