/* eslint-disable react/function-component-definition */
import {
  FormLabel,
  Grid,
  TextField as Base,
  TextFieldProps as BaseProps,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { ForwardedRef } from "react";
import withController from "../withController";

export type TextInputProps = BaseProps & {
  onChange: Required<BaseProps>["onChange"];
  value: Required<BaseProps>["value"];
  prompt?: string;
};

/**
 * TextInputBase defines any custom behaviors and styles of our standard text input.
 */
function TextInput(
  { value, variant, prompt, ...rest }: TextInputProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const variantProps: Partial<BaseProps> =
    variant === "filled"
      ? {
          variant,
          InputProps: {
            disableUnderline: true,
            sx: {
              backgroundColor: grey[50],
              borderRadius: 2,
            },
          },
        }
      : {
          variant,
        };

  return (
    <Grid container alignItems={{ xs: "flex-start", sm: "center" }} ref={ref}>
      {prompt && (
        <Grid item xs={12} sm={6}>
          <FormLabel>{prompt}</FormLabel>
        </Grid>
      )}
      <Grid item xs={12} sm={prompt ? 6 : 12}>
        <Base
          inputRef={ref}
          {...rest}
          value={value || ""}
          {...variantProps}
          fullWidth
        />
      </Grid>
    </Grid>
  );
}

export const TextInputBase = React.forwardRef(TextInput) as typeof TextInput;

export default withController(TextInputBase);
