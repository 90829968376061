import { doc, setDoc } from "firebase/firestore";
import Joi from "joi";
import { VideoAskItem } from "src/pages/OnboardingScreen/videoAskIds.enum";
import { UserAccount } from "src/types/User";
import serviceFunction from "src/utils/serviceFunction";

type Data = {
  user: UserAccount;
  videoAsk: VideoAskItem;
};

const schema = Joi.object<Data>({
  user: Joi.object().required(),
  videoAsk: Joi.object().required(),
});

const upsertVideoAskCompletion = serviceFunction(
  schema,
  async ({ user, videoAsk }, { db }): Promise<void> => {
    await setDoc(
      doc(db, `users/${user.uid}`, "videoasks", videoAsk.formId),
      {
        completed: true,
      },
      { merge: true }
    );
  }
);

export default upsertVideoAskCompletion;
