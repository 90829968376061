import { doc, updateDoc } from "firebase/firestore";
import Joi from "joi";
import { BankAccount } from "src/types/BankAccount";
import { UserAccount } from "src/types/User";
import serviceFunction from "src/utils/serviceFunction";

type Data = {
  user: UserAccount;
  bankAccount: BankAccount;
};

const schema = Joi.object<Data>({
  user: Joi.object().required(),
  bankAccount: Joi.alternatives()
    .try(
      Joi.object({
        type: Joi.string().valid("ach").required(),
        routingNumber: Joi.string().required(),
        accountNumber: Joi.string().required(),
      }),
      Joi.object({
        type: Joi.string().valid("cashapp").required(),
        appUsername: Joi.string().required(),
      }),
      Joi.object({
        type: Joi.string().valid("venmo").required(),
        appUsername: Joi.string().required(),
      })
    )
    .required(),
});

const updateUserBankAccountInformation = serviceFunction(
  schema,
  async ({ user, bankAccount }, { db }): Promise<void> => {
    await updateDoc(doc(db, `users/${user.uid}`), {
      bankAccount,
    });
  }
);

export default updateUserBankAccountInformation;
