import { useEffect, useMemo, useState } from "react";
import { useAppContext } from "src/contexts/AppContext";
import fetchCohorts from "src/firebase/fetchCohorts";
import { Cohort } from "src/types/Cohort";
import useErrorHandler from "./useErrorHandler";

export default function useCohorts() {
  const [cohorts, setCohorts] = useState<Cohort[]>([]);
  const errorHandler = useErrorHandler();
  const { clients } = useAppContext();

  useEffect(() => {
    async function loadData() {
      try {
        const response = await fetchCohorts(clients);
        setCohorts(response);
      } catch (error) {
        errorHandler(error);
      }
    }
    loadData();
  }, [clients, errorHandler]);

  const cohortsById = useMemo(
    () =>
      cohorts.reduce((acc, cohort) => {
        acc[cohort.uid] = cohort;
        return acc;
      }, {} as Record<string, Cohort>),
    [cohorts]
  );

  return { cohortsById, cohorts };
}
