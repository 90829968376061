import { isBefore } from "date-fns";
import { StudentPlanMilestone } from "src/types/StudentPlan";

export default function isMilestoneOverdue(
  milestone: StudentPlanMilestone
): boolean {
  return (
    isBefore(new Date(milestone.date), new Date()) && !milestone.completedAt
  );
}
