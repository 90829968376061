import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import esMonolith from "./monolith/es.json";

// TODO: delete this declaration once this is fixed: https://github.com/i18next/react-i18next/issues/1587
declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

const I18nNamespaceList = ["monolith"];

const resources = {
  es: {
    monolith: esMonolith,
  },
  en: {
    monolith: {},
  },
} as const;

i18n.use(initReactI18next).init({
  lng: "en",
  ns: I18nNamespaceList,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resources: resources as any,
  // allow keys to be phrases having `:` and `.`
  nsSeparator: false,
  keySeparator: false,
  returnNull: false,
});

export default i18n;
