import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import FullscreenBox from "src/components/FullScreenBox";
import { ScreenTitle } from "src/components/Typography";
import Placeholder from "src/Placeholder.svg";
import { useCurrentUser } from "src/SessionBoundary";

const DisabledReasonText = () => {
  const user = useCurrentUser();
  const { t } = useTranslation();

  switch (user.applicationStatus) {
    case "rejected":
      return (
        <>
          <Typography>
            {user.applicationStatusDetails
              ? t(
                  "Unfortunately, we cannot move you forward in the application process for the following reason: {{reason}}. ",
                  { reason: user.applicationStatusDetails }
                )
              : t(
                  "Unfortunately, we cannot move you forward in the application process at this time."
                )}
          </Typography>
          <Typography marginTop={2}>
            {t(
              "We have other resources and support services we can connect you with to support you. Please contact our team if you have any questions."
            )}
          </Typography>
        </>
      );

    case "withdrawn":
      return (
        <Typography>
          {t(
            "We deactivated your account at your request. Ready to jump back in? Please reach out to us!"
          )}
        </Typography>
      );
    case "inactive":
    case "dropped":
    default:
      return (
        <>
          <Typography>{t("Your account is currently inactive.")}</Typography>
          <Typography marginTop={2}>
            {t(
              "Think this was a mistake? Reach out to us, and we'll help you get it reactivated in no time!"
            )}
          </Typography>
        </>
      );
  }
};

export default function DisabledAccountScreen() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useCurrentUser();

  return (
    <FullscreenBox
      sx={(theme) => ({
        backgroundColor: theme.palette.background.paper,
      })}
    >
      <Stack
        spacing={6}
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingX: theme.spacing(2),
          marginTop: "160px",
        })}
      >
        <img src={Placeholder} alt="" width="160" height="160" />
        <ScreenTitle sx={{ textAlign: "center" }}>
          {t("Welcome back, {{ firstName }}!", { firstName: user.firstName })}
        </ScreenTitle>
        <Box sx={{ textAlign: "center" }}>
          <DisabledReasonText />
        </Box>

        <Stack direction="row">
          <Button
            variant="contained"
            size="large"
            onClick={() => navigate("/coach")}
          >
            {t("Get in touch")}
          </Button>
        </Stack>
      </Stack>
    </FullscreenBox>
  );
}
