import { EmailOutlined } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import CopyButton from "./CopyButton";

type Props = { email: string };

export default function CopiableEmail({ email }: Props) {
  return (
    <Stack direction="row" spacing={0.25} alignItems="center">
      <EmailOutlined
        fontSize="small"
        sx={(theme) => ({ color: theme.palette.grey["500"] })}
      />
      <Typography variant="body2">{email}</Typography>
      <CopyButton text={email} />
    </Stack>
  );
}
