import { collection, getDocs } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";
import { Reimbursement } from "src/types/Reimbursement";
import isReimbursementGuard from "src/utils/isReimbursementGuard";
import cleanFirestoreDoc from "./cleanFirestoreDoc";

export default async function fetchReimbursements(
  userId: string,
  { db }: Clients
): Promise<Reimbursement[]> {
  const reimbursements = (
    await getDocs(collection(db, "users", userId, "reimbursements"))
  ).docs
    .map(cleanFirestoreDoc)
    .filter(isReimbursementGuard);

  return reimbursements.sort(
    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  );
}
