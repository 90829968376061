import { Chip, ChipProps, Typography } from "@mui/material";
import { blue, green, grey, orange, red } from "@mui/material/colors";
import { ReferenceStatus } from "src/types/StudentReference";

type Props = {
  status?: keyof typeof ReferenceStatus;
} & ChipProps;

function statusToColor(status: keyof typeof ReferenceStatus) {
  switch (status) {
    case "not_verified":
      return grey;
    case "ready_to_verify":
      return orange;
    case "verified":
      return blue;
    case "verified_subscribed":
      return green;
    case "declined":
    case "unresponsive":
      return red;
    default:
      return grey;
  }
}

export default function ReferenceStatusChip({ status, ...rest }: Props) {
  if (!status) return <Typography>-</Typography>;
  return (
    <Chip
      sx={{
        color: statusToColor(status)[900],
        borderColor: statusToColor(status)[100],
        backgroundColor: statusToColor(status)[100],
      }}
      label={status in ReferenceStatus ? ReferenceStatus[status] : status}
      {...rest}
    />
  );
}
