import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useOnboardingUnitProgress, {
  OnboardingUnitType,
} from "src/utils/useCurrentUnitResource";
import AppSkeleton from "../AppSkeleton";
import ResourceView from "../ResourceScreen/ResourceView";
import UnitView from "../UnitPage/UnitView";

export default function CourseOverviewScreen({
  type,
}: {
  type: OnboardingUnitType;
}) {
  const { courseId } = useParams<{ courseId: string }>();
  const navigate = useNavigate();
  if (!courseId) throw new Error("Missing ID parameter");

  const onboardingUnitProgress = useOnboardingUnitProgress(type);

  useEffect(() => {
    if (onboardingUnitProgress.isComplete)
      navigate(`/units/${onboardingUnitProgress.unitId}`);
  }, [
    navigate,
    onboardingUnitProgress.isComplete,
    onboardingUnitProgress.unitId,
  ]);

  if (!onboardingUnitProgress.unitId || onboardingUnitProgress.loading)
    return <AppSkeleton />;

  if (
    onboardingUnitProgress.isComplete ||
    !onboardingUnitProgress.currentLesson
  ) {
    return <UnitView unitId={onboardingUnitProgress.unitId} />;
  }

  return (
    <ResourceView
      resourceId={onboardingUnitProgress.currentLesson.id}
      onBack={() => navigate("/")}
      disableAutoSkipToNextSection
    />
  );
}
