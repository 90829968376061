import { ContentCopyOutlined, ShareOutlined } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import React from "react";
import useCopyContent from "./useCopyContent";

type CopyButtonProps = {
  text: string;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
  iconType?: "share" | "copy";
};

const CopyButton: React.FC<CopyButtonProps> = ({
  text,
  onSuccess,
  onError,
  iconType = "copy",
}) => {
  const handleCopy = useCopyContent({ onSuccess, onError });

  return (
    <IconButton
      aria-label="copy"
      color="primary"
      onClick={() => handleCopy(text)}
      size="small"
    >
      {iconType === "copy" ? (
        <ContentCopyOutlined sx={{ fontSize: 16 }} />
      ) : (
        <ShareOutlined sx={{ fontSize: 16 }} />
      )}
    </IconButton>
  );
};

export default CopyButton;
