import { Box } from "@mui/material";
import { useCurrentUser } from "src/SessionBoundary";
import AdminParticipantChat from "../DashboardPage/AdminParticipantChatTab/AdminParticipantChatTab";

export default function CoachChatScreen() {
  const user = useCurrentUser();
  return (
    <Box>
      <AdminParticipantChat client={user} />
    </Box>
  );
}
