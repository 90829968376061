/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { t } from "i18next";
import { Trans } from "react-i18next";
import { PRIVACY_POLICY, TERMS_OF_SERVICE } from "src/utils/constants";

const links = {
  termsLink: <a href={TERMS_OF_SERVICE} target="_blank" rel="noreferrer" />,
  policyLink: <a href={PRIVACY_POLICY} target="_blank" rel="noreferrer" />,
};

export function TermsPrivacySignUp(): JSX.Element {
  return (
    <Trans
      t={t}
      defaults={
        "By signing up, I accept Emerge Career's <termsLink>Terms</termsLink> and <policyLink>Privacy Policy</policyLink>."
      }
      components={links}
    />
  );
}

export function TermsPrivacySignIn(): JSX.Element {
  return (
    <Trans
      t={t}
      defaults={
        "By signing in and using Emerge Career, I agree to Emerge Career's <termsLink>Terms</termsLink> and <policyLink>Privacy Policy</policyLink>."
      }
      components={links}
    />
  );
}
