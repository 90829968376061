import { doc, updateDoc } from "firebase/firestore";
import Joi from "joi";
import MilestoneType from "src/types/MilestoneType.enum";
import { StudentPlanMilestone } from "src/types/StudentPlan";
import { UserAccount } from "src/types/User";
import serviceFunction from "src/utils/serviceFunction";
import generateFirestoreTimestamps from "./generateFirestoreTimestamps";

type Data = {
  user: UserAccount;
  milestone: StudentPlanMilestone;
  title: string;
  date: Date;
  type: MilestoneType;
};

const schema = Joi.object<Data>({
  user: Joi.object().required(),
  milestone: Joi.object().required(),
  title: Joi.string().required(),
  date: Joi.date().required(),
  type: Joi.string()
    .valid(...Object.values(MilestoneType))
    .required(),
});

const updateMilestone = serviceFunction(
  schema,
  async ({ user, milestone, title, date, type }, { db }): Promise<void> => {
    await updateDoc(doc(db, `users/${user.uid}/plan/${milestone.uid}`), {
      title,
      type,
      ...generateFirestoreTimestamps(new Date(date), "date"),
      ...generateFirestoreTimestamps(new Date(), "lastUpdatedAt"),
    });
  }
);

export default updateMilestone;
