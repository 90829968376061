import { Snackbar } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Button from "./components/Button";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const ServiceWorkerSnackbar = () => {
  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] =
    React.useState<ServiceWorker | null>(null);
  const { t } = useTranslation();
  const location = useLocation();

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setWaitingWorker(registration.waiting);
    setShowReload(true);
  };

  useEffect(() => {
    serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = async () => {
    if (!waitingWorker) return;
    waitingWorker.postMessage({ type: "SKIP_WAITING" });
    setShowReload(false);
    window.location.reload();
  };

  useEffect(() => {
    // check for sw updates on page change
    if (!navigator || !navigator.serviceWorker) return;
    navigator.serviceWorker
      .getRegistrations()
      .then((regs) => regs.forEach((reg) => reg.update()));
  }, [location]);

  useEffect(() => {
    // detect controller change and refresh the page
    if (!navigator.serviceWorker) return;
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      window.location.reload();
    });
  }, [showReload]);

  return (
    <Snackbar
      open={showReload}
      message={t("A new version is available!")}
      onClick={reloadPage}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      action={
        <Button size="large" onClick={reloadPage}>
          {t("Update")}
        </Button>
      }
    />
  );
};

export default ServiceWorkerSnackbar;
