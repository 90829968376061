import { Grid, Stack } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SubmitButton from "src/components/Button/SubmitButton";
import Selfie from "src/pages/DashboardPage/RangeTab/Selfie.svg";
import { Reimbursement } from "src/types/Reimbursement";
import { StudentPlanMilestone } from "src/types/StudentPlan";
import SelectInput from "../Card/SelectInput";
import ImageInput from "../ImageInput";
import NumberInput from "../Input/NumberInput";
import TextInput from "../Input/TextInput";
import { Header20 } from "../Typography";

export type ReimbursementFormData = Omit<
  Reimbursement,
  | "uid"
  | "createdAt"
  | "createdAtFirestoreTimestamp"
  | "reimbursedAtFirestoreTimestamp"
  | "reimbursedAt"
> & { reimbursed?: boolean; file: File };

type Props = {
  onSubmit: SubmitHandler<ReimbursementFormData>;
  defaultValues?: Partial<ReimbursementFormData>;
  milestones: StudentPlanMilestone[];
};

export default function ReimbursementForm({
  onSubmit,
  defaultValues,
  milestones,
}: Props) {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ReimbursementFormData>({ defaultValues });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Header20>{t("Reimbursement Form")}</Header20>
      <Stack spacing={2} marginBottom={6} marginTop={4}>
        <TextInput control={control} name="title" label={t("Title")} required />
        <NumberInput
          control={control}
          name="amount"
          label={t("Amount")}
          required
        />
        <SelectInput
          name="milestoneId"
          label={t("Milestone")}
          control={control}
          items={milestones.map((m) => ({
            value: m.uid,
            name: m.title,
          }))}
          helperText="Select the milestone this reimbursement is related to (if any)"
        />
        <ImageInput
          label={t("Upload receipt or invoice")}
          name="file"
          control={control}
          rules={{ validate: (v) => !!v }}
          placeholderImage={Selfie}
          placeholderImageWidth={176}
        />

        {/* {type === UserType.Admin && (
          <YesNoToggleInput
            control={control}
            name="reimbursed"
            prompt={t("Has been reimbursed?")}
          />
        )} */}
      </Stack>
      <Grid container justifyContent="space-between">
        <SubmitButton disabled={isSubmitting} loading={isSubmitting}>
          {t("Save")}
        </SubmitButton>
      </Grid>
    </form>
  );
}
