import { Chip, ChipProps, Typography } from "@mui/material";
import { blue, green, grey, red, yellow } from "@mui/material/colors";
import {
  ApplicationStatus,
  DeprecatedApplicationStatus,
} from "src/types/UserStatus";
import isValidApplicationStatus from "src/utils/isValidApplicationStatus";

type Props = {
  status?:
    | keyof typeof ApplicationStatus
    | keyof typeof DeprecatedApplicationStatus;
} & ChipProps;

function statusToColor(status: Props["status"]) {
  switch (status) {
    case "conditionally_enrolled":
      return blue;
    case "enrolled":
      return green;
    case "on_hold":
      return yellow;
    case "dropped":
    case "withdrawn":
    case "rejected":
      return red;
    default:
      return grey;
  }
}

export default function ApplicationStatusChip({ status, ...rest }: Props) {
  if (!status) return <Typography variant="body2">-</Typography>;
  return (
    <Chip
      sx={{
        color: statusToColor(status)[900],
        borderColor: statusToColor(status)[100],
        backgroundColor: statusToColor(status)[100],
      }}
      label={
        isValidApplicationStatus(status)
          ? ApplicationStatus[status]
          : `${DeprecatedApplicationStatus[status]} (deprecated)`
      }
      {...rest}
    />
  );
}
