import { ViewAgendaOutlined } from "@mui/icons-material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppContext } from "src/contexts/AppContext";
import fetchStorageBlob from "src/firebase/fetchStorageBlob";
import useErrorHandler from "src/utils/useErrorHandler";
import Button from "./Button";

interface Props {
  filePath: string;
  label: string;
}

export default function DownloadFileButton({ filePath, label }: Props) {
  const { t } = useTranslation();
  const [blob, setBlob] = useState<File>();
  const { clients } = useAppContext();
  const errorHandler = useErrorHandler();

  const handleDownload = () => {
    if (!blob) {
      fetchStorageBlob({ path: filePath }, clients)
        .then((blob) => {
          setBlob(blob);
        })
        .catch(errorHandler);
    }
  };

  return (
    <div>
      {blob ? (
        <a download={filePath} href={URL.createObjectURL(blob)}>
          {t("Download")}
        </a>
      ) : (
        <Button startIcon={<ViewAgendaOutlined />} onClick={handleDownload}>
          {label || t("Prepare download")}{" "}
        </Button>
      )}
    </div>
  );
}
