import { ArrowBack } from "@mui/icons-material";
import { Button, Grid, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SubmitButton from "src/components/Button/SubmitButton";
import SelectInput from "src/components/Card/SelectInput";
import TextInput from "src/components/Input/TextInput";
import YesNoToggleInput from "src/components/Input/YesNoToggleInput";
import Citizenship from "src/utils/Citizenship.enum";
import Disability from "src/utils/Disability.enum";
import Gender from "src/utils/Gender.enum";
import isBoolean from "src/utils/isBoolean";
import isValidSSN from "src/utils/isValidSSN";
import Race from "src/utils/Race.enum";
import YesNoSkip from "src/utils/YesNoSkip";
import LABEL_MAP from "../ClientScreen/BackgroundTab/OnboardingSurveyLabels";

export type DemographicData = {
  race?: (keyof typeof Race)[];
  gender?: keyof typeof Gender;
  citizenship?: keyof typeof Citizenship;
  disability: keyof typeof YesNoSkip;
  disabilityType?: keyof typeof Disability;
  lgbtq: keyof typeof YesNoSkip;
  isHispanic: boolean;
  hasProficientEnglish: boolean;
  isVeteran: boolean;
  isMilitarySpouse: boolean;
  ssn: string;
};

type Props = {
  defaultValues: Partial<DemographicData>;
  onSubmit: (data: DemographicData) => void | Promise<void>;
  onBack: () => void;
};

export default function DemographicForm({
  defaultValues,
  onSubmit,
  onBack,
}: Props) {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<DemographicData>({
    mode: "onTouched",
    defaultValues,
  });

  const hasDisability = watch("disability") === YesNoSkip.Yes;

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <Stack spacing={4} marginBottom={6}>
        <TextInput
          control={control}
          name="ssn"
          prompt={LABEL_MAP.ssn}
          helperText={t("If you don't have one, keep it blank.")}
          rules={{
            validate: (value) =>
              isValidSSN(value) ||
              value === "" ||
              value === undefined ||
              t(
                "Please enter a valid SSN. If you don't have one, keep it blank."
              ),
          }}
          required={false}
        />
        <SelectInput
          name="gender"
          prompt={t(LABEL_MAP.gender)}
          control={control}
          items={Object.entries(Gender).map((entry) => ({
            value: entry[0],
            name: entry[1],
          }))}
          rules={{
            required: { value: true, message: t("This field is required") },
          }}
        />
        <SelectInput
          name="disability"
          prompt={t(LABEL_MAP.disability)}
          control={control}
          items={Object.entries(YesNoSkip).map((entry) => ({
            value: entry[0],
            name: entry[1],
          }))}
          rules={{
            required: { value: true, message: t("This field is required") },
          }}
        />
        {hasDisability && (
          <SelectInput
            name="disabilityType"
            prompt={t(LABEL_MAP.disabilityType)}
            control={control}
            items={Object.entries(Disability).map((entry) => ({
              value: entry[0],
              name: entry[1],
            }))}
            rules={{
              required: {
                value: hasDisability,
                message: t("This field is required"),
              },
            }}
          />
        )}
        <SelectInput
          name="lgbtq"
          prompt={t(LABEL_MAP.lgbtq)}
          control={control}
          items={Object.entries(YesNoSkip).map((entry) => ({
            value: entry[0],
            name: entry[1],
          }))}
          rules={{
            required: { value: true, message: t("This field is required") },
          }}
        />
        <SelectInput
          name="citizenship"
          prompt={t(LABEL_MAP.citizenship)}
          control={control}
          items={Object.entries(Citizenship).map((entry) => ({
            value: entry[0],
            name: entry[1],
          }))}
          rules={{
            required: { value: true, message: t("This field is required") },
          }}
        />
        <SelectInput
          name="race"
          prompt={t("Select the race(s) that you most identify with:")}
          control={control}
          multiple
          items={Object.entries(Race).map((entry) => ({
            value: entry[0],
            name: entry[1],
          }))}
          rules={{
            required: { value: true, message: t("This field is required") },
          }}
        />
        <YesNoToggleInput
          name="isHispanic"
          prompt={LABEL_MAP.isHispanic}
          control={control}
          rules={{
            validate: (value) =>
              isBoolean(value) || t("This field is required"),
          }}
        />
        <YesNoToggleInput
          name="hasProficientEnglish"
          prompt={LABEL_MAP.hasProficientEnglish}
          control={control}
          rules={{
            validate: (value) =>
              isBoolean(value) || t("This field is required"),
          }}
        />
        <YesNoToggleInput
          name="isVeteran"
          prompt={t("Are you a U.S. Veteran?")}
          control={control}
          rules={{
            validate: (value) =>
              isBoolean(value) || t("This field is required"),
          }}
        />
        <YesNoToggleInput
          name="isMilitarySpouse"
          prompt={t(
            "Are you the spouse or caregiver of someone in the military?"
          )}
          control={control}
          rules={{
            validate: (value) =>
              isBoolean(value) || t("This field is required"),
          }}
        />
      </Stack>
      <Grid container justifyContent="space-between">
        <Button onClick={onBack}>
          <ArrowBack />
          &nbsp;
          {t("Back")}
        </Button>
        <SubmitButton loading={isSubmitting} disabled={isSubmitting}>
          {t("Finish")}
        </SubmitButton>
      </Grid>
    </form>
  );
}
