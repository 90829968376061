import {
  Box,
  Card,
  CardContent,
  Modal,
  ModalProps,
  Stack,
} from "@mui/material";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import ModalContainer from "src/components/ModalContainer";
import { ScreenTitle } from "src/components/Typography";

type Props = {
  title: string;
  content: ReactNode;
  showBackButton?: boolean;
  onBackButtonClick?: () => void;
  backButtonText?: string;
  onOkButtonClick?: () => void;
  okButtonText?: string;
  illustration: string;
  illustrationWidth: number;
  open: ModalProps["open"];
};

export default function SuccessScreenModal({
  title,
  content,
  showBackButton,
  onBackButtonClick,
  backButtonText,
  onOkButtonClick,
  okButtonText,
  illustration,
  illustrationWidth,
  open,
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Modal
      open={open}
      onClose={() =>
        onBackButtonClick
          ? onBackButtonClick()
          : onOkButtonClick
          ? onOkButtonClick()
          : navigate("/")
      }
    >
      <ModalContainer>
        <Card>
          <CardContent>
            <Stack
              spacing={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                px: 2,
                marginTop: "40px",
              }}
            >
              <img src={illustration} alt="" width={illustrationWidth} />
              <Stack spacing={2}>
                <ScreenTitle style={{ textAlign: "center" }}>
                  {title}
                </ScreenTitle>
                <Box sx={{ textAlign: "center", marginTop: 2, maxWidth: 600 }}>
                  {content}
                </Box>
              </Stack>
              <Stack spacing={2}>
                <Button
                  size="large"
                  variant="contained"
                  onClick={() =>
                    onOkButtonClick ? onOkButtonClick() : navigate("/")
                  }
                >
                  {okButtonText || t("Return Home")}
                </Button>
                {showBackButton && (
                  <Button onClick={onBackButtonClick}>{backButtonText}</Button>
                )}
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </ModalContainer>
    </Modal>
  );
}
