import { addDoc, collection, getDoc } from "firebase/firestore";
import Joi from "joi";
import { ReferenceStatus, StudentReference } from "src/types/StudentReference";
import { UserAccount } from "src/types/User";
import formatNumberToE164 from "src/utils/formatNumberToE164";
import isStudentReferenceGuard from "src/utils/isStudentReferenceGuard";
import serviceFunction from "src/utils/serviceFunction";
import cleanFirestoreDoc from "./cleanFirestoreDoc";
import generateFirestoreTimestamps from "./generateFirestoreTimestamps";

type Data = {
  user: UserAccount;
  verified?: boolean;
  subscribed?: boolean;
} & Pick<
  StudentReference,
  "firstName" | "lastName" | "relationship" | "phone" | "email" | "type"
>;

// note that Google Cloud casts undefined to null during the serialization
// https://stackoverflow.com/questions/64086664/google-cloud-function-undefined-argument-detected-as-null
const schema = Joi.object<Data>({
  user: Joi.object().required(),
  type: Joi.string().required(),
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  relationship: Joi.string().required(),
  phone: Joi.string().required(),
  email: Joi.alternatives().try(Joi.string().allow(""), Joi.valid(null)),
});

const createReference = serviceFunction(
  schema,
  async ({ user, phone, ...data }, { db }): Promise<StudentReference> => {
    const doc = await addDoc(collection(db, "users", user.uid, "references"), {
      userId: user.uid,
      status: ReferenceStatus.not_verified,
      // TODO: change this post-releasing the e164 format and verifying it's ok in production
      phone: formatNumberToE164(phone) || phone,
      ...data,
      ...generateFirestoreTimestamps(new Date(), "createdAt"),
      ...generateFirestoreTimestamps(new Date(), "lastUpdatedAt"),
    });

    const reference = cleanFirestoreDoc(await getDoc(doc));

    if (!isStudentReferenceGuard(reference)) {
      throw new Error("Something went wrong");
    }
    return reference;
  }
);

export default createReference;
