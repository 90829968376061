import { useCallback, useEffect, useState } from "react";
import { useAppContext } from "src/contexts/AppContext";
import fetchAttendance from "src/firebase/fetchAttendance";
import { Attendance } from "src/types/Attendance";
import { UserAccount } from "src/types/User";
import batchUserRequest from "src/utils/batchUserRequest"; // Assuming you have a batch utility for requesting multiple users
import useErrorHandler from "src/utils/useErrorHandler";

interface UseUserAttendanceReturn {
  loading: boolean;
  getUserAttendance: (user: UserAccount) => Attendance[] | undefined;
  getUserLatestAttendance: (user: UserAccount) => Attendance | null;
  getUserAttendanceCount: (user: UserAccount) => number | undefined;
}

export default function useUserAttendanceById(
  users: UserAccount[]
): UseUserAttendanceReturn {
  const [loading, setLoading] = useState(true);
  const [attendanceById, setAttendanceById] = useState<{
    [id: string]: Attendance[] | undefined;
  }>({});

  const { clients } = useAppContext(); // Assuming this is how you get the firestore clients
  const errorHandler = useErrorHandler();

  useEffect(() => {
    if (!users || users.length === 0) return;

    const fetchAllAttendance = async () => {
      try {
        setLoading(true);

        // Use batchUserRequest to request attendance data for multiple users in parallel
        const attendanceData = await batchUserRequest(
          users,
          async (user) => await fetchAttendance(user.uid, clients)
        );

        setAttendanceById(attendanceData);
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllAttendance();
  }, [users, clients, errorHandler]);

  // Function to get attendance by user
  const getUserAttendance = useCallback(
    (user: UserAccount): Attendance[] | undefined => {
      if (loading || !attendanceById) return undefined;
      if (!Object.keys(attendanceById).includes(user.uid)) {
        throw new Error(`User with ID ${user.uid} does not exist`);
      }
      return attendanceById[user.uid];
    },
    [loading, attendanceById]
  );

  // Function to get the latest attendance record for a user by comparing dates
  const getUserLatestAttendance = useCallback(
    (user: UserAccount): Attendance | null => {
      const attendance = getUserAttendance(user);
      if (!attendance || attendance.length === 0) return null;
      return attendance.reduce((latest, current) => {
        const latestDate = new Date(latest.dateFirestoreTimestamp.toDate());
        const currentDate = new Date(current.dateFirestoreTimestamp.toDate());
        return currentDate > latestDate ? current : latest;
      }, attendance[0]);
    },
    [getUserAttendance]
  );

  // Function to get attendance count for a user
  const getUserAttendanceCount = useCallback(
    (user: UserAccount): number | undefined => {
      const attendance = getUserAttendance(user);
      if (!attendance) return undefined;
      return attendance.length;
    },
    [getUserAttendance]
  );

  return {
    loading,
    getUserAttendance,
    getUserLatestAttendance,
    getUserAttendanceCount,
  };
}
