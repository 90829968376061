import { Timestamp } from "firebase/firestore";
import { Note } from "src/types/Note";
import NoteType from "./NoteType.enum";

export default function isNoteGuard(obj: unknown): obj is Note {
  const note = obj as Note;

  return (
    note &&
    typeof note === "object" &&
    typeof note.uid === "string" &&
    typeof note.date === "string" &&
    note.dateFirestoreTimestamp instanceof Timestamp &&
    typeof note.createdAt === "string" &&
    note.createdAtFirestoreTimestamp instanceof Timestamp &&
    typeof note.lastUpdatedAt === "string" &&
    note.lastUpdatedAtFirestoreTimestamp instanceof Timestamp &&
    typeof note.description === "string" &&
    Object.values(NoteType).includes(note.type) &&
    typeof note.userId === "string" &&
    typeof note.authorId === "string" &&
    typeof note.author === "string" &&
    typeof note.authorOrganization === "string" &&
    (typeof note.assetUrl === "undefined" || typeof note.assetUrl === "string")
  );
}
