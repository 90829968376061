import { addDoc, collection, getDoc } from "firebase/firestore";
import Joi from "joi";
import {
  AdminActionItem,
  CreateAdminActionItemInput,
} from "src/types/AdminActionItem";
import isAdminActionItemGuard from "src/utils/isAdminActionItemGuard";
import serviceFunction from "src/utils/serviceFunction";
import cleanFirestoreDoc from "./cleanFirestoreDoc";
import generateFirestoreTimestamps from "./generateFirestoreTimestamps";

type Data = CreateAdminActionItemInput;

const schema = Joi.object<Data>({
  user: Joi.object().required(),
  owner: Joi.object().required(),
  dueDate: Joi.date().required(),
  title: Joi.string().required(),
});

const createAdminActionItem = serviceFunction(
  schema,
  async ({ user, dueDate, title, owner }, { db }): Promise<AdminActionItem> => {
    const doc = await addDoc(
      collection(db, `admins/${owner.uid}/actionItems`),
      {
        userId: user.uid,
        title,
        adminOwnerId: owner.uid,
        ...generateFirestoreTimestamps(dueDate, "dueDate"),
        ...generateFirestoreTimestamps(new Date(), "createdAt"),
        ...generateFirestoreTimestamps(new Date(), "lastUpdatedAt"),
      }
    );
    const actionItem = cleanFirestoreDoc(await getDoc(doc));
    if (!isAdminActionItemGuard(actionItem))
      throw new Error("The action item format is invalid.");
    return actionItem;
  }
);

export default createAdminActionItem;
