import {
  deleteField,
  doc,
  getDoc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import Joi from "joi";
import { Admin } from "src/types/Admin";
import { AdminActionItem } from "src/types/AdminActionItem";
import isAdminActionItemGuard from "src/utils/isAdminActionItemGuard";
import serviceFunction from "src/utils/serviceFunction";
import cleanFirestoreDoc from "./cleanFirestoreDoc";

type UpdateData = {
  payload: Partial<Pick<AdminActionItem, "title">> & {
    completed?: boolean;
    dueDate?: Date;
  };
  admin: Admin;
  actionItem: AdminActionItem;
};

const schema = Joi.object<UpdateData>({
  payload: Joi.object({
    title: Joi.string().optional(),
    description: Joi.string().optional(),
    completed: Joi.boolean().optional(),
  }).required(),
  admin: Joi.object().required(),
  actionItem: Joi.object().required(),
});

const updateAdminActionItem = serviceFunction(
  schema,
  async ({ payload, admin, actionItem }, { db }): Promise<AdminActionItem> => {
    const docRef = doc(db, `admins/${admin.uid}/actionItems/${actionItem.uid}`);
    const now = new Date();
    const { completed, ...data } = payload;
    const completedAt = completed ? now.toISOString() : deleteField();
    const completedAtFirestoreTimestamp = completed
      ? Timestamp.fromDate(now)
      : deleteField();

    await updateDoc(docRef, {
      ...payload,
      completedAt,
      completedAtFirestoreTimestamp,
      ...data,
    });

    const updatedDoc = cleanFirestoreDoc(await getDoc(docRef));
    if (!isAdminActionItemGuard(updatedDoc))
      throw new Error("The action item format is invalid.");

    return updatedDoc;
  }
);

export default updateAdminActionItem;
