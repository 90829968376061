import { UserAccount, UserOnboardingStatus } from "src/types/User";

export default function isUserOnboarded(user: UserAccount) {
  return (
    user.onboardingStatus === UserOnboardingStatus.ENROLLED ||
    user.onboardingStatus === UserOnboardingStatus.COMPLETED_THEORY ||
    user.onboardingStatus === UserOnboardingStatus.RANGE ||
    user.applicationStatus === "on_hold" ||
    user.applicationStatus === "conditionally_enrolled" ||
    user.applicationStatus === "enrolled" ||
    user.enrollmentStatus === "theory_training" ||
    user.enrollmentStatus === "completed_theory_training" ||
    user.enrollmentStatus === "completed_skills_training" ||
    user.enrollmentStatus === "skills_training" ||
    user.enrollmentStatus === "licensed"
  );
}
