import { Box, CircularProgress } from "@mui/material";

type Props = {
  description?: string;
};

const LoadingPage = ({ description }: Props) => (
  <Box
    display="flex"
    flexDirection="column"
    gap={4}
    height="100vh"
    padding={4}
    alignItems="center"
    justifyContent="center"
    margin="auto"
  >
    <CircularProgress />
    <p>{description || "Hang tight, we're loading your workspace 🚀"}</p>
  </Box>
);

export default LoadingPage;
