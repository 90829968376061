import { collection, getDocs } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";
import { Organization } from "src/types";
import isOrganizationGuard from "src/utils/isOrganizationGuard";
import sortBy from "src/utils/sortBy";
import cleanFirestoreDoc from "./cleanFirestoreDoc";

export default async function fetchAllOrganizations({
  db,
}: Clients): Promise<Organization[]> {
  const students = (await getDocs(collection(db, "organizations"))).docs
    .map(cleanFirestoreDoc)
    .filter(isOrganizationGuard);

  const sorted = sortBy(students, "name");
  return sorted;
}
