import { collection, getDocs } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";
import { AdminActionItem } from "src/types/AdminActionItem";
import isAdminActionItemGuard from "src/utils/isAdminActionItemGuard";
import cleanFirestoreDoc from "./cleanFirestoreDoc";

export default async function fetchAdminActionItems(
  adminId: string,
  { db }: Clients
): Promise<AdminActionItem[]> {
  const actionItems = (
    await getDocs(collection(db, "admins", adminId, "actionItems"))
  ).docs
    .map(cleanFirestoreDoc)
    .filter(isAdminActionItemGuard);

  return actionItems.sort(
    (a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime()
  );
}
