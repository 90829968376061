import { Timestamp } from "firebase/firestore";
import { AdminActionItem } from "src/types/AdminActionItem";

export default function isAdminActionItemGuard(
  obj: unknown
): obj is AdminActionItem {
  if (!obj) return false;
  const item = obj as AdminActionItem;

  return (
    typeof item.uid === "string" &&
    typeof item.createdAt === "string" &&
    typeof item.lastUpdatedAt === "string" &&
    typeof item.dueDate === "string" &&
    typeof item.title === "string" &&
    typeof item.userId === "string" &&
    typeof item.adminOwnerId === "string" &&
    (typeof item.completedAt === "string" || item.completedAt === undefined) &&
    item.createdAtFirestoreTimestamp instanceof Timestamp &&
    item.lastUpdatedAtFirestoreTimestamp instanceof Timestamp &&
    item.dueDateFirestoreTimestamp instanceof Timestamp &&
    (item.completedAtFirestoreTimestamp instanceof Timestamp ||
      item.completedAtFirestoreTimestamp === undefined)
  );
}
