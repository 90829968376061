/* eslint-disable jsx-a11y/media-has-caption */
import { track } from "@amplitude/analytics-browser";
import CloseIcon from "@mui/icons-material/Close";
import HideIcon from "@mui/icons-material/VisibilityOff";
import { Box, IconButton } from "@mui/material";
import { useEffect, useRef, useState } from "react";

type Props = {
  videoUrl: string;
  playVideo: boolean;
};

export default function FloatingVideo({ videoUrl, playVideo }: Props) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);

  const handleVideoClick = () => {
    if (isExpanded) return;
    setIsExpanded(true);
    track("Onboarding: Expand Video", { videoUrl, medium: "floating" });
  };

  const handleClose = () => {
    setIsExpanded(false);
    track("Onboarding: Close Video", { videoUrl, medium: "floating" });
  };

  const handleHide = () => {
    setFadeOut(true); // Trigger fade-out effect
    setTimeout(() => {
      setIsHidden(true); // Hide after fade-out completes
      setIsExpanded(false);
      track("Onboarding: Hide Video", { videoUrl, medium: "floating" });
    }, 300); // Duration matches the CSS transition time
  };

  const handleLoadedData = () => {
    setTimeout(() => {
      setIsLoaded(true); // Trigger fade-in effect after a slight delay
    }, 100); // Add a small delay for a smoother fade-in
  };

  const handleVideoEnded = () => {
    handleHide(); // Trigger fade-out when video ends
    track("Onboarding: Video Ended", { videoUrl, medium: "floating" });
  };

  useEffect(() => {
    if (playVideo && videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error("Playback failed: ", error);
      });
    }
  }, [playVideo]);

  return (
    <div>
      {!isHidden && (
        <Box
          sx={{
            position: "fixed",
            top: isExpanded ? 0 : { xs: 16, sm: "auto" },
            bottom: isExpanded ? "auto" : { sm: 16 },
            right: isExpanded ? 0 : 16,
            left: isExpanded ? 0 : "auto",
            width: isExpanded
              ? "100vw"
              : { xs: "80px", sm: "100px", md: "140px" },
            height: isExpanded
              ? "100vh"
              : { xs: "80px", sm: "100px", md: "140px" },
            borderRadius: isExpanded ? "0px" : "50%",
            overflow: "hidden",
            cursor: "pointer",
            transition: "all 0.3s ease-in-out, opacity 0.3s ease-in-out", // Transition for fade-in and fade-out
            zIndex: 1000,
            opacity: fadeOut ? 0 : isLoaded ? 1 : 0, // Handles both fade-in and fade-out
            backgroundColor: isExpanded ? "rgba(0, 0, 0, 0.8)" : "transparent",
          }}
          onClick={handleVideoClick}
        >
          <video
            ref={videoRef}
            width="100%"
            height="100%"
            onLoadedData={handleLoadedData} // Trigger fade-in when loaded
            onEnded={handleVideoEnded} // Trigger fade-out when video ends
            controls={isExpanded}
            src={videoUrl}
            style={{
              borderRadius: isExpanded ? "0px" : "100%",
              objectFit: "cover",
            }}
          />
          {isExpanded && (
            <Box
              sx={{
                position: "absolute",
                top: "10px",
                right: "10px",
                zIndex: 1100,
              }}
            >
              <IconButton
                onClick={handleClose}
                sx={{
                  color: "white",
                  marginRight: "10px",
                }}
              >
                <CloseIcon />
              </IconButton>
              <IconButton
                onClick={handleHide}
                sx={{
                  color: "white",
                }}
              >
                <HideIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      )}
    </div>
  );
}
