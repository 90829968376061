import { doc, getDoc } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";
import { Cohort } from "src/types/Cohort";
import isCohortGuard from "src/utils/isCohortGuard";
import cleanFirestoreDoc from "./cleanFirestoreDoc";

export default async function fetchCohort(
  organizationId: string,
  cohortId: string,
  { db }: Clients
): Promise<Cohort> {
  const cohort = cleanFirestoreDoc(
    await getDoc(doc(db, "organizations", organizationId, "cohorts", cohortId))
  );

  if (!isCohortGuard(cohort))
    throw new Error("Error loading the cohort model.");

  return cohort;
}
