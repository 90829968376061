import { Menu as MenuIcon } from "@mui/icons-material";
import {
  AppBar,
  Badge,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FullLogo from "src/assets/LogoBlack.svg";
import LogoIcon from "src/assets/LogoIcon.svg";

import LogoutButton from "src/components/Button/LogoutButton";
import useCurrentUserUnreadMessages from "src/components/Layout/Template/useCurrentUserUnreadMessages";
import { useAuth } from "src/contexts/AuthContext";
import { LIB_VERSION } from "src/version";

type Props = {
  children: React.ReactNode;
  navigationDisabled?: boolean;
};

export default function BasicMenuLayout({
  children,
  navigationDisabled,
}: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const authCtx = useAuth();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const unreadMessages = useCurrentUserUnreadMessages();

  const PAGES: { label: string; path: string; badgeCount?: number }[] = useMemo(
    () => [
      { label: t("Home"), path: "/" },
      { label: t("Coach"), path: "/coach", badgeCount: unreadMessages },
      // { label: t("Profile"), path: "/profile" },
    ],
    [t, unreadMessages]
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleClick = (path: string) => {
    handleCloseNavMenu();
    navigate(path);
  };

  if (navigationDisabled)
    return (
      <Box
        sx={{
          minHeight: "100vh",
          maxWidth: "100vw",
          background:
            "linear-gradient(339deg, #F7F1EA 36.57%, #FDFCFA 72.98%, #FBFBFB 86.11%)",
        }}
        flexDirection="column"
        display="flex"
      >
        <AppBar position="static" color="transparent">
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Box>
                <img src={FullLogo} color="black" alt="Logo icon" width={200} />
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
        {children}
      </Box>
    );

  return (
    <Box
      sx={{
        minHeight: "100vh",
        maxWidth: "100vw",
        background:
          "linear-gradient(339deg, #F7F1EA 36.57%, #FDFCFA 72.98%, #FBFBFB 86.11%)",
      }}
      flexDirection="column"
      display="flex"
    >
      <AppBar position="static" color="transparent">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", sm: "none" } }}>
              <Stack direction="row" alignItems="center">
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Stack
                  direction="row"
                  onClick={() => navigate("/")}
                  sx={{ cursor: "pointer", ":hover": { opacity: 0.8 } }}
                  justifyContent="flex-end"
                >
                  <Box>
                    <img src={LogoIcon} alt="Logo icon" />
                  </Box>
                  <Box alignSelf="flex-end">
                    <Typography fontSize={10}>v{LIB_VERSION}</Typography>
                  </Box>
                </Stack>
              </Stack>

              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {PAGES.map((page) => (
                  <MenuItem
                    key={page.path}
                    onClick={() => handleClick(page.path)}
                  >
                    <Box>
                      <Badge badgeContent={page.badgeCount} color="primary">
                        <Typography textAlign="center">{page.label}</Typography>
                      </Badge>
                    </Box>
                  </MenuItem>
                ))}
                <Divider />
                <MenuItem onClick={() => authCtx.logout()}>
                  <Typography textAlign="center">{t("Logout")}</Typography>
                </MenuItem>
              </Menu>
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "flex" } }}>
              <Stack
                direction="row"
                onClick={() => navigate("/")}
                sx={{ cursor: "pointer", ":hover": { opacity: 0.8 } }}
              >
                <img src={LogoIcon} alt="Logo icon" />
                <Box alignSelf="flex-end">
                  <Typography variant="body2">v{LIB_VERSION}</Typography>
                </Box>
              </Stack>
              {PAGES.map((page) => (
                <Box
                  sx={{
                    cursor: "pointer",
                    paddingX: 2,
                  }}
                  onClick={() => handleClick(page.path)}
                >
                  <Badge badgeContent={page.badgeCount} color="primary">
                    <Button
                      key={page.path}
                      sx={(theme) => ({
                        color: theme.palette.text.primary,
                        fontSize: 16,
                        cursor: "pointer",
                        padding: 0,
                      })}
                    >
                      {page.label}
                    </Button>
                  </Badge>
                </Box>
              ))}
            </Box>
            <Box
              marginLeft="auto"
              sx={{ display: { xs: "none", sm: "block" } }}
            >
              <LogoutButton />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {children}
    </Box>
  );
}
