import { differenceInDays } from "date-fns";
import { StudentPlanMilestone } from "src/types/StudentPlan";
import { SupportTier } from "./CoachSegmentPriorityCard";

export default function determineEngagementRisk({
  overdueMilestones,
  lastLoggedIn,
  upcomingMilestones,
  engagementRatio,
}: {
  overdueMilestones?: StudentPlanMilestone[];
  lastLoggedIn?: string;
  upcomingMilestones?: StudentPlanMilestone[];
  engagementRatio?: number;
}): { description: string; tierOfSupport: SupportTier } {
  let description = "";
  let tierOfSupport: SupportTier = "healthy"; // Default value

  if (overdueMilestones && overdueMilestones.length > 0) {
    description = `Overdue milestone: ${overdueMilestones[0].title}`;
    tierOfSupport = "attention"; // Mark as risky due to overdue milestones
  } else if (
    lastLoggedIn &&
    differenceInDays(new Date(), new Date(lastLoggedIn)) > 7
  ) {
    description = "Has not logged in over the past 7 days.";
    tierOfSupport = "risky";
  } else if (upcomingMilestones && upcomingMilestones.length > 0) {
    description = `Upcoming milestone: ${upcomingMilestones[0].title}`;
    tierOfSupport = "attention";
  } else if (engagementRatio !== undefined && engagementRatio <= 1) {
    description = "Low engagement ratio";
    tierOfSupport = "attention";
  } else {
    description = "Healthy.";
    tierOfSupport = "healthy";
  }

  return { description, tierOfSupport };
}
