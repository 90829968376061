import {
  DeleteOutlined,
  DownloadForOfflineOutlined,
  PlayCircleFilled,
} from "@mui/icons-material";
import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useLiveQuery } from "dexie-react-hooks";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CachedResourceMetadata, db } from "src/api/db";
import Image from "src/components/Image";
import { theme } from "src/theme";
import { capitalizeInitial } from "src/utils";
import downloadFile from "src/utils/donwloadFile";
import offlineModeEnabled from "src/utils/offlineModeEnabled";
import useResourceProgress from "src/utils/useResourceProgress";

interface Props {
  resource: CachedResourceMetadata;
  isSelected?: boolean;
}

export type WatchStatus = "watched" | "watching" | "not started";

function getWatchStatusColor(status: WatchStatus) {
  switch (status) {
    case "not started":
      return theme.palette.grey[500];
    case "watching":
      return theme.palette.success.dark;
    case "watched":
      return theme.palette.success.main;
    default:
      return theme.palette.grey[500];
  }
}

export default function ResourceLink({
  resource,
  isSelected,
}: Props): JSX.Element {
  const navigate = useNavigate();
  const { findResourceProgressById } = useResourceProgress();
  const [isDownloading, setIsDownloading] = useState(false);
  const cachedResource = useLiveQuery(
    () => db.resources.where("id").equals(resource.id).first(),
    [resource.id]
  );
  const progress = findResourceProgressById(resource.id);
  const hasStarted = !!progress;
  const watchStatus: WatchStatus = hasStarted
    ? progress.progressFraction > 0.8
      ? "watched"
      : "watching"
    : "not started";

  const handleIconClick = async (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    setIsDownloading(true);
    e.stopPropagation();
    e.preventDefault();

    try {
      if (cachedResource) {
        await db.removeVideo(cachedResource.cacheId!);
      } else {
        const blob = await downloadFile(resource.url);
        await db.addVideo({ blob, ...resource });
      }
    } catch (err) {
      Sentry.captureException(err);
    }

    setIsDownloading(false);
  };

  const handleNavigateClick = () => {
    switch (watchStatus) {
      case "not started":
        navigate(`/resources/${resource.id}?start=0`);
        break;
      case "watching":
      case "watched":
        if (!progress) throw new Error("Progress should exist");
        navigate(
          `/resources/${resource.id}?start=${progress.progressSeconds - 60} `
        );
        break;
      default:
        break;
    }
  };

  // TODO: fix this
  // eslint-disable-next-line react/no-unstable-nested-components
  const ResourceActionIcon = () => {
    if (!offlineModeEnabled()) return <div />;

    if (isDownloading) {
      return <CircularProgress sx={{ marginLeft: "auto" }} size={24} />;
    }

    if (cachedResource) {
      return (
        <DeleteOutlined
          sx={{
            marginLeft: "auto",
            zIndex: 10,
            color: theme.palette.primary.main,
          }}
          onClick={handleIconClick}
        />
      );
    }
    return (
      <DownloadForOfflineOutlined
        sx={{
          marginLeft: "auto",
          zIndex: 10,
          color: theme.palette.primary.main,
        }}
        onClick={handleIconClick}
      />
    );
  };

  return (
    <Box
      sx={[
        { cursor: "pointer", paddingY: theme.spacing(2) },
        !!isSelected && {
          opacity: 0.9,
          backgroundColor: theme.palette.primary.light,
          borderLeft: 4,
          borderColor: theme.palette.primary.main,
        },
      ]}
      onClick={handleNavigateClick}
    >
      <Grid
        container
        direction="row"
        spacing={theme.spacing(2)}
        alignItems="center"
      >
        <Grid item xs={4} md={5}>
          <Box position="relative">
            <PlayCircleFilled
              sx={[
                {
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 99,
                  color: theme.palette.common.white,
                },
              ]}
            />
            <Box>
              <Image
                src={resource.thumbnailUrl}
                alt={`Thumbnail image for ${resource.name}`}
                style={{
                  objectFit: "cover",
                  borderRadius: "8px",
                  flexShrink: 0,
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={8} md={7}>
          <Stack spacing={theme.spacing(1)}>
            <Stack
              alignItems="center"
              direction="row"
              spacing={theme.spacing(1)}
            >
              <Typography
                sx={[
                  isSelected
                    ? {
                        fontWeight: "medium",
                        color: theme.palette.primary.main,
                      }
                    : {},
                  {
                    maxWidth: 200,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  },
                ]}
              >
                {resource.name}
              </Typography>
              <ResourceActionIcon />
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              spacing={theme.spacing(0.5)}
            >
              <Typography variant="body2">
                {capitalizeInitial(resource.resourceType)}
              </Typography>
              <Typography variant="body2">•</Typography>
              <Typography variant="body2">{resource.duration} min</Typography>
              <Typography variant="body2">•</Typography>

              <Typography
                variant="body2"
                sx={{ color: getWatchStatusColor(watchStatus) }}
              >
                {watchStatus}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
