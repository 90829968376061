import { deleteDoc, doc } from "firebase/firestore";
import Joi from "joi";
import { Admin } from "src/types/Admin";
import { AdminActionItem } from "src/types/AdminActionItem";
import serviceFunction from "src/utils/serviceFunction";

type DeleteData = {
  admin: Admin;
  actionItem: AdminActionItem;
};

const schema = Joi.object<DeleteData>({
  admin: Joi.object().required(),
  actionItem: Joi.object().required(),
});

const deleteAdminActionItem = serviceFunction(
  schema,
  async ({ admin, actionItem }, { db }): Promise<void> => {
    const docRef = doc(db, `admins/${admin.uid}/actionItems/${actionItem.uid}`);
    await deleteDoc(docRef);
  }
);

export default deleteAdminActionItem;
