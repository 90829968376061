import {
  EmailOutlined,
  LockOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import SubmitButton from "src/components/Button/SubmitButton";
import { ScreenTitle } from "src/components/Typography";
import { useAuth } from "src/contexts/AuthContext";
import getFrom from "src/utils/getFrom";
import useErrorHandler from "src/utils/useErrorHandler";

export default function LoginScreen(): JSX.Element {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const authCtx = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = getFrom(location) || "/";
  const { t } = useTranslation();
  const theme = useTheme();
  const handleError = useErrorHandler();

  if (authCtx?.session) {
    return <Navigate to="/" />;
  }

  const handleLogin = async () => {
    try {
      setLoading(true);
      const success = !!(await authCtx?.login(
        email.toLowerCase().trim(),
        password.trim()
      ));
      setLoading(false);
      if (success) navigate(from, { replace: true });
    } catch (e) {
      handleError(e);
      setLoading(false);
    }
  };

  return (
    <Box>
      <Stack spacing={theme.spacing(3)}>
        <ScreenTitle>{t("New beginnings start here.")}</ScreenTitle>
        <TextField
          type="text"
          value={email}
          label="Email"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailOutlined />
              </InputAdornment>
            ),
          }}
          onChange={(e) => setEmail(e.target.value)}
        />

        <TextField
          type={showPassword ? "text" : "password"}
          value={password}
          label="Password"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlined />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((opt) => !opt)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => setPassword(e.target.value)}
        />
        <SubmitButton
          onClick={handleLogin}
          size="large"
          disabled={!email.length || !password.length}
          loading={loading}
        >
          {t("Sign In")}
        </SubmitButton>
        <Stack direction="row" alignItems="center">
          <Typography>{t("Don't have an account? ")}</Typography>
          <Button onClick={() => navigate("/register")} size="large">
            {t("Register now.")}
          </Button>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Typography>{t("Forgot your password? ")}</Typography>
          <Button onClick={() => navigate("/forgot-password")} size="large">
            {t("Reset it.")}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}
