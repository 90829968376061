import { DocumentData, QuerySnapshot } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { useSession } from "src/contexts/AuthContext";
import { UserResourceProgress } from "src/types/User";

export type UserResourceProgressMap = {
  [resourceId: string]: UserResourceProgress;
};

export default function useResourceProgress(uid?: string) {
  const session = useSession();
  const [progress, setProgress] = useState<UserResourceProgressMap>();

  const onSnapshot = (snapshot: QuerySnapshot<DocumentData>) => {
    const progressMap: UserResourceProgressMap = {};
    snapshot.docs.forEach((snap) => {
      const userResource = snap.data() as UserResourceProgress;
      progressMap[userResource.resourceId] = userResource;
    });
    setProgress(progressMap);
  };

  useEffect(() => {
    const unsubscribe = session.subscribeToUserResourceProgress(
      onSnapshot,
      uid
    );
    return unsubscribe;
  }, [session, uid]);

  const findResourceProgressById = useCallback(
    (resourceId: string): UserResourceProgress | undefined => {
      if (!progress) return undefined;
      return resourceId in progress ? progress[resourceId] : undefined;
    },
    [progress]
  );

  return {
    progress,
    findResourceProgressById,
    loading: !progress,
  };
}
