import { doc, getDoc } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";
import { Organization } from "src/types";
import isOrganizationGuard from "src/utils/isOrganizationGuard";
import cleanFirestoreDoc from "./cleanFirestoreDoc";

export default async function fetchOrganization(
  organizationId: string,
  { db }: Clients
): Promise<Organization> {
  const school = cleanFirestoreDoc(
    await getDoc(doc(db, "organizations", organizationId))
  );
  if (!isOrganizationGuard(school))
    throw new Error("Cannot locate organization record");
  return school;
}
