import { doc, Timestamp, updateDoc } from "firebase/firestore";
import Joi from "joi";
import { StudentPlanMilestone } from "src/types/StudentPlan";
import { UserAccount } from "src/types/User";
import serviceFunction from "src/utils/serviceFunction";

type Data = {
  user: UserAccount;
  milestone: StudentPlanMilestone;
  completed: boolean;
};

const schema = Joi.object<Data>({
  user: Joi.object().required(),
  milestone: Joi.object().required(),
  completed: Joi.boolean().required(),
});

const updateMilestoneCompletion = serviceFunction(
  schema,
  async (
    { user, milestone, completed },
    { db }
  ): Promise<
    Pick<StudentPlanMilestone, "completedAt" | "completedAtFirestoreTimestamp">
  > => {
    const now = new Date();
    const completedAt = completed ? now.toISOString() : null;
    const completedAtFirestoreTimestamp = completed
      ? Timestamp.fromDate(now)
      : null;

    await updateDoc(doc(db, `users/${user.uid}/plan/${milestone.uid}`), {
      completedAt,
      completedAtFirestoreTimestamp,
    });

    return { completedAt, completedAtFirestoreTimestamp };
  }
);

export default updateMilestoneCompletion;
