import { collection, getDocs, query, where } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";
import cleanFirestoreDoc from "src/firebase/cleanFirestoreDoc";
import { Cohort } from "src/types/Cohort";
import { CohortAnnouncement } from "src/types/CohortAnnouncement";
import isCohortAnnouncementGuard from "./isCohortAnnouncementGuard";

export default async function fetchAllCohortAnnouncements(
  {
    cohort,
    onlyActive,
  }: { cohort: Pick<Cohort, "uid" | "organizationId">; onlyActive?: boolean },
  { db }: Clients
): Promise<CohortAnnouncement[]> {
  const snapshot = onlyActive
    ? await getDocs(
        query(
          collection(
            db,
            "organizations",
            cohort.organizationId,
            "cohorts",
            cohort.uid,
            "cohortAnnouncements"
          ),
          where("expiresAtFirestoreTimestamp", ">=", new Date())
        )
      )
    : await getDocs(
        collection(
          db,
          "organizations",
          cohort.organizationId,
          "cohorts",
          cohort.uid,
          "cohortAnnouncements"
        )
      );
  const announcements = snapshot.docs
    .map(cleanFirestoreDoc)
    .filter(isCohortAnnouncementGuard);

  return announcements.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );
}
