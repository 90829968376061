import { doc, updateDoc } from "firebase/firestore";
import Joi from "joi";
import { ChatMessage } from "src/pages/DashboardPage/AdminParticipantChatTab/ChatMessage";
import { UserAccount } from "src/types/User";
import serviceFunction from "src/utils/serviceFunction";
import generateFirestoreTimestamps from "./generateFirestoreTimestamps";

type Data = {
  user: UserAccount;
  message: ChatMessage;
};

const schema = Joi.object<Data>({
  user: Joi.object().required(),
  message: Joi.object().required(),
});

const updateChatMessageReadAt = serviceFunction(
  schema,
  async ({ message, user }, { db }): Promise<void> => {
    await updateDoc(doc(db, `users/${user.uid}/messages/${message.uid}`), {
      ...generateFirestoreTimestamps(new Date(), "readAt"),
    });
  }
);

export default updateChatMessageReadAt;
