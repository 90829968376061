import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Grid, Stack, useTheme } from "@mui/material";
import { endOfYesterday } from "date-fns";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import SubmitButton from "src/components/Button/SubmitButton";
import SelectInput from "src/components/Card/SelectInput";
import DateInput from "src/components/Input/DateInput";
import NumberInput from "src/components/Input/NumberInput";
import TextInput from "src/components/Input/TextInput";
import YesNoToggleInput from "src/components/Input/YesNoToggleInput";
import USState from "src/types/states";
import HousingSituation, {
  getHousingSituationLabel,
} from "src/utils/HousingSituation";
import isBoolean from "src/utils/isBoolean";
import TransportationSituation from "src/utils/TransportationSituation";
import LABEL_MAP from "../ClientScreen/BackgroundTab/OnboardingSurveyLabels";

export type IncarcerationData = {
  // pre-release programs
  isIncarcerated: boolean;
  housingUnit?: string;
  incarcerationIdentifier?: string;
  releaseDate?: Date;
  nextCourtDate?: Date;
  nextParoleHearingDate?: Date;
  postReleaseCity?: string;
  postReleaseState?: USState;
  postReleaseTransportation?: keyof typeof TransportationSituation;
  postReleaseHousing?: keyof typeof HousingSituation;
  // post-release programs
  hasBeenIncarcerated: boolean;
  isProbationOrParole: boolean | null;
  totalIncarcerationDuration?: number;
  lastReleased?: Date;
  hasDoneDiversionProgram: boolean;
  isJusticeImpacted: boolean;
  hasBeenProbationOrParole: boolean;
  // DUI
  hasSexOffense: boolean;
  numberDUIPast7Years: number;
  numberDUIRecordLifetime: number;
  hasViolentOffense: boolean;
  // justice involved
  isFirstTimeOffender?: boolean;
  hasPendingCases: boolean;
  numberPointsDriverRecord: number;
  // environmental conditions free world
  housing?: keyof typeof HousingSituation;
  transportation?: keyof typeof TransportationSituation;
  hasMoreThan4FelonyConvictions7Years?: boolean;
};

type Props = {
  defaultValues: Partial<IncarcerationData>;
  onBack: () => void;
  onSubmit: (data: IncarcerationData) => void | Promise<void>;
};

export default function IncarcerationForm({
  defaultValues,
  onSubmit,
  onBack,
}: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<IncarcerationData>({
    mode: "onTouched",
    defaultValues,
  });

  const isIncarcerated = watch("isIncarcerated");
  const hasBeenIncarcerated = watch("hasBeenIncarcerated");
  const hasPendingCases = watch("hasPendingCases");
  const isFirstTimeOffender = watch("isFirstTimeOffender");

  const requiredIfFormerlyIncarcerated = {
    validate: (value: unknown) =>
      !hasBeenIncarcerated ||
      (value !== undefined && value !== null) ||
      t("The field is required"),
  };

  const requiredIfCurrentlyIncarcerated = {
    validate: (value: unknown) =>
      !isIncarcerated ||
      (value !== undefined && value !== null) ||
      t("The field is required"),
  };

  const requireIfNoDirectJusticeInvolvement = {
    validate: (value: unknown) =>
      isIncarcerated ||
      hasBeenIncarcerated ||
      (value !== undefined && value !== null) ||
      t("The field is required"),
  };

  const requiredIfJusticeInvolved = {
    validate: (value: unknown) =>
      !(hasBeenIncarcerated || isIncarcerated) ||
      (value !== undefined && value !== null) ||
      t("The field is required"),
  };

  const requireIfNotIncarcerated = {
    validate: (value: unknown) =>
      isIncarcerated === true ||
      (value !== undefined && value !== null) ||
      t("The field is required"),
  };

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <Stack spacing={4} marginBottom={theme.spacing(6)}>
        <YesNoToggleInput
          name="hasPendingCases"
          prompt={LABEL_MAP.hasPendingCases}
          control={control}
          rules={{
            validate: (value) =>
              isBoolean(value) || t("This field is required"),
          }}
        />
        <NumberInput
          name="numberPointsDriverRecord"
          prompt={LABEL_MAP.numberPointsDriverRecord}
          control={control}
          helperText={t('Enter "0" if none.')}
          rules={{
            required: {
              value: true,
              message: "Field retention is required",
            },
            min: 0,
          }}
        />
        <NumberInput
          name="numberDUIPast7Years"
          prompt={LABEL_MAP.numberDUIPast7Years}
          control={control}
          rules={{
            required: {
              value: true,
              message: "Field is required",
            },
            min: 0,
          }}
        />
        <NumberInput
          name="numberDUIRecordLifetime"
          prompt={LABEL_MAP.numberDUIRecordLifetime}
          control={control}
          rules={{
            required: {
              value: true,
              message: "Field is required",
            },
            min: 0,
          }}
        />
        <YesNoToggleInput
          name="isIncarcerated"
          prompt={LABEL_MAP.isIncarcerated}
          control={control}
          rules={{
            validate: (value) =>
              isBoolean(value) || t("This field is required"),
          }}
        />
        {isIncarcerated === true && (
          <Stack spacing={4}>
            <TextInput
              name="housingUnit"
              prompt={LABEL_MAP.housingUnit}
              control={control}
              rules={requiredIfCurrentlyIncarcerated}
            />{" "}
            <TextInput
              name="incarcerationIdentifier"
              prompt={LABEL_MAP.incarcerationIdentifier}
              control={control}
              rules={requiredIfCurrentlyIncarcerated}
            />
            {!hasPendingCases ? (
              <>
                <DateInput
                  name="releaseDate"
                  prompt={LABEL_MAP.releaseDate}
                  control={control}
                  minDate={endOfYesterday()}
                  helperText={t("Leave it blank if you don't know.")}
                />
                <DateInput
                  name="nextParoleHearingDate"
                  prompt={LABEL_MAP.nextParoleHearingDate}
                  control={control}
                  minDate={endOfYesterday()}
                  helperText={t("Leave it blank if it's not applicable.")}
                />
              </>
            ) : (
              <DateInput
                name="nextCourtDate"
                prompt={LABEL_MAP.nextCourtDate}
                control={control}
                minDate={endOfYesterday()}
                helperText={t("Leave it blank if you don't know.")}
              />
            )}
            <TextInput
              name="postReleaseCity"
              prompt={LABEL_MAP.postReleaseCity}
              control={control}
              label={t("Tap to select")}
              rules={requiredIfCurrentlyIncarcerated}
            />
            <SelectInput
              name="postReleaseState"
              prompt={LABEL_MAP.postReleaseState}
              control={control}
              label={t("Tap to select")}
              items={Object.entries(USState).map((entry) => ({
                value: entry[1],
                name: entry[0],
              }))}
              rules={requiredIfCurrentlyIncarcerated}
            />
            <SelectInput
              name="postReleaseTransportation"
              prompt={LABEL_MAP.postReleaseTransportation}
              control={control}
              label={t("Tap to select")}
              items={Object.entries(TransportationSituation).map((entry) => ({
                value: entry[0],
                name: entry[1],
              }))}
              rules={requiredIfCurrentlyIncarcerated}
            />
            <SelectInput
              name="postReleaseHousing"
              prompt={LABEL_MAP.postReleaseHousing}
              label={t("Tap to select")}
              control={control}
              rules={requiredIfCurrentlyIncarcerated}
              items={Object.entries(HousingSituation).map((entry) => ({
                value: entry[0],
                name: getHousingSituationLabel(entry[1], isIncarcerated),
              }))}
            />
          </Stack>
        )}
        {isIncarcerated === false && (
          <YesNoToggleInput
            name="hasBeenIncarcerated"
            prompt={LABEL_MAP.hasBeenIncarcerated}
            control={control}
            rules={{
              validate: (value) =>
                isBoolean(value) || t("This field is required"),
            }}
          />
        )}
        {(isIncarcerated || hasBeenIncarcerated) && (
          <Stack spacing={4}>
            <YesNoToggleInput
              name="isFirstTimeOffender"
              prompt={LABEL_MAP.isFirstTimeOffender}
              helperText={t(
                "People commonly refer to this as a first-time offense."
              )}
              control={control}
              rules={requiredIfJusticeInvolved}
            />
            {!isFirstTimeOffender && (
              <YesNoToggleInput
                name="hasMoreThan4FelonyConvictions7Years"
                prompt={LABEL_MAP.hasMoreThan4FelonyConvictions7Years}
                helperText={t(
                  "If you were convicted of multiple felonies in the same trial, please count each felony separately, as one. Do not group them together."
                )}
                control={control}
              />
            )}
            <YesNoToggleInput
              name="hasSexOffense"
              prompt={LABEL_MAP.hasSexOffense}
              control={control}
              rules={requiredIfJusticeInvolved}
            />
            <YesNoToggleInput
              name="hasViolentOffense"
              prompt={LABEL_MAP.hasViolentOffense}
              control={control}
              rules={requiredIfJusticeInvolved}
            />
            <NumberInput
              control={control}
              name="totalIncarcerationDuration"
              prompt={LABEL_MAP.totalIncarcerationDuration}
              label={t("Years incarcerated")}
              rules={requiredIfJusticeInvolved}
            />
          </Stack>
        )}
        {hasBeenIncarcerated && (
          <Stack spacing={4}>
            <DateInput
              control={control}
              name="lastReleased"
              prompt={LABEL_MAP.lastReleased}
              disableFuture
              disableHighlightToday
              rules={requiredIfFormerlyIncarcerated}
            />
            <YesNoToggleInput
              name="isProbationOrParole"
              prompt={LABEL_MAP.isProbationOrParole}
              control={control}
              rules={requiredIfFormerlyIncarcerated}
            />
          </Stack>
        )}
        {hasBeenIncarcerated === false && isIncarcerated === false && (
          <Stack spacing={4}>
            <YesNoToggleInput
              name="hasDoneDiversionProgram"
              prompt={LABEL_MAP.hasDoneDiversionProgram}
              control={control}
              rules={requireIfNoDirectJusticeInvolvement}
            />
            <YesNoToggleInput
              name="isJusticeImpacted"
              prompt={LABEL_MAP.isJusticeImpacted}
              control={control}
              rules={requireIfNoDirectJusticeInvolvement}
              helperText={t(
                "Have you experienced significant life changes because a family member was incarcerated?"
              )}
            />
            <YesNoToggleInput
              name="hasBeenProbationOrParole"
              prompt={LABEL_MAP.hasBeenProbationOrParole}
              control={control}
              rules={requireIfNoDirectJusticeInvolvement}
            />
          </Stack>
        )}

        {isIncarcerated === false && (
          <>
            <SelectInput
              name="transportation"
              prompt={LABEL_MAP.transportation}
              control={control}
              label={t("Tap to select")}
              items={Object.entries(TransportationSituation).map((entry) => ({
                value: entry[0],
                name: entry[1],
              }))}
              rules={requireIfNotIncarcerated}
            />
            <SelectInput
              name="housing"
              prompt={LABEL_MAP.housing}
              label={t("Tap to select")}
              control={control}
              rules={requireIfNotIncarcerated}
              items={Object.entries(HousingSituation).map((entry) => ({
                value: entry[0],
                name: getHousingSituationLabel(entry[1], isIncarcerated),
              }))}
            />
          </>
        )}
      </Stack>
      <Grid container justifyContent="space-between">
        <Button onClick={onBack}>
          <ArrowBackIcon />
          &nbsp;
          {t("Back")}
        </Button>
        <SubmitButton loading={isSubmitting}>{t("Next")}</SubmitButton>
      </Grid>
    </form>
  );
}
