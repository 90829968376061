import * as Types from '../../clients/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { ResourceMetadataFragmentDoc } from '../../graphql/ResourceMetadataFragment.generated';
export type GetResourcesByUnitQueryVariables = Types.Exact<{
  unitId: Types.Scalars['ItemId'];
}>;


export type GetResourcesByUnitQuery = { __typename?: 'Query', allResources: Array<{ __typename?: 'ResourceRecord', id: string, name: string, description: string, url: string, resourceType: string, order: number, duration: number, fallbackUrl: string, thumbnailUrl: string, quiz?: { __typename?: 'QuizRecord', id: string, name: string, quizType: string } | null }> };


export const GetResourcesByUnitDocument = {"kind":"Document", "definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetResourcesByUnit"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"unitId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ItemId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"allResources"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"unit"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"eq"},"value":{"kind":"Variable","name":{"kind":"Name","value":"unitId"}}}]}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ResourceMetadata"}},{"kind":"Field","name":{"kind":"Name","value":"quiz"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"quizType"}}]}}]}}]}},...ResourceMetadataFragmentDoc.definitions]} as unknown as DocumentNode<GetResourcesByUnitQuery, GetResourcesByUnitQueryVariables>;