import React, { ForwardedRef } from "react";
import withController from "../withController";
import { TextInputBase, TextInputProps } from "./TextInput";

type NumberInputProps = Omit<TextInputProps, "onChange" | "value"> & {
  onChange: (value: number | null) => void;
  value: number | null;
};

const toNumber = (s: string) => {
  const int = parseInt(s, 10); // directly parse the string to avoid redundant conversion
  return !Number.isNaN(int) ? int : null; // this will properly return 0 when s is "0"
};

const fromNumber = (n: number | null) =>
  n === null || n === undefined || Number.isNaN(n) ? "" : n.toString();

function NumberInput(
  { value, onChange, ...rest }: NumberInputProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  return (
    <TextInputBase
      ref={ref}
      {...rest}
      type="number"
      value={fromNumber(value)}
      onChange={(e) => onChange(toNumber(e.target.value))}
    />
  );
}

export const NumberInputBase = React.forwardRef(
  NumberInput
) as typeof NumberInput;

export default withController(NumberInputBase);
