import Joi from "joi";
import {
  ChatMessage,
  ChatMessageSenderType,
} from "src/pages/DashboardPage/AdminParticipantChatTab/ChatMessage";
import { UserAccount } from "src/types/User";
import serviceFunction from "src/utils/serviceFunction";
import createChatMessage from "./createChatMessage";

// Define the type for the input schema
export type CreateUserChatMessageData = {
  text: string;
  user: UserAccount;
};

// Joi validation schema to validate `text` and `user`
const schema = Joi.object<CreateUserChatMessageData>({
  text: Joi.string().required(),
  user: Joi.object().required(),
});

const createUserChatMessage = serviceFunction(
  schema,
  async (data: CreateUserChatMessageData, clients): Promise<ChatMessage> => {
    const { user, text } = data;

    // Pass the constructed message data to the existing `createChatMessage`
    return await createChatMessage(
      {
        participantId: user.uid,
        senderId: user.uid,
        senderType: ChatMessageSenderType.Participant,
        text,
      },
      clients
    );
  }
);

export default createUserChatMessage;
