import { collection, getDocs } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";
import { StudentReference } from "src/types/StudentReference";
import isStudentReferenceGuard from "src/utils/isStudentReferenceGuard";
import cleanFirestoreDoc from "./cleanFirestoreDoc";

export default async function fetchReferences(
  participantId: string,
  { db }: Clients
): Promise<StudentReference[]> {
  const snapshot = await getDocs(
    collection(db, "users", participantId, "references")
  );
  return snapshot.docs.map(cleanFirestoreDoc).filter(isStudentReferenceGuard);
}
