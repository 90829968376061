export default function groupBy<T, V extends string | symbol | number>(
  arr: T[],
  getKeyValue: (i: T) => V,
  initializer: Record<V, T[]> = {} as Record<V, T[]>
): Record<V, T[]> {
  const grouped = initializer;
  arr.forEach((i) => {
    const keyValue = getKeyValue(i);
    if (!grouped[keyValue]) grouped[keyValue] = [];
    grouped[keyValue].push(i);
  });
  return grouped;
}
