import { doc, getDoc, updateDoc } from "firebase/firestore";
import Joi from "joi";
import { ReferenceFormData } from "src/pages/OnboardingScreen/ReferenceForm";
import { StudentReference } from "src/types/StudentReference";
import { UserAccount } from "src/types/User";
import isStudentReferenceGuard from "src/utils/isStudentReferenceGuard";
import serviceFunction from "src/utils/serviceFunction";
import cleanFirestoreDoc from "./cleanFirestoreDoc";
import generateFirestoreTimestamps from "./generateFirestoreTimestamps";

type Data = {
  user: UserAccount;
  reference: StudentReference;
  payload: ReferenceFormData;
};

const schema = Joi.object<Data>({
  user: Joi.object().required(),
  reference: Joi.object().required(),
  payload: Joi.object().required(),
});

const updateReference = serviceFunction(
  schema,
  async ({ user, reference, payload }, { db }): Promise<StudentReference> => {
    await updateDoc(doc(db, `users/${user.uid}/references/${reference.uid}`), {
      ...payload,
      ...generateFirestoreTimestamps(new Date(), "lastUpdatedAt"),
    });
    const updated = cleanFirestoreDoc(
      await getDoc(doc(db, `users/${user.uid}/references/${reference.uid}`))
    );

    if (!isStudentReferenceGuard(updated))
      throw new Error("The update had malformed data");
    return updated;
  }
);

export default updateReference;
