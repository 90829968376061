import { Add, Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ConfirmDialog from "src/components/ConfirmDialog";
import Modal from "src/components/Modal";
import ModalContainer from "src/components/ModalContainer";
import { ListSkeleton } from "src/components/Skeleton/closet";
import { useAppContext } from "src/contexts/AppContext";
import createAdminActionItem from "src/firebase/createAdminActionItem";
import deleteAdminActionItem from "src/firebase/deleteAdminActionItem";
import fetchAdminActionItems from "src/firebase/fetchAdminActionItems";
import fetchAdminActionItemsForUser from "src/firebase/fetchAdminActionItemsForUser";
import fetchUser from "src/firebase/fetchUser";
import updateAdminActionItem from "src/firebase/updateAdminActionItem";
import { useAdmin } from "src/SessionBoundary";
import { AdminActionItem } from "src/types/AdminActionItem";
import { UserAccount } from "src/types/User";
import useErrorHandler from "src/utils/useErrorHandler";
import ClientProfileLink from "../AdminScreen/ClientProfileLink";
import AdminActionItemForm, {
  AdminActionItemFormData,
} from "./AdminActionItemForm";

type Props = {
  user?: UserAccount;
};

export default function AdminActionItemList({ user }: Props) {
  const [actionItems, setActionItems] = useState<AdminActionItem[]>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<AdminActionItem | null>(
    null
  );
  const { clients } = useAppContext();
  const admin = useAdmin();
  const { t } = useTranslation();
  const errorHandler = useErrorHandler();

  // Memoize loaded users for instances where we are loading all users for action items
  const [loadedUsers, setLoadedUsers] = useState<{
    [key: string]: UserAccount;
  }>({});

  useEffect(() => {
    const fetchData = async () => {
      const items = user
        ? await fetchAdminActionItemsForUser(admin, user, clients)
        : await fetchAdminActionItems(admin.uid, clients);
      setActionItems(items);
    };
    fetchData();
  }, [admin, clients, user]);

  useEffect(() => {
    // if there is a specific user you have in mind, dont load this as we'll be loading all users
    if (user) return;

    // otherwise
    const uniqueUserIds = Array.from(
      new Set((actionItems || []).map((item) => item.userId))
    );

    const fetchAllUsers = async () => {
      const res: typeof loadedUsers = {};

      await Promise.all(
        uniqueUserIds.map(async (userId) => {
          await fetchUser(userId, clients)
            .then((user) => {
              res[user.uid] = user;
            })
            .catch(errorHandler);
        })
      ).then(() => setLoadedUsers(res));
    };

    fetchAllUsers();
  }, [actionItems, user, clients, errorHandler]);

  const handleCreate = async (data: AdminActionItemFormData) => {
    if (!user) return;
    await createAdminActionItem({ ...data, user, owner: admin }, clients)
      .then((newItem) => {
        setActionItems((prevItems) => [...(prevItems || []), newItem]);
        setIsModalOpen(false);
      })
      .catch(errorHandler);
  };

  const handleDelete = async (item: AdminActionItem) => {
    await deleteAdminActionItem({ admin, actionItem: item }, clients)
      .then(() => {
        setActionItems((prevItems) =>
          (prevItems || []).filter((i) => i.uid !== item.uid)
        );
        setSelectedItem(null);
      })
      .catch(errorHandler);
  };

  const handleToggleCompletion = async (item: AdminActionItem) => {
    await updateAdminActionItem(
      {
        payload: {
          completed: !item.completedAt,
        },
        admin,
        actionItem: item,
      },
      clients
    )
      .then((updated) => {
        setActionItems((prevItems) =>
          (prevItems || []).map((i) => (i.uid === item.uid ? updated : i))
        );
      })
      .catch(errorHandler);
  };

  const openConfirmDialog = (item: AdminActionItem) => {
    setSelectedItem(item);
  };

  const closeConfirmDialog = () => {
    setSelectedItem(null);
  };

  if (!actionItems) return <ListSkeleton />;

  return (
    <Stack spacing={2}>
      <Box width="100%" display="flex">
        <Typography variant="h5">{t("Action Items")}</Typography>
        {user && (
          <Button
            sx={{ marginLeft: "auto" }}
            variant="outlined"
            startIcon={<Add />}
            onClick={() => setIsModalOpen(true)}
          >
            {t("Add")}
          </Button>
        )}
      </Box>
      <List>
        {actionItems.map((item) => {
          const loadedUser = loadedUsers[item.userId];

          return (
            <ListItem
              key={item.uid}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => openConfirmDialog(item)}
                >
                  <Delete />
                </IconButton>
              }
            >
              <ListItemIcon>
                <Checkbox
                  edge="end"
                  checked={!!item.completedAt}
                  onChange={() => handleToggleCompletion(item)}
                />
              </ListItemIcon>
              <ListItemAvatar>
                {loadedUser && <ClientProfileLink client={loadedUser} />}
              </ListItemAvatar>
              <ListItemText
                primary={item.title}
                secondary={`${format(new Date(item.dueDate), "MMM d")}`}
              />
            </ListItem>
          );
        })}
      </List>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div>
          <ModalContainer>
            <Card>
              <CardContent>
                <AdminActionItemForm onSubmit={handleCreate} />
              </CardContent>
            </Card>
          </ModalContainer>
        </div>
      </Modal>

      <ConfirmDialog
        open={!!selectedItem}
        onConfirm={() => selectedItem && handleDelete(selectedItem)}
        onCancel={closeConfirmDialog}
      />
    </Stack>
  );
}
