import { Box, CircularProgress, List, ListItem, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import Empty from "src/components/Empty";
import { Header32 } from "src/components/Typography";
import { useAppContext } from "src/contexts/AppContext";
import fetchOutstandingReimbursementCount from "src/firebase/fetchOutstandingReimbursementCount"; // The function you provided
import ClientProfileLink from "src/pages/AdminScreen/ClientProfileLink";
import { UserAccount } from "src/types/User";
import batchUserRequest from "src/utils/batchUserRequest";
import useErrorHandler from "src/utils/useErrorHandler";

type MissingReimbursementsProps = {
  users: UserAccount[];
};

const MissingReimbursements = ({ users }: MissingReimbursementsProps) => {
  const [loading, setLoading] = useState(true);
  const [usersWithMissingReimbursements, setUsersWithMissingReimbursements] =
    useState<UserAccount[]>([]);
  const { clients } = useAppContext();
  const errorHandler = useErrorHandler();

  useEffect(() => {
    setLoading(true);

    // Fetch outstanding reimbursements for each user
    batchUserRequest(
      users,
      async (user) =>
        await fetchOutstandingReimbursementCount(user.uid, clients)
    )
      .then((result) => {
        // Filter users with outstanding reimbursements
        const filteredUsers = users.filter(
          (user) => result[user.uid] !== undefined && result[user.uid]! > 0
        );
        setUsersWithMissingReimbursements(filteredUsers);
      })
      .catch(errorHandler)
      .finally(() => setLoading(false));
  }, [users, clients, errorHandler]);

  const handleUserClick = (userId: string) => {
    window.open(`/admin/clients/${userId}`, "_blank");
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (usersWithMissingReimbursements.length === 0) {
    return (
      <Stack spacing={2}>
        <Header32>Outstanding Reimbursements</Header32>
        <Empty />
      </Stack>
    );
  }

  return (
    <Box>
      <Header32 marginBottom={2}>Outstanding Reimbursements</Header32>
      <List>
        {usersWithMissingReimbursements.map((user) => (
          <ListItem key={user.uid} onClick={() => handleUserClick(user.uid)}>
            <ClientProfileLink client={user} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default MissingReimbursements;
