import { Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemText,
  Modal,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppContext } from "src/contexts/AppContext";
import updateUserBankAccountInformation from "src/firebase/updateUserBankAccount";
import BankAccountForm from "src/pages/Admin/SuperAdminScreen/BankAccountForm";
import { BankAccount } from "src/types/BankAccount";
import { UserAccount } from "src/types/User";
import ModalContainer from "../ModalContainer";

type BankAccountCardProps = {
  user: UserAccount;
};

export default function BankAccountCard({ user }: BankAccountCardProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { clients } = useAppContext();

  const handleEdit = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleFormSubmit = async (data: BankAccount) => {
    // Call the service function to update bank account information
    await updateUserBankAccountInformation(
      { user, bankAccount: data },
      clients
    );
    // Close the modal after updating
    handleClose();
  };

  return (
    <Box>
      {user.bankAccount ? (
        <Card>
          <CardHeader title={t("Bank Account")} />
          <CardContent>
            <List>
              <ListItem>
                <ListItemText
                  primary={t("Type")}
                  secondary={user.bankAccount?.type || t("Not Provided")}
                />
              </ListItem>
              {user.bankAccount?.type === "ach" && (
                <>
                  <ListItem>
                    <ListItemText
                      primary={t("Routing Number")}
                      secondary={
                        user.bankAccount?.routingNumber || t("Not Provided")
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={t("Account Number")}
                      secondary={
                        user.bankAccount?.accountNumber || t("Not Provided")
                      }
                    />
                  </ListItem>
                </>
              )}
              {(user.bankAccount.type === "cashapp" ||
                user.bankAccount.type === "venmo") && (
                <ListItem>
                  <ListItemText
                    primary={t("App Username")}
                    secondary={
                      user.bankAccount?.appUsername || t("Not Provided")
                    }
                  />
                </ListItem>
              )}
            </List>
            <CardActions>
              <Button startIcon={<Edit />} onClick={handleEdit}>
                {t("Edit")}
              </Button>
            </CardActions>
          </CardContent>
        </Card>
      ) : (
        <Card>
          <CardHeader title={t("Bank Account")} subheader={t("Incomplete")} />
          <CardActions>
            <Button startIcon={<Edit />} onClick={handleEdit}>
              {t("Edit")}
            </Button>
          </CardActions>
        </Card>
      )}
      {/* Modal for editing bank information */}
      <Modal open={open} onClose={handleClose}>
        <ModalContainer>
          <Card>
            <CardHeader title={t("Edit Account for Reimbursements")} />
            <CardContent>
              <BankAccountForm
                onSubmit={handleFormSubmit}
                defaultValues={user.bankAccount}
              />
            </CardContent>
          </Card>
        </ModalContainer>
      </Modal>{" "}
    </Box>
  );
}
