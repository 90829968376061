/* eslint-disable @typescript-eslint/no-explicit-any */
import { Timestamp } from "firebase/firestore";
import {
  BaseFeedback,
  Feedback,
  SkillsTrainingFeedback,
  TheoryTrainingFeedback,
} from "src/types/Feedback";

// Base feedback guard: Checks common fields (without 'type')
function isBaseFeedback(data: any): data is BaseFeedback {
  return (
    typeof data === "object" &&
    typeof data.createdAt === "string" &&
    data.createdAtFirestoreTimestamp instanceof Timestamp &&
    typeof data.userId === "string" &&
    typeof data.rating === "number"
  );
}

// Guard for TheoryTrainingFeedback (checks 'type' and 'resourceId')
export function isTheoryTrainingFeedback(
  data: any
): data is TheoryTrainingFeedback {
  return (
    data.type === "theory" &&
    typeof data.resourceId === "string" &&
    isBaseFeedback(data)
  );
}

// Guard for SkillsTrainingFeedback (checks 'type')
function isSkillsTrainingFeedback(data: any): data is SkillsTrainingFeedback {
  return data.type === "skills" && isBaseFeedback(data);
}

// General feedback guard that covers both types
export default function isFeedbackGuard(data: any): data is Feedback {
  return isTheoryTrainingFeedback(data) || isSkillsTrainingFeedback(data);
}
