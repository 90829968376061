import { Organization } from "src/types";

export default function isOrganizationGuard(obj: unknown): obj is Organization {
  const org = obj as Organization;
  return (
    typeof org?.uid === "string" &&
    typeof org?.name === "string" &&
    typeof org?.logoImageUrl === "string"
  );
}
