import { AddOutlined } from "@mui/icons-material";
import { Box, Button, Card, CardContent, Modal, Stack } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ReimbursementCard from "src/components/Card/ReimbursementCard";
import Empty from "src/components/Empty";
import ReimbursementForm, {
  ReimbursementFormData,
} from "src/components/Forms/ReimbursementForm";
import ModalContainer from "src/components/ModalContainer";
import { CardSkeleton } from "src/components/Skeleton/closet";
import { Header20 } from "src/components/Typography";
import useUserReimbursements from "src/hooks/useUserReimbursements";
import { UserAccount } from "src/types/User";
import useUserMilestones from "src/utils/useUserMilestones";

type Props = {
  user: UserAccount;
};

export default function ReimbursementSection({ user }: Props) {
  const {
    loading: loadingReimbursements,
    reimbursements,
    createReimbursementEntry,
    deleteReimbursementEntry,
    updateCompletionStatus,
  } = useUserReimbursements(user);
  const { t } = useTranslation();
  const { milestones, loading: loadingMilestones } = useUserMilestones(user);
  const [isCreating, setIsCreating] = useState(false);

  const handleCreate = async (data: ReimbursementFormData) => {
    await createReimbursementEntry(data).then(() => setIsCreating(false));
  };

  if (!milestones || loadingReimbursements || loadingMilestones)
    return <CardSkeleton />;

  return (
    <Box>
      <Header20 marginBottom={2}>{t("Reimbursements")}</Header20>
      {reimbursements && reimbursements.length > 0 ? (
        <Box>
          <Stack spacing={2}>
            {reimbursements.map((reimbursement) => (
              <Box key={reimbursement.uid}>
                <ReimbursementCard
                  reimbursement={reimbursement}
                  milestones={milestones}
                  onReimbursementCompletion={async () =>
                    await updateCompletionStatus(
                      reimbursement,
                      !reimbursement.sentReimbursement
                    )
                  }
                  onDelete={async () =>
                    await deleteReimbursementEntry(reimbursement.uid)
                  }
                  variant="outlined"
                />
              </Box>
            ))}
          </Stack>
          <Button
            variant="outlined"
            startIcon={<AddOutlined />}
            onClick={() => setIsCreating(true)}
            sx={{ marginTop: 2 }}
          >
            {t("Add reimbursement request")}
          </Button>
        </Box>
      ) : (
        <Empty
          description={t("Add reimbursement")}
          cta={{
            onClick: () => setIsCreating(true),
            label: t("Create first reimbursement"),
            variant: "text",
          }}
        />
      )}

      <Modal open={isCreating} onClose={() => setIsCreating(false)}>
        <ModalContainer>
          <div>
            <Card>
              <CardContent>
                <ReimbursementForm
                  milestones={milestones}
                  onSubmit={handleCreate}
                />
              </CardContent>
            </Card>
          </div>
        </ModalContainer>
      </Modal>
    </Box>
  );
}
