/* eslint-disable react/function-component-definition */
import {
  Alert,
  Box,
  FormLabel,
  Grid,
  MenuItem,
  SelectProps as BaseProps,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { ForwardedRef } from "react";
import withController from "../withController";

type ManagedProps = "select" | "children";
export type SelectProps = Omit<BaseProps, ManagedProps> & {
  onChange: Required<BaseProps>["onChange"];
  value: Required<BaseProps>["value"];
  error?: boolean;
  helperText?: string;
  emptyText?: string;
  prompt?: string;
  items: {
    value: string | undefined;
    name: string;
    disabled?: boolean;
    helperText?: string;
  }[];
};

// function isArrayOfStrings(value: unknown): value is string[] {
//   return (
//     Array.isArray(value) && value.every((item) => typeof item === "string")
//   );
// }
/**
 * SelectInputBase defines any custom behaviors and styles of our standard select input.
 */
function SelectInput(props: SelectProps, ref: ForwardedRef<HTMLInputElement>) {
  const { id, items, label, error, helperText, emptyText, prompt, ...rest } =
    props;
  return (
    <Grid container alignItems={{ xs: "flex-start", sm: "center" }} ref={ref}>
      {prompt && (
        <Grid item xs={12} sm={6}>
          <FormLabel>{prompt}</FormLabel>
        </Grid>
      )}
      <Grid item xs={12} sm={prompt ? 6 : 12}>
        <TextField
          select
          id={id}
          label={label}
          error={error}
          helperText={helperText}
          SelectProps={{
            ...rest,
            value: rest.value || "",
            // renderValue: (selected) =>
            //   isArrayOfStrings(selected)
            //     ? selected
            //         .map((x) => items.find((item) => item.value === x)?.name)
            //         .join(", ")
            //     : items.find((item) => item.value === selected)?.name,
          }}
          inputRef={ref}
          sx={{ width: 1 }}
        >
          {items.map(
            ({ value, name, disabled, helperText: itemHelperText }) => (
              <MenuItem
                value={value}
                disabled={disabled}
                key={`${value}-${name}`}
              >
                <Stack>
                  <Box sx={{ whiteSpace: "nowrap" }}>{name}</Box>
                  {itemHelperText && (
                    <Typography component="div" variant="caption">
                      {itemHelperText}
                    </Typography>
                  )}
                </Stack>
              </MenuItem>
            )
          )}
          {items.length === 0 && emptyText && (
            <Alert severity="info" sx={{ w: 1 }}>
              {emptyText}
            </Alert>
          )}
        </TextField>
      </Grid>
    </Grid>
  );
}

export const SelectInputBase = React.forwardRef(
  SelectInput
) as typeof SelectInput;

export default withController(SelectInputBase);
