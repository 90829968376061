import { doc, getDoc, Timestamp, updateDoc } from "firebase/firestore";
import Joi from "joi";
import generateFirestoreTimestamps from "src/firebase/generateFirestoreTimestamps";
import { MilestoneTask } from "src/types/MilestoneTask";
import isMilestoneTaskGuard from "src/utils/isMilestoneTaskGuard";
import serviceFunction from "src/utils/serviceFunction";
import cleanFirestoreDoc from "./cleanFirestoreDoc";

type Data = {
  task: MilestoneTask;
  completed: boolean;
};

const schema = Joi.object<Data>({
  task: Joi.object().required(),
  completed: Joi.boolean().required(),
});

const updateMilestoneTaskCompletion = serviceFunction(
  schema,
  async ({ completed, task }, { db }): Promise<MilestoneTask> => {
    const now = new Date();
    const completedAt = completed ? now.toISOString() : null;
    const completedAtFirestoreTimestamp = completed
      ? Timestamp.fromDate(now)
      : null;

    await updateDoc(
      doc(
        db,
        `users/${task.userId}/plan/${task.milestoneId}/tasks/${task.uid}`
      ),
      {
        completedAt,
        completedAtFirestoreTimestamp,
        ...generateFirestoreTimestamps(new Date(), "lastUpdatedAt"),
      }
    );

    const updatedDoc = await getDoc(
      doc(db, `users/${task.userId}/plan/${task.milestoneId}/tasks/${task.uid}`)
    );

    const updatedTask = cleanFirestoreDoc(updatedDoc);

    if (!isMilestoneTaskGuard(updatedTask)) {
      throw new Error("Updated task is not a MilestoneTask");
    }

    return updatedTask;
  }
);

export default updateMilestoneTaskCompletion;
