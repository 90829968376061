// needs to remain in sync with server
enum NoteType {
  Call = "Call",
  Text = "Text",
  Milestone = "Milestone",
  ActionItem = "Action Item",
  Comment = "Comment",
  Challenge = "Challenge",
}

export default NoteType;
