import { Card, CardContent, CardMedia, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Avatar from "src/components/Avatar";
import { useAppContext } from "src/contexts/AppContext";
import fetchAdmin from "src/firebase/fetchAdmin";
import { Admin } from "src/types/Admin";
import { CohortAnnouncement } from "src/types/CohortAnnouncement";
import { getFullName } from "src/utils";
import formatDateString from "src/utils/formatDateString";
import useErrorHandler from "src/utils/useErrorHandler";

type Props = {
  announcement: CohortAnnouncement;
};

export default function CohortAnnouncementCard({ announcement }: Props) {
  const [author, setAuthor] = useState<Admin>();
  const errorHandler = useErrorHandler();
  const { clients } = useAppContext();
  const { t } = useTranslation();

  useEffect(() => {
    fetchAdmin(announcement.authorId, clients)
      .then(setAuthor)
      .catch(errorHandler);
  }, [announcement, clients, errorHandler]);

  return (
    <Card>
      <CardContent
        sx={(theme) => ({ backgroundColor: theme.palette.info.light })}
      >
        <Stack spacing={1}>
          <CardMedia src={announcement.imageUrl} />{" "}
          <Typography variant="body2" color="text.secondary">
            {formatDateString(announcement.createdAt, "date")}
          </Typography>
          <Typography>{announcement.body}</Typography>
          {author && (
            <Stack spacing={1} direction="row" alignItems="center">
              <Typography>{t("posted by")}</Typography>
              <Avatar sx={{ height: 32, width: 32 }} src={author.photoUrl} />
              <Typography>{getFullName(author)}</Typography>
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
