import { CheckCircle, QuizOutlined, SchoolOutlined } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Fade,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StudyPlanByWeek } from "./generateStudyPlan"; // Assuming the StudyPlanByWeek type is defined in this file

type Props = {
  studyPlanByWeek: StudyPlanByWeek;
  fadeInDelay?: number; // Optional delay for fade-in effect in milliseconds
};

const THUMBNAIL_ASSETS = [
  "https://f004.backblazeb2.com/file/emerge-courses/thumbnails/IntroUnitThumbnail.jpeg",
  "https://f004.backblazeb2.com/file/emerge-courses/thumbnails/AirBrakesUnitThumbnail.jpeg",
  "https://f004.backblazeb2.com/file/emerge-courses/thumbnails/CombinationVehiclesUnit.jpeg",
  "https://f004.backblazeb2.com/file/emerge-courses/thumbnails/SafetyUnitThumbnail.jpeg",
  "https://f004.backblazeb2.com/file/emerge-courses/thumbnails/TransportationUnitThumbnail.jpeg",
];

export default function StudyPlanWeekCardList({
  studyPlanByWeek,
  fadeInDelay = 300,
}: Props) {
  const [visibleWeeks, setVisibleWeeks] = useState<number[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    const timeouts: NodeJS.Timeout[] = [];

    Object.keys(studyPlanByWeek).forEach((weekNumber, index) => {
      const timeout = setTimeout(() => {
        setVisibleWeeks((prevVisibleWeeks) => [
          ...prevVisibleWeeks,
          Number(weekNumber),
        ]);
      }, fadeInDelay * index); // Stagger the fade-in effect based on the index
      timeouts.push(timeout);
    });

    return () => {
      timeouts.forEach((timeout) => clearTimeout(timeout)); // Cleanup timeouts on unmount
    };
  }, [studyPlanByWeek, fadeInDelay]);

  return (
    <Grid container spacing={2}>
      {Object.values(studyPlanByWeek).map((week, idx) => (
        <Grid item xs={12} key={week.weekNumber}>
          <Fade in={visibleWeeks.includes(week.weekNumber)} timeout={1000}>
            <Card variant="outlined">
              <CardMedia
                component="img"
                image={THUMBNAIL_ASSETS[idx % THUMBNAIL_ASSETS.length]}
                height={100}
                // alt={`Week ${week.weekNumber + 1} Thumbnail`}
              />
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {`Week ${week.weekNumber + 1} Module`}
                </Typography>
                {week.lessonIds.length > 0 ? (
                  <Box display="flex" alignItems="center">
                    <SchoolOutlined sx={{ marginRight: 1 }} />
                    <Typography variant="body2">{`Total Lessons: ${week.lessonIds.length}`}</Typography>
                  </Box>
                ) : (
                  <Box display="flex" alignItems="center">
                    <CheckCircle color="success" sx={{ marginRight: 1 }} />
                    <Typography variant="body2">
                      {t(`You’ll have completed the lessons by this week`)}
                    </Typography>
                  </Box>
                )}
                {week.examIds.length > 0 && (
                  <Box display="flex" alignItems="center" marginTop={1}>
                    <QuizOutlined sx={{ marginRight: 1 }} />
                    <Typography variant="body2">{`Total Quizzes: ${week.examIds.length}`}</Typography>
                  </Box>
                )}
              </CardContent>
            </Card>
          </Fade>
        </Grid>
      ))}
    </Grid>
  );
}
