import { Timestamp } from "firebase/firestore";

export type Employer = {
  uid: string;
  createdAt: string;
  createdAtFirestoreTimestamp: Timestamp;
  lastUpdatedAt: string;
  lastUpdatedAtFirestoreTimestamp: Timestamp;
  name: string;
  description: string;
  applicationLink: string;
};

export enum StudentEmployerRelationshipStatus {
  NOT_STARTED = "NOT_STARTED",
  APPLIED = "APPLIED",
  INTERVIEWING = "INTERVIEWING",
  REJECTED = "REJECTED",
  OFFERED = "OFFERED",
}

export type StudentEmployerRelationship = {
  uid: string;
  createdAt: string;
  createdAtFirestoreTimestamp: Timestamp;
  lastUpdatedAt: string;
  lastUpdatedAtFirestoreTimestamp: Timestamp;
  status: StudentEmployerRelationshipStatus;
};
