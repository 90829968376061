/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  StudyPlanData,
  StudyPlanWeek,
} from "src/pages/OnboardingScreen/generateStudyPlan";

function isStudyPlanWeek(obj: any): obj is StudyPlanWeek {
  return (
    typeof obj === "object" &&
    obj !== null &&
    typeof obj.weekNumber === "number" &&
    Array.isArray(obj.lessonIds) &&
    obj.lessonIds.every((id: any) => typeof id === "string") &&
    Array.isArray(obj.examIds) &&
    obj.examIds.every((id: any) => typeof id === "string") &&
    typeof obj.totalDuration === "number" &&
    typeof obj.targetEndDate === "string"
  );
}

export default function isStudyPlanGuard(obj: any): obj is StudyPlanData {
  // Check if the object has the required properties with correct types
  return (
    typeof obj === "object" &&
    obj !== null &&
    typeof obj.totalVideoDuration === "number" &&
    typeof obj.totalExamDuration === "number" &&
    typeof obj.totalWeeks === "number" &&
    typeof obj.startDate === "string" &&
    typeof obj.weeklyCommitmentHours === "number" &&
    typeof obj.weeklyPlan === "object" &&
    obj.weeklyPlan !== null &&
    Object.values(obj.weeklyPlan).every(isStudyPlanWeek) // Validate each week
  );
}
