import { useQuery } from "@apollo/client";
import {
  AccessTime,
  Close,
  Event,
  LightbulbOutlined,
  RocketOutlined,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slider,
  Stack,
  Typography,
} from "@mui/material";
import { addDays, addWeeks, format } from "date-fns";
import { useEffect, useState } from "react";
import { isIOS } from "react-device-detect";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SubmitButton from "src/components/Button/SubmitButton";
import { CardSkeleton } from "src/components/Skeleton/closet";
import { Header20, Header32, ScreenTitle } from "src/components/Typography";
import { useAppContext } from "src/contexts/AppContext";
import createUserStudyPlan from "src/firebase/createUserStudyPlan";
import { useCurrentUser } from "src/SessionBoundary";
import useErrorHandler from "src/utils/useErrorHandler";
import AppSkeleton from "../AppSkeleton";
import generateStudyPlan, { StudyPlanData } from "./generateStudyPlan";
import { GetRequiredExamsForStudyPlanDocument } from "./GetRequiredExamsForStudyPlan.generated";
import { GetUnitsForStudyPlanDocument } from "./GetUnitsForStudyPlan.generated";
import StudyPlanWeekCardList from "./StudyPlanWeekCardList";

type Props = {
  courseId: string;
  onSuccess?: () => void;
};

const MIN_COMMITMENT_HOURS = 5;
const START_DATE = addDays(new Date(), 1);

export default function TheoryStudyPlanScreen({ courseId, onSuccess }: Props) {
  const [weeklyCommitmentHours, setWeeklyCommitmentHours] = useState<number>(); // Default weekly hours
  const errorHandler = useErrorHandler();
  const { t } = useTranslation();
  const { clients } = useAppContext();
  const navigate = useNavigate();
  const [viewDetails, setViewDetails] = useState(false);

  const user = useCurrentUser();
  // Load Units and Exams data only once, regardless of commitment changes
  const { data: unitsData, loading: unitsLoading } = useQuery(
    GetUnitsForStudyPlanDocument,
    {
      variables: { courseId },
      onError: errorHandler,
    }
  );

  const { data: examsData, loading: examsLoading } = useQuery(
    GetRequiredExamsForStudyPlanDocument,
    {
      variables: { courseId },
      onError: errorHandler,
    }
  );

  // Check if loading
  const loading = unitsLoading || examsLoading;

  // Study plan state
  const [studyPlan, setStudyPlan] = useState<StudyPlanData | undefined>();

  useEffect(() => {
    if (!weeklyCommitmentHours) return;
    if (unitsData && examsData) {
      const units = unitsData.allUnits || [];
      const exams = examsData.allQuizzes || [];
      // tomorrow
      const generatedStudyPlan = generateStudyPlan(
        units,
        exams,
        weeklyCommitmentHours,
        START_DATE
      );
      setStudyPlan(generatedStudyPlan);
    }
  }, [unitsData, examsData, weeklyCommitmentHours]);

  // Handle slider change
  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    // https://github.com/mui/material-ui/issues/31869
    // slider is snapping back on IOS after change
    if (isIOS && event.type === "keydown") {
      return;
    }
    setWeeklyCommitmentHours(newValue as number);
  };

  const handleSubmit = async () => {
    if (!studyPlan) return;

    await createUserStudyPlan({ user, studyPlan }, clients)
      .then(() => (onSuccess ? onSuccess() : navigate("/")))
      .catch(errorHandler);
  };

  if (loading) {
    return <AppSkeleton />;
  }

  const dailyStudyTimeMinutes = weeklyCommitmentHours
    ? (weeklyCommitmentHours * 60) / 7
    : 0; // Minutes per day
  const dailyStudyTimeHours = Math.floor(dailyStudyTimeMinutes / 60);
  const dailyStudyTimeRemainingMinutes = Math.round(dailyStudyTimeMinutes % 60);
  const targetEndDate = addWeeks(new Date(), studyPlan?.totalWeeks || 0);

  return (
    <Box sx={{ padding: 2 }}>
      <Header32 gutterBottom>{t("Set your weekly study goal")}</Header32>

      {/* Slider to select weekly commitment hours */}
      <Typography gutterBottom>
        {t(
          "Before you learn how to drive, you will study online to pass the test. How many hours a week do you commit to studying?"
        )}
      </Typography>
      <Slider
        value={weeklyCommitmentHours}
        onChange={handleSliderChange}
        aria-labelledby="commitment-hours-slider"
        min={MIN_COMMITMENT_HOURS}
        getAriaValueText={(hours) => `${hours} hours`}
        max={40}
        step={1}
        valueLabelDisplay="auto"
        marks={[
          {
            value: 7,
            label: "7h",
          },
          {
            value: 20,
            label: "20h",
          },
          {
            value: 40,
            label: "Full-Time",
          },
        ]}
      />
      {weeklyCommitmentHours !== undefined &&
        weeklyCommitmentHours <= MIN_COMMITMENT_HOURS && (
          <Alert severity="warning">
            {t(
              "During online training, you need to commit at least {{ MIN_COMMITMENT_HOURS }} hours a week to be eligible for our program.",
              { MIN_COMMITMENT_HOURS }
            )}
          </Alert>
        )}

      {weeklyCommitmentHours !== undefined && weeklyCommitmentHours >= 20 && (
        <Alert severity="success">
          {t("Amazing! You'll be in the driving yard in no time.", {
            MIN_COMMITMENT_HOURS,
          })}
        </Alert>
      )}

      {/* Display study plan information */}
      {!weeklyCommitmentHours ? (
        <Typography sx={{ fontStyle: "italic" }} variant="caption">
          {t(
            "Adjust the slider according to how many hours you can study during theory training!"
          )}
        </Typography>
      ) : studyPlan ? (
        <Card variant="outlined" sx={{ marginTop: 3 }}>
          <CardContent>
            <Header20 marginBottom={2}>{t(`Your Study Plan`)}</Header20>

            <List>
              {/* First List Item */}
              <ListItem>
                <ListItemIcon>
                  <AccessTime />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Trans
                      i18nKey="I'll study <strong>{{ weeklyCommitmentHours }}</strong> hours a week."
                      values={{ weeklyCommitmentHours }}
                      components={{ strong: <strong /> }}
                    />
                  }
                  secondary={
                    <Trans
                      i18nKey="About <strong>{{ dailyStudyTimeHours }}</strong><strong>{{ dailyStudyTimeRemainingMinutes }}</strong> per day."
                      values={{
                        dailyStudyTimeHours:
                          dailyStudyTimeHours > 0
                            ? dailyStudyTimeHours === 1
                              ? `${dailyStudyTimeHours} hr `
                              : `${dailyStudyTimeHours} hrs `
                            : "",
                        dailyStudyTimeRemainingMinutes:
                          dailyStudyTimeRemainingMinutes > 0
                            ? `${dailyStudyTimeRemainingMinutes} min`
                            : "",
                      }}
                      components={{ strong: <strong /> }}
                    />
                  }
                />
              </ListItem>

              {/* Second List Item */}
              <ListItem>
                <ListItemIcon>
                  <RocketOutlined />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Trans
                      i18nKey="That means, it'll take me <strong>{{ totalWeeks }}</strong> <strong>{{ weekLabel }}</strong> to finish the online course."
                      values={{
                        totalWeeks: studyPlan.totalWeeks,
                        weekLabel:
                          studyPlan.totalWeeks === 1 ? "week" : "weeks",
                      }}
                      components={{ strong: <strong /> }}
                    />
                  }
                />
              </ListItem>

              {/* Third List Item */}
              <ListItem>
                <ListItemIcon>
                  <Event />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Trans
                      i18nKey="I will finish my online training by <strong>{{ endDate }}</strong>"
                      values={{ endDate: format(targetEndDate, "MMM d") }}
                      components={{ strong: <strong /> }}
                    />
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <LightbulbOutlined />
                </ListItemIcon>
                <ListItemText color="text.secondary">
                  {t(
                    "Creating a routine is key to building your path to success!"
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      ) : (
        <CardSkeleton />
      )}
      <SubmitButton
        sx={{ marginTop: 2 }}
        size="large"
        loading={false}
        disabled={!studyPlan}
        onClick={() => setViewDetails(true)}
      >
        {t("Preview My Plan")}
      </SubmitButton>
      <Drawer
        anchor="bottom"
        open={viewDetails}
        onClose={() => setViewDetails(false)}
      >
        <Stack
          sx={{
            width: "100%",
            maxWidth: 800, // Default for small screens
            height: "75vh",
            maxHeight: "75vh",
            overflowY: "auto",
            marginY: 4,
            marginX: "auto",
          }}
        >
          <Box marginX={4}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <ScreenTitle>{t("Review your draft plan")}</ScreenTitle>
              <Box>
                <IconButton onClick={() => setViewDetails(false)}>
                  <Close />
                </IconButton>
              </Box>
            </Stack>
            <Typography
              variant="body2"
              gutterBottom
              marginTop={1}
              marginBottom={4}
              color="text.secondary"
            >
              {t(
                "Here's your online training outline based on your commitment to study {{ weeklyCommitmentHours }}hrs a week. ",
                { weeklyCommitmentHours }
              )}
            </Typography>
          </Box>

          {studyPlan && (
            <StudyPlanWeekCardList studyPlanByWeek={studyPlan?.weeklyPlan} />
          )}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            marginTop={2}
            sx={(theme) => ({
              [theme.breakpoints.down("xs")]: {
                position: "fixed",
                bottom: 0,
                width: "100%",
                backgroundColor: "white",
                boxShadow: "0 -2px 8px rgba(0,0,0,0.1)",
                paddingY: 4,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              },
            })}
          >
            <Button size="large">{t("Back")}</Button>

            <SubmitButton
              size="large"
              loading={!studyPlan}
              disabled={!studyPlan}
              onClick={handleSubmit}
            >
              {t("Create Plan")}
            </SubmitButton>
          </Stack>
        </Stack>
      </Drawer>
    </Box>
  );
}
