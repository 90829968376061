import {
  collection,
  getCountFromServer,
  query,
  where,
} from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";

export default async function fetchExamProgressCount(
  clientId: string,
  { db }: Clients
): Promise<number> {
  const resourceCollection = query(
    collection(db, "users", clientId, "grades"),
    where("totalQuestions", ">=", 10)
  );
  const snapshot = await getCountFromServer(resourceCollection);

  return snapshot.data().count;
}
