// usRoutingNumberValidator.ts
export default function usRoutingNumberValidator(
  routingNumber: string
): boolean {
  // Check if the routing number is a 9-digit number
  if (!/^\d{9}$/.test(routingNumber)) {
    return false;
  }

  // Checksum calculation
  const digits = routingNumber.split("").map(Number);
  const checksum =
    3 * (digits[0] + digits[3] + digits[6]) +
    7 * (digits[1] + digits[4] + digits[7]) +
    1 * (digits[2] + digits[5] + digits[8]);

  return checksum % 10 === 0;
}
