import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import FullscreenBox from "src/components/FullScreenBox";
import { ScreenTitle } from "src/components/Typography";
import Placeholder from "src/Placeholder.svg";

type Props = { error: Error };

export default function ErrorScreen({ error }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <FullscreenBox
      sx={(theme) => ({
        backgroundColor: theme.palette.background.paper,
      })}
    >
      <Stack
        spacing={6}
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingX: theme.spacing(2),
          marginTop: "160px",
        })}
      >
        <img src={Placeholder} alt="" width="160" height="160" />
        <ScreenTitle sx={{ textAlign: "center" }}>
          {t("Sorry, but we encountered an error:")}
        </ScreenTitle>
        <Typography sx={{ textAlign: "center" }}>{error.message}</Typography>
        <Typography sx={{ textAlign: "center" }}>
          {t(
            "We'll do what we can to fix this. In the meantime, you can try refreshing the page."
          )}
        </Typography>
        <Stack direction="row">
          <Button onClick={() => window.location.reload()}>
            {t("Refresh")}
          </Button>
          <Button onClick={() => navigate("/")}>{t("Go Home")}</Button>
        </Stack>
      </Stack>
    </FullscreenBox>
  );
}

/**
 * This really shouldn't need to exist. However, rendering ErrorScreen directly
 * results in complaints about violating hook rules. It may be the result of how
 * the fallback function gets called inside a state component.
 */
export function ErrorFallback(props: Props): JSX.Element {
  return <ErrorScreen {...props} />;
}
