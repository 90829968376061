/**
 * Pulled from discussion on how to type location state https://github.com/remix-run/react-router/issues/8503
 * suggested PR was ignored by router folks https://github.com/remix-run/react-router/pull/8706
 * But this was the best we could do
 */
export default function isObjectWithKey<T extends string>(
  given: unknown,
  key: T
): given is Record<T, unknown> {
  return typeof given === "object" && given !== null && key in given;
}
