import {
  Button as Base,
  ButtonProps as BaseProps,
  Tooltip,
} from "@mui/material";

export type ButtonProps = Omit<BaseProps, "disableElevation"> & {
  disabledReason?: string;
};

export default function Button({
  disabledReason,
  disabled,
  ...rest
}: ButtonProps) {
  return (
    // the tooltip is disabled when the button is enabled
    <Tooltip title={disabledReason || ""}>
      <div>
        <Base {...rest} disabled={disabled} disableElevation />
      </div>
    </Tooltip>
  );
}
