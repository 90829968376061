import USState from "src/types/states";

const licenseRegexMap: { [key in USState]: RegExp[] } = {
  AL: [/^\d{7,8}$/],
  AK: [/^\d{7}$/],
  AZ: [/^[A-Z]{1}\d{8}$/, /^\d{9}$/],
  AR: [/^\d{9}$/],
  CA: [/^[A-Z]{1}\d{7}$/],
  CO: [/^\d{9}$/],
  CT: [/^\d{9}$/],
  DE: [/^\d{2,7}$/],
  DC: [/^\d{7,9}$/],
  FL: [/^[A-Z]{1}\d{12}$/],
  GA: [/^\d{9}$/],
  HI: [/^H\d{8}$/, /^\d{9}$/],
  ID: [/^[A-Z]{2}\d{6}[A-Z]{1}$/, /^\d{9}$/],
  IL: [/^[A-Z]{1}\d{11,12}$/],
  IN: [/^\d{10}$/],
  IA: [/^\d{9}$/, /^\d{3}[A-Z]{2}\d{4}$/],
  KS: [/^K\d{8}$/],
  KY: [/^[A-Z]{1}\d{8}$/],
  LA: [/^0?\d{7,8}$/],
  ME: [/^\d{7}$/],
  MD: [/^[A-Z]{1}\d{12}$/, /^MD\d{11}$/],
  MA: [
    /^SA\d{7}$/, // Matches "SA" followed by 7 digits
    /^S\d{8}$/, // Matches "S" followed by 8 digits
    /^\d{9}$/, // Matches exactly 9 digits
  ],
  MI: [/^[A-Z]{1}\d{12}$/],
  MN: [/^[A-Z]{1}\d{12}$/],
  MS: [/^\d{9}$/],
  MO: [/^\d{9}$/, /^[A-Z]{1}\d{5,9}$/, /^\d{3}[A-Z]{1}\d{6}$/],
  MT: [/^\d{13}$/, /^(\d|[A-Z]){9}$/, /^[A-Z]{3}\d{10}$/],
  NE: [/^[A-Z]{1}\d{3,8}$/],
  NV: [/^\d{10}$/],
  NH: [/^\d{2}[A-Z]{3}\d{5}$/, /^NHL\d{8}$/],
  NJ: [/^[A-Z]{1}\d{14}$/],
  NM: [/^\d{9}$/],
  NY: [/^\d{9}$/, /^[A-Z]{1}\d{18}$/],
  NC: [/^\d{1,12}$/],
  ND: [/^(\d|[A-Z]){9}$/], // 9 Alpha-Numeric
  OH: [/^[A-Z]{2}\d{6}$/, /^\d{8}$/],
  OK: [/^[A-Z]{1}\d{9}$/, /^\d{9}$/],
  OR: [/^\d{5,7}$/, /^[A-Z]{1}\d{6}$/],
  PA: [/^\d{8}$/],
  PR: [/^\d{5,7}$/, /^\d{9}$/],
  RI: [/^\d{7,8}$/, /^V\d{6}$/],
  SC: [/^\d{3,10}$/],
  SD: [/^\d{6,9}$/],
  TN: [/^\d{7,9}$/],
  TX: [/^\d{7,8}$/],
  UT: [/^\d{4,10}$/],
  VT: [/^\d{8}$/, /^\d{7}A$/],
  VA: [/^[A-Z]{1}\d{8}$/, /^\d{9}$/],
  WA: [
    /^[A-Z*]{4,8}\d{2,3}[A-Z\d]{2}$/, // 4-8 Alpha + 2-3 Numeric + 2 Alpha-Numeric
    /^WDL[A-Z\d]{9}$/, // "WDL" + 9 Alpha-Numeric
  ],
  WV: [/^([A-Z]|\d){7}$/], // 7 Alpha-Numeric
  WI: [/^[A-Z]{1}\d{13}$/],
  WY: [/^\d{9}$/],
};

export default function isValidDriverLicense(
  state: USState,
  license: string
): boolean {
  const regexes = licenseRegexMap[state];
  return regexes.some((regex) => regex.test(license));
}
