import { LogoutOutlined } from "@mui/icons-material";
import { ButtonProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAuth } from "src/contexts/AuthContext";
import Button from "./Button";

type Props = ButtonProps;
const LogoutButton = ({ ...rest }: Props) => {
  const authCtx = useAuth();
  const { t } = useTranslation();
  return (
    <Button
      startIcon={<LogoutOutlined />}
      onClick={() => authCtx?.logout()}
      {...rest}
    >
      {t("Logout")}
    </Button>
  );
};

export default LogoutButton;
