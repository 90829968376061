import { useEffect, useState } from "react";
import { useAppContext } from "src/contexts/AppContext";
import checkVideoAskAnswersExists from "src/firebase/checkVideoAskAnswersExists";
import { VideoAskItem } from "src/pages/OnboardingScreen/videoAskIds.enum";
import { UserAccount } from "src/types/User";
import useErrorHandler from "src/utils/useErrorHandler";

export default function useVideoAskCompletion(
  user: UserAccount,
  videoAskItem: VideoAskItem
) {
  const { clients } = useAppContext();
  const errorHandler = useErrorHandler();
  const [loading, setLoading] = useState(true);
  const [completed, setCompleted] = useState<boolean>(false);

  useEffect(() => {
    async function checkCompletion() {
      try {
        const isCompleted = await checkVideoAskAnswersExists(
          user.uid,
          videoAskItem.formId,
          clients
        );
        setCompleted(isCompleted);
      } catch (err) {
        errorHandler(err);
      } finally {
        setLoading(false);
      }
    }

    checkCompletion();
  }, [clients, errorHandler, user.uid, videoAskItem.formId]);

  return { loading, completed };
}
