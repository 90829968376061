import { differenceInWeeks, parseISO } from "date-fns";
import { Clients } from "src/contexts/AppContext";
import fetchGrades from "src/firebase/fetchGrades";
import fetchResourceProgress from "src/firebase/fetchResourceProgress";
import { UserAccount } from "src/types/User";

export type TrainingStatus =
  | "late"
  | "on_time"
  | "ahead_of_schedule"
  | "completed";

// Function to calculate the student's progress status (late, on time, ahead of schedule)
function calculateTrainingStatus(
  startDate: string,

  completedModules: number
): TrainingStatus {
  const today = new Date();
  const weeksSinceStart = differenceInWeeks(today, parseISO(startDate));

  if (completedModules < weeksSinceStart) {
    return "late";
  }
  if (completedModules === weeksSinceStart) {
    return "on_time";
  }
  return "ahead_of_schedule";
}

export type CurrentTheoryTrainingModule = {
  currentModule: number;
  uncompletedElements: { lessons: string[]; exams: string[] };
  trainingStatus: TrainingStatus;
  overview: {
    totalVideosInModule: number;
    totalExamsInModule: number;
    completedVideosInModule: number;
    completedExamsInModule: number;
  };
} | null;

export default async function fetchCurrentTheoryTrainingModule(
  user: Pick<UserAccount, "uid" | "theoryTrainingPlan">,
  clients: Clients
): Promise<CurrentTheoryTrainingModule> {
  // Load video resource progress
  const { theoryTrainingPlan: studyPlan } = user;

  if (!studyPlan) return null;

  const videoProgress = await fetchResourceProgress(user.uid, clients);
  const completedVideos = videoProgress
    .filter((video) => video.hasCompleted)
    .map((video) => video.resourceId);

  // Load quiz grades
  const grades = await fetchGrades(user.uid, clients);
  const completedExams = grades.map((grade) => grade.quizId);

  // Iterate through each module to find the current module to focus on
  let currentModule = null;
  let completedModules = 0;
  let uncompletedElements: { lessons: string[]; exams: string[] } = {
    lessons: [],
    exams: [],
  };
  let overview: {
    totalVideosInModule: number;
    totalExamsInModule: number;
    completedVideosInModule: number;
    completedExamsInModule: number;
  } = {
    totalVideosInModule: 0,
    totalExamsInModule: 0,
    completedVideosInModule: 0,
    completedExamsInModule: 0,
  };

  for (let moduleNumber = 0; moduleNumber < studyPlan.totalWeeks; ) {
    const modulePlan = studyPlan.weeklyPlan[moduleNumber];

    // Dynamically calculate total activities (videos + exams)
    const totalVideosInModule = modulePlan.lessonIds.length;
    const totalExamsInModule = modulePlan.examIds.length;
    const moduleTotalActivities = totalVideosInModule + totalExamsInModule;

    // Calculate completed activities (completed videos + completed exams)
    const completedVideosInModule = modulePlan.lessonIds.filter((id) =>
      completedVideos.includes(id)
    ).length;
    const completedExamsInModule = modulePlan.examIds.filter((id) =>
      completedExams.includes(id)
    ).length;
    const moduleCompletedActivities =
      completedVideosInModule + completedExamsInModule;

    // Check if the module is completed
    const moduleIsCompleted =
      moduleCompletedActivities === moduleTotalActivities;

    if (!moduleIsCompleted) {
      // Found the current module the user should focus on
      currentModule = moduleNumber;
      uncompletedElements = {
        lessons: modulePlan.lessonIds.filter(
          (id) => !completedVideos.includes(id)
        ),
        exams: modulePlan.examIds.filter((id) => !completedExams.includes(id)),
      };

      overview = {
        totalVideosInModule,
        totalExamsInModule,
        completedVideosInModule,
        completedExamsInModule,
      };

      break; // Stop checking after finding the first uncompleted module
    }

    completedModules += 1;
    moduleNumber += 1;
  }

  // Calculate the training status (late, on time, ahead)
  const trainingStatus =
    currentModule === null
      ? "completed"
      : calculateTrainingStatus(studyPlan.startDate, completedModules);

  // If all modules are completed, the currentModule can be the last module
  if (currentModule === null) {
    currentModule = studyPlan.totalWeeks - 1; // Last module if everything is completed
  }

  return {
    currentModule,
    uncompletedElements,
    trainingStatus,
    overview,
  };
}
