import { useEffect, useMemo, useState } from "react";
import { useAppContext } from "src/contexts/AppContext";
import fetchAllOrganizations from "src/firebase/fetchAllOrganizations";
import { Organization } from "src/types/Organization";
import useErrorHandler from "./useErrorHandler";

export default function useOrganizations() {
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const errorHandler = useErrorHandler();
  const { clients } = useAppContext();

  useEffect(() => {
    async function loadData() {
      try {
        const response = await fetchAllOrganizations(clients);
        setOrganizations(response);
      } catch (error) {
        errorHandler(error);
      }
    }
    loadData();
  }, [clients, errorHandler]);

  // Memoize organizationsById for quick lookup by organizationId
  const organizationsById = useMemo(
    () =>
      organizations.reduce((acc, organization) => {
        acc[organization.uid] = organization;
        return acc;
      }, {} as Record<string, Organization>),
    [organizations]
  );

  return { organizationsById, organizations };
}
