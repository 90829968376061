import { doc, getDoc } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";
import { Admin } from "src/types/Admin";
import isAdminGuard from "src/utils/isAdminGuard";
import cleanFirestoreDoc from "./cleanFirestoreDoc";

export default async function fetchAdmin(
  adminId: string,
  { db }: Clients
): Promise<Admin> {
  const res = await getDoc(doc(db, "admins", adminId));
  const admin = cleanFirestoreDoc(res);

  if (!isAdminGuard(admin)) {
    throw new Error("Invalid admin");
  }

  return admin;
}
