import { collection, getCountFromServer } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";

export default async function fetchMilestonesCount(
  userId: string,
  { db }: Clients
): Promise<number> {
  const resourceCollection = collection(db, "users", userId, "plan");
  const snapshot = await getCountFromServer(resourceCollection);
  return snapshot.data().count;
}
