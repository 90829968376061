import { isValid, parseISO } from "date-fns";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function transformDateFields<T extends Record<string, any>>(
  data: T
): Partial<T> {
  const transformedData: Partial<T> = {};

  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === "string") {
      const parsedDate = parseISO(value);
      if (isValid(parsedDate)) {
        transformedData[key as keyof T] = parsedDate as unknown as T[keyof T];
      } else {
        transformedData[key as keyof T] = value as T[keyof T];
      }
    } else {
      transformedData[key as keyof T] = value as T[keyof T];
    }
  });

  return transformedData;
}
