import { MilestoneTask } from "src/types/MilestoneTask";

export default function isMilestoneTaskGuard(
  obj: unknown
): obj is MilestoneTask {
  if (!obj) return false;
  const milestone = obj as MilestoneTask;

  return (
    typeof milestone.uid === "string" &&
    typeof milestone.createdAt === "string" &&
    typeof milestone.lastUpdatedAt === "string" &&
    typeof milestone.title === "string" &&
    (typeof milestone.body === "string" || !milestone.body) &&
    typeof milestone.deadline === "string" &&
    (typeof milestone.completedAt === "string" || !milestone.completedAt) &&
    (typeof milestone.link === "string" || !milestone.link)
  );
}
