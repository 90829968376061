import { useTranslation } from "react-i18next";
import { PaddedLayout } from "src/components/Layout";
import { ScreenTitle } from "src/components/Typography";
import { useCurrentUser } from "src/SessionBoundary";
import ApplicationDocumentList from "../DashboardPage/DocumentsTab/ApplicationDocumentList";

export default function DocumentScreen() {
  const user = useCurrentUser();
  const { t } = useTranslation();

  return (
    <PaddedLayout>
      <ScreenTitle>{t("Upload Documents")}</ScreenTitle>
      <ApplicationDocumentList client={user} />
    </PaddedLayout>
  );
}
