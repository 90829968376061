import EmploymentStatus from "./EmploymentStatus";

function isEmployed(status: keyof typeof EmploymentStatus) {
  return (
    EmploymentStatus[status] === EmploymentStatus.full_time ||
    EmploymentStatus[status] === EmploymentStatus.part_time
  );
}

export default isEmployed;
