import { PhoneOutlined } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import CopyButton from "./CopyButton";

type Props = {
  phone: string;
};

export default function CopiablePhone({ phone }: Props) {
  return (
    <Stack direction="row" spacing={0.25} alignItems="center">
      <PhoneOutlined
        fontSize="small"
        sx={(theme) => ({ color: theme.palette.grey["500"] })}
      />
      <Typography variant="body2">{phone}</Typography>
      <CopyButton text={phone} />
    </Stack>
  );
}
