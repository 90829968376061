import { Create } from "@mui/icons-material";
import { Stack, Typography, useTheme } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SubmitButton from "src/components/Button/SubmitButton";
import DateInput from "src/components/Input/DateInput";
import TextInput from "src/components/Input/TextInput";
import { ScreenTitle } from "src/components/Typography";
import { MilestoneTask } from "src/types/MilestoneTask";
import { StudentPlanMilestone } from "src/types/StudentPlan";

type Props = {
  onSubmit: (data: MilestoneTaskFormData) => void;
  defaultValues?: Partial<Omit<StudentPlanMilestone, "date">> & { date: Date };
};

export type MilestoneTaskFormData = Pick<
  MilestoneTask,
  "title" | "body" | "link"
> & { deadline: Date };

export default function MilestoneTaskForm({ onSubmit, defaultValues }: Props) {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<MilestoneTaskFormData>({
    mode: "onTouched",
    defaultValues: defaultValues || {},
  });
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={theme.spacing(2)}>
        <Stack spacing={theme.spacing(1)}>
          <ScreenTitle>{t("Create a milestone action item")}</ScreenTitle>
          <Typography variant="body1">
            {t(
              "A milestone action item is an achievement that is relevant to the course."
            )}
          </Typography>
        </Stack>
        <TextInput
          control={control}
          name="title"
          label={t("Title")}
          rules={{
            required: { value: true, message: t("Title is required") },
          }}
        />
        <TextInput
          control={control}
          name="body"
          label={t("Description")}
          required={false}
        />
        <TextInput
          control={control}
          name="link"
          label={t("Link")}
          required={false}
        />
        <DateInput
          control={control}
          name="deadline"
          label={t("Deadline")}
          rules={{
            required: { value: true, message: t("Deadline is required") },
          }}
        />
        <SubmitButton
          loading={isSubmitting}
          size="large"
          startIcon={<Create />}
        >
          {t("Create")}
        </SubmitButton>
      </Stack>
    </form>
  );
}
