import { OrganizationSchool } from "src/types";

export default function isOrganizationSchoolGuard(
  obj: unknown
): obj is OrganizationSchool {
  const org = obj as OrganizationSchool;
  return (
    typeof org?.uid === "string" &&
    typeof org?.organizationId === "string" &&
    typeof org?.schoolId === "string"
  );
}
