import { Box, useTheme } from "@mui/material";
import React from "react";

type Props = {
  children: React.ReactNode;
  disablePadding?: boolean;
};

export default function AdminLayout({ children, disablePadding }: Props) {
  const theme = useTheme();
  return (
    <Box
      sx={[{ padding: theme.spacing(4) }, !!disablePadding && { paddingX: 0 }]}
    >
      {children}
    </Box>
  );
}
