import { useEffect, useState } from "react";
import { ReimbursementFormData } from "src/components/Forms/ReimbursementForm";
import { useAppContext } from "src/contexts/AppContext";
import createReimbursement from "src/firebase/createReimbursement";
import deleteReimbursement from "src/firebase/deleteReimbursement";
import fetchReimbursements from "src/firebase/fetchReimbursements";
import updateReimbursementCompletionStatus from "src/firebase/updateReimbursementCompletionStatus";
import { Reimbursement } from "src/types/Reimbursement";
import { UserAccount } from "src/types/User";
import replaceItem from "src/utils/replaceItem";
import useErrorHandler from "src/utils/useErrorHandler";

export default function useUserReimbursements(user: UserAccount) {
  const [reimbursements, setReimbursements] = useState<Reimbursement[]>();
  const [loading, setLoading] = useState(true);
  const { clients } = useAppContext();
  const errorHandler = useErrorHandler();

  // Fetch reimbursements on mount or when user ID changes
  useEffect(() => {
    setLoading(true);
    fetchReimbursements(user.uid, clients)
      .then((data) => setReimbursements(data))
      .catch(errorHandler)
      .finally(() => setLoading(false));
  }, [clients, user.uid, errorHandler]);

  // Create Reimbursement
  const createReimbursementEntry = async (data: ReimbursementFormData) => {
    try {
      const newReimbursement = await createReimbursement(
        {
          ...data,
          user,
        },
        clients
      );
      setReimbursements((prev) => [...(prev || []), newReimbursement]);
    } catch (error) {
      errorHandler(error);
    }
  };

  // Update Reimbursement Completion Status
  const updateCompletionStatus = async (
    reimbursement: Reimbursement,
    completed: boolean
  ) => {
    try {
      const updatedReimbursement = await updateReimbursementCompletionStatus(
        { reimbursement, completed },
        clients
      );
      setReimbursements((prev) =>
        prev
          ? replaceItem(
              prev,
              reimbursement,
              updatedReimbursement,
              (item) => item.uid === reimbursement.uid
            )
          : []
      );
    } catch (error) {
      errorHandler(error);
    }
  };

  // Delete Reimbursement
  const deleteReimbursementEntry = async (reimbursementId: string) => {
    try {
      await deleteReimbursement(
        { user, reimbursement: { uid: reimbursementId } as Reimbursement },
        clients
      );
      setReimbursements((prev) =>
        prev?.filter((r) => r.uid !== reimbursementId)
      );
    } catch (error) {
      errorHandler(error);
    }
  };

  // Memoize reimbursement counts and other derived data

  return {
    loading,
    reimbursements,
    createReimbursementEntry,
    updateCompletionStatus, // <-- New function for updating completion status
    deleteReimbursementEntry,
  };
}
