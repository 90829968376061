import { CohortAnnouncement } from "src/types/CohortAnnouncement";

export default function isCohortAnnouncementGuard(
  obj: unknown
): obj is CohortAnnouncement {
  const announcement = obj as CohortAnnouncement;
  if (!announcement) return false;
  return (
    typeof announcement.uid === "string" &&
    typeof announcement.body === "string" &&
    typeof announcement.expiresAt === "string" &&
    (typeof announcement.imageUrl === "string" ||
      typeof announcement.imageUrl === "undefined") &&
    typeof announcement.createdAt === "string" &&
    typeof announcement.authorId === "string"
  );
}
