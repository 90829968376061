import { collection, getDocs } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";
import isQuizGradeGuard from "src/utils/isQuizGradeGuard";
import cleanFirestoreDoc from "./cleanFirestoreDoc";
import { QuizGrade } from "./FirestoreClient";

export default async function fetchGrades(
  clientId: string,
  { db }: Clients
): Promise<QuizGrade[]> {
  const snapshot = await getDocs(collection(db, "users", clientId, "grades"));
  return snapshot.docs.map(cleanFirestoreDoc).filter(isQuizGradeGuard);
}
