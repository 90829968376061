import { useEffect, useState } from "react";
import { useAppContext } from "src/contexts/AppContext";
import fetchNewChatMessageCount from "src/firebase/fetchNewChatMessageCount";
import { useSessionAccountInformation } from "src/SessionBoundary";
import useErrorHandler from "src/utils/useErrorHandler";

export default function useCurrentUserUnreadMessages() {
  const account = useSessionAccountInformation();
  const { clients } = useAppContext();

  if (account.type !== "user") throw new Error("Account must be a user");

  const [unreadCount, setUnreadCount] = useState(0);
  const errorHandler = useErrorHandler();
  useEffect(() => {
    // Define a function to fetch new chat message count
    const fetchMessages = () => {
      fetchNewChatMessageCount(account.uid, account.uid, clients)
        .then(setUnreadCount)
        .catch(errorHandler);
    };

    // Immediately invoke the function upon mounting
    fetchMessages();

    // Set up an interval to run the function every minute (60000 milliseconds)
    const intervalId = setInterval(fetchMessages, 60000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [account, errorHandler, clients]);

  return unreadCount;
}
