import { doc, getDoc } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";

export default async function checkVideoAskAnswersExists(
  userId: string,
  formId: string,
  { db }: Clients
): Promise<boolean> {
  const videoAskRef = doc(db, "users", userId, "videoasks", formId);
  const videoAskDoc = await getDoc(videoAskRef);
  return (videoAskDoc.exists() && videoAskDoc.data().completed) || false;
}
