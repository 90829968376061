/* eslint-disable react/function-component-definition */
import {
  Checkbox as Base,
  CheckboxProps as BaseProps,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
} from "@mui/material";
import React, { ForwardedRef } from "react";
import withController from "../withController";

type ManagedProps = "checked";
export type CheckboxInputProps = Omit<BaseProps, ManagedProps> & {
  onChange: Required<BaseProps>["onChange"];
  value: Required<BaseProps>["checked"];
  label: FormControlLabelProps["label"];
  error: boolean;
  helperText: string;
};

function CheckboxInput(
  { label, error, helperText, value, ...rest }: CheckboxInputProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  return (
    <FormControlLabel
      disabled={rest.disabled}
      control={
        <Base {...rest} inputRef={ref} checked={value} color={rest.color} />
      }
      label={
        <>
          {label}
          <FormHelperText error={error}>{helperText}</FormHelperText>
        </>
      }
    />
  );
}

export const CheckboxInputBase = React.forwardRef(
  CheckboxInput
) as typeof CheckboxInput;

export default withController(CheckboxInputBase);
