import React, { useEffect, useState } from "react";
import { db } from "src/api/db";
import { BaseSkeleton } from "../Skeleton/closet";

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> & { alt: string; src: string };

// Cache-first image component
// It first checkes whether the given url path has been previously stored on IndexDB. Importnant for offline-first support
const Image = ({ alt, src, ...rest }: Props) => {
  const [source, setSource] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      try {
        const asset = await db.getAsset(src);
        setSource(asset ? URL.createObjectURL(asset.blob) : src);
      } catch (err) {
        setSource(src);
      }
      setIsLoading(false);
    })();
  }, [src]);

  if (!source || isLoading)
    return (
      <BaseSkeleton
        variant="rectangular"
        sx={{
          width: rest.style?.width || 150,
          height: rest.style?.height || 90,
          maxWidth: "100%",
          maxHeight: "100%",
        }}
      />
    );

  return (
    <img
      {...rest}
      alt={alt}
      src={source}
      style={{
        opacity: 0,
        transition: "opacity 0.5s ease-in-out",
        ...rest.style,
      }}
      onLoad={(e) => {
        e.currentTarget.style.opacity = "1";
      }}
    />
  );
};

export default Image;
