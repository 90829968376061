import { doc, Timestamp, updateDoc } from "firebase/firestore";
import Joi from "joi";
import { UserUploadedDocument } from "src/types/User";
import serviceFunction from "src/utils/serviceFunction";

type Data = {
  document: UserUploadedDocument;
  verified: boolean;
};

const schema = Joi.object<Data>({
  document: Joi.object().required(),
  verified: Joi.boolean().required(),
});

const updateDocumentVerification = serviceFunction(
  schema,
  async (
    { document, verified },
    { db }
  ): Promise<
    Pick<UserUploadedDocument, "verifiedAt" | "verifiedAtFirestoreTimestamp">
  > => {
    const now = new Date();
    const verifiedAt = verified ? now.toISOString() : null;
    const verifiedAtFirestoreTimestamp = verified
      ? Timestamp.fromDate(now)
      : null;

    await updateDoc(
      doc(db, `users/${document.userId}/documents/${document.uid}`),
      {
        verifiedAt,
        verifiedAtFirestoreTimestamp,
      }
    );

    return { verifiedAt, verifiedAtFirestoreTimestamp };
  }
);

export default updateDocumentVerification;
