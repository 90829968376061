import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconContainerProps,
  Modal,
  Rating,
  styled,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ModalContainer from "src/components/ModalContainer"; // Assuming you already have this reusable component
import pickRandom from "src/utils/pickRandom";

// Define the emoji ratings
const emojiLabels: Record<number, string> = {
  1: "😡", // Very dissatisfied
  2: "😕", // Dissatisfied
  3: "😐", // Neutral
  4: "🙂", // Satisfied
  5: "😄", // Very satisfied
};

const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    color: theme.palette.action.disabled,
  },
}));

export const sentimentLabels: { [index: string]: string } = {
  1: "Bad",
  2: "Poor",
  3: "Ok",
  4: "Good",
  5: "Excellent",
};

function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props;
  return <span {...other}>{emojiLabels[value]}</span>;
}

interface FeedbackModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (rating: number) => void;
}

const feedbackOptions: string[] = [
  t(
    "We care about your learning experience and would love to hear your feedback!"
  ),
  t("Your learning experience matters to us. Let us know how we’re doing!"),
  t(
    "We’re committed to improving your learning experience. Share your thoughts with us!"
  ),
  t("Your learning journey is important to us—tell us how it’s going!"),
  t("We’re here to support your learning. How was your experience?"),
  t(
    "Help us make your learning experience even better by sharing your feedback!"
  ),
  t("Your experience shapes how we grow—tell us what you think!"),
];

const feedbackCopy = pickRandom(feedbackOptions);
export default function LessonFeedbackModal({
  isOpen,
  onClose,
  onSubmit,
}: FeedbackModalProps) {
  const [rating, setRating] = useState<number | null>(null);
  const { t } = useTranslation();

  const handleSubmit = () => {
    if (rating !== null) {
      onSubmit(rating); // Pass the selected rating to the parent component
      onClose(); // Close the modal after submission
      setRating(null); // Reset rating after submission
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalContainer>
        <Card>
          <CardHeader
            title={t("How was this lesson?")}
            subheader={t("Please rate your experience")}
          />
          <CardContent>
            <Typography variant="body1" align="center" gutterBottom>
              {feedbackCopy}
            </Typography>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mb={2}
            >
              <StyledRating
                size="large"
                value={rating}
                onChange={(event, newValue) => setRating(newValue)}
                IconContainerComponent={IconContainer}
                highlightSelectedOnly
                getLabelText={(value) =>
                  emojiLabels[value] ? `Rating: ${value}` : ""
                }
                max={5} // Maximum rating is 5
              />
              {rating !== null && (
                <Box sx={{ ml: 2 }}>{sentimentLabels[rating]}</Box>
              )}
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Button
                onClick={handleSubmit}
                color="primary"
                variant="contained"
                disabled={rating === null} // Disable submit button until a rating is selected
              >
                {t("Submit")}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </ModalContainer>
    </Modal>
  );
}
