import { collection, getDocs, query, where } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";
import cleanFirestoreDoc from "src/firebase/cleanFirestoreDoc";
import { School } from "src/types/School";
import isOrganizationSchoolGuard from "src/utils/isOrganizationSchoolGuard";
import fetchSchools from "./fetchSchools";

export default async function fetchOrganizationSchools(
  organizationId: string,
  clients: Clients
): Promise<School[]> {
  const organizationSchools = (
    await getDocs(
      query(
        collection(clients.db, "organizationSchools"),
        where("organizationId", "==", organizationId)
      )
    )
  ).docs
    .map(cleanFirestoreDoc)
    .filter(isOrganizationSchoolGuard);

  const ids = organizationSchools.map((school) => school.schoolId);

  // Use the updated fetchSchools function with the filters object
  const schools = await fetchSchools(clients, { ids });
  return schools;
}
