import {
  Box,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Chip,
  Modal,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import SubmitButton from "src/components/Button/SubmitButton";
import ImageInput from "src/components/ImageInput";
import ModalContainer from "src/components/ModalContainer";
import { useAppContext } from "src/contexts/AppContext";
import createUserUploadedOrganizationDocument from "src/firebase/createUserUploadedOrganizationDocument";
import getOrganizationDocumentBlob from "src/firebase/getOrganizationDocumentBlob";
import updateDocumentVerification from "src/firebase/updateDocumentVerification";
import { useSessionAccountInformation } from "src/SessionBoundary";
import { useSnackbarContext } from "src/SnackbarProvider";
import { OrganizationDocument } from "src/types";
import { UserAccount, UserType, UserUploadedDocument } from "src/types/User";
import useErrorHandler from "src/utils/useErrorHandler";
import ID from "./OrgID.svg";

type Props = {
  organizationDocument: OrganizationDocument;
  submittedDocument?: UserUploadedDocument;
  user: UserAccount;
  children?: React.ReactNode;
};

type DocumentCardData = {
  uploadedDocument: File;
};

export default function OrganizationDocumentCard({
  organizationDocument,
  submittedDocument,
  user,
  children,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(!!submittedDocument?.verifiedAt);
  const [currentSubmittedDocument, setCurrentSubmittedDocument] = useState<
    UserUploadedDocument | undefined
  >(submittedDocument);
  const snackbarContext = useSnackbarContext();
  const account = useSessionAccountInformation();
  const { clients } = useAppContext();
  const errorHandler = useErrorHandler();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
    watch,
  } = useForm<DocumentCardData>();

  const handleOpen = async () => {
    setLoading(true);
    if (currentSubmittedDocument) {
      snackbarContext.alert(
        "info",
        t("Fetching the document from our secure storage.")
      );

      if (currentSubmittedDocument.organizationDocumentId) {
        const file = await getOrganizationDocumentBlob(
          {
            organizationDocumentId:
              currentSubmittedDocument.organizationDocumentId,
            userId: currentSubmittedDocument.userId,
          },
          clients
        );
        if (file) setValue("uploadedDocument", file);
      }
    }
    setLoading(false);
    setIsOpen(true);
  };

  const handleVerification = () => {
    if (account.type !== UserType.Admin)
      throw new Error("Only admins can verify");

    if (!currentSubmittedDocument)
      throw new Error("Must have submitted the document to verify it");

    updateDocumentVerification(
      { verified: !isVerified, document: currentSubmittedDocument },
      clients
    )
      .then(() => setIsVerified(!isVerified))
      .catch(errorHandler);
  };

  const file = watch("uploadedDocument");

  const handleSubmission = async (file: File) => {
    try {
      const newDocument = await createUserUploadedOrganizationDocument(
        {
          user,
          organizationDocument,
          file,
        },
        clients
      );
      setCurrentSubmittedDocument(newDocument);
      snackbarContext.alert("success", t("Document uploaded successfully."));
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <div>
      <Card variant="outlined">
        <Stack spacing={theme.spacing(1)}>
          <CardContent>
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Typography variant="body2" color="text.secondary">
                {currentSubmittedDocument ? (
                  <Chip
                    label={t("Submitted")}
                    variant="outlined"
                    size="small"
                    color="success"
                  />
                ) : (
                  <>
                    {organizationDocument.optional ? (
                      <Chip
                        label={t("Optional")}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      <Chip
                        label={t("Required")}
                        variant="outlined"
                        size="small"
                        color="warning"
                      />
                    )}

                    <Typography variant="body2" color="text.secondary">
                      {t("Not submitted")}
                    </Typography>
                  </>
                )}
              </Typography>
            </Stack>

            <Typography fontWeight="bold" mt={theme.spacing(1)}>
              {organizationDocument.name}
            </Typography>

            <Typography variant="body2" color="text.secondary">
              {t("Requested by the funding agency")}
            </Typography>
            {account.type !== UserType.User && (
              <Typography variant="body2" color="text.secondary">
                {isVerified ? t("Verified 🎉") : t("Not Verified")}
              </Typography>
            )}

            <Box marginTop={theme.spacing(2)}>{children}</Box>
          </CardContent>
          <CardActions>
            {currentSubmittedDocument ? (
              <Button
                variant="outlined"
                onClick={handleOpen}
                disabled={loading}
              >
                {t("View")}
              </Button>
            ) : (
              <Button variant="contained" onClick={handleOpen}>
                {t("Upload")}
              </Button>
            )}
          </CardActions>
        </Stack>
      </Card>

      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <div>
          <ModalContainer>
            <Card>
              <CardContent>
                <Box mb={theme.spacing(2)}>
                  <Typography variant="body2" color="text.secondary">
                    {organizationDocument.description}
                  </Typography>
                </Box>
                <form
                  onSubmit={handleSubmit(async (data) => {
                    await handleSubmission(data.uploadedDocument);
                    setIsOpen(false);
                  })}
                >
                  <ImageInput
                    label={organizationDocument.name}
                    name="uploadedDocument"
                    control={control}
                    rules={{ validate: (v) => !!v }}
                    placeholderImage={ID}
                    placeholderImageWidth={176}
                  />

                  <Stack
                    direction="row"
                    sx={{ marginTop: 4 }}
                    alignItems="center"
                  >
                    {!currentSubmittedDocument ? (
                      <SubmitButton
                        size="large"
                        disabled={isSubmitting || !file}
                        loading={isSubmitting}
                        sx={{ marginRight: 4 }}
                      >
                        {t("Upload")}
                      </SubmitButton>
                    ) : currentSubmittedDocument.path !== file?.name ? (
                      <SubmitButton
                        size="large"
                        disabled={isSubmitting}
                        variant="outlined"
                        loading={isSubmitting}
                        sx={{ marginRight: 4 }}
                      >
                        {t("Re-upload")}
                      </SubmitButton>
                    ) : (
                      <div />
                    )}
                  </Stack>
                </form>
              </CardContent>
              <CardActions>
                {file && (
                  <a download={file.name} href={URL.createObjectURL(file)}>
                    {t("Download")}
                  </a>
                )}
                {file && account.type === UserType.Admin && (
                  <Stack direction="row" alignItems="center">
                    <Checkbox
                      onClick={handleVerification}
                      color="success"
                      size="small"
                      checked={isVerified}
                    />
                    <Typography variant="body2" color="text.secondary">
                      {isVerified ? t("Verified 🎉") : t("Not Verified")}
                    </Typography>
                  </Stack>
                )}
              </CardActions>
            </Card>
          </ModalContainer>
        </div>
      </Modal>
    </div>
  );
}
