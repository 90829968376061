import { format } from "date-fns";

export default function formatOptionalDateString(
  type: "date" | "time" | "datetime" | "dateyear",
  optionalDate?: string
): string {
  if (!optionalDate || typeof optionalDate !== "string") return "";
  const date = new Date(optionalDate);
  if (type === "date") return format(date, "MM/dd/yy");
  if (type === "time") return format(date, "HH:mm");
  if (type === "datetime") return format(date, "MM/dd/yy HH:mm");
  if (type === "dateyear") return format(date, "yyyy-MM-dd");
  return "";
}
