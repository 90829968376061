import {
  FormHelperText,
  FormLabel,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps as BaseProps,
} from "@mui/material";
import React, { ForwardedRef } from "react";
import { useTranslation } from "react-i18next";
import withController from "../withController"; // Assuming you have a similar HOC for form control

type YesNoToggleInputProps = Omit<BaseProps, "onChange"> & {
  prompt?: string;
  value: boolean;
  onChange: (value: boolean) => void;
  error?: boolean;
  helperText?: string;
};

const YesNoToggleInput = (
  props: YesNoToggleInputProps,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const { value, onChange, prompt, error, helperText, ...rest } = props;
  const { t } = useTranslation();

  const handleChange = (_: React.MouseEvent<HTMLElement>, newValue: string) => {
    onChange(newValue === "yes");
  };

  return (
    <Grid container alignItems={{ xs: "flex-start", sm: "center" }} ref={ref}>
      {/* Conditionally render the label only if prompt exists */}
      {prompt && (
        <Grid item xs={12} sm={6}>
          <FormLabel error={error}>{prompt}</FormLabel>
        </Grid>
      )}

      <Grid item xs={12} sm={6}>
        <ToggleButtonGroup
          {...rest}
          color="primary"
          value={
            typeof value === "undefined" ? undefined : value ? "yes" : "no"
          }
          exclusive
          onChange={handleChange}
          aria-label="Yes or No"
        >
          <ToggleButton value="yes">{t("Yes")}</ToggleButton>
          <ToggleButton value="no">{t("No")}</ToggleButton>
        </ToggleButtonGroup>
        {helperText && (
          <FormHelperText error={error}>{helperText}</FormHelperText>
        )}
      </Grid>
    </Grid>
  );
};

export const YesNoToggleInputBase = React.forwardRef(
  YesNoToggleInput
) as typeof YesNoToggleInput;

export default withController(YesNoToggleInputBase);
