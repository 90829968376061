import { Box, Fade } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Avatar from "src/components/Avatar";
import { useAppContext } from "src/contexts/AppContext";
import { useCurrentUser } from "src/SessionBoundary";
import { Admin } from "src/types/Admin";
import useErrorHandler from "src/utils/useErrorHandler";
import AppSkeleton from "../AppSkeleton";
import SuccessScreen from "../SuccessScreen";

export default function AutomatedConditionalAcceptanceScreen() {
  const user = useCurrentUser();
  const navigate = useNavigate();
  const { clients } = useAppContext();
  const { t } = useTranslation();
  const updateApplicationStatusToConditionalAcceptance = httpsCallable<
    void,
    {
      coach: Pick<Admin, "firstName" | "lastName" | "photoUrl">;
    }
  >(clients.functions, "updateApplicationStatusToConditionalAcceptance");
  const [loading, setLoading] = useState(false);
  const errorHandler = useErrorHandler();
  const [assignedCoach, setAssignedCoach] =
    useState<Pick<Admin, "firstName" | "lastName" | "photoUrl">>();

  useEffect(() => {
    if (loading) return;
    if (!user.applicationStatus) {
      setLoading(true);
      updateApplicationStatusToConditionalAcceptance()
        .then((res) => setAssignedCoach(res.data.coach))
        .catch(errorHandler)
        .finally(() => setLoading(false));
    }
  }, [
    user,
    navigate,
    updateApplicationStatusToConditionalAcceptance,
    errorHandler,
    loading,
  ]);

  if (!assignedCoach || loading) return <AppSkeleton />;

  return (
    <SuccessScreen
      illustration={{
        type: "react",
        component: (
          <Fade in timeout={1000}>
            <Box>
              <Avatar
                sx={{ height: 64, width: 64 }}
                src={assignedCoach.photoUrl}
              />
            </Box>
          </Fade>
        ),
      }}
      title={t("Introducing your Emerge success coach!")}
      content={
        <Fade in timeout={1500}>
          <Box>
            <Trans
              i18nKey="<strong>{{ coach_name }}</strong> will be your biggest ally for the rest of this journey! They will give you a text soon."
              values={{ coach_name: assignedCoach.firstName }}
              components={{ strong: <strong /> }}
            />
          </Box>
        </Fade>
      }
      okButtonText={t(`Let's Do This!`)}
    />
  );
}
