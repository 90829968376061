import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Grid, InputAdornment, Stack, useTheme } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SubmitButton from "src/components/Button/SubmitButton";
import SelectInput from "src/components/Card/SelectInput";
import NumberInput from "src/components/Input/NumberInput";
import YesNoToggleInput from "src/components/Input/YesNoToggleInput";
import EmploymentStatus from "src/utils/EmploymentStatus";
import isBoolean from "src/utils/isBoolean";
import isEmployed from "src/utils/isEmployed";
import LABEL_MAP from "../ClientScreen/BackgroundTab/OnboardingSurveyLabels";

export type ProfessionalBackgroundData = {
  employmentStatus?: keyof typeof EmploymentStatus;
  salary?: number;
  hasBeenTerminated: boolean | null;
};

type Props = {
  defaultValues: Partial<ProfessionalBackgroundData>;
  onSubmit: (data: ProfessionalBackgroundData) => void | Promise<void>;
  onBack: () => void;
};

export default function ProfessionalBackgroundForm({
  defaultValues,
  onSubmit,
  onBack,
}: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<ProfessionalBackgroundData>({
    mode: "onTouched",
    defaultValues,
  });

  const employmentStatus = watch("employmentStatus");

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <Stack spacing={theme.spacing(2)} marginBottom={6}>
        <SelectInput
          name="employmentStatus"
          prompt={t(LABEL_MAP.employmentStatus)}
          control={control}
          items={Object.entries(EmploymentStatus).map((entry) => ({
            value: entry[0],
            name: entry[1],
          }))}
          rules={{
            required: {
              value: true,
              message: t("Employment status is required"),
            },
          }}
        />
        {employmentStatus && isEmployed(employmentStatus) && (
          <NumberInput
            control={control}
            name="salary"
            prompt={LABEL_MAP.salary}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            rules={{
              required: !!employmentStatus,
              validate: (value) => !!value && value > 0 && value < 1e6,
            }}
          />
        )}
        <YesNoToggleInput
          name="hasBeenTerminated"
          prompt={LABEL_MAP.hasBeenTerminated}
          control={control}
          rules={{
            validate: (value) =>
              isBoolean(value) || t("This field is required"),
          }}
        />
      </Stack>
      <Grid container justifyContent="space-between">
        <Button onClick={onBack}>
          <ArrowBackIcon />
          &nbsp;
          {t("Back")}
        </Button>
        <SubmitButton loading={isSubmitting}>{t("Next")}</SubmitButton>
      </Grid>
    </form>
  );
}
