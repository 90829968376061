import { Chip, ChipProps, Typography } from "@mui/material";
import { blue, green, grey, orange, red } from "@mui/material/colors";
import { EnrollmentStatus } from "src/types/UserStatus";

type Props = {
  status?: keyof typeof EnrollmentStatus;
} & ChipProps;

function statusToColor(status: keyof typeof EnrollmentStatus) {
  switch (status) {
    case "unsuccessful_exit":
    case "justifiable_exit":
      return red;
    case "theory_training":
    case "completed_theory_training":
      return orange;

    case "skills_training":
    case "completed_skills_training":
      return blue;

    case "licensed":
      return green;
    default:
      return grey;
  }
}

export default function EnrollmentStatusChip({ status, ...rest }: Props) {
  if (!status) return <Typography>-</Typography>;
  return (
    <Chip
      sx={{
        color: statusToColor(status)[900],
        borderColor: statusToColor(status)[100],
        backgroundColor: statusToColor(status)[100],
      }}
      label={EnrollmentStatus[status]}
      {...rest}
    />
  );
}
