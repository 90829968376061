import { addBusinessDays, addWeeks, nextMonday } from "date-fns";
import { UserAccount } from "src/types/User";

// Base MilestoneDates interface
export interface MilestoneDates {
  theoryEndDate: Date;
  medicalExamDate: Date;
  theoryExamDate: Date;
  skillsTrainingStartDate: Date;
  skillsTrainingEndDate?: Date;
  licenseExamDate?: Date;
}

// Return types for the utility
export type IncompleteMilestoneDates = Partial<MilestoneDates> & {
  allDatesPresent: false;
};
export type CompleteMilestoneDates = Required<MilestoneDates> & {
  allDatesPresent: true;
};

// Union of both types
export type MilestoneDatesResult =
  | IncompleteMilestoneDates
  | CompleteMilestoneDates;

export default function calculateMilestoneDates(
  user: UserAccount
): MilestoneDatesResult {
  if (!user.theoryTrainingPlan) {
    return {
      allDatesPresent: false, // No theory plan, return incomplete type
    };
  }

  const theoryEndDate = addWeeks(
    new Date(user.theoryTrainingPlan.startDate),
    user.theoryTrainingPlan.totalWeeks
  );

  const medicalExamDate = addBusinessDays(theoryEndDate, 3);
  const theoryExamDate = addBusinessDays(theoryEndDate, 5);
  const skillsTrainingStartDate = nextMonday(
    addBusinessDays(theoryExamDate, 5)
  );

  if (!user.skillsTrainingPlan) {
    return {
      theoryEndDate,
      medicalExamDate,
      theoryExamDate,
      skillsTrainingStartDate,
      allDatesPresent: false, // Missing skills training plan, return incomplete type
    };
  }

  const skillsTrainingEndDate = addWeeks(
    skillsTrainingStartDate,
    user.skillsTrainingPlan.projectedDurationWeeks
  );

  const licenseExamDate = addBusinessDays(skillsTrainingEndDate, 5);

  return {
    theoryEndDate,
    medicalExamDate,
    theoryExamDate,
    skillsTrainingStartDate,
    skillsTrainingEndDate,
    licenseExamDate,
    allDatesPresent: true, // All dates present, return complete type
  };
}
