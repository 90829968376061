import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { VideoAskItem } from "src/pages/OnboardingScreen/videoAskIds.enum";
import SliderExplanationScreen from "src/pages/SliderExplanationScreen";
import { getFullName } from "src/utils";

type Props = {
  videoAsk: VideoAskItem;
  onFinish: ({ contactId }: { contactId: string }) => void;
  width?: string;
  height?: string;
  allow?: string;
  onLoadModal?: () => void;
  onCloseModal?: () => void;
  onCloseWidget?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onMessage?: (message: any) => void;
  zIndex?: number;
  contactInfo?: {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    city?: string;
  };
};

export default function VideoAskIFrame({
  videoAsk,
  onFinish,
  width = "100%",
  height = "100%",
  allow = "camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;",
  onLoadModal,
  onCloseModal,
  onCloseWidget,
  onMessage,
  zIndex,
  contactInfo,
}: Props) {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.videoask.com/embed/embed.js";
    script.async = true;
    script.onload = () => setScriptLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const messageListener = (event: MessageEvent) => {
      if (
        event.origin === "https://www.videoask.com" &&
        event.data &&
        event.data.type &&
        event.data.type.startsWith("videoask_")
      ) {
        if (onMessage) onMessage(event.data);

        if (event.data.type === "videoask_submitted") {
          if (onFinish) onFinish(event.data);
        }
      }
    };

    window.addEventListener("message", messageListener);

    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, [onMessage, onFinish]);

  useEffect(() => {
    if (scriptLoaded) {
      const config = {
        url: `https://www.videoask.com/${videoAsk.shareId}`,
        widgetType: "inline",
        widgetStyle: {
          width,
          height,
          border: "none",
          ...(zIndex ? { zIndex } : {}),
        },
      };

      const callbacks = {
        onLoadModal,
        onCloseModal,
        onCloseWidget,
      };

      // Load the VideoAsk widget
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).videoask.loadEmbed(config, callbacks);
    }
  }, [
    scriptLoaded,
    videoAsk.shareId,
    width,
    height,
    onLoadModal,
    onCloseModal,
    onCloseWidget,
    zIndex,
  ]);

  const buildVideoAskUrl = (id: string, contactInfo?: Props["contactInfo"]) => {
    let url = `https://www.videoask.com/${id}`;
    if (contactInfo) {
      const params = new URLSearchParams();
      if (contactInfo.firstName && contactInfo.lastName)
        params.append(
          "contact_name",
          getFullName({
            firstName: contactInfo.firstName,
            lastName: contactInfo.lastName,
          })
        );
      if (contactInfo.lastName) params.append("lastName", contactInfo.lastName);
      if (contactInfo.email) params.append("contact_email", contactInfo.email);
      if (contactInfo.phone) params.append("contact_phone", contactInfo.phone);
      if (contactInfo.city) params.append("contact_city", contactInfo.city);
      url += `?${params.toString()}`;
    }
    return url;
  };

  const iframeUrl = buildVideoAskUrl(videoAsk.shareId, contactInfo);

  return (
    <Box width={width} height={height}>
      {videoAsk.sliderSteps && (
        <Box sx={{ zIndex: 9999 }}>
          <SliderExplanationScreen
            steps={videoAsk.sliderSteps}
            finishButtonProps={{
              label: t("Let's do this!"),
            }}
          />
        </Box>
      )}
      <iframe
        title={`Video Ask ${videoAsk.label}`}
        src={iframeUrl}
        allow={allow}
        width={width}
        height={height}
        style={{ border: "none", ...(zIndex ? { zIndex } : {}) }}
      />
    </Box>
  );
}
