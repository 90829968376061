import { CheckCircleOutlined, ErrorOutlined } from "@mui/icons-material";
import { Card, CardContent, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { db } from "src/api/db";
import Button from "src/components/Button";

export type DownloadStatus =
  | "not_started"
  | "downloading"
  | "success"
  | "error";
type Props = {
  url: string;
  downloadStatus: DownloadStatus;
  onRetry: () => void;
};

// status on object storage
type ArchiveStatus = "pending" | "downloaded" | "none";

const OfflineSetupItemCardStatus = ({
  downloadStatus,
  archiveStatus,
}: {
  downloadStatus: DownloadStatus;
  archiveStatus: ArchiveStatus;
}) => {
  if (archiveStatus === "downloaded" || downloadStatus === "success") {
    return (
      <p className="text-sm text-gray-500">
        Downloaded <CheckCircleOutlined color="success" />
      </p>
    );
  }

  if (archiveStatus === "pending" || downloadStatus === "downloading") {
    return (
      <div className="flex items-center gap-2">
        <CircularProgress size={24} />
        <p className="text-sm text-gray-500">
          {archiveStatus === "pending"
            ? "Checking local storage..."
            : "Downloading"}
        </p>
      </div>
    );
  }

  if (downloadStatus === "error") {
    return (
      <p className="text-sm text-gray-500">
        Error <ErrorOutlined color="error" />
      </p>
    );
  }

  return <p className="text-sm text-gray-500">Not started</p>;
};
const OfflineSetupItemCard = ({ url, downloadStatus, onRetry }: Props) => {
  const [archiveStatus, setArchiveStatus] = useState<
    "pending" | "downloaded" | "none"
  >("pending");

  useEffect(() => {
    (async () => {
      const asset = await db.getAsset(url);
      setArchiveStatus(asset ? "downloaded" : "none");
    })();
  }, [url]);

  return (
    <Card>
      <CardContent>
        <div className="flex flex-col gap-4">
          <p className="text-sm">{url}</p>
          <OfflineSetupItemCardStatus
            archiveStatus={archiveStatus}
            downloadStatus={downloadStatus}
          />
          {downloadStatus === "error" ? (
            <Button variant="outlined" onClick={onRetry}>
              Retry
            </Button>
          ) : archiveStatus === "none" && downloadStatus !== "downloading" ? (
            <Button variant="outlined" onClick={onRetry}>
              Download
            </Button>
          ) : (
            <div />
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default OfflineSetupItemCard;
