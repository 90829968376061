import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import VideoAskIFrame from "src/components/VideoAskIFrame";
import { useAppContext } from "src/contexts/AppContext";
import upsertVideoAskCompletion from "src/firebase/upsertVideoAskCompletion";
import { useCurrentUser } from "src/SessionBoundary";
import { useSnackbarContext } from "src/SnackbarProvider";
import useErrorHandler from "src/utils/useErrorHandler";
import VideoAskSchema from "./videoAskIds.enum";

export default function InterviewScreen() {
  const { id } = useParams<{ id: string }>();
  const user = useCurrentUser();
  const navigate = useNavigate();
  const { clients } = useAppContext();
  const snackbarContext = useSnackbarContext();
  const errorHandler = useErrorHandler();
  const { t } = useTranslation();

  // Find the video ask item by ID
  if (!id) throw new Error("You must specify the video ask ID");

  const videoAskItem = Object.values(VideoAskSchema).find(
    (value) => value.formId === id
  );

  if (!videoAskItem) {
    throw new Error(`Video ask item with ID ${id} not found`);
  }

  const handleFinish = async () => {
    await upsertVideoAskCompletion({ user, videoAsk: videoAskItem }, clients)
      .then(() =>
        snackbarContext.alert("success", t("You completed the section!"))
      )
      .catch(errorHandler)
      .finally(() => navigate("/"));
  };

  return (
    <Box width="100vw" height="100vh">
      <VideoAskIFrame
        videoAsk={videoAskItem}
        onFinish={handleFinish}
        contactInfo={user}
      />
    </Box>
  );
}
