import { Stack, useTheme } from "@mui/material";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import SubmitButton from "src/components/Button/SubmitButton";
import ImageInput from "src/components/ImageInput";
import DateInput from "src/components/Input/DateInput";
import NumberInput from "src/components/Input/NumberInput";
import Selfie from "./Selfie.svg";

type Props = {
  handleSubmission: (data: AttendanceFormData) => void;
};

export type AttendanceFormData = {
  photo: File;
  date: Date;
  duration: number;
};

function RangeAttendanceForm({ handleSubmission }: Props) {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<AttendanceFormData>({ defaultValues: { date: new Date() } });

  const theme = useTheme();

  return (
    <form onSubmit={handleSubmit((data) => handleSubmission(data))}>
      <Stack spacing={theme.spacing(1)}>
        <DateInput
          control={control}
          name="date"
          label={t("Date of Attendance")}
          disableFuture
          rules={{
            required: {
              value: true,
              message: t("Field is required"),
            },
          }}
        />
        <NumberInput
          control={control}
          name="duration"
          label={t("Duration of Attendance (in hours)")}
          rules={{
            required: {
              value: true,
              message: "Field is required",
            },
            min: 0,
          }}
        />
        <ImageInput
          label={t("Selfie at the school")}
          name="photo"
          control={control}
          rules={{ validate: (v) => !!v }}
          placeholderImage={Selfie}
          placeholderImageWidth={176}
        />

        <SubmitButton
          size="large"
          disabled={isSubmitting}
          loading={isSubmitting}
          sx={{ marginRight: 4 }}
        >
          {t("Upload")}
        </SubmitButton>
      </Stack>
    </form>
  );
}

export default RangeAttendanceForm;
