import { collection, getDocs } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";
import { Admin } from "src/types/Admin";
import isAdminGuard from "src/utils/isAdminGuard";
import cleanFirestoreDoc from "./cleanFirestoreDoc";

export default async function fetchAdmins({ db }: Clients): Promise<Admin[]> {
  const snapshot = await getDocs(collection(db, "admins"));
  const documents = snapshot.docs.map(cleanFirestoreDoc).filter(isAdminGuard);
  return documents;
}
