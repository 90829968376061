enum ReferralSource {
  FriendsFamily = "Friends and Family",
  WordOfMouth = "Word of Mouth",
  SocialMedia = "Social Media",
  InternetSearch = "Internet Search",
  SupervisionOfficer = "Supervision Officer",
  CommunityPartner = "Community Partner",
  WorkforceBoard = "Workforce Board",
  CorrectionalFacility = "Correctional Facility",
  Other = "Other",
}

export default ReferralSource;
