import { QuizGrade } from "src/firebase/FirestoreClient";

export default function isQuizGradeGuard(obj: unknown): obj is QuizGrade {
  const quizGrade = obj as QuizGrade;
  return (
    typeof quizGrade?.uid === "string" &&
    typeof quizGrade?.quizId === "string" &&
    typeof quizGrade?.grade === "number" &&
    typeof quizGrade?.totalQuestions === "number" &&
    typeof quizGrade?.totalCorrectAnswers === "number"
  );
}
