import {
  Avatar,
  Box,
  Grid,
  Stack,
  SxProps,
  Theme,
  Typography,
  useMediaQuery as measureScreenWidth,
} from "@mui/material";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PaddedLayout } from "src/components/Layout";
import { belowLargeTablet } from "src/components/responsiveHelpers";
import { Organization } from "src/types";
import addStylesForDevices from "src/utils/addStylesForDevices";

type Props = {
  corner?: React.ReactNode;
  children: React.ReactNode;
  organization?: Organization;
};

const cornerBaseStyles: SxProps<Theme> = {
  position: "absolute",
  top: (theme: Theme) => theme.spacing(4),
  display: "flex",
};

export default function RegisterLayout({
  corner,
  organization,
  children,
}: Props) {
  const { t } = useTranslation();
  const isBelowLargeTablet = belowLargeTablet(measureScreenWidth);
  const gridContainerStyles = addStylesForDevices(
    isBelowLargeTablet,
    { background: "linear-gradient(37deg, #002D5A 28.63%, #134D89 106.9%)" },
    { display: "none" }
  );
  const navigate = useNavigate();

  return (
    <Grid container sx={{ height: 1 }}>
      {corner && !isBelowLargeTablet && (
        <Grid
          container
          item
          md={4}
          sx={gridContainerStyles}
          minHeight={{ md: 766, xl: 700 }}
          maxHeight={1}
          overflow="hidden"
        >
          <Stack justifyContent="center">
            <Box
              sx={{
                ...cornerBaseStyles,
                left: (theme: Theme) => theme.spacing(2),
                cursor: "pointer",
              }}
              onClick={() => navigate("/")}
            >
              {corner}
            </Box>
            <PaddedLayout>
              <Typography variant="h5" color="white" marginBottom={2}>
                {t("Start your journey with us")}
              </Typography>
              {organization ? (
                <Stack direction="row" spacing={2}>
                  <Avatar
                    src={organization.logoImageUrl}
                    alt="Organization logo"
                    sx={{
                      height: 60,
                      width: 60,
                    }}
                  />

                  <Typography color="white">
                    <Trans
                      t={t}
                      defaults="We are proud to partner with <bold>{{ organization }}</bold> to offer opportunities for people looking for a new beginning"
                      values={{ organization: organization.name }}
                      components={{ bold: <b /> }}
                    />
                  </Typography>
                </Stack>
              ) : (
                <Typography color="white">
                  {t(
                    "We are the job training program for individuals looking for a new beginning"
                  )}
                </Typography>
              )}
            </PaddedLayout>
          </Stack>
        </Grid>
      )}

      <Grid item xs={11} sm={10} md={6} sx={{ marginX: "auto" }}>
        {corner && isBelowLargeTablet && (
          <Grid item xs={11} sx={cornerBaseStyles}>
            {corner}
          </Grid>
        )}
        <Grid sx={{ marginTop: { xs: 8, sm: 10 }, paddingY: 10 }}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
}
