import { doc, getDoc } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";
import { School } from "src/types/School";
import isSchoolGuard from "src/utils/isSchoolGuard";
import cleanFirestoreDoc from "./cleanFirestoreDoc";

export default async function fetchSchool(
  schoolId: string,
  { db }: Clients
): Promise<School> {
  const school = cleanFirestoreDoc(await getDoc(doc(db, "schools", schoolId)));
  if (!isSchoolGuard(school)) throw new Error("Cannot locate school record");
  return school;
}
