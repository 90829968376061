import { Box, Typography, useTheme } from "@mui/material";

interface Props {
  isOnline: boolean;
}

const OnlineCard = ({ isOnline }: Props) => {
  const theme = useTheme();
  if (isOnline) return <div />;
  return (
    <Box
      display="flex"
      alignContent="center"
      paddingY={theme.spacing(2)}
      paddingX={theme.spacing(4)}
      sx={{ borderRadius: 1000 }}
    >
      <Box
        sx={{
          height: 2,
          width: 2,
          backgroundColor: isOnline
            ? theme.palette.success.main
            : theme.palette.grey[500],
          rounded: 1000,
          display: "inline-flex",
          position: "absolute",
        }}
      />
      <Typography ml={theme.spacing(4)}>
        {isOnline ? "Online" : "Offline"}
      </Typography>
    </Box>
  );
};

export default OnlineCard;
