import { Box, Stack } from "@mui/material";
import { ReactElement, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import FullscreenBox from "src/components/FullScreenBox";
import ResetScroll from "src/components/ResetScroll";
import { Header32 } from "src/components/Typography";

type SuccessScreenButton = {
  text: string;
  onClick: () => void;
};

type IllustrationImage = { src: string; width: number; type: "image" };

function isIllustrationImage(
  illustration: Props["illustration"]
): illustration is IllustrationImage {
  return (illustration as IllustrationImage).type === "image";
}

type Props = {
  title: string;
  content: ReactNode;
  showBackButton?: boolean;
  onBackButtonClick?: () => void;
  backButtonText?: string;
  onOkButtonClick?: () => void;
  okButtonText?: string;
  illustration: IllustrationImage | { type: "react"; component: ReactElement };
  secondaryButton?: SuccessScreenButton;
};

export default function SuccessScreen({
  title,
  content,
  showBackButton,
  onBackButtonClick,
  backButtonText,
  onOkButtonClick,
  okButtonText,
  illustration,
  secondaryButton,
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <FullscreenBox
      sx={{
        background:
          "linear-gradient(339deg, #F7F1EA 36.57%, #FDFCFA 72.98%, #FBFBFB 86.11%)",
      }}
    >
      <ResetScroll />
      <Stack
        spacing={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          px: 2,
          marginTop: "40px",
        }}
      >
        {isIllustrationImage(illustration) ? (
          <img src={illustration.src} alt="" width={illustration.width} />
        ) : (
          illustration.component
        )}
        <Stack spacing={2} alignItems="center">
          <Header32 style={{ textAlign: "center" }}>{title}</Header32>
          <Box sx={{ textAlign: "center", marginTop: 2, maxWidth: 600 }}>
            {content}
          </Box>
        </Stack>
        <Stack spacing={2}>
          <Button
            size="large"
            variant="contained"
            fullWidth
            onClick={() =>
              onOkButtonClick ? onOkButtonClick() : navigate("/")
            }
          >
            {okButtonText || t("Return Home")}
          </Button>
          {showBackButton && (
            <Button fullWidth onClick={onBackButtonClick}>
              {backButtonText}
            </Button>
          )}
          {secondaryButton && (
            <Button
              variant="outlined"
              fullWidth
              onClick={secondaryButton.onClick}
            >
              {secondaryButton.text}
            </Button>
          )}
        </Stack>
      </Stack>
    </FullscreenBox>
  );
}
