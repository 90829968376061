export default function replaceItem<T>(
  original: T[],
  item: T,
  newItem: T,
  callback?: (element: T) => boolean
): T[] {
  const array = [...original];
  const index = callback ? array.findIndex(callback) : array.indexOf(item);
  if (index !== -1) {
    array[index] = newItem;
  }
  return array;
}
