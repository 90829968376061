import { useParams } from "react-router-dom";
import UnitView from "./UnitView";

export default function UnitScreen(): JSX.Element {
  const { id: unitId } = useParams<{ id: string }>();

  if (!unitId) throw new Error("Missing meeting id");

  return <UnitView unitId={unitId} />;
}
