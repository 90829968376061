export default async function downloadFile(url: string): Promise<Blob> {
  const stripped = url.replace("https://f004.backblazeb2.com/", "/");
  const response = await fetch(
    process.env.NODE_ENV === "production" ? url : stripped
  );

  if (!response.body) throw new Error("Failed to download video");
  const blob = await response.blob();

  return blob;
}
