import { EmailOutlined } from "@mui/icons-material";
import {
  Box,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import SubmitButton from "src/components/Button/SubmitButton";
import { ScreenTitle } from "src/components/Typography";
import { useAuth } from "src/contexts/AuthContext";
import useErrorHandler from "src/utils/useErrorHandler";

export default function ForgotPasswordScreen() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);
  const authCtx = useAuth();
  const { t } = useTranslation();
  const theme = useTheme();
  const handleError = useErrorHandler();

  const handleLogin = async () => {
    try {
      setLoading(true);
      await authCtx.resetPassword(email);
      setLoading(false);
      setFinished(true);
    } catch (e) {
      handleError(e);
      setLoading(false);
    }
  };

  if (finished)
    return (
      <Box>
        <Stack spacing={theme.spacing(3)}>
          <ScreenTitle>{t("Reset password.")}</ScreenTitle>
          <Typography variant="body1">
            {t(
              "We sent an email to your inbox. Please check your spam folder if you do not receive the email within 5 minutes."
            )}
          </Typography>
        </Stack>
      </Box>
    );

  return (
    <Box>
      <Stack spacing={theme.spacing(3)}>
        <ScreenTitle>{t("Reset password.")}</ScreenTitle>
        <Typography variant="body1">
          {t("We will send an email to the following email address")}
        </Typography>
        <TextField
          type="text"
          value={email}
          label="Email"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailOutlined />
              </InputAdornment>
            ),
          }}
          onChange={(e) => setEmail(e.target.value)}
        />

        <SubmitButton
          onClick={handleLogin}
          size="large"
          disabled={!email.length}
          loading={loading}
        >
          {t("Send Email")}
        </SubmitButton>
      </Stack>
    </Box>
  );
}
