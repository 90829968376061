import { Timestamp } from "firebase/firestore";

export const consentTypes = [
  "terms_of_service",
  "privacy_policy",
  "fcra",
  "background_disclosure",
  "background_authorization",
] as const;
export type ConsentType = typeof consentTypes[number];

export type Consent = {
  uid: string;
  userId: string;
  type: ConsentType;
  version: string;
  typedSignature?: string;
  consentText: string;
  consentGivenAt: string;
  consentGivenAtFirestoreTimestamp: Timestamp;
  revokedAt?: string;
  revokedAtFirestoreTimestamp?: Timestamp;
};
