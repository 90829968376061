import { doc, getDoc } from "firebase/firestore";
import { getBlob, ref } from "firebase/storage";
import Joi from "joi";
import { Clients } from "src/contexts/AppContext";
import { db } from "src/firebase"; // Adjust the import to match your firebase setup
import serviceFunction from "src/utils/serviceFunction";

type Data = {
  organizationDocumentId: string;
  userId: string;
};

const schema = Joi.object<Data>({
  organizationDocumentId: Joi.string().required(),
  userId: Joi.string().required(),
});

const getOrganizationDocumentBlob = serviceFunction(
  schema,
  async (
    { organizationDocumentId, userId }: Data,
    { storage }: Clients
  ): Promise<File | undefined> => {
    const documentRef = doc(
      db,
      "users",
      userId,
      "documents",
      `organization-${userId}-doc-${organizationDocumentId}`
    );
    const snapshot = await getDoc(documentRef);
    const data = snapshot.data();

    if (!data) return undefined;

    const fileBlob = await getBlob(ref(storage, data.path));
    const file = new File([fileBlob], data.path);

    return file;
  }
);

export default getOrganizationDocumentBlob;
