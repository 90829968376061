import { StudyPlanData } from "src/pages/OnboardingScreen/generateStudyPlan";
import isStudyPlanGuard from "./isStudyPlanGuard";

export default function calculateExpectedDailyEngagementRatio(
  studyPlanData: StudyPlanData
): number | undefined {
  if (!isStudyPlanGuard(studyPlanData)) return undefined;

  const { totalWeeks, weeklyPlan } = studyPlanData;

  // Total engagements (video lessons + quizzes)
  const totalEngagements = Object.values(weeklyPlan).reduce(
    (total, week) => total + week.lessonIds.length + week.examIds.length,
    0
  );

  // Calculate the expected engagement ratio (videos and quizzes per week)
  const expectedEngagementRatio = totalEngagements / (totalWeeks * 7);

  return expectedEngagementRatio;
}
