import { SxProps, Theme } from "@mui/material";

export default function addStylesForDevices(
  queryResult: boolean,
  defaultStyles: SxProps<Theme>,
  overrides: SxProps<Theme>
): SxProps<Theme> {
  let styles: SxProps<Theme> = defaultStyles;
  if (queryResult) {
    styles = {
      ...defaultStyles,
      ...overrides,
    } as SxProps<Theme>;
  }
  return styles;
}
