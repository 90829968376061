import { Typography, TypographyProps } from "@mui/material";

export function ScreenTitle(props: Omit<TypographyProps, "variant">) {
  return (
    <Typography
      fontWeight="bold"
      fontFamily="Space Grotesk"
      variant="h4"
      {...props}
    />
  );
}

export function Header32(props: Omit<TypographyProps, "variant">) {
  return (
    <Typography
      fontWeight="bold"
      fontFamily="Space Grotesk"
      fontSize={{ xs: 24, md: 32 }}
      {...props}
    />
  );
}

export function Header20(props: Omit<TypographyProps, "variant">) {
  return (
    <Typography
      fontWeight="bold"
      fontFamily="Space Grotesk"
      fontSize={{ xs: 18, md: 20 }}
      {...props}
    />
  );
}
