import { Consent, consentTypes } from "./Consent";

export default function isConsentGuard(obj: unknown): obj is Consent {
  if (!obj || typeof obj !== "object") return false;

  const value = obj as Consent;

  return (
    typeof value.uid === "string" &&
    typeof value.userId === "string" &&
    typeof consentTypes.includes(value.type) &&
    (typeof value.typedSignature === "undefined" ||
      typeof value.typedSignature === "string") &&
    typeof value.consentText === "string" &&
    typeof value.consentGivenAt === "string" &&
    typeof value.version === "string"
  );
}
