import { Timestamp } from "firebase/firestore";

export type OrganizationStaff = {
  uid: string;
  firstName: string;
  lastName: string;
  organizationId: string;
  phone: string;
  email: string;
  lastLoggedIn?: string;
  lastLoggedInFirestoreTimestamp?: Timestamp;
};

export type Organization = {
  uid: string;
  name: string;
  logoImageUrl: string;
};

export type OrganizationDocument = {
  uid: string;
  name: string;
  description: string;
  stage: TrainingStage;
  optional: boolean;
  organizationId: string;
  fileUri: string;
  externalUrl?: string;
  eSignatureAvailable?: boolean;
};

export const trainingStages = ["enrollment", "training", "employment"] as const;
export type TrainingStage = typeof trainingStages[number];

export type OrganizationSchool = {
  uid: string;
  schoolId: string;
  organizationId: string;
  createdAt: string;
};
