import { CheckCircle, Lock } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ResourceRecord, UnitRecord } from "src/clients/schema";
import { Header20 } from "../Typography";

export type UnitWithLessonProgress = Pick<
  UnitRecord,
  "name" | "id" | "order" | "thumbnailUrl"
> & {
  resources: (Pick<ResourceRecord, "id" | "name" | "order" | "thumbnailUrl"> & {
    videoCompleted: boolean;
    quizCompleted: boolean;
  })[];
  unlocked: boolean;
  current?: boolean;
};

interface Props {
  unit: UnitWithLessonProgress;
}

const UnitCard = ({ unit }: Props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();

  const totalLessons = unit.resources.length;

  const completedLessons = unit.resources.filter(
    (resource) => resource.videoCompleted && resource.quizCompleted
  ).length;

  const currentResource = unit.current
    ? unit.resources.find(
        (resource) => !resource.videoCompleted || !resource.quizCompleted
      )
    : undefined;

  const handleClick = () => {
    if (unit.current && currentResource)
      navigate(`/resources/${currentResource.id}`);
    else if (unit.unlocked) navigate(`/units/${unit.id}`);
  };

  return (
    <Card
      onClick={handleClick}
      sx={{
        cursor: unit.unlocked ? "pointer" : "not-allowed",
        ":hover": { opacity: 0.8 },
      }}
      elevation={1}
    >
      <Grid container>
        <Grid item xs={12} md={8}>
          <CardContent>
            <Stack gap={theme.spacing(1)}>
              <Header20>{unit.name}</Header20>
              <Stack
                spacing={1}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  {completedLessons === totalLessons ? (
                    <CheckCircle color="success" />
                  ) : completedLessons > 0 ? (
                    <CircularProgress
                      variant="determinate"
                      value={completedLessons / totalLessons}
                      color="success"
                    />
                  ) : (
                    <div />
                  )}
                  <Typography variant="body2" color="text.secondary">
                    {completedLessons}/{totalLessons} {t("lessons")}
                  </Typography>
                </Stack>
                <Box>
                  {completedLessons === totalLessons ? (
                    <Button onClick={() => navigate(`/units/${unit.id}`)}>
                      {t("Review")}
                    </Button>
                  ) : unit.unlocked ? (
                    <Button variant="contained">{t("Resume")}</Button>
                  ) : (
                    <Stack direction="row" alignItems="center" gap={1}>
                      <Lock />
                      <Typography variant="body2" color="text.secondary">
                        {t("Locked")}
                      </Typography>
                    </Stack>
                  )}
                </Box>
              </Stack>
            </Stack>
          </CardContent>
        </Grid>
        <Grid item xs={12} md={4}>
          {currentResource && (
            <CardMedia
              src={currentResource.thumbnailUrl}
              component="img"
              height="100"
              sx={{ objectFit: "cover" }}
              alt="current video photo"
            />
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default UnitCard;
