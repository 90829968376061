import { Box } from "@mui/material";
import { useState } from "react";
import FloatingVideo from "src/components/FloatingVideo";
import { useCurrentUser } from "src/SessionBoundary";
import BackgroundSurveyView from "./BackgroundSurveyView";

const UserBackgroundSurveyScreen = () => {
  const user = useCurrentUser();
  const [playVideo, setPlayVideo] = useState(false);
  const handleStart = () => {
    setPlayVideo(true); // Start playing the video after user interaction
  };
  return (
    <Box onClick={handleStart}>
      <BackgroundSurveyView user={user} />
      <FloatingVideo
        videoUrl="https://f004.backblazeb2.com/file/emerge-courses/CDL/videos/admissions/IntroAdmissionsVideo.mp4"
        playVideo={playVideo}
      />
    </Box>
  );
};

export default UserBackgroundSurveyScreen;
