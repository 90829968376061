import { PictureAsPdf } from "@mui/icons-material";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import isPDFFile from "src/utils/isPdfFile";
import Placeholder from "../Placeholder.svg";
import AspectRatio from "./AspectRatio";
import withController from "./withController";

type Props = {
  label: string;
  value: File;
  onChange: (file: File | undefined) => void;
  error?: boolean;
  placeholderImage: string;
  placeholderImageWidth: number;
};

export function ImageInputBase({
  label,
  value,
  onChange,
  error,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".png", ".pdf"],
    },
    maxFiles: 1,
    onDropAccepted: ([file]) => onChange(file),
  });

  const [previewUrl, setPreviewUrl] = useState<string>();
  const [isPdf, setIsPdf] = useState(false);

  useEffect(() => {
    if (!value) {
      setPreviewUrl(undefined);
      setIsPdf(false);
      return undefined; // Explicitly return undefined here.
    }

    const url = URL.createObjectURL(value);
    setPreviewUrl(url);
    setIsPdf(isPDFFile(value.name));
    return () => URL.revokeObjectURL(url);
  }, [value]);

  return (
    <>
      <Typography variant="overline">{label}</Typography>
      <AspectRatio ratio={3 / 2}>
        <Box
          {...getRootProps()}
          sx={{
            color: error ? "error.main" : undefined,
            cursor: "pointer",
            border: 1,
            borderStyle: "dashed",
            borderColor: "rgba(0 0 0 / .23)",
            padding: 1,
            height: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <input {...getInputProps()} />
          {value ? (
            isPdf ? (
              <Stack spacing={theme.spacing(1)} alignItems="center">
                <PictureAsPdf color="disabled" />
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    maxWidth: 200,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {value.name}
                </Typography>
              </Stack>
            ) : (
              <img
                src={previewUrl}
                alt=""
                style={{ maxWidth: "100%", maxHeight: "100%", margin: "auto" }}
              />
            )
          ) : (
            <Stack spacing={2} textAlign="center" alignItems="center">
              <img src={Placeholder} alt="" width={48} height={48} />
              <Typography>
                {t("Click or drag file to this area to upload")}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("JPG, PNG or PDF accepted")}
              </Typography>
            </Stack>
          )}
        </Box>
      </AspectRatio>
      {value && (
        <Typography variant="body2">
          {t("Click or drag to replace file")}
        </Typography>
      )}
    </>
  );
}

export default withController(ImageInputBase);
