import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Drawer,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { CardSkeleton } from "src/components/Skeleton/closet";
import { useAppContext } from "src/contexts/AppContext";
import { useCurrentUser } from "src/SessionBoundary";
import fetchCurrentTheoryTrainingModule, {
  CurrentTheoryTrainingModule,
} from "src/utils/fetchCurrentTheoryTrainingModule";
import useErrorHandler from "src/utils/useErrorHandler";
import StudyPlanWeekCardList from "../OnboardingScreen/StudyPlanWeekCardList";

type Props = {
  onContactCoachToResetGoals: () => void;
};
export default function CurrentTrainingWeeklyGoalCard({
  onContactCoachToResetGoals,
}: Props) {
  const { t } = useTranslation();
  const user = useCurrentUser();
  const errorHandler = useErrorHandler();
  const { clients } = useAppContext();
  const [loading, setLoading] = useState(true);
  const [currentTrainingModuleGoal, setCurrentTrainingModuleGoal] =
    useState<CurrentTheoryTrainingModule>(null);
  const [viewPlan, setViewPlan] = useState(false);

  useEffect(() => {
    fetchCurrentTheoryTrainingModule(user, clients)
      .then(setCurrentTrainingModuleGoal)
      .catch(errorHandler)
      .finally(() => setLoading(false));
  }, [clients, errorHandler, user]);

  if (!user.theoryTrainingPlan) return <div />;
  if (loading) return <CardSkeleton />;
  if (!currentTrainingModuleGoal) return <div />;

  const {
    trainingStatus,
    overview,
    // currentModule,
  } = currentTrainingModuleGoal;

  if (trainingStatus === "completed")
    return (
      <Alert>
        <AlertTitle>{t("You completed your lesson plan!")}</AlertTitle>
        {t(
          "Now, you need to schedule the exam and complete additional practice tests to prepare."
        )}
      </Alert>
    );

  return (
    <Box>
      {/* If ahead of schedule */}
      {trainingStatus === "ahead_of_schedule" && (
        <Alert
          severity="success"
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => setViewPlan(true)}
            >
              {t("REVIEW")}
            </Button>
          }
        >
          <AlertTitle>{t("You're ahead of schedule!")}</AlertTitle>
          {t(" Keep up the great work! 🎉")}
        </Alert>
      )}

      {/* If on time */}
      {trainingStatus === "on_time" && (
        <Alert severity="info">
          <AlertTitle>{t("This week's goal")}</AlertTitle>
          <Typography gutterBottom>
            <Trans
              t={t}
              defaults="Lessons: {{ completed }} / {{ total }} watched"
              values={{
                completed: overview.completedVideosInModule,
                total: overview.totalVideosInModule,
              }}
            />
          </Typography>
          <Typography gutterBottom>
            <Trans
              t={t}
              defaults="Exams: {{ completed }} / {{ total }} completed"
              values={{
                completed: overview.completedExamsInModule,
                total: overview.totalExamsInModule,
              }}
            />
          </Typography>
        </Alert>
      )}

      {/* If late */}
      {trainingStatus === "late" && (
        <Alert
          severity="warning"
          action={
            <Button
              color="inherit"
              size="small"
              onClick={onContactCoachToResetGoals}
            >
              {t("CONTACT")}
            </Button>
          }
        >
          <AlertTitle>
            {t("You're Just a Little Behind – No Big Deal!")}
          </AlertTitle>
          {t(
            "Life happens! Let’s refocus and get you back on track. Reach out to your coach, and together, we’ll reset your goals."
          )}
        </Alert>
      )}
      <Drawer
        open={viewPlan}
        anchor="bottom"
        onClose={() => setViewPlan(false)}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: 800, // Default for small screens
            height: "75vh",
            maxHeight: "75vh",
            overflowY: "auto",
            marginY: 4,
            marginX: "auto",
          }}
        >
          <StudyPlanWeekCardList
            studyPlanByWeek={user.theoryTrainingPlan?.weeklyPlan}
          />
        </Box>
      </Drawer>
    </Box>
  );
}
