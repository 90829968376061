import { Box } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import TrueFalseChip from "src/components/TrueFalseChip";
import MilestoneType from "src/types/MilestoneType.enum";
import { StudentPlanMilestone } from "src/types/StudentPlan";
import { UserAccount } from "src/types/User";
import { getFullName } from "src/utils";
import formatOptionalTimestamp from "src/utils/formatOptionalTimestamp";
import useCohorts from "src/utils/useCohorts";
import useSchools from "src/utils/useSchools";
import useUserAttendanceById from "src/utils/useUserAttendanceById";
import useUsersMilestonesById from "src/utils/useUsersMilestonesById";
import createMilestoneColumnUtil from "../Admin/CaseloadScreen/createMilestoneColumnUtil";
import DataGridCustomToolbar from "./DataGridCustomToolbar";

type SkillsTrainingTableProps = {
  users: UserAccount[];
};

type DataGridRow = {
  id: string;
  name: string;
  skillsTrainingStartMilestone?: StudentPlanMilestone;
  skillsTrainingEndMilestone?: StudentPlanMilestone;
  skillsTrainingExamMilestone?: StudentPlanMilestone;
  attendanceCount?: number;
  latestAttendance?: string;
  cohortName?: string;
  schoolName?: string;
  hasIssuedTheoryCertification: boolean;
  hasSubmittedSchoolEnrollment: boolean;
  hasPaidSchool: boolean;
  hasSubmittedFundingAgencyEnrollment: boolean;
};

export default function SkillsTrainingTable({
  users,
}: SkillsTrainingTableProps) {
  const { cohortsById } = useCohorts();
  const { schoolsById } = useSchools();
  const { getMilestonesById, loading: milestonesLoading } =
    useUsersMilestonesById(users);
  const {
    getUserAttendanceCount,
    getUserLatestAttendance,
    loading: attendanceLoading,
  } = useUserAttendanceById(users);

  const [data, setData] = useState<DataGridRow[]>([]);

  useEffect(() => {
    if (milestonesLoading || attendanceLoading) return;
    const formattedData = users.map((user) => {
      const milestones = getMilestonesById(user);
      const lastAttendance = getUserLatestAttendance(user);

      return {
        id: user.uid,
        name: getFullName(user),
        skillsTrainingStartMilestone: milestones?.find(
          (milestone) => milestone.type === MilestoneType.SKILLS_TRAINING_START
        ),
        skillsTrainingEndMilestone: milestones?.find(
          (milestone) => milestone.type === MilestoneType.SKILLS_TRAINING_END
        ),
        skillsTrainingExamMilestone: milestones?.find(
          (milestone) => milestone.type === MilestoneType.LICENSE_EXAM
        ),
        attendanceCount: getUserAttendanceCount(user),
        latestAttendance: lastAttendance
          ? formatOptionalTimestamp(lastAttendance.date)
          : "N/A",
        cohortName: user.cohortId
          ? cohortsById[user.cohortId]?.name || "N/A"
          : "N/A",
        schoolName: user.schoolId
          ? schoolsById[user.schoolId]?.name || "N/A"
          : "N/A",
        hasIssuedTheoryCertification:
          user.skillsCoachChecklist?.hasIssuedTheoryCertification ?? false,
        hasSubmittedSchoolEnrollment:
          user.skillsCoachChecklist?.hasSubmittedSchoolEnrollment ?? false,
        hasPaidSchool: user.skillsCoachChecklist?.hasPaidSchool ?? false,
        hasSubmittedFundingAgencyEnrollment:
          user.skillsCoachChecklist?.hasSubmittedFundingAgencyEnrollment ??
          false,
      };
    });
    setData(formattedData);
  }, [
    users,
    cohortsById,
    schoolsById,
    getMilestonesById,
    milestonesLoading,
    attendanceLoading,
    getUserLatestAttendance,
    getUserAttendanceCount,
  ]);

  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", width: 100 },
    { field: "cohortName", headerName: "Cohort", width: 150 },
    { field: "schoolName", headerName: "School", width: 150 },
    {
      field: "hasIssuedTheoryCertification",
      headerName: "Theory Certification Issued?",
      width: 150,
      renderCell: (params) => <TrueFalseChip value={params.value} />,
    },
    {
      field: "hasSubmittedSchoolEnrollment",
      headerName: "School Enrollment Submitted?",
      width: 150,
      renderCell: (params) => <TrueFalseChip value={params.value} />,
    },
    {
      field: "hasPaidSchool",
      headerName: "School Paid?",
      width: 150,
      renderCell: (params) => <TrueFalseChip value={params.value} />,
    },
    {
      field: "hasSubmittedFundingAgencyEnrollment",
      headerName: "Funding Agency Enrollment Submitted?",
      width: 150,
      renderCell: (params) => <TrueFalseChip value={params.value} />,
    },
    createMilestoneColumnUtil({
      field: "skillsTrainingStartMilestone",
      headerName: "Skills Training Start",
    }),
    {
      field: "attendanceCount",
      headerName: "Attendance Count",
      width: 100,
    },
    {
      field: "latestAttendance",
      headerName: "Latest Attendance",
      width: 100,
    },
    createMilestoneColumnUtil({
      field: "skillsTrainingEndMilestone",
      headerName: "Skills Training End",
    }),
    createMilestoneColumnUtil({
      field: "skillsTrainingExamMilestone",
      headerName: "Skills Exam",
    }),
  ];

  return (
    <Box>
      <DataGridPro
        rows={data}
        columns={columns}
        onRowClick={(row) => window.open(`/admin/clients/${row.id}`, "_blank")}
        initialState={{
          pinnedColumns: { left: ["name"] },
        }}
        slots={{
          toolbar: DataGridCustomToolbar,
        }}
        slotProps={{
          toolbar: { users },
        }}
      />
    </Box>
  );
}
