import { LooksOutlined, TaskOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import VideoPlayer from "src/components/VideoPlayer";
import { useAppContext } from "src/contexts/AppContext";
import { useCurrentUser } from "src/SessionBoundary";
import markUserAsCompletedOrientationLesson from "src/types/markUserAsCompletedOrientationLesson";
import { CONDITIONAL_ACCEPTANCE_LESSON_URL } from "src/utils/constants";
import useBackgroundProfile from "src/utils/useBackgroundProfile";
import useErrorHandler from "src/utils/useErrorHandler";
import SuccessScreenModal from "../SucessScreenModal";
import { trackEvent } from "./amplitude";
import SuccessScreenIllustration from "./SuccessScreen.svg";

export default function OrientationLessonScreen() {
  const errorHandler = useErrorHandler();
  const user = useCurrentUser();
  const { clients } = useAppContext();
  const { hasEligibleApplicationScore } = useBackgroundProfile(user);
  const [finished, setFinished] = useState(false);
  const navigate = useNavigate();

  const handleVideoEnded = async () => {
    trackEvent("Orientation Lesson Completed");

    await markUserAsCompletedOrientationLesson({ user }, clients)
      .then(() => setFinished(true))
      .catch(errorHandler);
  };

  // Redirect to the home page if the user doesn't have an eligible application score
  if (
    hasEligibleApplicationScore === false ||
    hasEligibleApplicationScore === null
  )
    navigate("/");

  return (
    <Box>
      <SuccessScreenModal
        open={finished}
        illustration={SuccessScreenIllustration}
        illustrationWidth={150}
        title={t("Nice Work!")}
        content={
          <Typography variant="body1">
            {t(
              "We just need a few more details about you to find the right scholarship."
            )}
          </Typography>
        }
        okButtonText={t(`Continue`)}
        backButtonText={t("Back Home")}
        showBackButton
        onBackButtonClick={() => navigate("/")}
        onOkButtonClick={() => navigate("/onboarding/eligibility")}
      />
      <Card>
        <CardHeader
          title={t("{{ firstName }}, you pre-qualified for a scholarship!", {
            firstName: user.firstName,
          })}
        />
        <CardMedia>
          <VideoPlayer
            url={CONDITIONAL_ACCEPTANCE_LESSON_URL}
            width="100%"
            playsInline
            progressInterval={5000}
            onError={errorHandler}
            onEnded={handleVideoEnded}
            fallback={<Typography>{t("Loading")}</Typography>}
            controls
          />
        </CardMedia>
        <CardContent>
          <Typography variant="body1" marginY={2}>
            {t(
              "Great news! Based on the info you shared, it looks like you can qualify for a training scholarship! "
            )}
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <LooksOutlined />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Trans
                    i18nKey="<strong>From OUR side:</strong> We’ll check your background to confirm you’re eligible and make sure we can find jobs for you after graduation."
                    components={{ strong: <strong /> }}
                  />
                }
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <TaskOutlined />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Trans
                    i18nKey="<strong>From YOUR side:</strong> Confirm your personal info and create a training plan that works for your schedule."
                    components={{ strong: <strong /> }}
                  />
                }
              />
            </ListItem>
          </List>
        </CardContent>
        <CardActions>
          <Button size="large" variant="contained" disabled={!finished}>
            {t("Continue")}
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}
