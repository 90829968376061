import { FirebaseError } from "firebase/app";
import i18n from "src/i18n/i18nConfig";

export default function parseFirebaseError(err: FirebaseError): string {
  if (err.message === "Firebase: Error (auth/user-not-found).")
    return i18n.t("The account does not exist");

  if (err.message === "Firebase: Error (auth/wrong-password).")
    return i18n.t("The password is incorrect");

  if (err.message === "Firebase: Error (auth/email-already-exists).")
    return i18n.t("The email has already been used");

  if (err.message === "Firebase: Error (auth/invalid-email).") {
    return i18n.t("The email is not valid");
  }
  return err.message;
}
