import * as amplitude from "@amplitude/analytics-browser";
import { DocumentSnapshot, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth, useSession } from "./contexts/AuthContext";
import cleanFirestoreDoc from "./firebase/cleanFirestoreDoc";
import { SessionAccountInformation } from "./firebase/FirestoreClient";
import AppSkeleton from "./pages/AppSkeleton";
import DisabledAccountScreen from "./pages/Common/DisabledAccountScreen";
import { UserAccount, UserType } from "./types/User";
import isUserAccountDeactivated from "./utils/isUserAccountDeactivated";
import isUserOnboarded from "./utils/isUserOnboarded";
import { LIB_VERSION } from "./version";

export function useSessionAccountInformation(): SessionAccountInformation {
  const session = useSession();

  if (!session.metadata) throw new Error("Information was not loaded");

  return session.metadata;
}

export function useAdmin() {
  const session = useSession();

  if (!session.metadata)
    throw new Error("Only admins are allowed to access this");
  if (session.metadata.type !== UserType.Admin)
    throw new Error("Only admins are allowed to access this.");

  return session.metadata;
}

export function useOrganizationStaff() {
  const session = useSession();
  if (!session.metadata) throw new Error("Type narrowing");
  if (session.metadata.type !== UserType.OrganizationStaff)
    throw new Error("Type narrowing");

  return session.metadata;
}

export function useCurrentUser() {
  const account = useSessionAccountInformation();
  const session = useSession();

  if (account.type !== UserType.User)
    throw new Error("This interface cannot be accessed by organization staff");

  const [user, setUser] = useState<UserAccount>(account);

  // TODO: move this out of this hook and into auth hook
  // this is being instantiated in multiple pages
  useEffect(() => {
    //  listen for changes to the user object
    const unsubscribe = onSnapshot(
      session.getUserDoc(),
      (doc: DocumentSnapshot) => {
        const updatedUser = cleanFirestoreDoc(doc);
        if (!updatedUser) return;
        session.updateUserAccount(updatedUser as UserAccount);
        setUser(updatedUser as UserAccount);
      }
    );

    return unsubscribe;
  }, [session]);

  return user;
}

export type OnboardingRedirectRule =
  | "redirectToOnboardingIfRequired"
  | "redirectToDashboardIfOnboarded"
  | "allowInactivedUserAccountsAccess";

interface Props {
  children: JSX.Element;
  onboardingRedirectRule?: OnboardingRedirectRule;
  userType?: UserType;
}

const SessionBoundary = ({
  children,
  onboardingRedirectRule,
  userType,
}: Props): JSX.Element => {
  const authCtx = useAuth();
  const location = useLocation();
  const account = authCtx.session?.metadata;

  if (authCtx.isLoading) {
    return <AppSkeleton />;
  }

  if (!authCtx.isLoggedIn) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }

  if (!account) {
    return <AppSkeleton />;
  }

  // set amplitude
  amplitude.setUserId(account.uid);
  const identify = new amplitude.Identify();
  amplitude.identify(identify, {
    user_id: account.uid,
    app_version: LIB_VERSION,
  });

  if (
    account.type === UserType.OrganizationStaff ||
    account.type === UserType.Admin
  ) {
    if (location.pathname.includes("admin")) {
      return children;
    }

    return <Navigate to="/admin" />;
  }

  // Redirection for routes that are exclusive to certain user types (e.g only students can access the student dashboard)
  if (userType && account.type !== userType) {
    return <Navigate to="/" />;
  }

  // User redirection
  if (account.type === UserType.User) {
    if (
      isUserAccountDeactivated(account) &&
      onboardingRedirectRule !== "allowInactivedUserAccountsAccess"
    )
      return <DisabledAccountScreen />;

    if (
      onboardingRedirectRule === "redirectToDashboardIfOnboarded" &&
      isUserOnboarded(account)
    ) {
      return <Navigate to="/" replace />;
    }

    if (
      onboardingRedirectRule === "redirectToOnboardingIfRequired" &&
      !isUserOnboarded(account)
    ) {
      return <Navigate to="/onboarding" replace />;
    }
  }

  return children;
};

export default SessionBoundary;
