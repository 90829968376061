import { doc, updateDoc } from "firebase/firestore";
import Joi from "joi";
import { JobPreferenceFormData } from "src/pages/OnboardingScreen/JobPreferenceForm";
import { UserAccount } from "src/types/User";
import serviceFunction from "src/utils/serviceFunction";
import generateFirestoreTimestamps from "./generateFirestoreTimestamps";

type Data = {
  user: UserAccount;
  jobPreference: JobPreferenceFormData;
};

const schema = Joi.object<Data>({
  user: Joi.object().required(),
  jobPreference: Joi.object().required(),
});

const updateUserJobPreference = serviceFunction(
  schema,
  async ({ user, jobPreference }, { db }): Promise<void> => {
    await updateDoc(doc(db, `users/${user.uid}`), {
      jobPreference: {
        ...jobPreference,
        ...generateFirestoreTimestamps(new Date(), "createdAt"),
      },
    });
  }
);

export default updateUserJobPreference;
