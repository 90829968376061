/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Box,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

export default function FCRAAgreement() {
  const tableData = [
    {
      type: "Banks, savings associations, and credit unions with total assets of over $10 billion and their affiliates",
      contacts: [
        {
          name: "Consumer Financial Protection Bureau",
          address: "1700 G Street NW, Washington, DC 20552",
        },
        {
          name: "Federal Trade Commission",
          address:
            "Consumer Response Center, 600 Pennsylvania Avenue, NW, Washington, DC 20580",
          phone: "(877) 382-4357",
        },
      ],
    },
    {
      type: "National banks, federal savings associations, and federal branches and federal agencies of foreign banks",
      contacts: [
        {
          name: "Office of the Comptroller of the Currency",
          address:
            "Customer Assistance Group, P.O. Box 53570, Houston, TX 77052",
        },
      ],
    },
    {
      type: "State member banks, branches and agencies of foreign banks, commercial lending companies owned or controlled by foreign banks, and organizations operating under section 25 or 25A of the Federal Reserve Act",
      contacts: [
        {
          name: "Federal Reserve Consumer Help Center",
          address: "P.O. Box 1200, Minneapolis, MN 55480",
        },
      ],
    },
    {
      type: "Nonmember Insured Banks, Insured State Branches of Foreign Banks, and insured state savings associations",
      contacts: [
        {
          name: "Division of Depositor and Consumer Protection",
          address:
            "National Center for Consumer and Depositor Assistance, Federal Deposit Insurance Corporation, 1100 Walnut Street, Box #11, Kansas City, MO 64106",
        },
      ],
    },
    {
      type: "Federal Credit Unions",
      contacts: [
        {
          name: "National Credit Union Administration",
          address:
            "Office of Consumer Financial Protection, 1775 Duke Street, Alexandria, VA 22314",
        },
      ],
    },
    {
      type: "Air carriers",
      contacts: [
        {
          name: "Assistant General Counsel for Office of Aviation Consumer Protection",
          address:
            "Department of Transportation, 1200 New Jersey Avenue SE, Washington, DC 20590",
        },
      ],
    },
    {
      type: "Creditors Subject to Surface Transportation Board",
      contacts: [
        {
          name: "Office of Public Assistance, Governmental Affairs, and Compliance",
          address:
            "Surface Transportation Board, 395 E Street SW, Washington, DC 20423",
        },
      ],
    },
    {
      type: "Creditors Subject to the Packers and Stockyards Act, 1921",
      contacts: [
        {
          name: "Nearest Packers and Stockyards Division Regional Office",
        },
      ],
    },
    {
      type: "Small Business Investment Companies",
      contacts: [
        {
          name: "Associate Administrator, Office of Capital Access",
          address:
            "United States Small Business Administration, 409 Third Street SW, Suite 8200, Washington, DC 20416",
        },
      ],
    },
    {
      type: "Brokers and Dealers",
      contacts: [
        {
          name: "Securities and Exchange Commission",
          address: "100 F Street, NE, Washington, DC 20549",
        },
      ],
    },
    {
      type: "Institutions that are members of the Farm Credit System",
      contacts: [
        {
          name: "Farm Credit Administration",
          address: "1501 Farm Credit Drive, McLean, VA 22102-5090",
        },
      ],
    },
    {
      type: "Retailers, Finance Companies, and All Other Creditors Not Listed Above",
      contacts: [
        {
          name: "Federal Trade Commission",
          address:
            "Consumer Response Center, 600 Pennsylvania Avenue, NW, Washington, DC 20580",
          phone: "(877) 382-4357",
        },
      ],
    },
  ];

  return (
    <Box>
      <Typography variant="body1" gutterBottom>
        Para información en español, visite{" "}
        <Link
          // href="https://www.consumerfinance.gov/learnmore"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.consumerfinance.gov/learnmore
        </Link>{" "}
        o escribe a la Consumer Financial Protection Bureau, 1700 G Street NW,
        Washington, DC 20552.
      </Typography>
      <Typography variant="h5" gutterBottom>
        A Summary of Your Rights Under the Fair Credit Reporting Act
      </Typography>
      <Typography variant="body1" gutterBottom>
        The federal Fair Credit Reporting Act (FCRA) promotes the accuracy,
        fairness, and privacy of information in the files of consumer reporting
        agencies. There are many types of consumer reporting agencies, including
        credit bureaus and specialty agencies (such as agencies that sell
        information about check writing histories, medical records, and rental
        history records). Here is a summary of your major rights under FCRA. For
        more information, including information about additional rights, go to{" "}
        <Link
          // href="https://www.consumerfinance.gov/learnmore"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.consumerfinance.gov/learnmore
        </Link>{" "}
        or write to: Consumer Financial Protection Bureau, 1700 G Street NW,
        Washington, DC 20552.
      </Typography>
      <Typography variant="body1" gutterBottom>
        You must be told if information in your file has been used against you.
        Anyone who uses a credit report or another type of consumer report to
        deny your application for credit, insurance, or employment – or to take
        another adverse action against you – must tell you, and must give you
        the name, address, and phone number of the agency that provided the
        information.
      </Typography>
      <Typography variant="body1" gutterBottom>
        You have the right to know what is in your file. You may request and
        obtain all the information about you in the files of a consumer
        reporting agency (your “file disclosure”). You will be required to
        provide proper identification, which may include your Social Security
        number. In many cases, the disclosure will be free. You are entitled to
        a free file disclosure if:
      </Typography>
      <ul>
        <li>
          A person has taken adverse action against you because of information
          in your credit report;
        </li>
        <li>
          You are the victim of identity theft and place a fraud alert in your
          file;
        </li>
        <li>Your file contains inaccurate information as a result of fraud;</li>
        <li>You are on public assistance;</li>
        <li>
          You are unemployed but expect to apply for employment within 60 days.
        </li>
      </ul>
      <Typography variant="body1" gutterBottom>
        In addition, all consumers are entitled to one free disclosure every 12
        months upon request from each nationwide credit bureau and from
        nationwide specialty consumer reporting agencies. See{" "}
        <Link
          // href="https://www.consumerfinance.gov/learnmore"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.consumerfinance.gov/learnmore
        </Link>{" "}
        for additional information.
      </Typography>
      <Typography variant="body1" gutterBottom>
        You have the right to ask for a credit score. Credit scores are
        numerical summaries of your credit-worthiness based on information from
        credit bureaus. You may request a credit score from consumer reporting
        agencies that create scores or distribute scores used in residential
        real property loans, but you will have to pay for it. In some mortgage
        transactions, you will receive credit score information for free from
        the mortgage lender.
      </Typography>
      <Typography variant="body1" gutterBottom>
        You have the right to dispute incomplete or inaccurate information. If
        you identify information in your file that is incomplete or inaccurate,
        and report it to the consumer reporting agency, the agency must
        investigate unless your dispute is frivolous. See{" "}
        <Link
          href="https://www.consumerfinance.gov/learnmore"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.consumerfinance.gov/learnmore
        </Link>{" "}
        for an explanation of dispute procedures.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Consumer reporting agencies must correct or delete inaccurate,
        incomplete, or unverifiable information. Inaccurate, incomplete, or
        unverifiable information must be removed or corrected, usually within 30
        days. However, a consumer reporting agency may continue to report
        information it has verified as accurate.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Consumer reporting agencies may not report outdated negative
        information. In most cases, a consumer reporting agency may not report
        negative information that is more than seven years old, or bankruptcies
        that are more than 10 years old.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Access to your file is limited. A consumer reporting agency may provide
        information about you only to people with a valid need – usually to
        consider an application with a creditor, insurer, employer, landlord, or
        other business. The FCRA specifies those with a valid need for access.
      </Typography>
      <Typography variant="body1" gutterBottom>
        You must give your consent for reports to be provided to employers. A
        consumer reporting agency may not give out information about you to your
        employer, or a potential employer, without your written consent given to
        the employer. Written consent generally is not required in the trucking
        industry. For more information, go to{" "}
        <Link
          // href="https://www.consumerfinance.gov/learnmore"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.consumerfinance.gov/learnmore
        </Link>
        .
      </Typography>
      <Typography variant="body1" gutterBottom>
        You may limit “prescreened” offers of credit and insurance you get based
        on information in your credit report. Unsolicited “prescreened” offers
        for credit and insurance must include a toll-free phone number you can
        call if you choose to remove your name and address from the lists these
        offers are based on. You may opt out with the nationwide credit bureaus
        at 1-888-567-8688.
      </Typography>
      <Typography variant="body1" gutterBottom>
        The following FCRA right applies with respect to nationwide consumer
        reporting agencies:
      </Typography>
      <Typography variant="h6" gutterBottom>
        CONSUMERS HAVE THE RIGHT TO OBTAIN A SECURITY FREEZE
      </Typography>
      <Typography variant="body1" gutterBottom>
        You have a right to place a “security freeze” on your credit report,
        which will prohibit a consumer reporting agency from releasing
        information in your credit report without your express authorization.
        The security freeze is designed to prevent credit, loans, and services
        from being approved in your name without your consent. However, you
        should be aware that using a security freeze to take control over who
        gets access to the personal and financial information in your credit
        report may delay, interfere with, or prohibit the timely approval of any
        subsequent request or application you make regarding a new loan, credit,
        mortgage, or any other account involving the extension of credit.
      </Typography>
      <Typography variant="body1" gutterBottom>
        As an alternative to a security freeze, you have the right to place an
        initial or extended fraud alert on your credit file at no cost. An
        initial fraud alert is a 1-year alert that is placed on a consumer’s
        credit file. Upon seeing a fraud alert display on a consumer’s credit
        file, a business is required to take steps to verify the consumer’s
        identity before extending new credit. If you are a victim of identity
        theft, you are entitled to an extended fraud alert, which is a fraud
        alert lasting 7 years.
      </Typography>
      <Typography variant="body1" gutterBottom>
        A security freeze does not apply to a person or entity, or its
        affiliates, or collection agencies acting on behalf of the person or
        entity, with which you have an existing account that requests
        information in your credit report for the purposes of reviewing or
        collecting the account. Reviewing the account includes activities
        related to account maintenance, monitoring, credit line increases, and
        account upgrades and enhancements.
      </Typography>
      <Typography variant="body1" gutterBottom>
        You may seek damages from violators. If a consumer reporting agency, or,
        in some cases, a user of consumer reports or a furnisher of information
        to a consumer reporting agency violates the FCRA, you may be able to sue
        in state or federal court.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Identity theft victims and active duty military personnel have
        additional rights. For more information, visit{" "}
        <Link
          // href="https://www.consumerfinance.gov/learnmore"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.consumerfinance.gov/learnmore
        </Link>
        .
      </Typography>
      <Typography variant="body1" gutterBottom>
        States may enforce the FCRA, and many states have their own consumer
        reporting laws. In some cases, you may have more rights under state law.
        For more information, contact your state or local consumer protection
        agency or your state Attorney General. For information about your
        federal rights, contact:
      </Typography>
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Type of Business</TableCell>
              <TableCell>Contact</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row) => (
              <TableRow key={row.type}>
                <TableCell>{row.type}</TableCell>
                <TableCell>
                  {row.contacts.map((contact) => (
                    <Box key={contact.name} sx={{ marginBottom: 2 }}>
                      <Typography variant="body2" component="div">
                        <strong>{contact.name}</strong>
                      </Typography>
                      {"address" in contact && contact.address && (
                        <Typography variant="body2" component="div">
                          {contact.address}
                        </Typography>
                      )}
                      {"phone" in contact && contact.phone && (
                        <Typography variant="body2" component="div">
                          {contact.phone}
                        </Typography>
                      )}
                    </Box>
                  ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
