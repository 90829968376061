// batchRequest.ts
import { UserAccount } from "src/types/User";

export default async function batchUserRequest<T>(
  users: UserAccount[],
  serviceFunction: (user: UserAccount) => Promise<T>,
  maxConcurrentRequests: number = 20
): Promise<{ [id: string]: T | undefined }> {
  const resultData: { [id: string]: T | undefined } = {};

  // Split users into batches of maxConcurrentRequests
  const batches = users.reduce((result, user, index) => {
    const batchIndex = Math.floor(index / maxConcurrentRequests);
    const updated = [...result];
    if (!updated[batchIndex]) {
      updated[batchIndex] = [];
    }
    updated[batchIndex].push(user);
    return updated;
  }, [] as UserAccount[][]);

  // Process each batch sequentially to avoid hitting Firestore limits
  await batches.reduce(async (previousBatchPromise, batch) => {
    // Wait for the previous batch to complete
    await previousBatchPromise;

    // Process the current batch
    await Promise.allSettled(
      batch.map(async (user) => {
        try {
          const data = await serviceFunction(user);
          resultData[user.uid] = data;
        } catch (error) {
          resultData[user.uid] = undefined;
        }
      })
    );
  }, Promise.resolve()); // Initial value to start the reduce

  return resultData;
}
