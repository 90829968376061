import { Box, CircularProgress, Stack, Typography } from "@mui/material";

interface Props {
  progress: number;
  total: number;
  label: string;
}

export default function ProgressIndicator({ progress, total, label }: Props) {
  if (total === 0) return <div />;

  return (
    <Stack direction="row" alignItems="center" gap={1} marginBottom={2}>
      <Box>
        <CircularProgress
          variant="determinate"
          color="success"
          value={Math.min((progress * 100) / total, 100)}
          size={20}
        />
      </Box>
      <Typography>
        {progress}/{total} {label}
      </Typography>
    </Stack>
  );
}
