import * as Types from '../../clients/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetUnitWithAssessmentsQueryVariables = Types.Exact<{
  unitId: Types.Scalars['ItemId'];
}>;


export type GetUnitWithAssessmentsQuery = { __typename?: 'Query', unit?: { __typename?: 'UnitRecord', id: string, name: string, thumbnailUrl: string, order: number, course: { __typename?: 'CourseRecord', name: string }, assessments: Array<{ __typename?: 'QuizRecord', id: string, name: string, quizType: string }> } | null };


export const GetUnitWithAssessmentsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetUnitWithAssessments"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"unitId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ItemId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"unit"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"id"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"eq"},"value":{"kind":"Variable","name":{"kind":"Name","value":"unitId"}}}]}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"thumbnailUrl"}},{"kind":"Field","name":{"kind":"Name","value":"order"}},{"kind":"Field","name":{"kind":"Name","value":"course"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"assessments"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"quizType"}}]}}]}}]}}]} as unknown as DocumentNode<GetUnitWithAssessmentsQuery, GetUnitWithAssessmentsQueryVariables>;