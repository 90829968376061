import { BookOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSessionAccountInformation } from "src/SessionBoundary";
import { UserAccount, UserType } from "src/types/User";
import { trackEvent } from "src/utils";
import groupBy from "src/utils/groupBy";
import useCompleteQuizGrades from "src/utils/useCompleteQuizGrades";
import StatisticsCard from "../AdminScreen/StatisticsCard";
import AppSkeleton from "../AppSkeleton";
import GreenDot from "./GreenDot.svg";
import mapQuizCategoryToName from "./mapQuizCategoryToName";
import YellowDot from "./YellowDot.svg";

type Props = {
  client: UserAccount;
  hideGradeBreakdown?: boolean;
};

const GradeChip = ({ score }: { score?: number }) => {
  const { t } = useTranslation();
  if (!score)
    return (
      <Typography variant="body2" color="text.secondary">
        {t("Not started")}
      </Typography>
    );

  if (score >= 0.8) {
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <img src={GreenDot} alt="Green Dot" width={20} />
        <Typography>{t("Passed")}</Typography>
        <Typography>
          ({Math.round(score * 100)}
          %)
        </Typography>
      </Stack>
    );
  }
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <img src={YellowDot} alt="Yellow Dot" width={20} />
      <Typography>{t("Did not pass")}</Typography>
      <Typography>
        ({Math.round(score * 100)}
        %)
      </Typography>
    </Stack>
  );
};

const GradesTab = ({ client, hideGradeBreakdown }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const account = useSessionAccountInformation();

  const { completeReport } = useCompleteQuizGrades(client.uid);

  const groupedRequiredQuizzes = useMemo(
    () =>
      completeReport
        ? groupBy(
            completeReport.completeQuizGrades.filter(
              (quiz) => quiz.isRequired
            ) || [],
            (quiz) => quiz.category
          )
        : {},
    [completeReport]
  );

  const handleNavigateToQuiz = (quiz: { id: string }) => {
    trackEvent("Grade View: Navigate to Quiz");
    navigate(`/quizzes/${quiz.id}`);
  };

  const groupedAdditionalExams = useMemo(
    () =>
      completeReport
        ? groupBy(
            completeReport.completeQuizGrades.filter(
              (quiz) => !quiz.isRequired && quiz.quizType === "exam"
            ),
            (quiz) => quiz.category
          )
        : [],
    [completeReport]
  );

  if (!completeReport) return <AppSkeleton />;

  // TODO update grade group using this new component
  return (
    <Stack spacing={theme.spacing(2)}>
      <Grid container spacing={theme.spacing(2)}>
        {Object.entries(completeReport.gradeGroupAverages).map(
          ([key, average]) => (
            <Grid item xs={4} id={key}>
              <StatisticsCard
                title="Average Grade"
                subtitle={mapQuizCategoryToName(key)}
                value={average ? `${Math.round(average * 100)}%` : "N/A"}
              />
            </Grid>
          )
        )}
      </Grid>
      {!hideGradeBreakdown && (
        <Stack spacing={2}>
          {Object.entries(groupedRequiredQuizzes).map(([key, quizzes]) => (
            <Card>
              <CardContent>
                <Typography variant="h6">
                  {mapQuizCategoryToName(key)}
                </Typography>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("Quiz")}</TableCell>
                        <TableCell>{t("Result")}</TableCell>
                        <TableCell>{t("Grade")}</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {quizzes.map((quizResult) => (
                        <TableRow key={quizResult.id}>
                          <TableCell>
                            <Typography>{quizResult.name}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" color="text.secondary">
                              {quizResult.totalCorrectAnswers &&
                              quizResult.totalQuestions
                                ? `${quizResult.totalCorrectAnswers} / ${quizResult.totalQuestions}`
                                : t("N/A")}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <GradeChip score={quizResult.grade} />
                          </TableCell>
                          {account.type === UserType.User && (
                            <TableCell>
                              {!quizResult.grade ? (
                                <Button
                                  variant="outlined"
                                  onClick={() =>
                                    handleNavigateToQuiz(quizResult)
                                  }
                                >
                                  {t("Start")}
                                </Button>
                              ) : (
                                <Button
                                  onClick={() =>
                                    handleNavigateToQuiz(quizResult)
                                  }
                                >
                                  {t("Retake")}
                                </Button>
                              )}
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          ))}
        </Stack>
      )}
      <Card>
        <CardContent>
          <Typography variant="h6">{t("Additional Practice Exams")}</Typography>
          {Object.entries(groupedAdditionalExams).map(([key, quizzes]) => (
            <Stack direction="row" alignItems="center" spacing={1}>
              <BookOutlined fontSize="small" />
              <Stack direction="row">
                <Typography variant="body2">
                  {quizzes.filter((quiz) => !!quiz.grade).length} /{" "}
                  {quizzes.length}
                </Typography>
              </Stack>
              <Typography variant="body2">
                {mapQuizCategoryToName(key)}
              </Typography>
            </Stack>
          ))}

          {Object.entries(groupedAdditionalExams).map(([key, quizzes]) => (
            <Box marginTop={4}>
              <Typography>
                {t("Extra")} {mapQuizCategoryToName(key)}
              </Typography>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("Quiz")}</TableCell>
                      <TableCell>{t("Result")}</TableCell>
                      <TableCell>{t("Grade")}</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {quizzes.map((quizResult) => (
                      <TableRow key={quizResult.id}>
                        <TableCell>
                          <Typography>{quizResult.name}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" color="text.secondary">
                            {quizResult.totalCorrectAnswers &&
                            quizResult.totalQuestions
                              ? `${quizResult.totalCorrectAnswers} / ${quizResult.totalQuestions}`
                              : t("N/A")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <GradeChip score={quizResult.grade} />
                        </TableCell>
                        {account.type === UserType.User && (
                          <TableCell>
                            {!quizResult.grade ? (
                              <Button
                                variant="outlined"
                                onClick={() => handleNavigateToQuiz(quizResult)}
                              >
                                {t("Start")}
                              </Button>
                            ) : (
                              <Button
                                onClick={() => handleNavigateToQuiz(quizResult)}
                              >
                                {t("Retake")}
                              </Button>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ))}
        </CardContent>
      </Card>
    </Stack>
  );
};

export default GradesTab;
