// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

export const trackEvent = (
  event: string,
  properties?: Record<string, unknown>
): void => {
  window.analytics.track(event, properties);
};

export const trackPage = (
  category: string,
  page: string,
  properties?: Record<string, unknown>
): void => {
  window.analytics.page(category, page, properties);
};

export const identify = (data: {
  user: {
    email: string;
    firstName: string;
    lastName: string;
  };
  metadata?: { appVersion?: string };
}): void => {
  const { user } = data;
  const metadata = data.metadata || {};
  window.analytics.identify(user.email, {
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    ...metadata,
  });
};
