import { useState } from "react";
import { useTranslation } from "react-i18next";
import { UserAccount } from "src/types/User";
import formatDateString from "src/utils/formatDateString";
import useCohorts from "src/utils/useCohorts";
import useOrganizations from "src/utils/useOrganizations"; // Import the useOrganizations hook
import useSchools from "src/utils/useSchools";
import AdminOnlyButton from "./AdminOnlyButton";

interface Props {
  users: UserAccount[];
}

const generateCSV = (data: object[]) => {
  const header = Object.keys(data[0]);
  const rows = data.map((row) =>
    header.map((key) => row[key as keyof typeof row] || "").join(",")
  );
  return [header.join(","), ...rows].join("\n");
};

const downloadCSV = (csvContent: string) => {
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `openphone_export_${formatDateString(
    new Date().toISOString(),
    "datetime"
  )}.csv`;
  link.click();
};

export default function ExportForOpenPhoneButton({ users }: Props) {
  const { t } = useTranslation();
  const { cohortsById } = useCohorts();
  const { schoolsById } = useSchools();
  const { organizationsById } = useOrganizations();
  const [loading, setLoading] = useState(false);

  const exportToCSV = async () => {
    setLoading(true);

    const allUserData = users.map((user) => {
      const cohortName = user.cohortId ? cohortsById[user.cohortId]?.name : "";
      const schoolName = user.schoolId ? schoolsById[user.schoolId]?.name : "";
      const organizationName = user.organizationId
        ? organizationsById[user.organizationId]?.name
        : "";

      return {
        "First Name": user.firstName,
        "Last Name": user.lastName,
        "Incarceration Status": user.incarcerationStatus || "",
        "Application Status": user.applicationStatus || "",
        "Enrollment Status": user.enrollmentStatus || "",
        "Employment Status": user.employmentStatus || "",
        Cohort: cohortName || "",
        Organization: organizationName || "",
        Phone: user.phone || "",
        School: schoolName || "",
        Email: user.email || "",
        City: user.city || "",
        State: user.state || "",
      };
    });

    const csvContent = generateCSV(allUserData); // Generate CSV content from the data
    downloadCSV(csvContent); // Utility function to download CSV

    setLoading(false);
  };

  return (
    <AdminOnlyButton onClick={exportToCSV} disabled={loading}>
      {t("Export for OpenPhone")}
    </AdminOnlyButton>
  );
}
