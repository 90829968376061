import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import TestimonialList from "../DashboardPage/ProfileScreen/TestimonialList";

const OnboardingInactiveState = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <Typography variant="body1">
        {t(
          "You are on the waitlist. One of our student coordinators will reach out to you soon."
        )}
      </Typography>
      <Typography variant="body1">
        {t(
          "Do you have any questions? Feel free to email us at: support@emergecareer.com"
        )}
      </Typography>
      <Typography variant="body1">
        {t("In the meantime, check out some of our success stories.")}
      </Typography>
      <TestimonialList />
    </Stack>
  );
};

export default OnboardingInactiveState;
