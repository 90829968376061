import { subHours } from "date-fns";
import { collection, getDocs, query, where } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";
import { CohortMeeting } from "src/types/CohortMeeting";
import isCohortMeetingGuard from "src/types/isCohortMeetingGuard";
import cleanFirestoreDoc from "./cleanFirestoreDoc";

export default async function fetchCohortMeetings(
  {
    organizationId,
    cohortId,
    futureOnly,
    lastMeetingDate,
  }: {
    organizationId: string;
    cohortId: string;
    futureOnly?: boolean;
    lastMeetingDate: Date;
  },
  { db }: Clients
): Promise<CohortMeeting[]> {
  const snapshot = futureOnly
    ? await getDocs(
        query(
          collection(
            db,
            "organizations",
            organizationId,
            "cohorts",
            cohortId,
            "cohortMeetings"
          ),
          where("startDateFirestoreTimestamp", ">=", subHours(new Date(), 6)),
          where("startDateFirestoreTimestamp", "<=", lastMeetingDate)
        )
      )
    : await getDocs(
        collection(
          db,
          "organizations",
          organizationId,
          "cohorts",
          cohortId,
          "cohortMeetings"
        )
      );

  const meetings = snapshot.docs
    .map(cleanFirestoreDoc)
    .filter(isCohortMeetingGuard)
    .sort(
      (a, b) =>
        new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
    );

  return meetings;
}
