import React, { useCallback, useState } from "react";

const tolerance = 1;

export default function useScrolledToBottom(startingValue: boolean) {
  const [scrolledToBottom, setScrolledToBottom] = useState(startingValue);

  const onScroll = useCallback((e: React.UIEvent<HTMLElement>) => {
    const atBottom =
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop <
      e.currentTarget.clientHeight + tolerance;
    setScrolledToBottom(atBottom);
  }, []);

  return {
    scrolledToBottom,
    onScroll,
  };
}
