import { Box, BoxProps } from "@mui/material";
import React from "react";

type Props = BoxProps & {
  children?: React.ReactNode | React.ReactNode[];
  noXMargin?: boolean;
  noYMargin?: boolean;
};

export default function PaddedLayout({
  children,
  noXMargin,
  noYMargin,
  ...rest
}: Props) {
  return (
    <Box
      {...rest}
      sx={[
        noXMargin ? { marginX: 0 } : { marginX: 4 },
        noYMargin ? { marginY: 0 } : { marginY: 2 },
        { paddingY: 1, marginX: { lg: 8 } },
      ]}
    >
      {children}
    </Box>
  );
}
