import { Alert, Box, CircularProgress, Snackbar } from "@mui/material";
import { useLiveQuery } from "dexie-react-hooks";
import React, { useContext, useEffect, useState } from "react";
import { db } from "src/api/db";
import { OnlineCard } from "src/components/Card";
import { timeout } from "src/utils";
import { useAuth } from "./AuthContext";

// Small file that we use to fetch every so often
const PING_RESOURCE = "/ping.txt";
const TIMEOUT_TIME_MS = 3000;
const onlinePollingInterval = 10000;

const checkOnlineStatus = async () => {
  const controller = new AbortController();
  const { signal } = controller;

  //
  try {
    await timeout(
      TIMEOUT_TIME_MS,
      fetch(PING_RESOURCE, {
        method: "GET",
        signal,
      })
    );
    return true;
  } catch (error) {
    // This can be because of request timed out
    // so we abort the request for any case
    controller.abort();
  }
  return false;
};

const OnlineStatusContext = React.createContext(true);

export const OnlineStatusProvider: React.FC = ({ children }) => {
  const [isOnline, setOnlineStatus] = useState<boolean>(true);
  const [isSyncing, setIsSyncing] = useState<boolean>(false);
  const [showSyncSuccess, setShowSyncSuccess] = useState<boolean>(false);
  const actions = useLiveQuery(() => db.actions.toArray());
  const authCtx = useAuth();
  const session = authCtx?.session;

  const checkStatus = async () => {
    const online = await checkOnlineStatus();
    setOnlineStatus(online);
  };

  useEffect(() => {
    window.addEventListener("offline", () => {
      setOnlineStatus(false);
    });

    // Add polling incase of slow connection
    const id = setInterval(() => {
      checkStatus();
    }, onlinePollingInterval);

    return () => {
      window.removeEventListener("offline", () => {
        setOnlineStatus(false);
      });

      clearInterval(id);
    };
  }, []);

  // When app is back online, sync changes
  // TODO: move all this logic to app.tsx
  useEffect(() => {
    if (!isOnline || !actions || !session) return;
    const sync = async () => {
      setIsSyncing(true);
      const res = await Promise.allSettled(
        actions.map(async (action): Promise<number> => {
          // do the appropriate syncing
          switch (action.type) {
            case "quiz":
              await session.setUserGrade(action.payload);
              break;
            case "resource":
              await session.setUserResourceProgress(action.id, action.payload);
              break;
            case "book":
              await session.setBookPage(action.id, action.payload);
              break;
            default:
              break;
          }
          return action.cacheId!;
        })
      );
      const fulfilled = res
        .filter((res) => res.status === "fulfilled")
        .map(
          (fulfilled) => (fulfilled as PromiseFulfilledResult<number>).value
        );
      await db.actions.bulkDelete(fulfilled);
      setIsSyncing(false);
      setShowSyncSuccess(true);
    };
    sync();
  }, [isOnline, actions, session]);

  // TODO: fix this
  // eslint-disable-next-line react/no-unstable-nested-components
  const SyncSnackbar = () => (
    <div>
      <Snackbar open={isSyncing}>
        <Alert severity="info">
          <div>
            <span>
              You&apos;re back online 🎉! We&apos;re saving the{" "}
              {actions?.length} offline changes you made.
            </span>
            <CircularProgress size={16} />
          </div>
        </Alert>
      </Snackbar>
      <Snackbar
        open={showSyncSuccess}
        autoHideDuration={3000}
        onClose={() => setShowSyncSuccess(false)}
      >
        <Alert severity="success">
          <span>Your account is all synced up 🙌!</span>
        </Alert>
      </Snackbar>
    </div>
  );

  return (
    <OnlineStatusContext.Provider value={isOnline}>
      <Box position="fixed" top={4} right={4} zIndex={10}>
        <OnlineCard isOnline={isOnline} />
      </Box>
      <SyncSnackbar />
      {children}
    </OnlineStatusContext.Provider>
  );
};

export const useOnlineStatus = () => {
  const store = useContext(OnlineStatusContext);
  return store;
};

export default useOnlineStatus;
