import { Box, BoxProps } from "@mui/material";
import React from "react";

type Props = BoxProps & {
  children?: React.ReactNode | React.ReactNode[];
};

export default function PaddedLayoutContainer({ children }: Props) {
  return (
    <Box margin="auto" maxWidth={1500}>
      {children}
    </Box>
  );
}
