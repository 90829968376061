import {
  Slider as Base,
  SliderProps as BaseProps,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import withController from "../withController";

type SliderInputProps = BaseProps & {
  onChange: Required<BaseProps>["onChange"];
  value: Required<BaseProps>["value"];
  label: string;
  explanation?: string;
};

function SliderInput({
  onChange,
  label,
  explanation,
  ...rest
}: SliderInputProps) {
  const [showExplanation, setShowExplanation] = useState(false);

  const marks = [
    { value: 1, label: "No" },
    { value: 2, label: "" },
    { value: 3, label: "Maybe" },
    { value: 4, label: "" },
    { value: 5, label: "Yes" },
  ];

  return (
    <div>
      <Typography>{label}</Typography>
      <Base
        {...rest}
        marks={marks}
        min={1}
        max={5}
        step={1}
        onChange={(e, value, activeThumb) => {
          onChange(e, value, activeThumb);
          setShowExplanation(true);
        }}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) =>
          marks.find((m) => m.value === value)?.label
        }
      />
      {showExplanation && (
        <Typography variant="body2">{explanation}</Typography>
      )}
    </div>
  );
}

export const SliderInputBase = React.forwardRef(
  SliderInput
) as typeof SliderInput;

export default withController(SliderInputBase);
