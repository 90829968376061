import { doc, updateDoc } from "firebase/firestore";
import Joi from "joi";
import { UserAccount } from "src/types/User";
import serviceFunction from "src/utils/serviceFunction";

type Data = {
  user: UserAccount;
};

const schema = Joi.object<Data>({
  user: Joi.object().required(),
});

const markUserAsCompletedOrientationLesson = serviceFunction(
  schema,
  async ({ user }, { db }): Promise<void> => {
    await updateDoc(doc(db, `users/${user.uid}`), {
      hasCompletedOrientationLesson: true,
    });
  }
);

export default markUserAsCompletedOrientationLesson;
