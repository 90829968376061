import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ListSkeleton } from "src/components/Skeleton/closet";
import { UserAccount } from "src/types/User";
import useUserUploadedDocuments from "src/utils/useUserUploadedDocuments";
import DefaultEnrollmentDocumentDescription from "./DefaultEnrollmentDocumentDescription";
import DOCUMENT_SCHEMA from "./defaultEnrollmentDocuments";
import DocumentCard from "./DocumentCard";
import OrganizationDocumentCard from "./OrganizationDocumentCard";
import useUploadedDocuments from "./useUploadedDocuments";

type Props = {
  client: UserAccount;
};

const ApplicationDocumentList = ({ client }: Props) => {
  const { t } = useTranslation();
  const { uploadDocument, findDocument } = useUploadedDocuments(client);
  const {
    loading,
    missingEmergeDocuments,
    missingOrganizationDocuments,
    submittedEmergeDocuments,
    submittedOrganizationDocuments,
  } = useUserUploadedDocuments(client);

  if (loading) return <ListSkeleton />;

  return (
    <Stack spacing={2}>
      {missingEmergeDocuments.map((docType) => (
        <Box key={docType}>
          <DocumentCard
            documentDescription={DOCUMENT_SCHEMA[docType]}
            submittedDocument={findDocument(docType)}
            handleSubmission={uploadDocument}
          >
            <DefaultEnrollmentDocumentDescription key={docType} />
          </DocumentCard>
        </Box>
      ))}

      {missingOrganizationDocuments.map((doc) => (
        <Box key={doc.uid}>
          <OrganizationDocumentCard organizationDocument={doc} user={client} />
        </Box>
      ))}

      <Typography marginTop={4}>{t("Submitted")}</Typography>
      {submittedEmergeDocuments.map((submitted) => (
        <Box key={submitted.name}>
          <DocumentCard
            documentDescription={DOCUMENT_SCHEMA[submitted.name]}
            submittedDocument={findDocument(submitted.name)}
            handleSubmission={uploadDocument}
          >
            <DefaultEnrollmentDocumentDescription key={submitted.name} />
          </DocumentCard>
        </Box>
      ))}

      {submittedOrganizationDocuments.map((doc) => (
        <OrganizationDocumentCard
          key={doc.organizationDocument.uid}
          organizationDocument={doc.organizationDocument}
          submittedDocument={doc.uploadedDocument}
          user={client}
        />
      ))}
    </Stack>
  );
};

export default ApplicationDocumentList;
