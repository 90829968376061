import { Admin } from "src/types/Admin";

export default function isAdminGuard(obj: unknown): obj is Admin {
  const admin = obj as Admin;
  return (
    typeof admin?.uid === "string" &&
    typeof admin?.firstName === "string" &&
    typeof admin?.lastName === "string" &&
    typeof admin?.phone === "string" &&
    typeof admin?.email === "string"
  );
}
