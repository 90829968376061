import { collection, getDocs } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";

import { Attendance } from "src/types/Attendance";
import isAttendanceGuard from "src/utils/isAttendanceGuard";
import cleanFirestoreDoc from "./cleanFirestoreDoc";

export default async function fetchAttendance(
  userId: string,
  { db }: Clients
): Promise<Attendance[]> {
  const snapshot = await getDocs(collection(db, "users", userId, "attendance"));

  const documents = snapshot.docs
    .map(cleanFirestoreDoc)
    .filter(isAttendanceGuard);

  return documents;
}
