import * as Sentry from "@sentry/react";
import { FirebaseError } from "firebase/app";
import { useCallback } from "react";
import parseFirebaseError from "src/pages/parseFirebaseError";
import { useSnackbarContext } from "../SnackbarProvider";

export default function useErrorHandler(): (e: unknown) => void {
  const snackbarContext = useSnackbarContext();

  const handleError = useCallback(
    (e: unknown) => {
      // eslint-disable-next-line no-console
      if (process.env.NODE_ENV === "development") console.error(e);
      if (!(e instanceof Error)) return;
      if (e instanceof FirebaseError) {
        snackbarContext.alert("error", parseFirebaseError(e));
        return;
      }
      Sentry.captureException(e);
      snackbarContext.alert("error", e.message);
    },
    [snackbarContext]
  );

  return handleError;
}
