import { createTheme, darken, lighten, PaletteMode } from "@mui/material";
import type {} from "@mui/x-data-grid/themeAugmentation";

export const shade = (color: string, p: number) =>
  `${color}${Math.round((p / 100) * 255)
    .toString(16)
    .padStart(2, "0")}`;
export const getColor = (mode: PaletteMode) =>
  mode === "light" ? darken : lighten;
export const getBGColor = (mode: PaletteMode) =>
  mode === "light" ? lighten : darken;

// default values: https://mui.com/material-ui/customization/default-theme
export const theme = createTheme({
  palette: {
    primary: {
      main: "#4B75B6",
      dark: "#002D5A",
      light: "#DCE9FF",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#F9A300",
      dark: "#825400",
      light: "#FFE9C1",
    },
    success: {
      main: "#059268",
      dark: "#004928",
      light: "#CAF2E6",
    },
    // OLD
    secondary: {
      main: "#F24029",
      dark: "#B5170E",
      light: "#FFE6EA",
    },
    info: {
      main: "#2E47BD",
      dark: "#001D92",
      light: "#E6E9F7",
    },
    error: {
      main: "#DE3535",
      dark: "#920000",
      light: "#E55D5D",
    },
    text: {
      primary: "#1E1B16",
      secondary: "rgba(0 0 0 / .6)",
    },
    // The following colors are the MUI defaults:
    // background
    // action
    // text
    // other
    // common
  },
  typography: {
    fontFamily: "'Noto Sans','Roboto', 'Helvetica', 'Arial', 'sans-serif'",
    h1: {
      fontWeight: 300, // 'light'
      fontSize: "96px",
      lineHeight: "116.7%",
      letterSpacing: "-1px",
    },
    h2: {
      fontWeight: 300, // 'light'
      fontSize: "60px",
      lineHeight: "120%",
      letterSpacing: "-0.7px",
    },
    h3: {
      fontWeight: 400, // 'regular'
      fontSize: "48px",
      lineHeight: "116.7%",
      letterSpacing: "0px",
    },
    h4: {
      fontWeight: 400, // 'regular'
      fontSize: "28px",
      lineHeight: "123.5%",
      letterSpacing: "0.1px",
    },
    h5: {
      fontWeight: 400, // 'regular'
      fontSize: "24px",
      lineHeight: "133.4%",
      letterSpacing: "0px",
    },
    h6: {
      fontWeight: 500, // 'medium'
      fontSize: "20px",
      lineHeight: "160%",
      letterSpacing: "0px",
    },
    subtitle1: {
      fontWeight: 500, // 'medium'
      fontSize: "18px",
      lineHeight: "175%",
      letterSpacing: "0px",
    },
    subtitle2: {
      fontWeight: 500, // 'medium'
      fontSize: "14px",
      lineHeight: "157%",
      letterSpacing: "0px",
    },
    body1: {
      fontWeight: 400, // 'regular'
      fontSize: "16px",
      lineHeight: "150%",
      letterSpacing: "0px",
      opacity: 0.8,
    },
    body2: {
      fontWeight: 400, // 'regular'
      fontSize: "14px",
      lineHeight: "143%",
      letterSpacing: "0.02px",
      opacity: 0.8,
    },
    caption: {
      fontWeight: 400, // 'regular'
      fontSize: "12px",
      lineHeight: "166%",
      letterSpacing: "0.2px",
    },
    overline: {
      fontWeight: 400, // 'regular'
      fontSize: "12px",
      lineHeight: "266%",
      letterSpacing: "0.6px",
      textTransform: "uppercase",
    },
    button: {
      // This applies if the button `size`="medium"
      fontWeight: 500, // 'medium'
      fontSize: "14px",
      lineHeight: "143%",
      letterSpacing: "0.02px",
    },
    // The following texts are customized through components, below:
    // Button small, medium, large
    // Avatar Initials
    // Input Label
    // Helper Text
    // Input Text
    // Tooltip
  },
  components: {
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontFamily: "Space Grotesk, sans-serif",
          fontWeight: "bold",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "8px",
        },
        sizeLarge: {
          fontWeight: 500, // 'medium'
          fontSize: "15px",
          lineHeight: "26px",
          letterSpacing: "0.2px",
          paddingBottom: "8px",
          paddingTop: "8px",
          paddingLeft: "22px",
          paddingRight: "22px",
        },
        sizeMedium: {
          fontWeight: 500, // 'medium'
          fontSize: "14px",
          lineHeight: "24px",
          letterSpacing: "0.2px",
          paddingBottom: "6px",
          paddingTop: "6px",
          paddingLeft: "16px",
          paddingRight: "16px",
        },
        sizeSmall: {
          fontWeight: 500, // 'medium'
          fontSize: "13px",
          lineHeight: "22px",
          letterSpacing: "0.2px",
          paddingBottom: "6px",
          paddingTop: "6px",
          paddingLeft: "16px",
          paddingRight: "16px",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
          "&.highlight": {
            backgroundColor: "#F9C3AC",
            transition: "background-color 1s",
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        title: {
          fontFamily: "Space Grotesk, sans-serif",
          fontWeight: "bold",
          fontSize: 24,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: "100%",
        },
        body: {
          backgroundColor: "rgb(250,250,250)",
          height: "100%",
        },
        "#root": {
          height: "100%",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: "0px 0px 40px rgba(0, 0, 0, 0.06)",
        },
        outlined: {
          borderWidth: "0.5px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.highlight": {
            backgroundColor: "#F9C3AC",
            transition: "background-color 1s",
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
          "&.highlight": {
            backgroundColor: "#F9C3AC",
            transition: "background-color 1s",
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
        icon: {
          alignItems: "center",
        },
        standardInfo: ({ theme: currentTheme }) => ({
          color: getColor(currentTheme.palette.mode)(
            currentTheme.palette.info.main,
            0.6
          ),
          background: getBGColor(currentTheme.palette.mode)(
            currentTheme.palette.info.main,
            0.9
          ),
          "& .MuiAlert-icon": {
            color: currentTheme.palette.info.main,
          },
        }),
      },
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});
