import { doc, getDoc } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";
import { BackgroundFormData } from "src/pages/BackgroundSurveyScreen/BackgroundSurveyView";
import isBackgroundGuard from "src/utils/isBackgroundGuard";

export default async function fetchBackground(
  participantId: string,
  { db }: Clients
): Promise<BackgroundFormData | undefined> {
  const snapshot = await getDoc(doc(db, `backgrounds`, participantId));
  const data = snapshot.data();

  if (!data) return undefined;

  const { releaseDate, lastReleased, ...rest } = data;

  const adjustedData = {
    ...rest,
    releaseDate: releaseDate ? new Date(releaseDate) : undefined,
    lastReleased: lastReleased ? new Date(lastReleased) : undefined,
  };

  if (!isBackgroundGuard(adjustedData)) {
    throw new Error("Invalid background data");
  }

  return adjustedData;
}
