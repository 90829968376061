import {
  collection,
  getCountFromServer,
  query,
  where,
} from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";

export default async function fetchOutstandingReimbursementCount(
  clientId: string,
  { db }: Clients
): Promise<number> {
  const queryStatement = query(
    collection(db, "users", clientId, "reimbursements"),
    where("sentReimbursement", "==", false)
  );
  const snapshot = await getCountFromServer(queryStatement);

  return snapshot.data().count;
}
