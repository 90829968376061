import { Cohort, CohortTrainingStatus, TrainingType } from "src/types/Cohort";

export default function isCohortGuard(obj: unknown): obj is Cohort {
  const cohort = obj as Cohort;

  return (
    cohort &&
    typeof cohort === "object" &&
    typeof cohort.uid === "string" &&
    typeof cohort.name === "string" &&
    typeof cohort.organizationId === "string" &&
    typeof cohort.startDate === "string" &&
    Object.values(TrainingType).includes(cohort.trainingType) &&
    Object.values(CohortTrainingStatus).includes(cohort.status)
  );
}
