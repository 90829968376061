export default function formatNumberToE164(
  phoneNumber: unknown
): string | null {
  // Check if the input is a string
  if (typeof phoneNumber !== "string") {
    return null;
  }

  // Remove non-numeric characters
  const cleaned = phoneNumber.replace(/\D/g, "");

  // E.164 format requires a number to be up to 15 digits long
  if (cleaned.length < 10 || cleaned.length > 15) {
    return null;
  }

  // Check if the number has 10 digits, assuming it's a US phone number
  if (cleaned.length === 10) {
    // Prepend the US country code
    return `+1${cleaned}`;
  }

  // Check for an international dialing prefix (like "011" or "00")
  const intlPrefixMatch = cleaned.match(/^(011|00)/);
  const numberWithoutIntlPrefix = intlPrefixMatch
    ? cleaned.slice(intlPrefixMatch[0].length)
    : cleaned;

  // Ensure the number is within the correct length range for E.164
  if (
    numberWithoutIntlPrefix.length < 10 ||
    numberWithoutIntlPrefix.length > 15
  ) {
    return null;
  }

  // Ensure it starts with a plus sign
  const e164Number = numberWithoutIntlPrefix.startsWith("+")
    ? numberWithoutIntlPrefix
    : `+${numberWithoutIntlPrefix}`;

  // Validate that the number follows the E.164 format
  const isValidE164 = /^\+\d{10,15}$/.test(e164Number);

  return isValidE164 ? e164Number : null;
}
