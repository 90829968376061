/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Box,
  Checkbox,
  FormControlLabel,
  Link,
  Typography,
} from "@mui/material";

export default function AcknowledgmentAuthorization() {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Acknowledgment and Authorization for Background Check
      </Typography>
      <Typography variant="body1" gutterBottom>
        I acknowledge receipt of the separate documents entitled{" "}
        <strong>Disclosure Regarding Background Investigation</strong> and{" "}
        <strong>
          A Summary of Your Rights Under the Fair Credit Reporting Act
        </strong>{" "}
        and certify that I have read and understand both of those documents. I
        hereby authorize the obtaining of “consumer reports” and/or
        “investigative consumer reports” by the Emerge Career - Staging (the
        “Company”) at any time after receipt of this authorization and
        throughout my employment, if applicable. To this end, I hereby authorize
        any law enforcement agency, administrator, state or federal agency,
        institution, school or university (public or private), information
        service bureau, past or present employers, motor vehicle records
        agencies, or insurance company to furnish any and all background
        information requested by Checkr, Inc., One Montgomery Street, Suite
        2400, San Francisco, CA 94104 | (844) 824-3257
        {/* |{" "} <Link
          href="https://emergecareer.com/help"
          target="_blank"
          rel="noopener noreferrer"
        >
          Help Center
        </Link>{" "}
        |{" "}
        <Link
          href="https://emergecareer.com/candidate-portal"
          target="_blank"
          rel="noopener noreferrer"
        >
          Candidate Portal
        </Link>{" "} */}
        and/or the Company. I agree that a facsimile (“fax”), electronic, or
        photographic copy of this Authorization shall be as valid as the
        original.
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>New York residents/candidates only:</strong> Upon request, you
        will be informed whether or not a consumer report was requested by the
        Employer, and if such report was requested, informed of the name and
        address of the consumer reporting agency that furnished the report. You
        have the right to inspect and receive a copy of any investigative
        consumer report requested by the Employer by contacting the consumer
        reporting agency identified above directly. By signing below, you
        acknowledge receipt of{" "}
        <Link
          // href="https://www.nycourts.gov/courts/nyc/criminal/nyccriminal-pdfs/Fair_Chance_Act.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Article 23-A of the New York Correction Law
        </Link>
        .
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>New York City residents/candidates only:</strong> You
        acknowledge and authorize the Employer to provide any notices required
        by federal, state or local law to you at the address(es) and/or email
        address(es) you provided to the Employer.
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>Washington State candidates only:</strong> You also have the
        right to request from the consumer reporting agency a written summary of
        your rights and remedies under the Washington Fair Credit Reporting Act.
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>Minnesota and Oklahoma candidates only:</strong> Please check
        the box below if you would like to receive a copy of a consumer report
        if one is obtained by the Company.
      </Typography>
      <FormControlLabel
        control={<Checkbox name="minnesotaOklahomaCopy" />}
        label="I would like to receive a copy of my consumer report."
      />
      <Typography variant="body1" gutterBottom>
        <strong>San Francisco candidates only:</strong> Please click below for
        the San Francisco Fair Chance Act Notice. -{" "}
        <Link
          // href="https://sfgov.org/olse/sites/default/files/FCO%20poster%20English.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          English
        </Link>{" "}
        -{" "}
        <Link
          // href="https://sfgov.org/olse/sites/default/files/FCO%20poster%20Spanish.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Spanish
        </Link>{" "}
        -{" "}
        <Link
          // href="https://sfgov.org/olse/sites/default/files/FCO%20poster%20Tagalog.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Tagalog
        </Link>{" "}
        -{" "}
        <Link
          // href="https://sfgov.org/olse/sites/default/files/FCO%20poster%20Chinese.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Chinese
        </Link>
        .
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>Los Angeles candidates only:</strong> Please click{" "}
        <Link
          // href="https://bca.lacity.org/uploads/ffda/Final_FF_DLSE%20Notice%20to%20Applicants%20and%20Employees%20for%20Private%20Employers%20poster.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </Link>{" "}
        for the Los Angeles Notice to Candidates and Employees for Private
        Employers.
      </Typography>
    </Box>
  );
}
