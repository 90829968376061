import { Chip } from "@mui/material";
import { green, red } from "@mui/material/colors";
import { useTranslation } from "react-i18next";

export default function TrueFalseChip({ value }: { value?: boolean }) {
  const { t } = useTranslation();
  return (
    <Chip
      label={value ? t("True") : t("False")}
      sx={{
        backgroundColor: value ? green[900] : red[900],
        color: "white",
      }}
    />
  );
}
