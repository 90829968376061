import { CircularProgress, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { differenceInDays, format, isAfter } from "date-fns";
import React from "react"; // Import React to use `createElement`
import { StudentPlanMilestone } from "src/types/StudentPlan";

interface MilestoneColumnProps {
  field: string;
  headerName: string;
}

export default function createMilestoneColumnUtil({
  field,
  headerName,
}: MilestoneColumnProps): GridColDef {
  return {
    field,
    headerName,
    disableColumnMenu: true,
    valueGetter: (milestone?: StudentPlanMilestone | "loading") => {
      if (milestone === "loading") return "loading";
      if (!milestone) return undefined;
      if (milestone.completedAt) return "completed";
      return milestone.date
        ? format(new Date(milestone.date), "MM/dd/yy")
        : undefined;
    },
    renderCell: (params: { value?: "completed" | "loading" | Date }) => {
      if (params.value === "loading") {
        return React.createElement(CircularProgress, {
          size: 12,
          variant: "indeterminate",
        });
      }

      if (params.value === "completed") {
        return React.createElement("span", null, "✅");
      }

      if (params.value instanceof Date) {
        const isOverdue = isAfter(new Date(), params.value);
        const isUpcoming = differenceInDays(params.value, new Date()) <= 7;

        return React.createElement(
          Typography,
          {
            variant: "body2",
            alignSelf: "center",
            display: "flex",
            margin: "auto",
            color: isOverdue
              ? "error"
              : isUpcoming
              ? "darkblue"
              : "text.secondary",
          },
          format(params.value, "MMM d")
        );
      }

      return React.createElement("span", null, "");
    },
  };
}
