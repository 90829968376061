import * as Sentry from "@sentry/react";
import { useState } from "react";
import { db, purgeDatabase } from "src/api/db";
import { ResourceRecord } from "src/clients/schema";
import Button from "src/components/Button";
import { PaddedLayout } from "src/components/Layout";
import Template from "src/components/Layout/Template";
import { ScreenTitle } from "src/components/Typography";
import downloadFile from "src/utils/donwloadFile";
import offlineModeEnabled from "src/utils/offlineModeEnabled";
import OfflineSetupItemCard, { DownloadStatus } from "./OfflineSetupItemCard";

export const LOCAL_STORAGE_OFFLINE_MODE_KEY = "LOCAL_STORAGE_OFFLINE_MODE_KEY";

type AssetType = "image" | "video" | "resource";

type OfflineAsset =
  | { url: string; variant: AssetType }
  | ({
      url: string;
      variant: "resource";
    } & ResourceRecord);

export default function OfflineSetupPage(): JSX.Element {
  const [isOfflineMode, setIsOfflineMode] = useState(offlineModeEnabled());
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadedItems, setDownloadedItems] = useState<{
    [url: string]: { status: DownloadStatus };
  }>({});

  // return [
  //   ...OFFLINE_UNITS.map((u) => ({
  //     url: u.thumbnailUri,
  //     variant: "image" as AssetType,
  //   })),
  //   ...TESTIMONIALS.map((t) => [
  //     { url: t.uri, variant: "video" as AssetType },
  //     { url: t.imgUri, variant: "video" as AssetType },
  //   ]).flat(),
  //   ...OFFLINE_RESOURCES.map((r) => ({
  //     url: r.uri,
  //     variant: "resource" as AssetType,
  //     ...r,
  //   })),
  // ];
  const offlineAssets: OfflineAsset[] = [];

  const handleDownload = async (asset: OfflineAsset) => {
    try {
      const blob = await downloadFile(asset.url);
      if (asset.variant === "resource") {
        // TODO: rework this logic
        // db.addVideo({ blob, ...(asset as Resource) });
      } else {
        db.upsertAsset({ blob, url: asset.url });
      }
      setDownloadedItems((items) => ({
        ...items,
        [asset.url]: { status: "success" },
      }));
    } catch (err) {
      Sentry.captureException(err);
      // eslint-disable-next-line no-alert
      window.alert(
        err instanceof Error ? JSON.stringify(err) : "Unknown error"
      );
      setDownloadedItems((items) => ({
        ...items,
        [asset.url]: { status: "error" },
      }));
    }
  };

  const handleClick = async () => {
    localStorage.setItem(LOCAL_STORAGE_OFFLINE_MODE_KEY, "true");
    setIsOfflineMode(true);
    setIsDownloading(true);
    try {
      await Promise.all(
        offlineAssets.map(async (asset) => {
          setDownloadedItems((items) => ({
            ...items,
            [asset.url]: { status: "downloading" },
          }));
          await handleDownload(asset);
        })
      );

      // TODO: decide if we download the entire first chapter
      setIsDownloading(false);
      // TODO: move this back once we handle error
      // window.location.reload();
    } catch (err) {
      setIsDownloading(false);
    }
  };

  const handleDeactivation = () => {
    localStorage.removeItem(LOCAL_STORAGE_OFFLINE_MODE_KEY);
    setIsOfflineMode(false);
  };

  return (
    <Template>
      <PaddedLayout>
        <div className="flex flex-col gap-4">
          <ScreenTitle>Offline Setup</ScreenTitle>
          <p>
            Emerge Career can seamless operate in offline environment. All it
            takes is to hit download button.
          </p>
          <div className="flex gap-4">
            {isOfflineMode ? (
              <Button onClick={handleDeactivation} variant="outlined">
                Deactive Offline
              </Button>
            ) : (
              <Button disabled={isDownloading} onClick={handleClick}>
                Activate Offline
              </Button>
            )}
            <Button
              variant="outlined"
              onClick={async () => {
                await purgeDatabase();
                window.location.reload();
              }}
            >
              Clear storage
            </Button>
          </div>
          <div className="flex flex-col gap-4">
            {offlineAssets.map((asset) => (
              <OfflineSetupItemCard
                key={asset.url}
                url={asset.url}
                onRetry={async () => {
                  setDownloadedItems((items) => ({
                    ...items,
                    [asset.url]: { status: "downloading" },
                  }));
                  await handleDownload(asset);
                }}
                downloadStatus={
                  downloadedItems[asset.url]?.status || "not_started"
                }
              />
            ))}
          </div>
        </div>
      </PaddedLayout>
    </Template>
  );
}
