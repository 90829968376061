// eslint-disable-next-line max-classes-per-file
import Joi from "joi";
import { Clients } from "../contexts/AppContext";

export type ServiceFunctionContext = Clients;

type ServiceFunction<D, R> = (
  data: D,
  context: ServiceFunctionContext
) => Promise<R>;

/**
 * Tags any error that should be reported back to a user.
 * Everything else can use the standard `Error`.
 */
export class UserError extends Error {
  constructor(public code: string, message: string) {
    super(message);
  }
}

export class DataLockedError extends UserError {
  constructor(message: string) {
    super("423", message);
  }
}

export default function serviceFunction<Data, Response>(
  schema: Joi.Schema<Data>,
  handler: ServiceFunction<Data, Response>
): ServiceFunction<Data, Response> {
  return async (data, context) => {
    const result = schema.validate(data, { stripUnknown: true });
    if (result.error) {
      throw new UserError("422", result.error.details[0].message);
    }
    const res = await handler(result.value, context);
    return res;
  };
}
