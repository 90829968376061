import { ResourceRecord, UnitRecord } from "src/clients/schema";
import { UserResourceProgress } from "src/types/User";

export default function calculateUnitsProgress(
  units: (Pick<UnitRecord, "id" | "order" | "name"> & {
    resources: Pick<ResourceRecord, "id">[];
  })[],
  progress: {
    [resourceId: string]: Pick<
      UserResourceProgress,
      "progressFraction" | "hasCompleted"
    >;
  }
) {
  const totalSeen = units.reduce(
    (prev, curr) =>
      prev +
      curr.resources.reduce(
        (prev, curr) =>
          prev +
          (progress &&
          (progress[curr.id]?.progressFraction >= 0.8 ||
            progress[curr.id]?.hasCompleted)
            ? 1
            : 0),
        0
      ),
    0
  );

  const totalVideos = units.reduce(
    (prev, curr) => prev + curr.resources.length,
    0
  );

  const completionRate = Math.round((totalSeen / totalVideos) * 100);

  const unitProgress = units.map((unit) => {
    const seen = unit.resources.reduce(
      (prev, resource) =>
        prev +
        (progress &&
        (progress[resource.id]?.progressFraction > 0.8 ||
          progress[resource.id]?.hasCompleted)
          ? 1
          : 0),
      0
    );

    const total = unit.resources.length;

    return {
      unitName: unit.name,
      unitId: unit.id,
      totalVideos: total,
      totalSeen: seen,
      completionRate: seen / total,
    };
  });

  return { totalVideos, totalSeen, completionRate, unitProgress };
}
