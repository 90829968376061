import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import Lottie from "lottie-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PartyPopperLottie from "src/assets/Lotties/PartyPopper.json";
import { ScreenTitle } from "src/components/Typography";
import { useAppContext } from "src/contexts/AppContext";
import createConsent from "src/firebase/createConsent";
import { useCurrentUser } from "src/SessionBoundary";
import { useSnackbarContext } from "src/SnackbarProvider";
import { ConsentType } from "src/types/Consent";
import useBackgroundProfile from "src/utils/useBackgroundProfile";
import useErrorHandler from "src/utils/useErrorHandler";
import { amplitudeClickHandler } from "../OnboardingScreen/amplitude";
import SuccessScreen from "../SuccessScreen";
import LegalConsentForm, { ConsentFormData } from "./ConsentForm";

const stepLabels: Record<ConsentType, string> = {
  fcra: "Your Rights",
  background_disclosure: "Disclosure",
  background_authorization: "Authorization",
  terms_of_service: "Terms of Service",
  privacy_policy: "Privacy Policy",
};

export const consentSteps: ConsentType[] = [
  "fcra",
  "background_disclosure",
  "background_authorization",
];

const getStepIndex = (stepName: ConsentType): number =>
  consentSteps.indexOf(stepName);

export default function ConsentScreen() {
  const { t } = useTranslation();
  const [step, setStep] = useState<ConsentType>("fcra");
  const [finished, setFinished] = useState(false);
  const user = useCurrentUser();
  const navigate = useNavigate();
  const snackbarContext = useSnackbarContext();
  const { hasEligibleApplicationScore } = useBackgroundProfile(user);

  const errorHandler = useErrorHandler();
  const { clients } = useAppContext();

  const handleConsent = async (
    input: ConsentFormData & {
      consentText: string;
      type: ConsentType;
      version: string;
    },
    onSuccess: () => void
  ) => {
    await createConsent({ user, ...input }, clients)
      .then(onSuccess)
      .catch(errorHandler);
  };

  const navigateToOrientationLesson = () => {
    snackbarContext.alert(
      "success",
      t(`Awesome! Just a short video, and you'll be all set.`)
    );

    navigate(`/onboarding/orientation-lesson`);
  };

  const handleRemindMeLater = () => {
    snackbarContext.alert(
      "warning",
      t(`No worries! We will remind you later.`)
    );

    navigate("/");
  };

  if (!finished) {
    return (
      <>
        <ScreenTitle>{stepLabels[step]}</ScreenTitle>

        <Box width="100%" marginY={2}>
          <LinearProgress
            value={(getStepIndex(step) * 100) / consentSteps.length}
            variant="determinate"
          />
          <Stack direction="row">
            <Typography variant="body2" color="text.secondary">
              {`${Math.round(
                (getStepIndex(step) * 100) / consentSteps.length
              )}%`}
            </Typography>
          </Stack>
        </Box>
        {step === "fcra" && (
          <LegalConsentForm
            type="fcra"
            onSubmit={(data) =>
              handleConsent(data, () => setStep("background_disclosure"))
            }
          />
        )}
        {step === "background_disclosure" && (
          <LegalConsentForm
            type="background_disclosure"
            onSubmit={(data) =>
              handleConsent(data, () => setStep("background_authorization"))
            }
          />
        )}
        {step === "background_authorization" && (
          <LegalConsentForm
            type="background_authorization"
            onSubmit={(data) => handleConsent(data, () => setFinished(true))}
          />
        )}
      </>
    );
  }

  if (!hasEligibleApplicationScore) {
    navigate("/");
  }

  return (
    <SuccessScreen
      illustration={{
        type: "react",
        component: (
          <Box
            sx={{
              height: {
                xs: 200,
                sm: 250,
                md: 400,
              },
            }}
          >
            <Lottie animationData={PartyPopperLottie} />
          </Box>
        ),
      }}
      title={t("You pre-qualify!")}
      content={t(
        "Good news! It looks like you’re eligible for a training scholarship based on what you shared."
      )}
      okButtonText={t(`Let's do this!`)}
      onOkButtonClick={amplitudeClickHandler(
        "Consent Success Screen: Clicked on Continue Now",
        navigateToOrientationLesson
      )}
      secondaryButton={{
        text: t("I don't have time now, but I can do it later"),
        onClick: amplitudeClickHandler(
          "Consent Success Screen: Clicked on Continue Later",
          handleRemindMeLater
        ),
      }}
    />
  );
}
