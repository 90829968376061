enum MilestoneType {
  MEDICAL_EXAM = "medical_exam",
  THEORY_TRAINING_COMPLETION = "finish_theory_training",
  THEORY_EXAM = "theory_test",
  DRUG_TEST = "drug_test",
  SKILLS_TRAINING_START = "start_skills_training",
  SKILLS_TRAINING_END = "end_skills_training",
  LICENSE_EXAM = "license_exam",
  OTHER = "other",
}

export default MilestoneType;
