import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CircularProgress,
  Collapse,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PaddedLayout } from "src/components/Layout";
import { Header32 } from "src/components/Typography";
import { useAppContext } from "src/contexts/AppContext";
import fetchActiveCohorts from "src/firebase/fetchActiveCohorts";
import fetchStudentsByCoach from "src/firebase/fetchStudentsByCoach";
import SkillsTrainingTable from "src/pages/AdminScreen/SkillsTrainingTable";
import AppSkeleton from "src/pages/AppSkeleton";
import AdminActionItemList from "src/pages/ClientScreen/AdminActionItemList";
import { useAdmin } from "src/SessionBoundary";
import { Admin } from "src/types/Admin";
import { Cohort } from "src/types/Cohort";
import { UserAccount } from "src/types/User";
import { getFullName } from "src/utils";
import isUserAccountDeactivated from "src/utils/isUserAccountDeactivated";
import useAdminsById from "src/utils/useAdminsById";
import useErrorHandler from "src/utils/useErrorHandler";
import useTimelineEventCountByUserId from "src/utils/useTimelineEventCountByUserId";
import useUsersExamProgressCount from "src/utils/useUsersExamProgressCount";
import useUsersLessonProgressCount from "src/utils/useUsersLessonProgressCount";
import useUsersMilestonesById from "src/utils/useUsersMilestonesById";
import CoachSegmentPriorityCard from "./CoachSegmentPriorityCard";
import MissingReimbursements from "./MissingReimbursements";
import splitUsersByPriority from "./splitUsersByPriority";

export default function CaseloadScreen() {
  const admin = useAdmin();
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [users, setUsers] = useState<UserAccount[]>([]);
  const errorHandler = useErrorHandler();
  const { getEngagementRatioByUser, loading: engagementLoading } =
    useTimelineEventCountByUserId(users);
  const { loading: lessonsLoading, getLessonProgress } =
    useUsersLessonProgressCount(users);

  const { loading: examsLoading, getExamProgress } =
    useUsersExamProgressCount(users);

  const {
    loading: milestonesLoading,
    getOverdueMilestones,
    getUpcomingMilestones,
  } = useUsersMilestonesById(users);
  const [activeCohorts, setActiveCohorts] = useState<Cohort[]>([]);
  const { clients } = useAppContext();
  const [isSkillsTrainingCardExpanded, setIsSkillsTrainingCardExpanded] =
    useState(false);

  const { admins, loading: loadingAdmins } = useAdminsById(); // Using the hook
  const [selectedAdmin, setSelectedAdmin] = useState<Admin>(admin); // Default initial selectedAdmin is the current admin

  // Fetch cohorts
  useEffect(() => {
    fetchActiveCohorts(clients).then(setActiveCohorts).catch(errorHandler);
  }, [clients, errorHandler]);

  // Fetch students when the selected admin changes
  useEffect(() => {
    setLoadingUsers(true);
    fetchStudentsByCoach({ coach: selectedAdmin }, clients)
      .then(setUsers)
      .catch(errorHandler)
      .finally(() => setLoadingUsers(false));
  }, [selectedAdmin, clients, errorHandler]);

  const cohortsById = useMemo(() => {
    const cohortDict: Record<string, Cohort> = {};
    activeCohorts.forEach((cohort) => {
      cohortDict[cohort.uid] = cohort;
    });
    return cohortDict;
  }, [activeCohorts]);

  const isActiveCohort = useCallback(
    (cohortId: string): boolean => !!cohortsById[cohortId],
    [cohortsById]
  );

  const groupedUsers = useMemo(() => {
    if (
      lessonsLoading ||
      milestonesLoading ||
      engagementLoading ||
      loadingUsers ||
      examsLoading
    )
      return undefined;

    const filtered = users.filter(
      (user) =>
        !isUserAccountDeactivated(user) &&
        user.applicationStatus !== "on_hold" &&
        user.cohortId &&
        isActiveCohort(user.cohortId)
    );

    return splitUsersByPriority(filtered, {
      getOverdueMilestones,
      getUpcomingMilestones,
      getEngagementRatioByUser,
      getLessonProgress,
      getExamProgress,
    });
  }, [
    loadingUsers,
    lessonsLoading,
    milestonesLoading,
    engagementLoading,
    examsLoading,
    users,
    getOverdueMilestones,
    getUpcomingMilestones,
    getEngagementRatioByUser,
    getExamProgress,
    getLessonProgress,
    isActiveCohort,
  ]);

  if (loadingAdmins || !groupedUsers) return <AppSkeleton />;

  return (
    <Box>
      <PaddedLayout>
        <Stack spacing={2}>
          {/* Admin Select Dropdown */}
          <Box>
            <FormControl>
              <InputLabel>{t("Select Admin")}</InputLabel>
              <Select
                value={selectedAdmin.uid}
                onChange={(e) =>
                  setSelectedAdmin(
                    admins.find((admin) => admin.uid === e.target.value)!
                  )
                }
              >
                {admins.map((admin) => (
                  <MenuItem key={admin.uid} value={admin.uid}>
                    <Box display="flex" gap={1} alignItems="center">
                      <Avatar src={admin.photoUrl} />
                      <Typography>{getFullName(admin)}</Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          {/* Show loading spinner while fetching students */}
          {loadingUsers ? (
            <CircularProgress />
          ) : (
            <>
              <Stack marginBottom={4}>
                <Header32 marginTop={4}>{t("Priority")}</Header32>
                <CoachSegmentPriorityCard
                  title="Job Searching"
                  users={groupedUsers.jobSearching || []}
                  cohortsById={cohortsById}
                />
                <Card>
                  <CardActionArea
                    onClick={() =>
                      setIsSkillsTrainingCardExpanded((curr) => !curr)
                    }
                  >
                    <CardHeader
                      title="Skills Training"
                      subheader={t("Total Students: {{ totalStudents }}", {
                        totalStudents:
                          groupedUsers?.skillsTraining?.length || 0,
                      })}
                    />
                  </CardActionArea>
                  <CardContent>
                    <Collapse
                      in={isSkillsTrainingCardExpanded}
                      timeout="auto"
                      unmountOnExit
                    >
                      <SkillsTrainingTable
                        users={groupedUsers.skillsTraining}
                      />
                    </Collapse>
                  </CardContent>
                </Card>
              </Stack>

              <Stack spacing={2}>
                <Header32 marginTop={4}>{t("Theory Training")}</Header32>

                <CoachSegmentPriorityCard
                  title={`Test-Ready (${groupedUsers.readyToTest?.length})`}
                  subtitle="Completed all mandatory lessons and more than 14 required exams"
                  users={groupedUsers.readyToTest || []}
                  cohortsById={cohortsById}
                />
                <CoachSegmentPriorityCard
                  title={`Proficient (${groupedUsers.practiceExams?.length})`}
                  subtitle="Watched all videos lessons but completed less than 14 practice exams"
                  users={groupedUsers.practiceExams || []}
                  cohortsById={cohortsById}
                />
                <CoachSegmentPriorityCard
                  title={`Advanced (${groupedUsers.advanced?.length})`}
                  subtitle="More than 75% of lessons completed"
                  users={groupedUsers.advanced || []}
                  cohortsById={cohortsById}
                />
                <CoachSegmentPriorityCard
                  title={`Newcomers (${groupedUsers.newcomers?.length})`}
                  subtitle="Application status changed over the past 7 days"
                  users={groupedUsers.newcomers || []}
                  cohortsById={cohortsById}
                />
                <CoachSegmentPriorityCard
                  title={`Beginners (${groupedUsers.beginners?.length})`}
                  subtitle="They've been with us for more than 7 days but have completed less than 75% of lessons"
                  users={groupedUsers.beginners || []}
                  cohortsById={cohortsById}
                />
              </Stack>
            </>
          )}
        </Stack>

        <AdminActionItemList />
        <MissingReimbursements users={users} />
      </PaddedLayout>
    </Box>
  );
}
