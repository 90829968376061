type SortByCallback<T> = (item: T) => string | number;

type SortDirection = "asc" | "desc";

export default function sortBy<T, K extends keyof T>(
  array: T[],
  callback: K | SortByCallback<T>,
  direction: SortDirection = "asc"
): T[] {
  const getValue = (item: T) => {
    if (typeof callback === "string") {
      return item[callback];
    }

    if (typeof callback === "function") {
      return callback(item);
    }

    throw new Error("Sort by is not using a valid callback");
  };

  const sortable = [...array];

  return sortable.sort((a, b) => {
    const sortByA = getValue(a);
    const sortByB = getValue(b);

    let result = 0;

    if (sortByA < sortByB) {
      result = -1;
    } else if (sortByA > sortByB) {
      result = 1;
    }

    return direction === "asc" ? result : -result;
  });
}
