import { addDoc, collection, getDoc } from "firebase/firestore";
import Joi from "joi";
import { ChatMessage } from "src/pages/DashboardPage/AdminParticipantChatTab/ChatMessage";
import isChatMessageGuard from "src/pages/DashboardPage/AdminParticipantChatTab/isChatMessageGuard";
import serviceFunction from "src/utils/serviceFunction";
import cleanFirestoreDoc from "./cleanFirestoreDoc";
import generateFirestoreTimestamps from "./generateFirestoreTimestamps";

export type CreateChatMessageData = Pick<
  ChatMessage,
  "participantId" | "senderId" | "senderType" | "text"
>;

const schema = Joi.object<CreateChatMessageData>({
  participantId: Joi.string().required(),
  senderId: Joi.string().required(),
  senderType: Joi.string().valid("participant", "admin").required(),
  text: Joi.string().required(),
});

const createChatMessage = serviceFunction(
  schema,
  async (data, { db }): Promise<ChatMessage> => {
    const doc = await addDoc(
      collection(db, "users", data.participantId, "messages"),
      {
        ...data,
        ...generateFirestoreTimestamps(new Date(), "createdAt"),
      }
    );

    const chatMessage = cleanFirestoreDoc(await getDoc(doc));

    if (!isChatMessageGuard(chatMessage)) {
      throw new Error("Something went wrong");
    }

    return chatMessage;
  }
);

export default createChatMessage;
