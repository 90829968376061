import { Create } from "@mui/icons-material";
import { Stack, Typography, useTheme } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SubmitButton from "src/components/Button/SubmitButton";
import DateInput from "src/components/Input/DateInput";
import TextInput from "src/components/Input/TextInput";
import { ScreenTitle } from "src/components/Typography";
import {
  AdminActionItem,
  CreateAdminActionItemInput,
} from "src/types/AdminActionItem";

export type AdminActionItemFormData = Pick<
  CreateAdminActionItemInput,
  "title" | "dueDate"
>;

type Props = {
  onSubmit: (data: AdminActionItemFormData) => void;
  defaultValues?: Partial<AdminActionItem> & { dueDate: Date };
};

const AdminActionItemForm = ({ onSubmit, defaultValues }: Props) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<AdminActionItemFormData>({
    mode: "onTouched",
    defaultValues: defaultValues || {},
  });
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={theme.spacing(2)}>
        <Stack spacing={theme.spacing(1)}>
          <ScreenTitle>{t("Create an Action Item")}</ScreenTitle>
          <Typography variant="body1">
            {t("An action item is a task assigned to a user with a due date.")}
          </Typography>
        </Stack>
        <TextInput
          control={control}
          name="title"
          label={t("Title")}
          rules={{
            required: { value: true, message: t("Title is required") },
          }}
        />
        <DateInput
          control={control}
          name="dueDate"
          label={t("Due Date")}
          rules={{
            required: { value: true, message: t("Due Date is required") },
          }}
          disablePast
        />
        <SubmitButton
          loading={isSubmitting}
          size="large"
          startIcon={<Create />}
        >
          {t("Create")}
        </SubmitButton>
      </Stack>
    </form>
  );
};

export default AdminActionItemForm;
