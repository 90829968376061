import { DeleteOutline } from "@mui/icons-material";
import { Button, ButtonProps } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ConfirmDialog from "../ConfirmDialog";

type DeleteButtonWithConfirmDialogProps = {
  onDelete: () => void; // Callback function that triggers the delete action
  title?: string;
  description?: string;
  buttonLabel?: string; // Optional label for the button, defaults to "Delete"
  buttonVariant?: ButtonProps["variant"];
};

const DeleteButtonWithConfirmDialog = ({
  onDelete,
  title,
  description,
  buttonLabel,
  buttonVariant,
}: DeleteButtonWithConfirmDialogProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleConfirmDelete = () => {
    onDelete(); // Trigger the delete action
    handleCloseDialog(); // Close the dialog after confirming
  };

  return (
    <>
      <Button
        variant={buttonVariant || "text"}
        color="error"
        startIcon={<DeleteOutline />}
        onClick={handleOpenDialog}
      >
        {buttonLabel || t("Delete")}
      </Button>

      <ConfirmDialog
        open={open}
        title={title || t("Confirm Deletion")}
        description={
          description ||
          t(
            "Are you sure you want to delete this? This action cannot be undone."
          )
        }
        onConfirm={handleConfirmDelete}
        onCancel={handleCloseDialog}
      />
    </>
  );
};

export default DeleteButtonWithConfirmDialog;
