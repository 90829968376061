import EventIcon from "@mui/icons-material/Event";
import { FormLabel, Grid, InputAdornment } from "@mui/material";
import {
  DatePicker,
  DatePickerProps as BaseProps,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useState } from "react";
import withController from "../withController";

type ResolvedBaseProps = BaseProps<Date>;
type ManagedProps = "renderInput" | "defaultValue" | "sx" | "open";
export type DateInputProps = Omit<ResolvedBaseProps, ManagedProps> & {
  onChange: Required<ResolvedBaseProps>["onChange"];
  prompt?: string;
  value: Required<ResolvedBaseProps>["value"];
  error?: boolean;
  helperText?: string;
};

export function DateInputBase({
  value,
  error,
  helperText,
  prompt,
  ...rest
}: DateInputProps) {
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Grid container alignItems={{ xs: "flex-start", sm: "center" }}>
      {prompt && (
        <Grid item xs={12} sm={6}>
          <FormLabel>{prompt}</FormLabel>
        </Grid>
      )}
      <Grid item xs={12} sm={prompt ? 6 : 12}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            {...rest}
            value={value || null}
            open={open}
            onClose={onClose}
            slotProps={{
              textField: {
                placeholder: "mm/dd/yyyy",
                helperText,
                error,
                sx: { width: 1 },
                onClick: () => setOpen(true),
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <EventIcon />
                    </InputAdornment>
                  ),
                },
              },
            }}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
}

export default withController(DateInputBase);
