import { useNavigate, useParams } from "react-router-dom";
import ResourceView from "./ResourceView";

const ResourceScreen = () => {
  const { id: resourceId } = useParams<{ id: string }>();
  const navigate = useNavigate();

  if (!resourceId) throw new Error("Missing ID parameter");

  // TODO: add correct navigation back to unit? Or maybe not if this is ok?
  return <ResourceView resourceId={resourceId} onBack={() => navigate("/")} />;
};

export default ResourceScreen;
