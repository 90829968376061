import { ArticleOutlined, CheckCircle } from "@mui/icons-material";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { QuizRecord } from "src/clients/schema";
import Button from "src/components/Button";
import { Header20 } from "src/components/Typography";
import mapQuizCategoryToName from "src/pages/ClientScreen/mapQuizCategoryToName";

type Props = {
  category: string;
  exams: (Pick<QuizRecord, "id"> & { hasPassed: boolean })[];
};

export default function ExamCategoryCard({ exams, category }: Props) {
  const { t } = useTranslation();
  const passed = exams.filter((exam) => exam.hasPassed).length;
  const navigate = useNavigate();
  const handleClick = () => {
    const nextExam = exams.find((exam) => !exam.hasPassed);

    if (nextExam) navigate(`/quizzes/${nextExam.id}`);
    else navigate(`/quizzes?category=${category}`);
  };

  if (passed >= exams.length)
    return (
      <Card
        onClick={handleClick}
        sx={{ cursor: "hover", ":hover": { opacity: 0.8 } }}
      >
        <CardContent>
          <Stack
            spacing={1}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack spacing={1}>
              <Box alignItems="center" display="flex">
                <CheckCircle color="success" />
                <Typography variant="body2" marginLeft={1}>
                  {passed} / {exams.length} {t("completed")}
                </Typography>
              </Box>

              <Header20>
                {mapQuizCategoryToName(category)} {t("Exams")}
              </Header20>
            </Stack>

            <Button onClick={() => navigate(`/quizzes?category=${category}`)}>
              {t("Review")}
            </Button>
          </Stack>
        </CardContent>
      </Card>
    );

  return (
    <Card
      onClick={handleClick}
      sx={{ cursor: "hover", ":hover": { opacity: 0.8 } }}
    >
      <CardContent>
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack spacing={1}>
            <Typography variant="body2">
              {passed} / {exams.length} {t("completed")}
            </Typography>
            <Header20>
              {mapQuizCategoryToName(category)} {t("Exams")}
            </Header20>
            <Stack spacing={1} direction="row" alignItems="center">
              <ArticleOutlined />
              <Typography variant="body2">
                {exams.length} {t("practice exams")}
              </Typography>
            </Stack>
          </Stack>

          <Button>{t("Start Exam")}</Button>
        </Stack>
      </CardContent>
    </Card>
  );
}
