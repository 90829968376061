import { UserResourceProgress } from "src/types/User";

export default function isUserResourceProgressGuard(
  obj: unknown
): obj is UserResourceProgress {
  if (!obj) return false;

  const resourceProgress = obj as UserResourceProgress;
  return (
    typeof resourceProgress === "object" &&
    typeof resourceProgress.userId === "string" &&
    typeof resourceProgress.resourceId === "string" &&
    typeof resourceProgress.progressSeconds === "number" &&
    typeof resourceProgress.progressFraction === "number"
  );
}
