enum EducationalAttainment {
  none = "No Schooling Completed",
  some_high_school = "Some High School",
  high_school = "High School Diploma",
  ged = "High School Equivalence",
  some_college = "Some College",
  associate = "Associate's Degree",
  bachelor = "Bachelor's Degree",
  graduate = "Graduate Degree",
}

export default EducationalAttainment;
