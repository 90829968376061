import { Timestamp } from "firebase/firestore";

export enum TrainingType {
  CDL_A = "CDL Class A",
  CDL_B = "CDL Class B",
}

export enum CohortTrainingStatus {
  CANCELLED = "cancelled",
  PENDING = "pending",
  ACTIVE = "active",
  COMPLETED = "completed",
}

export enum CohortType {
  PRE_RELEASE = "pre-release",
  POST_RELEASE = "post-release",
}

export type Cohort = {
  uid: string;
  name: string;
  startDate: string;
  startDateFirestoreTimestamp: Timestamp;
  trainingType: TrainingType;
  status: CohortTrainingStatus;
  cohortType: CohortType;
  organizationId: string;
};
