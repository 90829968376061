enum Race {
  AfricanAmerican = "African American/Black",
  NativeAmeriacn = "American Indian/Alaskan Native",
  Asian = "Asian",
  Hawaaian = "Hawaiian/Other Pacific Islander",
  White = "White",
  Other = "Other",
}

export default Race;
