import { collection, getDocs } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";
import { UserResourceProgress } from "src/types/User";
import isUserResourceProgressGuard from "src/utils/isUserResourceProgressGuard";
import cleanFirestoreDoc from "./cleanFirestoreDoc";

export default async function fetchResourceProgress(
  clientId: string,
  { db }: Clients
): Promise<UserResourceProgress[]> {
  const snapshot = await getDocs(
    collection(db, "users", clientId, "resources")
  );

  const progress = snapshot.docs
    .map(cleanFirestoreDoc)
    .filter(isUserResourceProgressGuard);

  return progress as unknown as UserResourceProgress[];
}
