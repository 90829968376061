import LockIcon from "@mui/icons-material/Lock";
import { Button, ButtonProps, Tooltip } from "@mui/material";
import React from "react";
import { useSessionAccountInformation } from "src/SessionBoundary";
import { UserType } from "src/types/User";

type AdminOnlyButtonProps = ButtonProps & {
  children: React.ReactNode;
};

const AdminOnlyButton: React.FC<AdminOnlyButtonProps> = ({
  children,
  ...rest
}) => {
  const account = useSessionAccountInformation();

  if (account.type !== UserType.Admin) {
    return <div />;
  }

  return (
    <Tooltip title="This button is only visible to Emerge admins">
      <Button
        {...rest}
        startIcon={<LockIcon />}
        style={{ position: "relative" }}
      >
        {children}
      </Button>
    </Tooltip>
  );
};

export default AdminOnlyButton;
