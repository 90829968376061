import { subDays } from "date-fns";
import { collection, getDocs, query, where } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";
import { Note } from "src/types/Note";
import isNoteGuard from "src/utils/isNoteGuard";
import cleanFirestoreDoc from "./cleanFirestoreDoc";

export default async function fetchNotes(
  clientId: string,
  { db }: Clients,
  timeHorizonDays?: number
): Promise<Note[]> {
  const snapshot = timeHorizonDays
    ? await getDocs(
        query(
          collection(db, "users", clientId, "notes"),
          where(
            "createdAtFirestoreTimestamp",
            ">",
            subDays(new Date(), timeHorizonDays)
          )
        )
      )
    : await getDocs(query(collection(db, "users", clientId, "notes")));

  const notes = snapshot.docs.map(cleanFirestoreDoc).filter(isNoteGuard);
  return notes;
}
