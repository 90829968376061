import { BackgroundFormData } from "src/pages/BackgroundSurveyScreen/BackgroundSurveyView";

export default function isBackgroundGuard(
  obj: unknown
): obj is BackgroundFormData {
  if (!obj) return false;
  const data = obj as BackgroundFormData;

  //   TODO: expand this -- can be challenging to make it backwards compatible
  return !!data;
  // return (
  //   typeof data.hasProficientEnglish === "boolean" &&
  //   typeof data.hasBeenIncarcerated === "boolean" &&
  //   typeof data.hasDriverLicense === "boolean" &&
  //   Array.isArray(data.race) &&
  //   data.race.every((r) => Object.keys(Race).includes(r))
  // );
}
