import { Timestamp } from "firebase/firestore";

interface SerializedDate {
  [key: string]: string | Timestamp;
}

export default function generateFirestoreTimestamps(
  date: Date | undefined,
  keyName: string
): SerializedDate {
  if (!date) return {};
  const dateString = date.toISOString();
  const firestoreTimestamp: Timestamp = Timestamp.fromDate(date);
  return {
    [keyName]: dateString,
    [`${keyName}FirestoreTimestamp`]: firestoreTimestamp,
  };
}
