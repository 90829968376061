import { CallOutlined } from "@mui/icons-material";
import { ButtonProps } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppContext } from "src/contexts/AppContext";
import fetchReferences from "src/firebase/fetchReferences";
import { useAdmin } from "src/SessionBoundary";
import { StudentReference } from "src/types/StudentReference";
import { UserAccount } from "src/types/User";
import { getFullName } from "src/utils";
import formatNumberToE164 from "src/utils/formatNumberToE164";
import AdminOnlyButton from "./AdminOnlyButton";

type SpecialFields = {
  id: string;
  reference1Id?: string;
  reference1Name?: string;
  reference1Phone?: string;
  reference2Id?: string;
  reference2Name?: string;
  reference2Phone?: string;
  reference3Id?: string;
  reference3Name?: string;
  reference3Phone?: string;
  callerId: string;
  callerName: string;
  source: "emerge_application";
};

export type UserRequiredCallingFields = Pick<
  UserAccount,
  "uid" | "firstName" | "lastName" | "phone"
>;

type Props = {
  users: UserRequiredCallingFields[];
} & ButtonProps;

const generateCSV = (data: (UserRequiredCallingFields & SpecialFields)[]) => {
  // Collect all keys from the user object plus special fields
  const header = Array.from(
    data.reduce<Set<string>>(
      (keys, user) => new Set([...keys, ...Object.keys(user)]),
      new Set<string>([
        "id",
        "reference1Id",
        "reference1Name",
        "reference1Phone",
        "reference2Id",
        "reference2Name",
        "reference2Phone",
        "reference3Id",
        "reference3Name",
        "reference3Phone",
        "callerId",
        "callerName",
        "source",
      ])
    )
  );

  const rows = data.map((user) =>
    header
      .map(
        (key) =>
          user[key as keyof (UserRequiredCallingFields & SpecialFields)] || ""
      )
      .join(",")
  );

  return [header.join(","), ...rows].join("\n");
};

const downloadCSV = (csvContent: string) => {
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `elto_export_${new Date().toISOString()}.csv`;
  link.click();
};

export const ExportForCallingButton = ({ users, ...rest }: Props) => {
  const { clients } = useAppContext();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const admin = useAdmin();

  const exportToCSV = async () => {
    setLoading(true);

    const allUserData: (UserRequiredCallingFields & SpecialFields)[] =
      await Promise.all(
        users.map(async (user) => {
          const references: StudentReference[] = await fetchReferences(
            user.uid,
            clients
          );

          // Include special fields with specific logic
          const specialFields: SpecialFields = {
            id: user.uid,
            reference1Id: references[0]?.uid,
            reference1Name: references[0] ? getFullName(references[0]) : "",
            reference1Phone: formatNumberToE164(references[0]?.phone) || "",
            reference2Id: references[1]?.uid,
            reference2Name: references[1] ? getFullName(references[1]) : "",
            reference2Phone: formatNumberToE164(references[1]?.phone) || "",
            reference3Id: references[2]?.uid,
            reference3Name: references[2] ? getFullName(references[2]) : "",
            reference3Phone: formatNumberToE164(references[2]?.phone) || "",
            callerId: admin.uid,
            callerName: getFullName(admin),
            source: "emerge_application" as const,
          };

          const sanitizeValues = <T extends object>(obj: T): T =>
            Object.fromEntries(
              Object.entries(obj)
                .filter(([, value]) => typeof value !== "object")
                .map(([key, value]) => [key, value ?? ""]) // Convert undefined or null to an empty string
            ) as T;
          // Merge the special fields with the user object fields
          return {
            ...sanitizeValues<UserRequiredCallingFields>(user), // Spread the user object to include all its fields
            ...specialFields, // Include the special fields with specific logic
          };
        })
      );

    const csvContent = generateCSV(allUserData);
    downloadCSV(csvContent);
    setLoading(false);
  };

  return (
    <AdminOnlyButton
      variant="contained"
      color="primary"
      onClick={exportToCSV}
      disabled={loading}
      startIcon={<CallOutlined />}
      {...rest}
    >
      {t("Export for Elto Calling")}
    </AdminOnlyButton>
  );
};

export default ExportForCallingButton;
