import { collectionGroup, getDocs } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";
import { Cohort } from "src/types/Cohort";
import isCohortGuard from "src/utils/isCohortGuard";
import sortBy from "src/utils/sortBy";
import cleanFirestoreDoc from "./cleanFirestoreDoc";

export default async function fetchCohorts({ db }: Clients): Promise<Cohort[]> {
  const cohorts = (await getDocs(collectionGroup(db, "cohorts"))).docs
    .map(cleanFirestoreDoc)
    .filter(isCohortGuard);

  return sortBy(cohorts, "name");
}
