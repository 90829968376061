import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { Box, LinearProgress, Stack, Tab, useTheme } from "@mui/material";
import { useLiveQuery } from "dexie-react-hooks";
import { useState } from "react";
import { db } from "src/api/db";
import ResourceLink from "src/components/Card/TopicCard/ResourceLink";
import { PaddedLayout } from "src/components/Layout";
import { ScreenTitle } from "src/components/Typography";
import useStorageStats from "src/hooks/useStorageStats";

const BYTES_IN_GB = 1073741824;
const BYTES_IN_MB = 1048576;

function StorageStat(): JSX.Element {
  const { quota, usage, usagePct } = useStorageStats();

  if (!quota || !usage || !usagePct) return <div>Hi</div>;

  const usageLabel =
    usage < BYTES_IN_GB
      ? `${Math.round(usage / BYTES_IN_MB)} MB`
      : `${Math.round(usage / BYTES_IN_GB)} GB`;

  const quotaLabel = `${Math.round(quota / BYTES_IN_GB)} GB`;

  return (
    <div className="grid gap-1">
      <p className="text-sm">Storage used</p>
      <div className="flex items-center gap-4">
        <p className="text-lg font-medium">
          {usageLabel} of {quotaLabel}
        </p>
        <Box width={16}>
          <LinearProgress
            value={usagePct}
            variant="determinate"
            className="h-32"
          />
        </Box>
      </div>
    </div>
  );
}

type TTab = "Videos/Articles" | "Books";

export default function SavedTab() {
  const resources = useLiveQuery(() => db.resources.toArray()) || [];
  const [tab, setTab] = useState<TTab>("Videos/Articles");
  const theme = useTheme();

  return (
    <div className="">
      <PaddedLayout>
        <Stack spacing={theme.spacing(2)}>
          <ScreenTitle>Saved Content</ScreenTitle>
          <span className="font-gray-500">
            This content is available offline.
          </span>
          <StorageStat />
        </Stack>
      </PaddedLayout>

      <TabContext value={tab}>
        <PaddedLayout>
          <TabList
            onChange={(_, value) => setTab(value)}
            aria-label="Download Options"
            scrollButtons="auto"
          >
            <Tab label="Videos/Articles" value="Videos/Articles" />
            <Tab label="Books" value="Books" />
          </TabList>
          {tab === "Videos/Articles" &&
            resources.map((resource) => (
              <Box
                sx={{
                  background: theme.palette.common.white,
                  paddingY: theme.spacing(2),
                  borderBottom: 1,
                  borderColor: theme.palette.grey[200],
                }}
                key={resource.id}
              >
                <ResourceLink resource={resource} />
              </Box>
            ))}
        </PaddedLayout>
      </TabContext>
    </div>
  );
}
