import { UserUploadedDocument } from "./User";

export default function isUserDocumentGuard(
  obj: unknown
): obj is UserUploadedDocument {
  if (!obj || typeof obj !== "object") {
    return false;
  }

  const userDocument = obj as UserUploadedDocument;

  return (
    typeof userDocument.path === "string" &&
    typeof userDocument.uid === "string" &&
    typeof userDocument.userId === "string" &&
    typeof userDocument.name === "string"
  );
}
