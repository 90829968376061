import { Box } from "@mui/material";
import React from "react";

type Props = {
  children: React.ReactNode;
};

const ModalContainer = ({ children }: Props) => (
  <Box
    sx={{
      top: "50%",
      left: "50%",
      width: 500,
      maxWidth: "100vw",
      position: "absolute",
      transform: "translate(-50%, -50%)",
      maxHeight: "80vh",
      overflowY: "auto",
    }}
  >
    {children}
  </Box>
);

export default ModalContainer;
