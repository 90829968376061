import { useEffect, useState } from "react";
import { useAppContext } from "src/contexts/AppContext";
import fetchNotes from "src/firebase/fetchNotes";
import { Note } from "src/types/Note";
import { UserAccount } from "src/types/User";
import batchUserRequest from "src/utils/batchUserRequest"; // Import the batch request utility
import useErrorHandler from "src/utils/useErrorHandler";

type CategorizedNotes = {
  allNotes: Note[];
  pinnedNotes: Note[];
  callNotes: Note[];
  lastCall?: Note;
};

export default function useUsersNotesById(users: UserAccount[]) {
  const [notesById, setNotesById] = useState<{
    [id: string]: CategorizedNotes | undefined;
  }>({});
  const { clients } = useAppContext();
  const errorHandler = useErrorHandler();

  useEffect(() => {
    if (!users) return;

    const fetchAllUserNotes = async () => {
      // Use the batchUserRequest utility to fetch notes for each user
      const notesData = await batchUserRequest(users, async (user) => {
        // Fetch notes for a single user
        const notes = await fetchNotes(user.uid, clients);
        const pinnedNotes = notes.filter((note) => note.isPinned);
        const callNotes = notes.filter((note) => note.type === "Call");
        const lastCall = callNotes.reduce(
          (latest, note) =>
            latest && new Date(latest.createdAt) > new Date(note.createdAt)
              ? latest
              : note,
          undefined as Note | undefined
        );
        return {
          allNotes: notes,
          pinnedNotes,
          callNotes,
          lastCall,
        };
      });

      setNotesById(notesData);
    };

    fetchAllUserNotes().catch(errorHandler);
  }, [users, clients, errorHandler]);

  return notesById;
}
