import { collection, getDocs } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";
import { StudentPlanMilestone } from "src/types/StudentPlan";
import isStudentPlanMilestoneGuard from "src/utils/isStudentPlanMilestoneGuard";
import cleanFirestoreDoc from "./cleanFirestoreDoc";

export default async function fetchStudentPlanMilestones(
  userId: string,
  { db }: Clients
): Promise<StudentPlanMilestone[]> {
  const milestones = (
    await getDocs(collection(db, "users", userId, "plan"))
  ).docs
    .map(cleanFirestoreDoc)
    .filter(isStudentPlanMilestoneGuard);

  return milestones.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );
}
