import { Box } from "@mui/material";
import React from "react";
import Menu from "./Menu";

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  children?: React.ReactNode | React.ReactNode[];
}

const Template = ({ children }: Props) => (
  <Box
    sx={{
      minHeight: "100vh",
      maxWidth: "100vw",
      background:
        "linear-gradient(339deg, #F7F1EA 36.57%, #FDFCFA 72.98%, #FBFBFB 86.11%)",
    }}
    flexDirection="column"
    display="flex"
  >
    <Menu />
    {children}
  </Box>
);

export default Template;
