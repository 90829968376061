import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { CardSkeleton } from "src/components/Skeleton/closet";
import { useAppContext } from "src/contexts/AppContext";
import { Cohort } from "src/types/Cohort";
import { CohortAnnouncement } from "src/types/CohortAnnouncement";
import fetchAllCohortAnnouncements from "src/utils/fetchAllCohortAnnouncements";
import useErrorHandler from "src/utils/useErrorHandler";
import CohortAnnouncementCard from "./CohortAnnouncementCard";

type Props = { cohort: Pick<Cohort, "organizationId" | "uid"> };

function DashboardCohortAnnouncements({ cohort }: Props) {
  const [cohortAnnouncements, setCohortAnnoucements] =
    useState<CohortAnnouncement[]>();
  const { clients } = useAppContext();
  const errorHandler = useErrorHandler();

  useEffect(() => {
    if (!cohort) return;
    fetchAllCohortAnnouncements({ cohort, onlyActive: true }, clients)
      .then(setCohortAnnoucements)
      .catch(errorHandler);
  }, [cohort, clients, errorHandler]);

  if (!cohort) return <div />;
  if (!cohortAnnouncements) return <CardSkeleton />;

  return (
    <Grid container>
      {cohortAnnouncements.map((announcement) => (
        <Grid item xs={4} id={announcement.uid}>
          <CohortAnnouncementCard announcement={announcement} />
        </Grid>
      ))}
    </Grid>
  );
}

export default DashboardCohortAnnouncements;
