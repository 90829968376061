import MilestoneType from "src/types/MilestoneType.enum";
import { StudentPlanMilestone } from "../types/StudentPlan";

export default function isStudentPlanMilestoneGuard(
  obj: unknown
): obj is StudentPlanMilestone {
  if (!obj) return false;
  const milestone = obj as StudentPlanMilestone;

  return (
    typeof milestone.uid === "string" &&
    typeof milestone.createdAt === "string" &&
    typeof milestone.lastUpdatedAt === "string" &&
    typeof milestone.title === "string" &&
    typeof milestone.date === "string" &&
    (typeof milestone.completedAt === "string" ||
      milestone.completedAt === null) &&
    Object.values(MilestoneType).includes(milestone.type)
  );
}
