import { Timestamp } from "firebase/firestore";
import { TrainingType } from "src/types/Cohort";
import { School } from "src/types/School";
import USState from "src/types/states";

export default function isSchoolGuard(obj: unknown): obj is School {
  const school = obj as School;
  return (
    school &&
    typeof school.uid === "string" &&
    typeof school.name === "string" &&
    typeof school.description === "string" &&
    typeof school.hours === "string" &&
    typeof school.address === "string" &&
    typeof school.email === "string" &&
    typeof school.phone === "string" &&
    typeof school.city === "string" &&
    Object.values(TrainingType).includes(school.type) &&
    Object.values(USState).includes(school.state) &&
    typeof school.createdAt === "string" &&
    typeof school.lastUpdatedAt === "string" &&
    school.lastUpdatedAtFirestoreTimestamp instanceof Timestamp &&
    school.createdAtFirestoreTimestamp instanceof Timestamp
  );
}
